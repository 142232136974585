import { Navigate, Route } from "react-router-dom";
import Home from "../components/persian/home/home";
// import BlogOut from "../components/screens/blog/blogOut";
// import BusinessOut from "./outlets/businessOut";
// import blogRoutes from "./blogRoutes";
// import businessRoutes from "./businessRoutes";
import StoreOut from "./outlets/storeOut";
import storeRoutes from "./storeRoutes";
import DashboardOut from "../components/screens/dashboard/dashboardOut";
import DashRoutes from "./dashRoutes";
import { useContext } from "react";
import { CartContext } from "../components/contexts/cartContext";
// import AccountActivation from "../components/emailVerify/accountActivation";
// import PassRecover2 from "../components/screens/login/passRecover2";
// import jobsRoutes from "./jobsRoutes";
// import JobsOut from "./outlets/jobsOut";
// import mediaRoutes from "./mediaRoutes";
// import MediaOut from "./outlets/MediaOut";
import PromptOut from "./outlets/promptOut";
// import promptRoutes from "./promptRoutes";
// import eventRoutes from "./eventRoutes";
// import EventOut from "./outlets/eventOut";
// import demyRoutes from "./demyRoutes";
import DemyOut from "./outlets/demyOut";
// import MarketPlaceOut from "./outlets/MarketPlaceOut";
// import MarketPlaceRoutes from "./MarketPlaceRoutes";
import demyRoutesEn from "./demyRoutesEn";
import promptRoutesEn from "./promptRoutesEn";
import AccountActivationEn from "../components/emailVerify/accountActivationEn";
import PassRecoverEn from "../components/screens/login/passRecoverEn";
// import MarketPlaceRoutes from "./MarketPlaceRoutes";


export default function EnRoutes() {
  const lang = window.location.pathname.split("/")[1];  
  const { user } = useContext(CartContext); 
  return (
    <>
      {/* <Route path="" element={<Home />} /> */}
      <Route path="" element={<Navigate replace to={`/${lang}/demy`} />} />
      <Route
        path="register/activate/:token/:id"
        element={<AccountActivationEn />}
      />
      <Route path="passRecover/:token/:id" element={<PassRecoverEn />} />
      <Route path="prompt" element={<PromptOut />}>
      {promptRoutesEn()}
      </Route>
      {/* <Route path="blog" element={<BlogOut />}>
        {blogRoutes()}
      </Route> */}
      {/* <Route path="business" element={<BusinessOut />}>
        {businessRoutes()}
      </Route> */}
      {/* <Route path="mp" element={<MarketPlaceOut />}>
        {MarketPlaceRoutes()}
      </Route> */}
      {/* <Route path="event"element={user?.roles?.includes(1000) ? <EventOut />: <Navigate replace to={`/${lang}`} />}>
        {eventRoutes()}
      </Route> */}
      
      <Route path="demy"element={<DemyOut />}>
        {demyRoutesEn()}
      </Route>      
      {/* <Route path="jobs" element={<JobsOut />}>
        {jobsRoutes()}
      </Route> */}
      {/* <Route path="media" element={<MediaOut />}>
        {mediaRoutes()}
      </Route> */}
      <Route path="store" element={<StoreOut />}>
        {storeRoutes()}
      </Route>
      <Route
        path="dashboard"
        element={user?.roles?.includes(9999) ? <DashboardOut />: <Navigate replace to={`/${lang}`} />}>        
        {DashRoutes()}

      </Route>
    </>
  );
}
