import { Component } from "react";
import { CartContext } from "../../contexts/cartContext";
import ItemCard from "./itemCard";
import { Link } from "react-router-dom";
import renderData from "../../../utils/renderData";
import MyIcons from "../../golbal/icons/myIcons";
import tools from "../../../utils/tools";
// import apiStore from '../../../utils/apiStore';

class CartSidebar extends Component {
  constructor(props) {
    super(props);
  }
  static contextType = CartContext;

  state = {
    loading: false,
    countryDetails : JSON.parse(localStorage?.getItem("countryDetails")) ?? {}
  };

  handleLinkClick = () => {
    console.log("handleLinkClick");
    const offcanvas = document.getElementById("offcanvasWithBothOptions");
    if (offcanvas) {
      offcanvas.classList.remove("show");
    }
  };

  cartValue = ({ productItems, serviceItems }) => {
    let price = 0;
    productItems.forEach((x) => {
      price += Math.floor(x.price * x.orderCount * 100) / 100;
    });
    // console.log(price);
    serviceItems?.forEach((data) => (price += data?.price));
    return price;
  };

  render() {
    const {
      productItems,
      serviceItems,
      countItems,
      user,
      increment,
      serviceIncrement,
      decrementItem,
      serviceDecrement,
    } = this.context;
    const trans  = {
      quickView:{fa:"خریدهای من در یک نگاه کیف پول ",en:"My cart Items"},
      tutorsList:{fa:"لیست اساتید",en:"Tutors list"},
      emptyBasket:{fa:"سبد خرید شما خالیه!",en:"Your cart is empty!"},
      wallet:{fa:"کیف پول ",en:"Wallet"},
      total:{fa:"جمع",en:"Total"},
      item:{fa:"آیتم",en:"Item(s)"},
      services:{fa:"  : سرویس های ویژه ",en:" services :"},
      cart:{fa:"سبد خرید",en:"Cart"},
      continue:{fa:"تائید و ادامه",en:"Continue"},
      it:{fa:"آیتم",en:"Item(s)"},
  }
    return (
      <div
        className=" offcanvas offcanvas-cart offcanvas-start"
        data-bs-scroll="true"
        tabIndex="-1"
        id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel"
      >
        <div className="offcanvas-header offcanvas-countryLanguageTitle">
          <p className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
            {trans.quickView[window.lang]} 
          </p>
          {/* <span
                    // style={{ color: "white", fontSize: "10px" }}
                    title="Wallet"
                  >
                  کیف پول { user?.wallet?.toLocaleString('us',{ style: 'currency',  currency:'CAD', })}
          </span> */}
          <div className="d-flex" title="Wallet Balance">
                        {/* <i className="bi bi-cash font-16 pe-1 "></i>    */}
                        <span>{trans.wallet[window.lang]}:</span>                     
                         <span>
                        <strong>
                        {tools.showPrice(
                            user?.wallet * this.props. countryDetails?.exRate,
                            this.props.countryDetails?.currency
                          )}
                          </strong> 
                         </span>
                         
                        </div>

          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body  ">
          {!productItems?.length && !serviceItems?.length ? (
            <div className="m-3 mt-0 px-3 empty-cart  ">
              <div className="d-flex align-items-center  justify-content-center">
                <img src="/img/empty-cart-2.svg" alt="empty-cart" loading="lazy"/>
              </div>
              <div className=" d-flex flex-column   align-items-center  justify-content-center px-0">
                <p>{trans.emptyBasket[window.lang]}</p>
                {/* <Link
                  className="btn primary-btn btn-404 checkout-btn cart-footer mb-5 mt-5"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  to={`/${window.lang}/store/plans`}
                >
                  <button
                    type="button"
                    className="icon-btn"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    برو به صفحه پلن ها
                  </button>
                </Link> */}
                <Link
                  className="btn primary-btn btn-404 checkout-btn cart-footer mb-5 mt-5"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  to={`/${window.lang}/demy/tutor/filters`}
                >
                  <button
                    type="button"
                    className="icon-btn"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    {trans.tutorsList[window.lang]}
                  </button>
                </Link>
              </div>
            </div>
          ) : (
            <div>
              {productItems.map((value, i) => {
                return (
                  <ItemCard
                    key={i}
                    product={value}
                    decrementItem={decrementItem}
                    increment={increment}
                    lang={window.lang}
                  />
                );
              })}

              {serviceItems?.length > 0 && trans.services[window.lang]}
              {serviceItems?.map((item, i) => (
                <ServicesRender
                  key={i}
                  data={item}
                  serviceDecrement={serviceDecrement}
                  increment={increment}
                  lang={window.lang}
                  countryDetails={this.props.countryDetails}
                />
              ))}
            </div>
          )}
        </div>

        {(productItems?.length || serviceItems?.length) && (
          <div className="offcanvas-footer">
            <div className="select-total">
              <span className="px-3"> = [ {trans.total[window.lang]} {tools.dynamicNumber({number:countItems(),lang:window.lang}) } {trans.item[window.lang]} ] </span>
              <span dir="ltr" className="px-3">
                <h5>
                  
                  {
                    tools.showPrice(this.cartValue({
                      productItems,
                      serviceItems,
                    }),serviceItems[0]?.currency ||
                    this.props.countryDetails.currency,)
      
                }
                </h5>
              </span>
            </div>

            <div className="select-button">
              {user?.roles?.includes(9999) ? (
                <div>
                  <Link className="p-0" to={`/${window.lang}/store/cart`} onClick={e=>window.scrollTo(0,0)}>
                  
                    <div
                      // type="button"
                      className="btn primary-btn view-cart cart-footer"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      {trans.cart[window.lang]}
                    
                    </div>
                  </Link>

                  <Link className=" p-0" to={`/${window.lang}/store/cart`} onClick={e=>window.scrollTo(0,0)}>
                    
                    <div
                      // type="button"
                      className=" btn primary-btn checkout-btn cart-footer"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      {trans.continue[window.lang]}                     
                    </div>
                  </Link>
                </div>
              ) : (
                <Link
                  className=" btn primary-btn checkout-btn cart-footer"
                  data-bs-toggle="modal"
                  to="#exampleModalToggle"
                  role="button"
                >
                  {trans.continue[window.lang]} 
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function ServicesRender({ data, serviceDecrement, lang, countryDetails }) {
  const planName = renderData.plansName.find(
    (planN) => planN.name === data.type
  )[lang];

  return (
    <div key={data._id} className="select-items">
      <table>
        <tbody>
          <tr>
            <td className="si-pic">
              <img src={data.img} alt={data.title} loading="lazy"/>
            </td>
            <td className="si-text w-100">
              <div className="product-selected text-start ps-3">
                {planName}
                <div className="small-gray-font"> {data.title} </div>
                {/* {data.type} | <span className='small-gray-font'> {data.description} </span> */}
                {/* <h6 className="text-center">{Object.keys(data)[0]}</h6> */}
                <div className="text-end pe-3" dir="ltr">
                  <div>
                    <div key={data._id} className="d-flex justify-content-space-between align-items-center">
                      <span className="small-gray-font">
                        {
                        // (data.targetIdentifier==="SessionT"?" ساعت":" انتخاب")+ " "+
                        tools.dynamicNumber({number:data.count,lang:window.lang})+" x "+
                        tools.dynamicNumber({number:data.price/data.count,lang:window.lang})+
                        " = " 
                        }
                      </span>
                      {tools.showPrice(data.price,data.currency)}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td
              className="si-close"
              onClick={(e) => {
                if (
                  window.confirm(
                    `Remove ${planName+" ،"+data.title} from your cart?`
                  ) !== true
                )
                  return;
                serviceDecrement({ _id: data._id, type: data.type });
              }}
            >
              <MyIcons icon="delete2" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CartSidebar;
