import { useContext, useRef } from "react";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";

export default function TutoringRulesEn() {
  const { user } = useContext(CartContext);
  const messageRef = useRef(null);
  return (
    <div className="mx-md-5">
      <ToastNote messageRef={messageRef} />
      <h1 className="titr7 text-red-dark mt-5 mb-3 text-start my-5">
        Rules and regulations
      </h1>
      <div id="introduction">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          Introduction
        </h5>
        <div className="top-margin justify terms__content">
          Metamarce as a platform that only provides the possibility of training
          and booking classes online and in person with a set of tutors.
          Metamarce users can hold their classes with active tutors by reserving
          their desired time, the rules of this section (metademy) are between
          Metamarce as a service provider and Metamarce users (user account
          holders, consisting of students and tutors). At any time, Metamarce
          may make changes to the rules, which will be notified to its users and
          its contents will always be visible in this section.
        </div>
      </div>
      <div id="onus">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          User Obligations
        </h5>
        <div className="top-margin justify terms__content">
          At the time of registration, the user is required to enter his
          personal information, including first and last name, email address or
          mobile number correctly, in case of any discrepancy, the user will be
          responsible for it. If any user intends to cause any disruption or
          disturbance in any way, Metamarce can delete his account without prior
          notice.
        </div>
      </div>
      <div id="trialclassName">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          Trial classes
        </h5>
        <div className="top-margin justify terms__content">
          Trial classes are often to get to know the tutor better and check the
          suitability of the student's needs with the teacher's capabilities,
          the tutor is responsible for identifying the course of this class and
          the course of this class is taken in agreement with the student.
        </div>
      </div>
      <div id="nonTrialclassName">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          Choosing a teacher and holding training classes (non-experimental)
        </h5>
        <div className="top-margin justify terms__content">
          After booking a class with the desired teacher, the user is obliged to
          attend the class or, if he wishes to change, move or cancel his class
          according to the regulations of the platform, otherwise, if he does
          not attend the class, the desired class will be considered held. .
          Metamarce, on the other hand, regularly and regularly according to its
          developed algorithms, checks the quality of education and the
          completeness of tutors' profiles and is trying to make it easier for
          users to choose the best tutors, but this does not mean that it
          guarantees the quality of holding tutors' classes with It is
          Metamarce. Therefore, it is strongly recommended that the student
          carefully check the tutor's profile (watch the tutor's introduction
          video, read the comments of previous students, etc.) and then book a
          trial class with the desired tutor. It is the responsibility of the
          student to choose the right teacher.
        </div>
      </div>
      <div id="financial">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          Financial regulations
        </h5>
        <div className="top-margin justify terms__content"></div>
        <div className="terms__child-title-box">
          <div id="share">
            <div className="text-pink-orange-dark my-3 ms-md-5 ms-2">
              <i className="bi bi-circle-fill font-10 me-1"></i>
              Metamarce potion
            </div>
            <div className="top-margin justify terms__content">
              <div className="flex full-width center-aligned"></div>Metamarce
              collects 20% of the class amount as commission and payment of
              international financial transfers, support and also in order to
              provide the possibility of infrastructure development for more
              facilities and better user experience. Cancellations will be
              directly credited to the teacher's or student's account without
              any changes.
            </div>
          </div>
          <div id="time">
            <div className="text-pink-orange-dark my-3 ms-md-5 ms-2">
              <i className="bi bi-circle-fill font-10 me-1"></i>
              Settlement time
            </div>
            <div className="top-margin justify terms__content">
              <div className="flex full-width center-aligned"></div>The net
              performance of the tutors is calculated according to the months of
              the calendar year and paid to the respected tutors in the first
              week of the following month.
            </div>
          </div>

          <div id="cancel">
            <div className="text-pink-orange-dark my-3 ms-md-5 ms-2">
              <i className="bi bi-circle-fill font-10 me-1"></i>
              Class cancellation rules
            </div>
            <div className="top-margin justify terms__content">
              <div className="flex full-width center-aligned">
                <div className="row">
                  <div className="col-4"></div>
                  <div className="col-8">
                    <div className="row">
                      <div className="col-5"></div>
                      <div className="col-7"></div>
                    </div>
                  </div>
                </div>
                <table className="mb-3">
                  <thead>
                    <tr className="bold">
                      <th>Time left until class starts </th>
                      <th className="center-aligned">
                        {" "}
                        Cancellation by the teacher{" "}
                      </th>
                      <th className="center-aligned">
                        {" "}
                        Cancellation by the student
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>More than 24 hours </td>
                      <td className="center-aligned">No penalty </td>
                      <td className="center-aligned"> Full refund</td>
                    </tr>
                    <tr>
                      <td>Less than 24 hours </td>
                      <td className="center-aligned"> * </td>
                      <td className="center-aligned">
                        100% refundable by the tutor's decision
                      </td>
                    </tr>
                    <tr>
                      <td>Failure to attend class </td>
                      <td className="center-aligned"> * *</td>
                      <td className="center-aligned">
                        100% refundable by the tutor's decision
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                - If the student cancels more than 24 hours before the start of
                the class, Metamarce will return the entire amount to the
                student's account. However, if the cancellation is made less
                than 24 hours before the start of the class, or the student does
                not attend the class, the decision will be the responsibility of
                the tutor. In this way, after the end of the class time, the
                option of refund or non-refund will be activated for the teacher
                and the teacher's decision will be applied automatically. In
                this case, the contribution will not be considered as a penalty
                or cancellation fee for Metamarce. However, depending on the
                type of transaction and the money transfer fees that will be
                imposed by the bank, PayPal or other third parties to return the
                money, it may be deducted from the account that caused the
                cancellation.
              </p>
              <div>
                💡 In Metamarce, tutors will receive thirty dollars credit as a
                welcome gift at the time of registration. You can see your gift
                credit balance
                <span
                  onClick={(e) => {
                    if (!window.logined(9999)) return;
                    if (!user?.roles?.includes(1505))
                      return messageRef.current.showError({
                        message:
                          "شما تاکنون پروفایل تدریس خود را کامل نکرده‌اید.",
                      });
                    window.navigateHandle(
                      `/${window.lang}/dashboard/user/demy/myTutorlist`
                    );
                  }}
                  // href={`/${window.lang}/dashboard/user/demy/myTutorlist`}

                  className="content__link bold-700 cursor"
                >
                  <strong> (here) </strong>
                </span>
              </div>
              <p>
                * Respected tutors should also note that if you do not attend
                the class, $15 will be deducted from your credit.
              </p>
              <p>
                ** Each cancellation of a class with reservation status will
                result in a deduction of $7 and each cancellation of a class
                with a confirmed status will result in a deduction of $10 from
                your credit.
              </p>

              <p>
                - To display and access the tutor's profile in the list of
                tutors, the amount of this credit must be positive, and if it
                becomes zero or negative, the tutor's name will be automatically
                removed from the list of tutors.
              </p>

              <p>
                - The balance of Metamarce gift credit can be withdrawn after
                teaching 500 hours of Learning Sessions.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="condition">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          شرایط برگزاری مناسب کلاس های آموزشی
        </h5>
        <div className="text-red-dark">
          در کلاس‌‌های آن‌لاین، استاد و دانشجو باید از اینترنت مناسب و پایدار برای کلاس
          های خود استفاده کنند و توصیه داریم همواره از لپ‌تاپ و با هدفون در
          کلاس‌ها شرکت نمایید، قطعا روشن بودن وبکم نیز باعث می‌شود کلاس
          سرزنده‌تری را تجربه نمایید. در صورتیکه قطع و وصلی های حین کلاس به دلیل
          نوسانات اینترنت دانشجو و یا استاد باشد، متامارس هیچگونه مسئولیتی در
          عودت مبلغ آن کلاس به دانشجو یا استاد ندارد. متامارس تنها ارائه
          کننده زیرساخت برگزاری کلاس های آموزشی بوده و مسئولیتی در قبال محتوا و
          مطالبی که توسط اساتید در کلاس ها ارائه می‌شود ندارد.
        </div>
      </div>
      <div id="record">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          ادامه روند کلاس ها و ضبط جلسات
        </h5>
        <div className="text-red-dark">
          در صورت انتشار آگاهانه یا غیرآگاهانه محتوای رویدادها توسط استاد و یا
          دانشجو و یا هرشخص ثالثی، متامارس هیچ‌گونه مسئولیتی را بر عهده
          نداشته و عواقب ناشی از آن تماما متوجه استاد و دانشجو خواهد بود. از
          آنجا که اساتید همانند دانشجویان برای حفظ یا حذف پروفایل خود در این
          بستر کاملا مختار می‌باشند و بدیهی است که متامارس نمی‌تواند هیچگونه
          تعهدی در قبال فعال بودن درازمدت پروفایل استاد در سایت داشته باشد و لذا
          در صورت غیرفعال شدن پروفایل استاد، دانشجویان، می توانند روند آموزش
          خود را با سایر اساتید فعال ادامه دهند.
        </div>
      </div>
      <div id="update">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          بروزرسانی و تغییرات در قوانین
        </h5>
        <div className="text-red-dark">
          پیرو تغییرات سریع به روزرسانی های متامارس میتواند بصورت مستمر نسبت به
          اصلاح و تعدیل مفاد این توافق نامه اقدام نموده و با ارائه نسخه‌های جدید
          و اصلاح شده آن در سایت، این مفاد را بروزرسانی نماید هر ماده ای از این
          توافق نامه که بنا به دلایلی به طور کلی یا جزیی بی اعتبار یا غیر قابل
          اجرا گردد تاثیری بر مفاد دیگر نخواهد داشت.
        </div>
      </div> */}
    </div>
  );
}
