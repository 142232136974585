import React, { useContext } from 'react'
import { CartContext } from '../../../contexts/cartContext'

export default function ActionBtnGenerator({session,currentStatus ,actionHandler,currentDate,access}){
   const {user}=useContext(CartContext)
   const trans = {
    completed:{fa:" برگزار شد", en:"Complete"},
    noShowedS:{fa:"غیبت دانشجو", en:"Student Absence"},
    confirmed:{fa:"تائید", en:"Confirm"},
    canceled:{fa:"کنسل", en:"Cancel"},
    refunded:{fa:"بازگشت وجه", en:"Refund"},
    notRefunded:{fa:"واریز به حساب من", en:"Transfer to my account"},
    noShowedT:{fa:"گزارش غیبت استاد", en:"Tutor Absence"},
   
  }
    const buttons =[]
    if(access==='bus'||access==='tutor'||access==='admin'){
    (currentStatus === "confirmed" ||
    currentStatus === "booked" ||
    currentStatus === "test" )&&
    (new Date(session.sessionInfo.start).getTime()+( Number(session.sessionInfo.duration)*60*60*1000)) <= currentDate && function(){              
      buttons.push(<div
        className="btn primary-btn checkout-btn w-100 my-2 p-1 font-10 max-w-200 bg-greenGray"
        onClick={(e) =>
          actionHandler({
            e,
            currentStatus: currentStatus,
            newStatus: "completed",
            session,
          })
        }
      > {trans.completed[window.lang]}       
      </div>)
    buttons.push(<div
      className="btn primary-btn checkout-btn w-100  p-1  tutor-color0 font-10 max-w-200 "
      onClick={(e) =>
        actionHandler({
          e,
          currentStatus: currentStatus,
          newStatus: "noShowedS",
          session,
        })
      }
    >  {trans.noShowedS[window.lang]}   
      
    </div>)
    }()
  
    if((currentStatus !== "canceled" &&
    currentStatus !== "refunded" &&
    currentStatus !== "notRefunded" &&
    currentStatus !== "selected" &&
    new Date(session.sessionInfo.start) >= currentDate)){
      currentStatus !== "confirmed" && buttons.push(<div
        className="btn primary-btn checkout-btn w-100 my-2 p-1 font-10 max-w-200 bg-pinkOrange"
        onClick={(e) =>
          actionHandler({
            e,
            currentStatus: currentStatus,
            newStatus: "confirmed",
            session,
          })
        }
      > {trans.confirmed[window.lang]} 
        
      </div>)
      currentStatus !== "canceled" && buttons.push(<div
        className="btn primary-btn checkout-btn w-100  p-1  tutor-color0 font-10 max-w-200"
        // className="btn primary-btn checkout-btn w-100 my-2 p-1 font-10 max-w-200"
        onClick={(e) =>
          actionHandler({
            e,
            currentStatus: currentStatus,
            newStatus: "canceled",
            session,
          })
        }
      > {trans.canceled[window.lang]}
         
      </div>)
    }
  
    if(
      (currentStatus === "canceled") &&
      session.history[0]?.user !== user?._id&&
      session.guest._id !== user?._id&&
      session.sessionInfo.rate > 0&&
      new Date(session.sessionInfo.start).getTime() -
    new Date(session.history[0].timestamp).getTime() <=
    24 * 60 * 60 * 1000 ||currentStatus === "noShowedS"){
      buttons.push(<div
        className="btn primary-btn checkout-btn w-100 my-2 p-1 font-10 max-w-200 bg-redDark"
        onClick={(e) =>
          actionHandler({
            e,
            currentStatus: currentStatus,
            newStatus: "refunded",
            session,
          })
        }
      > {trans.refunded[window.lang]}
        
      </div>)
  
      buttons.push(<div
        className="btn primary-btn checkout-btn w-100  p-1  tutor-color0 font-10 max-w-200"
        onClick={(e) =>
          actionHandler({
            e,
            currentStatus: currentStatus,
            newStatus: "notRefunded",
            session,
          })
        }
      > {trans.notRefunded[window.lang]}        
      </div>)
  
    }
  
  
  
    }

    if(access==="user"){
        if((currentStatus === "confirmed" ||
           currentStatus === "booked" ||
           currentStatus === "test" )&&
           (new Date(session.sessionInfo.start).getTime()+( Number(session.sessionInfo.duration)*60*60*1000)) <= currentDate) {      
           buttons.push(<div
             className="btn primary-btn checkout-btn w-100  p-1  tutor-color0 font-10 max-w-200 "
             onClick={(e) =>
               actionHandler({
                 e,
                 currentStatus: currentStatus,
                 newStatus: "noShowedT",
                 session,
               })
             }
           > {trans.noShowedT[window.lang]}            
           </div>)
           }
       
             if((currentStatus === "confirmed" ||
           currentStatus === "booked" ||
           currentStatus === "test" )&&
           currentStatus !== "canceled"&&
           new Date(session.sessionInfo.start) > currentDate) {              
            
           buttons.push(<div
             className="btn primary-btn checkout-btn w-100  p-1  tutor-color0 font-10 max-w-200 "
             onClick={(e) =>
               actionHandler({
                 e,
                 currentStatus: currentStatus,
                 newStatus: "canceled",
                 session,
               })
             }
           > {trans.canceled[window.lang]}               
           </div>)
           }
           
           }

  
  
  
    return(buttons)
    
  }
