import { useState, useRef, useEffect, useContext } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import api from "../../../../utils/apiDemy";
import Cluster from "../../../googleMap/googleCluster";
import renderData from "../../../../utils/renderData";
import SearchMultiSelect from "../../../golbal/dropdown/searchMultiSelectFilters";
import { CartContext } from "../../../contexts/cartContext";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import PlansX4 from "../../../golbal/sliders/plansX4";
import BusSmallCardSlider from "../../../golbal/sliders/busCardSliderS1";
import MainSidebar from "../../media/components/mainSidebar";
import tools from "../../../../utils/tools";
import MetaDecorator from "../../../../utils/metaDecorator";
import CenterCard from "../components/centerCard";
import { CircularProgress } from "@mui/material";
import BlogSearch from "../../../persian/common/pagination/searchPagination";
import countryStates from "../../../../utils/countryStates";
import NewPosts2x2 from "../../../golbal/sliders/components/newPosts2x2";

export default function CenterFilters() {
  const navigate = useNavigate();
  const section = "center";
  const page = "centerFilters";
  const { lang, state:{state,stateShort,lat,lng}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  const countryInfo = countryStates.find(coun=>coun.countryShort===countryShort.toUpperCase())
  // console.log("countryInfo", countryInfo);
  const { user } = useContext(CartContext);
  const [pubData] = useOutletContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const [view, setView] = useState({
    thumbnail: true,
    cluster: false,
  });

  const [resultsTotal, setResultsTotal] = useState(0);
  const [loading, setLoading] = useState({ spinner: false });
  const [data, setData] = useState([]);
  const initialFilters = {
    lang: window.lang,
    country,
    countryShort,
    states: [],
    state: "",
    stateShort: "",
    centerType: "university",
    cities: [],
    tag: "",
    searchKey: "",
    pageNumber: 1,
    itemsToShow: 12,
    sort: "default",
  };
  let storedFilters = JSON.parse(localStorage.getItem("storedFilters"))?.[
    section
  ];
  storedFilters = storeFilters.lang === window.lang?storeFilters:null
  const [filters, setFilters] = useState(storedFilters || initialFilters);

  const effectRan = useRef(true);
  useEffect(() => {
    if (effectRan.current) return;
    fetchData();
    storeFilters({ section, filters });
  }, [filters, window.lcs]);
  function storeFilters({ section, filters }) {
    const storedFilters =
      JSON.parse(localStorage.getItem("storedFilters")) ?? {};
    storedFilters[section] = filters;
    localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    return;
  }

  useEffect(() => {
    // const keys = Array.from(searchParams.keys())
    const params = {};
    for (let [key, value] of searchParams) {
      params[key] = value;
    }

    setFilters((pre) => ({
      ...pre,
      pageNumber: 1,
      cities: params?.cities ? [params?.cities] : pre.cities,
      searchKey: params?.searchKey ?? pre.searchKey,
    }));
    effectRan.current = false;
  }, [searchParams]);

  function queryRemover(field) {
    console.log(field);
    searchParams?.delete(field);
    const searchString = searchParams?.toString();

    navigate(`?${searchString}`);
  }

  async function fetchData() {
    setLoading((pre) => ({
      ...pre,
      spinner: true,
    }));
    const response = await api.filters({ filters });
    setLoading((pre) => ({
      ...pre,
      spinner: false,
    }));
    if (response?.error) return alert(response?.error);
    setResultsTotal(response.data.resultsTotal);
    setData(response.data.data);
  }

  async function searchHandle(e) {
    e.preventDefault();
    const value = e.target.parentElement.querySelector("input").value;

    filters.searchKey = value;
    if (e.key === "Enter" || e.type === "click") {
      setFilters((prev) => ({
        ...prev,
        searchKey: value,
        pageNumber: 1,
      }));
    }
  }
  function deleteFilter(e) {
    const field = e.currentTarget.id;
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key] = "";
              if (key === "mainCat") {
                newFilters.mainCatId = "";
                newFilters.subCat = [];
                newFilters.subCatId = [];
                queryRemover("mainCatId");
              }
              if (key === "busName") {
                newFilters.busId = "";
                queryRemover("busId");
              }
              newFilters.pageNumber = 1;
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }

    // effectRan.current = false;
    setFilters(update());
  }

  function deleteArrayFilter(e) {
    const field = e.currentTarget.id.split(",")[0];
    const index = e.currentTarget.id.split(",")[1];
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key].splice(index, 1);
              newFilters.pageNumber = 1;
              if (key === "subCat") {
                newFilters.subCatId.splice(index, 1);
                queryRemover("subCatId");
              }
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }
    setFilters(update());
  }

  const selectedFiltersRender = (() => {
    let rows = [];

    for (let key in filters) {
      if (Array.isArray(filters[key]))
        filters[key].map((option, i) => {
          // console.log(key, renderData[key], option);
          if (key !== "states" && key !== "cities") {
            const translate = renderData[key]?.find(
              (option1) => option1.name === option
            );
            // key === "languages"
            //   ? renderData[key]?.find((option1) => option1.name === option)
            //   : renderData[key]?.find((option1) => option1.name === option);
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                {/* <span>{option} </span>        */}
                <span>{translate?.[window.lang]} </span>
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }

          if (key === "cities" || key === "subCat" || key === "states") {
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{option} </span>
                {/* <span>{translate.nameFa} </span> */}
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }
        });

      !Array.isArray(filters[key]) &&
        key !== "lang" &&
        key !== "busId" &&
        key !== "country" &&
        key !== "countryShort" &&
        key !== "stateShort" &&
        key !== "centerType" &&
        key !== "state" &&
        key !== "section" &&
        key !== "sort" &&
        key !== "pageNumber:" &&
        key !== "itemsToShow" &&
        key !== "mainCatId" &&
        filters[key]?.length &&
        rows.push(
          <div className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center">
            <span>
              {key === "tag" && "# "}
              {filters[key]}
            </span>
            <span className="ms-3 me-2" id={key} onClick={deleteFilter}>
              <i className="bi bi-x-lg"></i>
            </span>
          </div>
        );
    }
    return rows;
  })();

  function setCitiesHandle(value) {
    setFilters((prev) => ({ ...prev, cities: value, pageNumber: 1 }));
    // effectRan.current = false;
  }
  async function viewMorePosts(e) {
    e.preventDefault();
    setFilters((pre) => ({
      ...pre,
      pageNumber: pre.pageNumber + 1,
    }));
  }

  const { translations } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = `لیست دانشگاه‌ها و مراکز آموزشی کانادا`;
  const structuredData = [
    ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name:
        "دانشگاه‌های کانادا ",
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/demy`,
              name: `متادمی`,
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/demy/center/filters`,
              name: `جستجو در دانشگاه های ${translations?.[window.lang]}`,
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title,
    description: `لیست دانشگاه های کانادا | اپلیکیش فی | شهریه "
    } `,
    section: "universities",
    url: `https://www.metamarce.com/${window.lang}/demy/center/filters`,
    canonical: `https://www.metamarce.com/${window.lang}/demy/center/filters`,
    img: "http://localhost:3000/img/Metademy/Metamarce-15.png",
    type: "website",
  };
  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {loading.spinner && view.thumbnail && <ColoredSpiner />}
      {/* <!-- *********************************** main ******************************************************************** --> */}

      <section className="mx-auto ads-all py-0">
        <nav className="p-md-3 p-2" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={`/${window.lang}`} className="breadcrumb-item">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/${window.lang}/demy`} className="breadcrumb-item">
                Metademy
              </Link>
            </li>
            <li className="breadcrumb-item">
              <span className="breadcrumb-item active">
                <strong>Centers</strong>
              </span>
            </li>
          </ol>
        </nav>

        <div className="row blog px-md-4 p-0 py-1" dir="ltr">
          {/* <!-- **************Right side ************** --> */}
          <div className="col-lg-9 entries  ">
            {/* <!-- ************** MAP ************** --> */}
            <a id="top"> </a>

            {/* map/card view selector */}
            <div className="col-6 d-flex justify-content-center "></div>

            {/* <!-- Pagination *******************************--> */}

            <div className="mx-1 p-0 ">
              {view.thumbnail && (
                <div>
                  {/* <!-- **************Filters  ************** --> */}

                  <div className=" d-flex flex-wrap px-3 justify-content-start my-2 border-0 ">
                    {resultsTotal !== 0 && (
                      <span className="box4 py-1 px-2 mx-2">
                        {resultsTotal} results
                      </span>
                    )}
                    <div className="blog-search mx-3">
                      <BlogSearch searchHandle={searchHandle} />
                    </div>
                    <button
                      className="btn button-filter me-md-3 me-0 ms-auto my-2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarTogglerDemo02"
                      aria-controls="navbarTogglerDemo02"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="bi bi-filter me-1"> Filters</i>
                    </button>
                    <span
                      className={`me-1 map-card my-2 d-flex justify-content-center ${
                        view.cluster && "map-card-active"
                      }`}
                      onClick={(e) =>
                        setView({ thumbnail: false, cluster: true })
                      }
                    >
                      Map <i className="bi bi-map mx-1 "></i>
                    </span>
                    <span
                      className={`me-1 map-card my-2 d-flex justify-content-center ${
                        view.thumbnail && "map-card-active"
                      }`}
                      onClick={(e) =>
                        setView({ thumbnail: true, cluster: false })
                      }
                    >
                      Cards<i className="bi bi-image mx-1"></i>
                    </span>
                    <span className="mx-2 pt-2" title="My saves">
                      <i
                        className={`bi ${
                          filters.saved ? "bi-bookmark-fill" : "bi-bookmark"
                        } icon-stack-1x mx-3 cursor`}
                        onClick={(e) => {
                          if (!window.logined(9999)) return;
                          if (user?.saved?.[section]?.length <= 0)
                            return alert(
                              "You havent any saved item."
                            );
                          setFilters((pre) => ({
                            ...initialFilters,
                            saved: !pre.saved,
                            section,
                          }));
                        }}
                      ></i>
                    </span>

                    {/* collapse */}
                    <div
                      className="navbar-collapse collapse "
                      id="navbarTogglerDemo02"
                    >
                      <div className="d-flex flex-wrap mt-3">
                        {/* <!-- Button filter 1 *******************************--> */}
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn button-filter dropdown-toggle"
                            data-bs-toggle="dropdown"
                            // id="#filter5"
                            aria-expanded="false"
                            // aria-controls="filter5"
                          >
                            <span>
                              <i className="bi bi-arrow-down-up me-1"></i>
                            </span>
                            Sort
                            {/* {filters.sort !== "default" && <CheckBox />} */}
                            <span className="ps-3">
                              <i className="bi bi-chevron-down"></i>
                            </span>
                          </button>

                          {/* <!-- Modal --> */}

                          <div className="dropdown-menu checkbox-form px-0 ">
                            <div className=" ">
                              <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                                <input
                                  className="form-check-input  mx-1"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  checked={filters.sort === "default"}
                                  onChange={(e) => {
                                    setFilters((prev) => ({
                                      ...prev,
                                      sort: "default",
                                      pageNumber: 1,
                                    }));
                                    // effectRan.current = false;
                                  }}
                                />
                                <label
                                  className="form-check-label "
                                  htmlFor="flexRadioDefault1"
                                >
                                  Default
                                </label>
                              </div>
                              <div className="form-check px-3 d-flex justify-content-between  flex-row-reverse">
                                <input
                                  className="form-check-input mx-1"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault3"
                                  // value={flexRadioDefault3}
                                  checked={filters.sort === "appFee"}
                                  onChange={(e) => {
                                    // effectRan.current = false;
                                    setFilters((prev) => ({
                                      ...prev,
                                      sort: "appFee",
                                      pageNumber: 1,
                                    }));
                                  }}
                                />
                                <span className="checkmark-radio-btn "></span>
                                <label
                                  className="form-check-label "
                                  htmlFor="flexRadioDefault3"
                                >
                                  Application Fee
                                </label>
                              </div>
                              <div className="form-check px-3 d-flex justify-content-between  flex-row-reverse">
                                <input
                                  className="form-check-input mx-1"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefaultAppFee"
                                  // value={flexRadioDefaultAppFee}
                                  checked={filters.sort === "nameB"}
                                  onChange={(e) => {
                                    // effectRan.current = false;
                                    setFilters((prev) => ({
                                      ...prev,
                                      sort: "nameB",
                                      pageNumber: 1,
                                    }));
                                  }}
                                />
                                <span className="checkmark-radio-btn "></span>
                                <label
                                  className="form-check-label "
                                  htmlFor="flexRadioDefaultAppFee"
                                >
                                  Name
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- Button State 2.5 *******************************--> */}
                        {filters.country?.length > 0 && (
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn button-filter dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>
                                <i className="bi bi-geo-alt me-1"></i>
                                Province/State
                              </span>
                              <span className="ps-3">
                                <i className="bi bi-chevron-down"></i>
                              </span>
                            </button>

                            {/* <!-- Modal --> */}

                            <div className="dropdown-menu px-0 checkbox-form">
                              <div
                                className="reset-btn w-100"
                                onClick={(e) => {
                                  setFilters((prev) => ({
                                    ...prev,
                                    states: [],
                                    state: "",
                                    stateShort: "",
                                    pageNumber: 1,
                                  }));
                                }}
                              >
                                Clear
                              </div>
                              {countryInfo.states?.map((state, i) => (
                                <div
                                  key={"states" + i}
                                  className="form-check d-flex justify-content-between flex-row-reverse px-3"
                                >
                                  <input
                                    className="form-check-input mx-3"
                                    type="checkbox"
                                    // value={state.name?.toLowerCase()}
                                    onChange={(e) => {
                                      const states = filters?.states
                                        ? [...filters.states]
                                        : [];
                                      const state1 = {
                                        stateShort: "",
                                        state: "",
                                      };
                                      const value = state?.state?.toLowerCase();
                                      console.log("value", value);
                                      const index = states.indexOf(value);
                                      if (index !== -1) {
                                        states.splice(index, 1);
                                        const contry = countryStates.find(
                                          (count) =>
                                            count.countryShort.toUpperCase() ===
                                            filters.countryShort.toUpperCase()
                                        );
                                        if (states.length === 1) {
                                          state1.stateShort = stateShort;
                                          state1.state = states[0];
                                        } else {
                                          state1.stateShort = "";
                                          state1.state = "";
                                        }
                                      } else {
                                        states.push(value);
                                        if (states.length === 1) {
                                          state1.stateShort = state.stateShort;
                                          state1.state = value;
                                        } else {
                                          state1.stateShort = "";
                                          state1.state = "";
                                        }
                                      }
                                      setFilters((pre) => ({
                                        ...pre,
                                        states,
                                        state: state1.state,
                                        stateShort: state1.stateShort,
                                      }));
                                    }}
                                    checked={filters.states?.includes(
                                      state.state.toLowerCase()
                                    )}
                                    id={`statesCheckBox${i}`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`statesCheckBox${i}`}
                                  >
                                    {state?.state}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        {/* <!-- Button City 3 *******************************--> */}
                        {filters.states.length === 1 &&
                          filters?.state?.length > 0 && (
                            <div className="dropdown">
                              <button
                                type="button"
                                className="btn button-filter dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <span>
                                  <i className="bi bi-geo-alt me-1"></i>
                                </span>
                                City
                                <span className="ps-3">
                                  <i className="bi bi-chevron-down"></i>
                                </span>
                              </button>

                              {/* <!-- Modal --> */}

                              <div className="dropdown-menu checkbox-form">
                                <SearchMultiSelect
                                  updateParent={setCitiesHandle}
                                  data={{
                                    countryShort: filters.countryShort,
                                    stateShort: filters.stateShort,
                                    cities: filters.cities,
                                  }}
                                />
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className=" d-flex flex-wrap my-2 show-filters ">
                    {/* <div
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasScrolling2"
                      aria-controls="offcanvasScrolling2"
                      className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7"
                    >
                      <span>{window.capitalize(filters?.country)} ,</span>
                      <span className="ms-3 me-2">
                        <i className="bi bi-arrow-repeat"></i>
                      </span>
                    </div> */}
                    {selectedFiltersRender}
                    {selectedFiltersRender.length > 0 && (
                      <div
                        className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7 px-3 filter-del-btn"
                       onClick={(e) => {
                    // removing all url queries
                    navigate('')
                    setFilters(initialFilters)
                  }}
                      >
                        <span>‌‌Clear all</span>
                      </div>
                    )}
                  </div>

                  {loading.spinner ? (
                    <img src="/img/searching-error.svg" alt="Searching..." />
                  ) : (
                    data?.length <= 0 && (
                      <div className="row m-5 px-md-5 px-0 text-center align-items-center ">
                        <div className="col-md-6 ">
                          <div className="titr5">No result found! </div>
                          <div className="small-gray-font">
                            Try other keywords or remove filters.
                          </div>
                          {/* <div
                            className="btn-search shadow-none m-2 d-flex  align-items-center filter-del-btn img-banner-text-7"
                            onClick={(e) =>
                              document.querySelector("#lcsChangeBtn").click()
                            }
                          >
                            دیگر استانها یا کشورها
                          </div> */}
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                          <img
                            src="/img/cart-is-empty.svg"
                            alt="cart-is-empty"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    )
                  )}

                  {/* <!-- ************** Cards************** --> */}
                  <div
                    className="row row-cols-1 
                     g-4"
                  >
                    {data?.map((job, i) => (
                      <div key={i}>
                        <CenterCard data={job} />
                      </div>
                    ))}
                  </div>
                  <div className="mt-2 d-flex justify-content-center pe-5">
                    <button
                      className="btn px-5 btn-sm shadow-none login-btn p-1 "
                      onClick={viewMorePosts}
                      disabled={resultsTotal <= data?.length || loading.spinner}
                    >
                      {loading.spinner ? (
                        <CircularProgress color="inherit" size="20px" />
                      ) : resultsTotal <= data?.length ? (
                        "End of Results "
                      ) : (
                        " more..."
                      )}
                    </button>
                  </div>
                </div>
              )}
              {view.cluster && (
                <div className="listing-all-map px-0 ads-all mx-auto">
                  <div className="d-flex">
                    <span
                      className={`me-1 map-card my-2 d-flex justify-content-center ${
                        view.cluster && "map-card-active"
                      }`}
                      onClick={(e) =>
                        setView({ thumbnail: false, cluster: true })
                      }
                    >
                      Map <i className="bi bi-map mx-1 "></i>
                    </span>
                    <span
                      className={`me-1 map-card my-2 d-flex justify-content-center ${
                        view.thumbnail && "map-card-active"
                      }`}
                      onClick={(e) =>
                        setView({ thumbnail: true, cluster: false })
                      }
                    >
                      Cards<i className="bi bi-image mx-1"></i>
                    </span>
                  </div>
                  <Cluster
                    data={data}
                    state={{lat,lng}}
                    filters={filters}
                    section={section}
                  />          
                </div>
              )}
            </div>
          </div>
          <div
            className="col-lg-3  sidebar-all align-items-center text-center  p-md-4 p-1"
            dir="rtl"
          >
            {/* <!-- ********left side *************************************************************** --> */}

            <MainSidebar section={section} />
          </div>
        </div>
        {/* <!-- *********** end of listing ******* --> */}
      </section>

      <section className="mx-md-5 pt-0 mx-0 p-0">
        <div className="divider2 mb-0">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">معرفی ویژه</span>
        </div>
        <PlansX4 plansX4={pubData.ads?.plansX4} page={page} />
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">Selected businesses</span>
        </div>
        <BusSmallCardSlider plansS1={pubData?.ads?.plansS1} page={page} />
      </section>

      {/* <!-- تازه‌های بلاگ  --> */}

      <div className=" mx-5 ">
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
          </svg>
          <span className="p-0 mb-0 ">New in blog</span>
        </div>

        <div className="row row-cols-1 row-cols-md-2 g-4 my-5 ads-all mx-auto ">
          {pubData?.newPosts?.length > 0 && (
            <NewPosts2x2 newPosts={pubData.newPosts} user={user} />
          )}
        </div>
      </div>

      {/* <!-- *********************************** end of main ******************************************************************** --> */}

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Go to the top"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>

      {/* <!-- **********end of back to top************************* --> */}
    </div>
  );
}
