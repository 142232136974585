// import ChatBox from "../golbal/chatBox/chatBox";
// import CookieNotice from "../golbal/notifications/cookieNotice";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  function navigateHandle(url) {
    window.scroll(0, 0);
    setTimeout((e) => navigate(url), 250);
  }
//    useEffect(() => autologout(), [user]);
//  const logoutHandle = async () => {
//     const response = await api.logout();
//     if (response.error) return alert(response.error);
//     console.log(response);
//     userEmptier();
//   };
  // function autologout() {
  //   if (!user.roles) return;
  //   let timer = setTimeout(() => {
  //     logoutHandle();
  //     eventsRemover();
  //   }, 48 * 60 * 60 * 1000);
  //   window.addEventListener("mousemove", resetTimer);
  //   window.addEventListener("keydown", resetTimer);
  //   function resetTimer() {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       eventsRemover();
  //       logoutHandle();
  //     }, 48 * 60 * 60 * 1000);
  //   }
  //   return () => {
  //     eventsRemover();
  //     clearTimeout(timer);
  //   };
  //   function eventsRemover() {
  //     window.removeEventListener("mousemove", resetTimer);
  //     window.removeEventListener("keydown", resetTimer);
  //   }
  // }
  return (
    <>
      {/* <CookieNotice /> */}
      {/* <ChatBox /> */}
      <div
        style={{ background: "#a99282", height: "120px", overflow: "hidden" }}
      >
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d="M-42.61,57.72 C173.53,156.41 310.10,-10.36 508.17,69.56 L500.00,0.00 L0.00,0.00 Z"
            style={{ stroke: "none", fill: "#f7f5f3 " }}
          ></path>
        </svg>
      </div>

      <footer className="myfooter text-center ">
        {/* <!-- Grid container --> */}
        <div className="container-fluid pt-1 px-0">
          <div className="row">
            <div className="col-md-4 ">
              <div
                className="footer-brand cursor"
                onClick={(e) => navigateHandle(`/`)}
              >
                Metamarce
              </div>
              {/* <!-- Section: Social media --> */}
              <div
                className="d-flex justify-content-around align-items-center mt-0  "
                id="mr"
              >
                <ul className="d-flex justify-content-center align-items-center m-0 p-2 flex-wrap w-100">
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=61555192350817&mibextid=LQQJ4d"
                      target="_blank"
                      aria-label="Explore more about metamarce on facebook"
                    >
                      <i className="bi bi-facebook icon"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/i/flow/login?redirect_after_login=%2FMetamarceCom"
                      target="_blank"
                      aria-label="Explore more about metamarce on twitter"
                    >
                      <i className="bi bi-twitter icon"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://t.me/joinchat/BfW__kawWflm6_tY_fwH8w"
                      target="_blank"
                      aria-label="Explore more about metamarce on telegram"
                    >
                      <i className="bi bi-telegram icon"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="https://www.metamarce.com"
                      aria-label="Explore more about metamarce on google"
                    >
                      <i className="bi bi-google icon"></i>
                    </a>
                  </li> */}
                  <li>
                    {/* <a
                      href="https://www.metamarce.com"
                      aria-label="Explore more about metamarce on youtube"
                    >
                      <i className="bi bi-youtube icon"></i>
                    </a> */}
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/metamarce?igsh=azJoejNtdjdydXY4&utm_source=qr"
                      target="_blank"
                      aria-label="Explore more about metamarce on instagram"
                    >
                      <i className="bi bi-instagram icon"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <img
                src="/img/landing/logo-no-bg.png"
                style={{ width: 100, height: 100 }}
                alt="metamarce logo"
                loading="lazy"
              />
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4 d-flex flex-column bd-highlight mb-md-5">
                  <div
                    onClick={(e) => navigateHandle(`/${window.lang}/demy/`)}
                    className="navbar-brand2 cursor"
                  >
                    متادمی (آکادمی متامارس)
                  </div>

                  <div
                    onClick={(e) =>
                      navigateHandle(`/${window.lang}/blog/filters`)
                    }
                    className="navbar-brand2 cursor"
                  >
                    مجله
                  </div>
                  <div
                    onClick={(e) => navigateHandle(`/${window.lang}/business`)}
                    className="navbar-brand2 cursor"
                  >
                    کسب و کار
                  </div>
                  <div
                    onClick={(e) => navigateHandle(`/${window.lang}/jobs`)}
                    className="navbar-brand2 cursor"
                  >
                    کاریابی
                  </div>
                  <div
                    onClick={(e) => navigateHandle(`/${window.lang}/media`)}
                    className="navbar-brand2 cursor"
                  >
                    مدیا دایرکتوری
                  </div>
                </div>
                <div className="col-md-4 d-flex flex-column bd-highlight mb-md-5">
                  <div
                    onClick={(e) =>
                      navigateHandle(`/${window.lang}/prompt/tutor`)
                    }
                    className="navbar-brand2 cursor"
                  >
                    راهنما و قوانین متادمی
                  </div>
                  <div
                    className="navbar-brand2 cursor"
                    onClick={(e) =>{
                      if(!window.logined(9999,"business")){
                        return window.navigateHandle(
                           `/${window.lang}/business/register`
                         );
                       };
                      navigateHandle(`/${window.lang}/dashboard/user/business/register`)}
                    }
                  >
                    افزودن کسب و کار
                  </div>
                  <div
                    onClick={(e) =>
                      navigateHandle(`/${window.lang}/store/plans`)
                    }
                    className="navbar-brand2 cursor"
                  >
                    تبلیغات
                  </div>
                  <div
                    onClick={(e) =>
                      navigateHandle(`/${window.lang}/prompt/guides`)
                    }
                    className="navbar-brand2 cursor"
                  >
                    راهنمای متامارس
                  </div>
                </div>
                <div className="col-md-4 d-flex flex-column bd-highlight mb-5">
                  <div
                    onClick={(e) =>
                      navigateHandle(`/${window.lang}/prompt/about`)
                    }
                    className="navbar-brand2 cursor"
                    // target="_blank"
                  >
                    درباره ما
                  </div>
                  <div
                    onClick={(e) =>
                      navigateHandle(`/${window.lang}/prompt/contact`)
                    }
                    className="navbar-brand2 cursor"
                    // target="_blank"
                  >
                    تماس با ما
                  </div>
                  <div
                    onClick={(e) => navigateHandle(`/${window.lang}/prompt/faq`)}
                    to={`/${window.lang}/prompt/faq`}
                    className="navbar-brand2 cursor"
                  >
                    پرسش های پر تکرار (FAQ)
                  </div>
                  <div
                    onClick={(e) =>
                      navigateHandle(`/${window.lang}/prompt/disclaimer`)
                    }
                    to={`/${window.lang}/prompt/disclaimer`}
                    className="navbar-brand2 cursor"
                    // target="_blank"
                  >
                    رفع مسئولیت
                  </div>
                  <div
                    onClick={(e) =>
                      navigateHandle(`/${window.lang}/prompt/privacy`)
                    }
                    className="navbar-brand2 cursor mb-5"
                    // target="_blank"
                  >
                    حفظ حریم شخصی
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Copyright --> */}
        <div
          dir="ltr"
          className="text-center copyright-line m-0 mt-5 p-2"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)", width: "100%" }}
        >
          {/* تمامی حقوق مادی و معنوی این وب‌سایت به متامارس تعلق دارد. */}
          <span className="mx-3" dir="ltr">
            © 2024 Copyright:
          </span>
          <span>Metamarce</span>
        </div>
        {/* <!-- Copyright --> */}
      </footer>
    </>
  );
}
