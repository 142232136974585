import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";
import { CartContext } from "../../../contexts/cartContext";
import { useContext } from "react";

export default function AffiliationEn() {
  const { user } = useContext(CartContext);
  const structuredData = [
     {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Affiliation",
      description: "Earn money without having initial capital",

      url: "https://www.metamarce.com/fa/prompt/affiliation",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "landing",
              description: "A bridge between teachers and students around the world",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/prompt/affiliation`,
              name: "Affiliation",
              description: "Affiliation, earn money without having initial capital",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "Affiliation ",
    description: "Earn money on Metamars through a dedicated referral link",
    section: "affiliation",
    url: `https://www.metamarce.com/${window.lang}/prompt/affiliation`,
    // canonical: `https://www.metamarce.com/${window.lang}/prompt/affiliation`,
    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
  };
  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="my-header m-0">
        <div className="bg-image fixed-bg-media bg-contact-us"></div>
        <div className="p-3 pb-0 px-5">
          <h1 className="mx-auto header-firstpage text-justify-metamarce titr1 font-40 text-start mt-md-5">
          Guide to earning money through Metamars referral links (Affiliation)
          </h1>
        </div>
        <img
          src="/img/homePage/wave-haikei-3.svg"
          className="p-0 pt-2 w-100 overlay-b"
          alt="homePage01"
        />
      </div>

      <div className="px-md-5 px-3 ads-all mx-auto">
        {/* <div className="row"> */}
        <div className="px-4 d-flex flex-column align-items-center justify-content-center">
          <p className="titr7"> Make money by introducing Metamarce!</p>
          <p className="titr7 titr7-5" style={{ color: "#c3b091" }}>
          Introduce Metamars and its various departments and share 50% of its revenue!
          </p>
          <div className="container">
            <section>
              <div className="text-justify-metamarce">
              If you also want to start a simple business, without the need for initial capital, with low risk and high returns, Metamars has paved this path for you through collaborative sales.
              </div>

              <div>MetaMars Affiliate Marketing is designed based on ease, transparency, and without the need for capital or risk.
              </div>
               </section>

            <section>
              <h3 className=" titr6 font-20 mt-3 mt-md-4">
              What is Affiliate Marketing or Sales Collaboration?
              </h3>
              <div className="text-justify-metamarce">Affiliate or sales cooperation is a simple way to earn money without having initial capital. By introducing Metamars to your friends or on social networks such as Instagram, Telegram, etc., you help them become familiar with the services and facilities that Metamars provides, and in return, you will receive 50% of the sales commission from Metamars. The Metamars affiliate system, with its low risk, is considered one of the best and most profitable ways to earn money.
              </div>
            </section>

            <section>
              <div className="row">
                <div className="col-md-7">
                  <h2 className=" titr6 font-20 mt-3 mt-md-4">
                  Registration process
                  </h2>
                  <ul className="text-justify-metamarce px-0">
                    <li>
                      <i class="bi bi-dot "></i> Register on Metamars.
                      {!user?.roles?.includes(9999) && (
                        <strong
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalToggle"
                          role="button"
                        >
                          (here)
                        </strong>
                      )}
                    </li>
                    <li>
                      {" "}
                      <i class="bi bi-dot"></i>
                      After registration, a special link will be issued to you.
                      {/* <span className="fw-bold fst-italic cursor" onClick={(e) => {
                    if (!window.logined(9999)) return;
                    window.navigateHandle(`/${window.lang}/dashboard/user/profile/me`);
                  }}>
                    (اینجا)
                  </span> */}
                    </li>
                    <li>
                      {" "}
                      <i class="bi bi-dot"></i>You can copy and publish your own link, and then your commission will start from users who enter the website through your link, and you will be eligible to receive a commission from all users' purchases for the next two years.
                    </li>
                  </ul>

                  <h2 className=" titr6 font-20 mt-3 mt-md-4">
                  Providing an affiliation link
                  </h2>
                  <ul className="text-justify-metamarce px-0">
                    <li>
                      <i class="bi bi-dot"></i>To create a affiliation link, you must first log in to your account.
                    </li>
                    <li>
                      {" "}
                      <i class="bi bi-dot"></i>
                      Then go to the page or post you want to promote.
                    </li>
                    <li>
                      {" "}
                      <i class="bi bi-dot"></i>
                      By clicking on your username, select
                      <span
                        className="fw-bold fst-italic cursor"
                        onClick={(e) => {
                          if (!window.logined(9999)) return;
                          document
                            .querySelector("#offcanvasLoginTrigger")
                            .click();
                        }}
                      >
                       "Your unique link to this page" 
                      </span>
                      copy the link to the current page.
                    </li>
                    <li>
                      {" "}
                      <i class="bi bi-dot"></i>After copying, you can use it to introduce Metamars.
                    </li>
                    <li>
                      {" "}
                      <i class="bi bi-dot"></i>
                      Track your performance in the
                      <span
                        className="fw-bold fst-italic cursor"
                        onClick={(e) => {
                          if (!window.logined(9999)) return;
                          window.navigateHandle(
                            `/${window.lang}/dashboard/user/trans/affilatelist`
                          );
                        }}
                      >
                         "Transactions" 
                      </span>
                      section.
                    </li>
                  </ul>
                </div>
                <div className="col-md-5">
                  <div className="w-100">
                    <img
                      className="w-100 px-md-5 px-3 mx-auto"
                      src="/img/guide/affiliationLink2.png"
                      alt="Affiliation Link"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section>
              <h2 className=" titr6 font-20 mt-3 mt-md-4">Earning commission </h2>
              <ul className="text-justify-metamarce px-0">
                <li>
                  {" "}
                  <i class="bi bi-dot"></i>
                You earn 50% commission from MetaMars' share for two years on all purchases made by users who have entered your website.
                </li>
                <li>
                  {" "}
                  <i class="bi bi-dot"></i>This 50% commission applies to users who register by the end of 2024, and a different rate may be applied to new users in 2025.
                </li>
                <li>
                  {" "}
                  <i class="bi bi-dot"></i>Commissions are paid monthly via PayPal.
                </li>
                <li>
                  {" "}
                  <i class="bi bi-dot"></i>The minimum threshold for commission reimbursement is $50.
                </li>
              </ul>
            </section>

            {/* <section>
              <h2>قوانین و راهنماها</h2>
              <ul>
                <li><strong>روش‌های تبلیغاتی مجاز:</strong> پست‌های شبکه‌های اجتماعی، مقالات بلاگ، خبرنامه‌های ایمیلی.</li>
                <li><strong>فعالیت‌های ممنوع:</strong> اسپم، ادعاهای گمراه‌کننده، یا استفاده از لینک همکاری در فروش خود در سایت‌های فروش کوپن.</li>
                <li><strong>رعایت قوانین:</strong> اطمینان حاصل کنید که تمامی فعالیت‌های تبلیغاتی با قوانین محلی سازگار هستند.</li>
              </ul>
            </section> */}

            <section>
              <h2 className=" titr6 font-20 mt-3 mt-md-4">Monitoring and reporting</h2>
              <p className="text-justify-metamarce">We use advanced tracking tools to monitor your referrals and detect any fraudulent activity. Your dashboard shows real-time data on clicks and sales.
              </p>
              <span
                className="fw-bold fst-italic cursor"
                onClick={(e) => {
                  if (!window.logined(9999)) return;
                  window.navigateHandle(
                    `/${window.lang}/dashboard/user/profile/me`
                  );
                }}
              >
                (here)
              </span>
            </section>

            <section>
              <h2 className=" titr6 font-20 mt-3 mt-md-4">Support</h2>
              <p className="text-justify-metamarce">Need help? Email our support team at metamarce.com@gmail.com or contact us on 
                <span
                  className="fw-bold fst-italic cursor"
                  onClick={(e) => {
                    if (!window.logined(9999)) return;
                    document.querySelector("#chatBoxBtn").click();
                  }}
                >"MetaChat" </span>              
              </p>
            </section>
          </div>
        </div>

        {/* <div className="col-md-4 text-center">
            <div style={{ position: "relative" }}>
              <img
                src="/img/illustration/pair-programming-animate.svg"
                className="mb-3 px-0 w-100"
                alt="homePage"
              />
            </div>
          </div> */}
        {/* </div> */}
      </div>
    </div>
  );
}
