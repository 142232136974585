// import renderData from "../../../../utils/renderData";
// import MetaDecorator from "../../../../utils/metaDecorator";
import { useContext } from "react";
import { CartContext } from "../../../contexts/cartContext";

export default function StudentsGuideEn() {
  const { user } = useContext(CartContext);

  return (
    <div className="d-flex align-items-start">
      <div className="tab-content" id="v-pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="v-pills-registerUser"
          role="tabpanel"
          aria-labelledby="v-pills-registerUser-tab"
        >
          <div className="content__container">
            <h1 className="titr7 text-start text-gray-green">
              Registration procedures and how to use metademy classes
            </h1>

            <div className=" ">
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                  1
                </div>
                <span>Login or create a user account in Metamarce</span>
                {!user?.roles?.includes(9999) && (
                  <strong
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalToggle"
                    role="button"
                  >
                    (here)
                  </strong>
                )}
              </div>

              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1">
                  2
                </div>
                <span>
                  {" "}
                  Search between different courses and tutors and find the right
                  class
                </span>
                <a
                  href={`/${window.lang}/demy/tutor/filters`}
                  className="content__link bold-700 ms-2"
                >
                  <strong>(here)</strong>
                </a>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1 mt-1 mt-1">
                  3
                </div>
                <span></span>
                Choosing the time and hours of meetings from the list of empty
                meetings in the teaching calendar
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1 mt-1">
                  4
                </div>
                <span>
                  Confirmation and transfer of sessions to shopping cart
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1">
                  5
                </div>
                <span>
                  Payment through PayPal's secure portal and through credit
                  cards (MasterCard, Visa, etc.) or directly from your account.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1">
                  6
                </div>
                <span>Receive a bill</span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1">
                  7
                </div>
                <span>Receive the teacher's confirmation email</span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1">
                  8
                </div>
                <span>Receive a reminder email one hour before the class</span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1">
                  9
                </div>
                <span>
                  Receive the link to enter the class by email half an hour
                  before the class
                </span>
              </div>
            </div>

            <div>
              <div>
                <div className="">
                  <p className=" ">
                    Also, after booking a trail session or a Learning Session,
                    the desired teacher will be added to the list of users in
                    Metamarce chat and you can directly communicate with your
                    teacher.
                  </p>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
