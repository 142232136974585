import { useState, useRef, useEffect, useContext } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import apiDemy from "../../../../utils/apiDemy";
import Pagination from "../../../golbal/pagination/pagination";
// import Cluster from "../../../googleMap/googleCluster";
import Search from "../../../persian/common/pagination/searchPagination";
import renderData from "../../../../utils/renderData";
import SearchMultiSelect from "../../../golbal/dropdown/searchMultiSelectFilters";
import { CartContext } from "../../../contexts/cartContext";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import PlansX4 from "../../../golbal/sliders/plansX4";
// import BusSmallCardSlider from "../../../golbal/sliders/busCardSliderS1";
// import MainSidebar from "../../media/components/mainSidebar";
import tools from "../../../../utils/tools";
import FiltersCatsSelector from "../../../golbal/filtersCatsSelector";
import MetaDecorator from "../../../../utils/metaDecorator";
import TutorCard from "../components/tutorCard";
import DropdownSearch from "../../../golbal/dropdown/DropdownSearch";
import countryStates from "../../../../utils/countryStates";
import demyData from "../../../../utils/demyData";
import ToastNote from "../../../golbal/notifications/toastNote";
import api from "../../../../utils/api";
import HorizontalSlider from "../../../golbal/sliders/horizontalSlider";
import BlogSearch from "../../../persian/common/pagination/searchPagination";
import NewPosts2x2 from "../../../golbal/sliders/components/newPosts2x2";

export default function TutorFiltersEn() {
  const navigate = useNavigate();
  const section = "tutor";
  const page = "tutorFilters";
  const { lang, state:{state,stateShort}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  const { user } = useContext(CartContext);
  const [pubData] = useOutletContext();
  const messageRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [video, setVideo] = useState("");

  // const [view, setView] = useState({
  //   thumbnail: true,
  //   cluster: false,
  // });

  const [resultsTotal, setResultsTotal] = useState(0);
  const [loading, setLoading] = useState({ fetchBus: false });
  const [states, setStates] = useState([]);
  // const [showCities, setShowCities] = useState(false);
  const [request, setRequest] = useState({ email: "", message: "" });
  const [data, setData] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [activeTutor, setActiveTutor] = useState({});
  // console.log('activeTutor',activeTutor)
  async function fetchAcceptedCurHandle() {
    if (currencies?.length) return;
    // console.log();
    const response = await api.getAcceptedCur({
      token: user.token,
      accepted: "all",
    });
    if (response.error)
      return messageRef.current.showError({
        message: `مشکل در انجام عملیات : ${response.error}`,
      });
    setCurrencies(response.data);
    // console.log('fetchAcceptedCurHandle',currencies);
  }

  const initialFilters = {
    lang:window.lang,
    country: "",
    countryShort: "",
    states: [],
    state: "",
    stateShort: "",
    cities: [],
    mainCatId: "",
    subCat: [],
    video: "",
    img: "",
    freeSession: "",
    // subCatId: [],
    // busId: "",
    busName: "",
    searchKey: "",
    tag: "",
    weekDays: [],
    ageGroups: [],
    tutorLevel: [],
    serviceTypes: [],
    pageNumber: 1,
    itemsToShow: 15,
    sort: "default",
  };
  let storedFilters = JSON.parse(localStorage.getItem("storedFilters"))?.[
    section
  ];
  storedFilters = storeFilters.lang === window.lang?storeFilters:null
  const [filters, setFilters] = useState(storedFilters || initialFilters);

  const [backFilters, setBackFilters] = useState({ ...filters });

  const effectRan = useRef(true);
  useEffect(() => {
    console.log('useEffectRun00',); 
    if (effectRan.current) return;
    filtersFunction()
  }, [filters]);
  function storeFilters({ section, filters }) {
    const storedFilters =
      JSON.parse(localStorage.getItem("storedFilters")) ?? {};
    storedFilters[section] = filters;
    localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    return;
  }

  useEffect(() => {
    // const keys = Array.from(searchParams.keys())
    console.log('useEffectRun02',); 
    const params = {};
    for (let [key, value] of searchParams) {
      params[key] = value;
    }
    if (Object.keys(params).length > 0) {
      setFilters((pre) => ({
        ...pre,
        // lang:urlLang,
        pageNumber: 1,
        country: "",
        state: "",
        stateShort: "",
        mainCat: params?.mainCat ?? pre.mainCat,
        mainCatId: params?.mainCatId ?? pre.mainCatId,
        subCat: params?.subCat ? [params?.subCat] : pre.subCat,
        // subCatId: params?.subCatId ? [params?.subCatId] : pre.subCatId,
        cities: params?.cities ? [params?.cities] : pre.cities,
        searchKey: params?.searchKey ?? pre.searchKey,
        tag: params?.tag ?? pre.tag,
        serviceTypes: params?.serviceTypes
          ? [params?.serviceTypes]
          : pre.serviceTypes,
        tutorLevel: params?.tutorLevel ? [params?.tutorLevel] : pre.tutorLevel,
        ageGroups: params?.ageGroups ? [params?.ageGroups] : pre.ageGroups,
        weekDays: params?.weekDays ? [params?.weekDays] : pre.weekDays,
      }));
    }
    console.log('useEffectRun03',); 
    filtersFunction()
    effectRan.current = false;
  }, [searchParams]);

  function filtersFunction(){
    console.log('useEffectRun01','filterFunction');    
    fetchData();
    setBackFilters({ ...filters });
    storeFilters({ section, filters });
    fetchAcceptedCurHandle();
  }

  function queryRemover(field) {
    console.log(field);
    searchParams?.delete(field);
    // searchParams.set(field,"")
    const searchString = searchParams?.toString();
    setFilters((pre) => {
      const newFilters = { ...pre };
      delete newFilters[field];
      return newFilters;
    });
    navigate(`?${searchString}`);
  }

  async function fetchData() {
    setLoading((pre) => ({
      ...pre,
      fetchBus: true,
    }));
    const response = await apiDemy.filtersTutor({ filters });
    setLoading((pre) => ({
      ...pre,
      fetchBus: false,
    }));
    if (response?.error) return alert(response?.error);
    setResultsTotal(response?.data?.resultsTotal);
    setData(response?.data?.data);
  }

  async function searchHandle(e) {
    e.preventDefault();
    const value = e.target.parentElement.querySelector("input").value;

    filters.searchKey = value;
    if (e.key === "Enter" || e.type === "click") {
      setFilters((prev) => ({
        ...prev,
        searchKey: value,
        pageNumber: 1,
      }));
    }
  }
  const itemsToShowHandle = async (itemsToS) => {
    setFilters((prev) => ({
      ...prev,
      itemsToShow: itemsToS,
      pageNumber: 1,
    }));
  };
  const pageNumberHandle = (PageN) => {
    setFilters((prev) => ({
      ...prev,
      pageNumber: PageN,
    }));
  };

  function deleteFilter(e) {
    const field = e.currentTarget.id;
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key] = "";
              if (key === "mainCat") {
                newFilters.mainCatId = "";
                newFilters.subCat = [];
                newFilters.subCatId = [];
                queryRemover("mainCatId");
              }
              if (key === "country") {
                newFilters.countryShort = "";
              }
              newFilters.pageNumber = 1;
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }

    // effectRan.current = false;
    setFilters(update());
  }

  function deleteArrayFilter(e) {
    const field = e.currentTarget.id.split(",")[0];
    const index = e.currentTarget.id.split(",")[1];
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key].splice(index, 1);
              newFilters.pageNumber = 1;
              if (key === "subCat") {
                newFilters.subCatId.splice(index, 1);
                queryRemover("subCatId");
              }
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }
    setFilters(update());
  }

  const selectedFiltersRender = (() => {
    let rows = [];
    for (let key in filters) {
      if (Array.isArray(filters[key]))
        filters[key].map((option, i) => {
          // console.log(key, renderData[key], option);
          if (
            key !== "states" &&
            key !== "cities" &&
            key !== "subCat" &&
            key !== "subCatId"
          ) {
            const translate = (renderData[key] || demyData[key]).find(
              (option1) => option1.name == option
            );
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{translate?.[window.lang]} </span>
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }

          if (key === "states" || key === "cities" || key === "subCat") {
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{option} </span>
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }
        });

      !Array.isArray(filters[key]) &&
        key !== "lang" &&
        key !== "countryShort" &&
        key !== "stateShort" &&
        key !== "state" &&
        key !== "section" &&
        key !== "sort" &&
        key !== "pageNumber:" &&
        key !== "itemsToShow" &&
        key !== "mainCatId" &&
        filters[key]?.length &&
        rows.push(
          <div className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center">
            <span>
              {key === "tag" && "# "}
              {filters[key]}
            </span>
            <span className="ms-3 me-2" id={key} onClick={deleteFilter}>
              <i className="bi bi-x-lg"></i>
            </span>
          </div>
        );
    }
    return rows;
  })();

  function setCitiesHandle(value) {
    setFilters((prev) => ({ ...prev, cities: value, pageNumber: 1 }));
    // effectRan.current = false;
  }
  const { translations } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = `Metademy | Tutors  `;
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Online and Inperson tutoring all around the world",
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "landing",
              description: "A bridge between tutors and students around the world",
            },
          },
          {
            // "@type": "ListItem",
            // position: 2,
            // item: {
            //   "@id": `https://www.metamarce.com/${window.lang}`,
            //   name: `Home`,
            //   description:
            //     "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، درآمد دلاری از تدریس، آموزش آنلاین و مجازی بدون مرز، تولید کنندگان محتوا و... !",
            // },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/demy`,
              name: `Metademy`,
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/demy/filters`,
              name: `Tutors list`,
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title,
    description: `جستجوی بهترین اساتید تدریس خصوص`,
    section: "metademy filters",
    url: `https://www.metamarce.com/${window.lang}/demy/tutor/filters`,
    canonical: `https://www.metamarce.com/${window.lang}/demy/tutor/filters`,
    img: "https://www.metamarce.com/img/Metademy/Metademy-green-red.png",
    type: "website",
  };

  return (
    <div dir="ltr">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      {loading.fetchBus && <ColoredSpiner />}
      {/* <!-- *********************************** main ******************************************************************** --> */}

      <section className="mx-auto ads-all py-0">
        <nav className="p-md-3 p-2" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={`/${window.lang}`} className="breadcrumb-item">
              Home
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/${window.lang}/demy`} className="breadcrumb-item">
                Metademy
              </Link>
            </li>
            <li className="breadcrumb-item">
              <span className="breadcrumb-item active">
                <strong> Filters</strong>
              </span>
            </li>
          </ol>
        </nav>

        <div className="row blog px-md-4 p-0 py-1">
          {/* <!-- **************Right side ************** --> */}
          <div className="col-lg-12 entries  ">
            {/* <!-- ************** MAP ************** --> */}
            <a id="top"> </a>

            {/* <!-- **************Filters  ************** --> */}
            <div className=" d-flex flex-wrap x-3 justify-content-between my-2 border-0 ">
              <div className="d-flex">
                <span className="pt-2" title="نمایش ذخیره ها">
                  <i
                    className={`bi ${
                      filters.saved ? "bi-bookmark-fill" : "bi-bookmark"
                    } icon-stack-1x cursor`}
                    onClick={(e) => {
                      if (!window.logined(9999)) return;
                      if (user?.saved?.[section]?.length <= 0)
                        return alert("شما تاکنون آیتم ذخیره شده ای ندارین.");
                      setFilters((pre) => ({
                        ...initialFilters,
                        saved: !pre.saved,
                        section,
                      }));
                    }}
                  ></i>
                </span>
                {resultsTotal !== 0 && (
                  <span className="box4 py-1 px-2 mx-md-2 mx-0">
                    {resultsTotal} Result(s)
                  </span>
                )}
                <div className="blog-search ">
                  <BlogSearch searchHandle={searchHandle} />
                </div>
              </div>
              <div className="d-flex">
                <div
                  className="btn btn-primary addbusiness-btn my-2 me-2 pt-2"
                  onClick={(e) => {
                    if (!window.logined(9999, section)) {
                      return window.navigateHandle(
                        `/${window.lang}/demy/tutor/register`
                      );
                    }
                    window.navigateHandle(
                      `/${window.lang}/dashboard/user/demy/registertutor`
                    );
                  }}
                >
                  <i className="bi bi-geo me-1"></i>
                 Become a tutor
                </div>
                <button
                  className="btn btn-primary button-filter me-md-3 me-0 ms-auto my-2"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarTogglerDemo02"
                  aria-controls="navbarTogglerDemo02"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="bi bi-filter me-1"> Filters</i>
                </button>
              </div>
              <div
                className="collapse navbar-collapse "
                id="navbarTogglerDemo02"
              >
                <div className="d-flex flex-wrap mt-3">
                  {/* <!-- Button filter 1 *******************************--> */}
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn  button-filter dropdown-toggle"
                      data-bs-toggle="dropdown"
                      // id="#filter5"
                      aria-expanded="false"
                      // aria-controls="filter5"
                    >
                      <span>
                        <i className="bi bi-arrow-down-up me-1"></i>
                      </span>
                      Sort
                      {/* {filters.sort !== "default" && <CheckBox />} */}
                      <span className="ps-3">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </button>

                    {/* <!-- Modal --> */}

                    <div className="dropdown-menu checkbox-form px-0 ">
                      <div className=" ">
                        <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={filters.sort === "default"}
                            onChange={(e) => {
                              setFilters((prev) => ({
                                ...prev,
                                sort: "default",
                                pageNumber: 1,
                              }));
                              // effectRan.current = false;
                            }}
                          />
                          <label
                            className="form-check-label "
                            htmlFor="flexRadioDefault1"
                          >
                            Default
                          </label>
                        </div>

                        <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={filters.sort === "dateB"}
                            onChange={(e) => {
                              // effectRan.current = false;
                              setFilters((prev) => ({
                                ...prev,
                                sort: "dateB",
                                pageNumber: 1,
                              }));
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            Newest First
                          </label>
                        </div>
                        <div className="form-check px-3 d-flex justify-content-between  flex-row-reverse">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault3"
                            // value={flexRadioDefault3}
                            checked={filters.sort === "priceB"}
                            onChange={(e) => {
                              // effectRan.current = false;
                              setFilters((prev) => ({
                                ...prev,
                                sort: "priceB",
                                pageNumber: 1,
                              }));
                            }}
                          />
                          <span className="checkmark-radio-btn "></span>
                          <label
                            className="form-check-label "
                            htmlFor="flexRadioDefault3"
                          >
                            Fees (Low to High)
                          </label>
                        </div>
                        <div className="form-check px-3 d-flex justify-content-between  flex-row-reverse">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault334"
                            // value={flexRadioDefault334}
                            checked={filters.sort === "priceA"}
                            onChange={(e) => {
                              // effectRan.current = false;
                              setFilters((prev) => ({
                                ...prev,
                                sort: "priceA",
                                pageNumber: 1,
                              }));
                            }}
                          />
                          <span className="checkmark-radio-btn "></span>
                          <label
                            className="form-check-label "
                            htmlFor="flexRadioDefault334"
                          >
                             Fees (High to Low)
                          </label>
                        </div>
                        {/* <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioVideo"
                        checked={filters.sort === "videoB"}
                        onChange={(e) => {
                          // effectRan.current = false;
                          setFilters((prev) => ({
                            ...prev,
                            sort: "videoB",
                            pageNumber: 1,
                          }));
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioVideo"
                      >
                        With Video
                      </label>
                    </div>
                    <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioImg"
                        checked={filters.sort === "imgB"}
                        onChange={(e) => {
                          // effectRan.current = false;
                          setFilters((prev) => ({
                            ...prev,
                            sort: "imgB",
                            pageNumber: 1,
                          }));
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioImg"
                      >
                        With photo
                      </label>
                    </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- Button country 2 *******************************--> */}
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn button-filter dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="bi bi-geo-alt me-1"></i>
                      </span>
                      Country
                      <span className="ps-3">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </button>

                    {/* <!-- Modal --> */}

                    <div className="dropdown-menu checkbox-form">
                      <DropdownSearch updateParent={countrySet} />
                    </div>
                  </div>

                  {/* <!-- Button State 2.5 *******************************--> */}
                  {filters.country?.length > 0 && (
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn button-filter dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span>
                          <i className="bi bi-geo-alt me-1"></i>
                        </span>
                        State
                        <span className="ps-3">
                          <i className="bi bi-chevron-down"></i>
                        </span>
                      </button>

                      {/* <!-- Modal --> */}

                      <div className="dropdown-menu px-0 checkbox-form">
                        <div
                          className="reset-btn w-100"
                          onClick={(e) => {
                            setFilters((prev) => ({
                              ...prev,
                              states: [],
                              state: "",
                              stateShort: "",
                              pageNumber: 1,
                            }));
                          }}
                        >
               Delete                        
      </div>
                        {states?.map((state, i) => (
                          <div
                            key={"states" + i}
                            className="form-check d-flex justify-content-between flex-row-reverse px-3"
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              // value={state.name?.toLowerCase()}
                              onChange={(e) => {
                                const states = filters?.states
                                  ? [...filters.states]
                                  : [];
                                const state1 = { stateShort: "", state: "" };
                                const value = state.state;
                                console.log("value", value);
                                const index = states.indexOf(value);
                                if (index !== -1) {
                                  states.splice(index, 1);
                                  // const contry = countryStates.find(
                                  //   (count) =>
                                  //     count.countryShort ===
                                  //     filters.countryShort.toUpperCase()
                                  // );
                                  // const stateShort = contry.states.find(
                                  //   (state) => state.state === states[0]
                                  // ).stateShort;
                                  console.log("stateShort", stateShort);
                                  // const stateShort= countryStates.find(count=>count.countryShort === filters.countryShort)
                                  if (states.length === 1) {
                                    state1.stateShort = stateShort;
                                    state1.state = states[0];
                                  } else {
                                    state1.stateShort = "";
                                    state1.state = "";
                                  }
                                } else {
                                  states.push(value);
                                  if (states.length === 1) {
                                    state1.stateShort = state.stateShort;
                                    state1.state = value;
                                  } else {
                                    state1.stateShort = "";
                                    state1.state = "";
                                  }
                                }
                                setFilters((pre) => ({
                                  ...pre,
                                  states,
                                  state: state1.state,
                                  stateShort: state1.stateShort,
                                }));
                              }}
                              checked={filters.states?.includes(state.state)}
                              id={`statesCheckBox${i}`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`statesCheckBox${i}`}
                            >
                              {state?.state}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* <!-- Button City 3 *******************************--> */}
                  {filters.states.length === 1 &&
                    filters?.state?.length > 0 && (
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn button-filter dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span>
                            <i className="bi bi-geo-alt me-1"></i>
                          </span>
                          City
                          <span className="ps-3">
                            <i className="bi bi-chevron-down"></i>
                          </span>
                        </button>

                        {/* <!-- Modal --> */}

                        <div className="dropdown-menu checkbox-form">
                          <SearchMultiSelect
                            updateParent={setCitiesHandle}
                            data={{
                              countryShort: filters.countryShort,
                              stateShort: filters.stateShort,
                              cities: filters.cities,
                            }}
                          />
                        </div>
                      </div>
                    )}

                  {/* <!-- Button filter 3 *******************************--> */}
                  <FiltersCatsSelector
                    section={section}
                    filters={filters}
                    setFilters={setFilters}
                  />

                  {/* <!-- Button filter 5 *******************************--> */}
                  <div className="dropdown">
                    <button
                      className="btn button-filter"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasWithBothOptionsFilters"
                      aria-controls="offcanvasWithBothOptionsFilters"
                    >
                      <span>
                        <i className="bi bi-filter me-1"></i>
                      </span>
                      More Filters
                      <span className="ps-3">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </button>

                    <div
                      className="offcanvas offcanvas-end offcanvas-countryLanguage "
                      data-bs-scroll="true"
                      tabIndex="-1"
                      id="offcanvasWithBothOptionsFilters"
                      aria-labelledby="offcanvasWithBothOptionsLabelFilters"
                    >
                      <div className="offcanvas-header offcanvas-countryLanguageTitle ">
                        <h5
                          className="offcanvas-title "
                          id="offcanvasWithBothOptionsLabelFilters"
                        >
                          More Filters
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div className="offcanvas-footer  text-center px-5 py-4">
                        <button
                          className="btn  checkout-btn  text-center w-100 mb-2 py-2"
                          type="button"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                         onClick={(e) => {
                    // removing all url queries
                    navigate('')
                    setFilters(initialFilters)
                  }}
                        >
                        Clear All Filters
                        </button>
                        <button
                          className="btn  view-cart text-center w-100 py-2"
                          type="button"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          onClick={(e) => {
                            // effectRan.current = false;
                            backFilters.pageNumber = 1;
                            setFilters(backFilters);
                          }}
                        >
                          View Results
                        </button>
                      </div>

                      <div className="offcanvas-body px-0 pb-3 pt-0">
                        <div
                          className="accordion w-100 p-0 m-0"
                          id="accordionPanelsStayOpenBusinessFilters"
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-headingZero"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpenBusinessFilters-collapseZero"
                                aria-expanded="false"
                                aria-controls="panelsStayOpenBusinessFilters-collapseZero"
                              >
                                <p className="form-titr2">Rating </p>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpenBusinessFilters-collapseZero"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-headingZero"
                            >
                              <div className="accordion-body form-font px-5">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="4"
                                    checked={
                                      backFilters?.rate?.slice(0, 1) == 4
                                    }
                                    id={`flexCheckRates4`}
                                    name="rateFilter"
                                    onChange={(e) => {
                                      setBackFilters((prev) => ({
                                        ...prev,
                                        rate: "4 Stars & up",
                                        pageNumber: 1,
                                      }));
                                    }}
                                  />

                                  <label
                                    className="form-check-label d-flex"
                                    htmlFor={`flexCheckRates4`}
                                  >
                                    <div className="star-filter me-1">
                                      <i className="bi bi-star-fill ps-1"></i>
                                      <i className="bi bi-star-fill ps-1"></i>
                                      <i className="bi bi-star-fill ps-1"></i>
                                      <i className="bi bi-star-fill ps-1"></i>
                                      <i className="bi bi-star ps-1"></i>
                                    </div>
                                    <span className="small-gray-font pt-1"> & up</span>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="3"
                                    checked={
                                      backFilters?.rate?.slice(0, 1) == 3
                                    }
                                    id={`flexCheckRates3`}
                                    name="rateFilter"
                                    onChange={(e) => {
                                      setBackFilters((prev) => ({
                                        ...prev,
                                        rate: "3 Stars & up",
                                        pageNumber: 1,
                                      }));
                                      // effectRan.current = false;
                                    }}
                                  />
                                  <label
                                    className="form-check-label d-flex"
                                    htmlFor={`flexCheckRates3`}
                                  >
                                    <div className="star-filter me-1">
                                      <i className="bi bi-star-fill ps-1"></i>
                                      <i className="bi bi-star-fill ps-1"></i>
                                      <i className="bi bi-star-fill ps-1"></i>
                                      <i className="bi bi-star ps-1"></i>
                                      <i className="bi bi-star ps-1"></i>
                                    </div>
                                    <span className="small-gray-font pt-1"> & up</span>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="2"
                                    checked={
                                      backFilters?.rate?.slice(0, 1) == 2
                                    }
                                    onChange={(e) => {
                                      setBackFilters((prev) => ({
                                        ...prev,
                                        rate: "2 stars & up",
                                        pageNumber: 1,
                                      }));
                                      // effectRan.current = false;
                                    }}
                                    id={`flexCheckRates2`}
                                    name="rateFilter"
                                  />
                                  <label
                                    className="form-check-label d-flex"
                                    htmlFor={`flexCheckRates2`}
                                  >
                                    <div className="star-filter me-1">
                                      <i className="bi bi-star-fill ps-1"></i>
                                      <i className="bi bi-star-fill ps-1"></i>
                                      <i className="bi bi-star ps-1"></i>
                                      <i className="bi bi-star ps-1"></i>
                                      <i className="bi bi-star ps-1"></i>
                                    </div>
                                    <span className="small-gray-font pt-1"> & up</span>
                                  </label>
                                </div>

                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="1"
                                    checked={
                                      backFilters?.rate?.slice(0, 1) == 1 &&
                                      true
                                    }
                                    onChange={(e) => {
                                      setBackFilters((prev) => ({
                                        ...prev,
                                        rate: "1 star & up",
                                        pageNumber: 1,
                                      }));
                                      // effectRan.current = false;
                                    }}
                                    id={`flexCheckRates1`}
                                    name="rateFilter"
                                  />
                                  <label
                                    className="form-check-label d-flex"
                                    htmlFor={`flexCheckRates1`}
                                  >
                                    <div className="star-filter me-1">
                                      <i className="bi bi-star-fill ps-1"></i>
                                      <i className="bi bi-star ps-1"></i>
                                      <i className="bi bi-star ps-1"></i>
                                      <i className="bi bi-star ps-1"></i>
                                      <i className="bi bi-star ps-1"></i>
                                    </div>
                                    <span className="small-gray-font pt-1"> & up</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-headingZero"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#stayOpenWeedkDays"
                                aria-expanded="false"
                                aria-controls="stayOpenWeedkDays"
                              >
                                <p className="form-titr2">Availability (Weekdays)</p>
                              </button>
                            </h2>
                            <div
                              id="stayOpenWeedkDays"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-headingZero"
                            >
                              <div className="accordion-body form-font px-5  row row-cols-2">
                                {renderData.weekDays.map((ad, i) => (
                                  <div
                                    key={i}
                                    className="form-check m-0 py-0 px-1"
                                  >
                                    <input
                                      className="form-check-input form-check-input-filter1 m-0 p-0"
                                      type="checkbox"
                                      name="weekDay"
                                      value={ad.name}
                                      id={`weekDay,${i}`}
                                      checked={backFilters?.weekDays?.includes(
                                        ad.name
                                      )}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        const weekDays = backFilters?.weekDays
                                          ? [...backFilters.weekDays]
                                          : [];
                                        const index = weekDays.indexOf(value);
                                        if (index !== -1) {
                                          weekDays.splice(index, 1);
                                        } else {
                                          weekDays.push(ad.name);
                                        }
                                        setBackFilters((prev) => ({
                                          ...prev,
                                          weekDays,
                                        }));
                                      }}
                                    />
                                    <label
                                      className="form-check-label px-2"
                                      htmlFor={`weekDay,${i}`}
                                    >
                                      {ad?.[window.lang]}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-headingTwo"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpenBusinessFilters-collapseTwo"
                                aria-expanded="false"
                                aria-controls="panelsStayOpenBusinessFilters-collapseTwo"
                              >
                                <p className="form-titr2">Age Group</p>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpenBusinessFilters-collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-headingTwo"
                            >
                              <div className="accordion-body form-font px-5">
                                {renderData.ageGroups.map((at, i) => (
                                  <div key={i} className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={at.name}
                                      checked={backFilters?.ageGroups?.includes(
                                        at.name
                                      )}
                                      id={`checkBoxavailableTime${i}`}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        const ageGroups = backFilters.ageGroups
                                          ? [...backFilters.ageGroups]
                                          : []; // Create a shallow copy of the array
                                        console.log("ageGroups", ageGroups);
                                        const index =
                                          backFilters.ageGroups.indexOf(value);
                                        if (index !== -1) {
                                          ageGroups.splice(index, 1);
                                        } else {
                                          // Add the value if it's not selected
                                          ageGroups.push(value);
                                        }
                                        // backFilters.ageGroups=ageGroups
                                        setBackFilters((pre) => ({
                                          ...pre,
                                          ageGroups,
                                        }));
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`checkBoxavailableTime${i}`}
                                    >
                                      {at[lang]}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-headingOne"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter "
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpenBusinessFilters-collapseOne"
                                aria-expanded="false"
                                aria-controls="panelsStayOpenBusinessFilters-collapseOne"
                              >
                                <p className="form-titr2">Language</p>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpenBusinessFilters-collapseOne"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-headingOne"
                            >
                              <div className="accordion-body form-font px-5  row row-cols-3 ">
                                {renderData.languages.map((lang, i) =>
                                  !lang.fa.length ? (
                                    ""
                                  ) : (
                                    <div key={i} className="morelang-line">
                                      <input
                                        className="form-check-input  red moreLang"
                                        type="checkbox"
                                        value={lang.name}
                                        checked={backFilters.languages?.includes(
                                          lang.name
                                        )}
                                        id={`flexLanguages${i}`}
                                        onChange={(e) => {
                                          const languages =
                                            backFilters?.languages
                                              ? [...backFilters.languages]
                                              : [];
                                          const value = e.target.value;
                                          const index =
                                            languages.indexOf(value);
                                          if (index !== -1) {
                                            languages.splice(index, 1);
                                          } else {
                                            languages.push(value);
                                          }
                                          setBackFilters((pre) => ({
                                            ...pre,
                                            languages,
                                          }));
                                        }}
                                      />
                                      <label
                                        className="form-check-label ps-2 red moreLang"
                                        htmlFor={`flexLanguages${i}`}
                                      >
                                        {lang[window.lang]}
                                      </label>
                                    </div>
                                  )
                                )}

                                <button
                                  className="btn button-filter py-0 mt-3"
                                  type="button"
                                >
                                  <label htmlFor="moreLangCheckbox">
                                    {" "}
                                    more...{" "}
                                  </label>
                                  {/* <span>
                              <i className="bi bi-three-dots ps-2 py-0 my-0"></i>
                            </span> */}
                                  <input
                                    id="moreLangCheckbox"
                                    type="checkbox"
                                    onClick={(e) => {
                                      const elemet =
                                        document.querySelectorAll(".moreLang");
                                      if (e.target.checked) {
                                        document.querySelector(
                                          "label[for=moreLangCheckbox]"
                                        ).innerHTML = "less...";
                                        Array.from(elemet).map(
                                          (el) =>
                                            (el.style.display = "inline-block")
                                        );
                                      } else {
                                        document.querySelector(
                                          "label[for=moreLangCheckbox]"
                                        ).innerHTML = " more...";
                                        Array.from(elemet).map(
                                          (el) => (el.style.display = "none")
                                        );
                                      }
                                    }}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-headingThree"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpenBusinessFilters-collapseThree"
                                aria-expanded="false"
                                aria-controls="panelsStayOpenBusinessFilters-collapseThree"
                              >
                                <p className="form-titr2">Course Level </p>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpenBusinessFilters-collapseThree"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-headingThree"
                            >
                              <div className="accordion-body form-font px-5">
                                {demyData.tutorLevel
                                  .slice(0, 2)
                                  .map((tr, i) => (
                                    <div
                                      key={i}
                                      className="form-check form-switch m-0 py-0 px-1"
                                    >
                                      <input
                                        className="form-check-input form-check-input-filter1 m-0 p-0"
                                        type="checkbox"
                                        role="switch"
                                        name="tutorLevel"
                                        value={tr.name}
                                        id={`tutorLevel,${i}`}
                                        checked={backFilters?.tutorLevel?.includes(
                                          tr.name
                                        )}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const tutorLevel =
                                            backFilters.tutorLevel
                                              ? [...backFilters.tutorLevel]
                                              : [];
                                          const index =
                                            tutorLevel.indexOf(value);
                                          if (index !== -1) {
                                            tutorLevel.splice(index, 1);
                                          } else {
                                            tutorLevel.push(tr.name);
                                          }
                                          setBackFilters((prev) => ({
                                            ...prev,
                                            tutorLevel,
                                          }));
                                        }}
                                      />
                                      <label
                                        className="form-check-label px-2"
                                        htmlFor={`tutorLevel,${i}`}
                                      >
                                        {tr?.[window.lang]}
                                      </label>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-heading-Five"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpenBusinessFilters-collapse-Five"
                                aria-expanded="false"
                                aria-controls="panelsStayOpenBusinessFilters-collapse-Five"
                              >
                                <p className="form-titr2">
                                  {" "}
                                  Class Format
                                </p>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpenBusinessFilters-collapse-Five"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-heading-Five"
                            >
                              <div className="accordion-body form-font px-5">
                                {renderData.serviceTypes
                                  .slice(0, 2)
                                  .map((tr, i) => (
                                    <div
                                      key={i}
                                      className="form-check form-switch m-0 py-0 px-1"
                                    >
                                      <input
                                        className="form-check-input form-check-input-filter1 m-0 p-0"
                                        type="checkbox"
                                        role="switch"
                                        name="serviceTypes"
                                        value={tr.name}
                                        id={`serviceTypes,${i}`}
                                        checked={backFilters?.serviceTypes?.includes(
                                          tr.name
                                        )}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const serviceTypes =
                                            backFilters.serviceTypes
                                              ? [...backFilters.serviceTypes]
                                              : [];
                                          const index =
                                            serviceTypes.indexOf(value);
                                          if (index !== -1) {
                                            serviceTypes.splice(index, 1);
                                          } else {
                                            serviceTypes.push(tr.name);
                                          }
                                          setBackFilters((prev) => ({
                                            ...prev,
                                            serviceTypes,
                                          }));
                                        }}
                                      />
                                      <label
                                        className="form-check-label px-2"
                                        htmlFor={`serviceTypes,${i}`}
                                      >
                                        {tr?.[window.lang]}
                                      </label>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-heading-six"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpenBusinessFilters-collapse-six"
                                aria-expanded="false"
                                aria-controls="panelsStayOpenBusinessFilters-collapse-six"
                              >
                                <p className="form-titr2"> Others</p>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpenBusinessFilters-collapse-six"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-heading-six"
                            >
                              <div className="accordion-body form-font px-5">
                                <div className="form-check form-switch m-0 py-0 px-1">
                                  <input
                                    className="form-check-input form-check-input-filter1 m-0 p-0"
                                    type="checkbox"
                                    role="switch"
                                    name="img"
                                    // value={'tr.name'}
                                    id={`imgBackF`}
                                    checked={backFilters?.img}
                                    onChange={(e) => {
                                      setBackFilters((prev) => ({
                                        ...prev,
                                        img: prev?.img?.length
                                          ? ""
                                          : "With photo",
                                      }));
                                    }}
                                  />
                                  <label
                                    className="form-check-label px-2"
                                    htmlFor={`imgBackF`}
                                  >
                                    With photo
                                  </label>
                                </div>
                                <div className="form-check form-switch m-0 py-2 px-1">
                                  <input
                                    className="form-check-input form-check-input-filter1 m-0 p-0"
                                    type="checkbox"
                                    role="switch"
                                    name="video"
                                    // value={'tr.name'}
                                    id={`videoBackF`}
                                    checked={backFilters?.video}
                                    onChange={(e) => {
                                      setBackFilters((prev) => ({
                                        ...prev,
                                        video: prev?.video?.length
                                          ? ""
                                          : "With Video",
                                      }));
                                    }}
                                  />
                                  <label
                                    className="form-check-label px-2"
                                    htmlFor={`videoBackF`}
                                  >
                                    With Video
                                  </label>
                                </div>
                                <div className="form-check form-switch m-0 py-2 px-1">
                                  <input
                                    className="form-check-input form-check-input-filter1 m-0 p-0"
                                    type="checkbox"
                                    role="switch"
                                    name="freeSession"
                                    // value={'tr.name'}
                                    id={`freeSessionBackF`}
                                    checked={backFilters?.freeSession}
                                    onChange={(e) => {
                                      setBackFilters((prev) => ({
                                        ...prev,
                                        freeSession: prev.freeSession?.length
                                          ? ""
                                          : "Trial session",
                                      }));
                                    }}
                                  />
                                  <label
                                    className="form-check-label px-2"
                                    htmlFor={`freeSessionBackF`}
                                  >
                                  Offers Trial Session
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ***************** */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" d-flex flex-wrap my-2 show-filters ">
              {selectedFiltersRender}
              {selectedFiltersRender.length > 0 && (
                <div
                  className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7 px-3 filter-del-btn"
                 onClick={(e) => {
                    // removing all url queries
                    navigate('')
                    setFilters(initialFilters)
                  }}
                >
                  <span>Remove Filters</span>
                </div>
              )}
            </div>

            {/* <!-- Pagination *******************************--> */}
            <div className="mx-1 p-0 ">
              <div className="g-2 mb-3">
                <Pagination
                  data={{ resultsTotal, itemsToShow: filters.itemsToShow }}
                  setPageNumber={pageNumberHandle}
                  pageNumber={filters.pageNumber}
                  setItemsToShow={itemsToShowHandle}
                  position="top"
                />
              </div>

              <div>
                {loading.fetchBus ? (
                  <img src="/img/searching-error.svg" alt="Searching..." />
                ) : (
                  data?.length &&
                  !loading.fetchBus <= 0 && (
                    <div className="row m-5 px-md-5 px-0">
                      <div className="col-md-6 d-flex align-items-center justify-content-center px-0">
                        No Data where found
                        <div
                          className="btn-search shadow-none m-2 d-flex  align-items-center filter-del-btn img-banner-text-7"
                          onClick={(e) =>
                            document.querySelector("#lcsChangeBtn").click()
                          }
                        >
                          Other countries
                        </div>
                      </div>
                      <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <img
                          src="/img/cart-is-empty.svg"
                          alt="cart-is-empty"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  )
                )}
                {/* <!-- ************** Cards************** --> */}

                <div
                  className="row row-cols-1 
              g-4"
                >
                  {data?.map((da, i) => (
                    <div className="metademy-en" key={i}>
                      <TutorCard
                        data={da}
                        currencies={currencies}
                        setVideo={setVideo}
                        setActiveTutor={setActiveTutor}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-5 ">
                <Pagination
                  data={{ resultsTotal, itemsToShow: filters.itemsToShow }}
                  setPageNumber={pageNumberHandle}
                  pageNumber={filters.pageNumber}
                  position="down"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-1  sidebar-all align-items-center text-center  p-md-4 p-1">
            {/* <!-- ********left side *************************************************************** --> */}

            {/* <MainSidebar section={section} /> */}
          </div>
        </div>
        {/* <!-- *********** end of listing ******* --> */}
      </section>

      <section className="mx-md-5 pt-0 mx-0 p-0">
        {/* <!-- تخفیف‌های ویژه --> */}

        {/* <div className="divider2 mb-0">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>          
          </svg>
          <span className="p-0 mb-0 ">معرفی ویژه</span>
        </div> */}

        <PlansX4 plansX4={pubData.ads?.plansX4} page={page} />
      </section>

      {/* <!-- تازه‌ترین اساتید --> */}
      <div className="mx-md-5">
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 "> New Tutors</span>
        </div>
        <HorizontalSlider data={pubData?.newTutors} section={section} />
      </div>
      {/* <!-- تازه‌ترین اساتید end of --> */}

      {/* <!-- تازه‌های بلاگ  --> */}

      {/* <div className=" mx-5 ">
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
          </svg>
          <span className="p-0 mb-0 ">New Posts </span>
        </div>

        <div className="row row-cols-1 row-cols-md-2 g-4 my-5 ads-all mx-auto ">
          {pubData?.newPosts?.length > 0 && (
            <NewPosts2x2 newPosts={pubData.newPosts} user={user} />
          )}
        </div>
      </div> */}

      {/* <!-- *********************************** end of main ******************************************************************** --> */}

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>

      {/* <!-- **********end of back to top************************* --> */}
      {/* <!-- Modal Video --> */}
      <div
        className="modal fade"
        id="showVideoModal"
        tabIndex="-1"
        aria-labelledby="showYoutubeIframe"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                // id="showYoutubeIframe"
              >
                Display Video
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setVideo("");
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="ratio ratio-16x9 video-frame">
                {tools.generateYoutubeIframe(video)}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Message Modal */}
      <div
        className="modal fade"
        id="messageModal"
        tabIndex="-1"
        aria-labelledby="sendMessageModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title titr5 text-gray-green "
                id="sendMessageModalLabel"
              >
                Sending text to {activeTutor?.name?.[window.lang]}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-7">
                  <div>
                    <label
                      className="med-gray-font m-3"
                      htmlFor="sessionRequest"
                    >
                      می‌تونید خودتون رو این‌جا معرفی کنید، درباره هدفتون از
                      شرکت در کلاس با استادتون صحبت کنید و اگه پرسشی داشتید
                      بپرسید.
                    </label>
                    <textarea
                      placeholder="پیامتون رو این‌جا بنویسید..."
                      className="w-100 px-3 border-0"
                      name=""
                      id="sessionRequest"
                      cols="50"
                      rows="10"
                      onChange={(e) =>{
                        e.target.style.height = "auto";
                        e.target.style.height = e.target.scrollHeight + "px";
                        setRequest((pre) => ({
                          ...pre,
                          message: e.target.value,
                        }))}
                      }
                    ></textarea>
                    <button
                      type="button"
                      className="btn btn-primary border-0 bg-pinkOrange save-cover-btn w-100 px-3"
                      // data-bs-dismiss="modal"
                      onClick={sendRequestHandle}
                    >
                      فرستادن پیام
                    </button>
                  </div>
                </div>
                <div className="col-md-5 align-items-center  display-none-md">
                  <img
                    src="/img/Metademy/Messaging.svg"
                    alt="claim"
                    loading="lazy"
                    // style={{ verticalAlign: "center" }}
                  />
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
                   
                      <button
                        type="button"
                        className="btn btn-outline-secondary save-cover-btn ms-3"
                        data-bs-dismiss="modal"
                      >
                        بستن
                      </button>
                    </div> */}
          </div>
        </div>
      </div>
    </div>
  );

  async function sendRequestHandle(e) {
    if (!window.logined(9999)) return;
    // console.log(request);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (request.message.length < 10)
      return messageRef.current.showError({
        message: " لطفا توضیحات بیشتری بنویسید.",
      });
    if (request.message.length > 1000)
      return messageRef.current.showError({
        message: " لطفا توضیحات را به صورت مختصر بنویسید.",
      });
    if (request.email.length && !emailRegex.test(request.email))
      return messageRef.current.showError({
        message: "فرمت ایمیل درست نمی باشد.",
      });

    e.target.setAttribute("disabled", true);

    setLoading((pre) => ({ ...pre, spinner: true }));
    const email = request.email.length ? request.email : user.email;
    if (!activeTutor?.moreInfo?.history?.createdBy?._id)
      return messageRef.current.showError({
        message: "شناسه کاربری استاد فراهم نشده است",
      });
    const response = await apiDemy.reqTutor({
      token: user.token,
      data: {
        message: request.message,
        guestEmail: email,
        tutor: activeTutor.name,
        lcs: activeTutor.lcs,
        hostEmail: activeTutor.email,
        hostUser: activeTutor.moreInfo.history.createdBy._id,
        hostId: activeTutor._id,
        lang: window.lang,
        guestId: user._id,
      },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    // setTimeout(() => e.target.removeAttribute("disabled"), 10000);
    if (response.error)
      return messageRef.current.showError({
        message: "خطا در انجام عملیات : " + response.error,
      });
    messageRef.current.showSuccess({
      message: (
        <div>
          <div>درخواست شما با موفقیت ثبت شد</div>
          <div>استاد در متاچت به درخواست شما پاسخ خواهد داد</div>
        </div>
      ),
    });
    // setData(response.data);
    var myModal = document.getElementById("messageModal"); // Get the modal element
    var modal = window.bootstrap.Modal.getInstance(myModal);
    modal.hide();
    document.querySelector("textarea").value = "";
    setRequest({ message: "", email: "" });
  }

  function countrySet(countryShort) {
    const coun = countryStates.find(
      (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    );
    setStates(coun.states);
    setFilters((prev) => ({
      ...prev,
      country: coun.country,
      countryShort: countryShort.toLowerCase(),
      state: "",
      stateShort: "",
      states: [],
      cities: [],
    }));
  }
}
