import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import { CheckCircle, Error, Publish, Translate } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
// import AddSocialMedia from "../../../persian/addSocialMedia";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import RegisterAddressPlaces from "../../business/register/registerAddressPlaces";
import MyIcons from "../../../golbal/icons/myIcons";
import { Link, useNavigate, useParams } from "react-router-dom";
import renderData from "../../../../utils/renderData";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import apiDemy from "../../../../utils/apiDemy";
import addErrClass from "../../../persian/common/alerts/addErrClass";
import api from "../../../../utils/api";
import MetaDecorator from "../../../../utils/metaDecorator";
import demyData from "../../../../utils/demyData";
import tools from "../../../../utils/tools";
import CatsSelector from "../../../golbal/catsSelector";
import AddLevelSub from "./addLevelSub";
import LangsLevel from "../../../persian/common/LangsLevel";
import Schedule from "../../../golbal/schedule";
import CountryStateCitySelector from "../../../golbal/dropdown/countryStateCitySelector";
import FormikListErr from "../../../persian/common/alerts/formikListErr";
// import Schedule from "../../../golbal/schedule";

export default function TutorRegister({ action }) {
  const { lang, state:{state,stateShort}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  const [resisedPhotoAuth, setResisedPhotoAuth] = useState(null);
  const [originalPhoto, setOriginalPhoto] = useState(null);
  // const [resisedLogoAuth, setResisedLogoAuth] = useState(null);
  // const [originalLogo, setOriginalLogo] = useState(null);
  const photoHandle = async (e, setResisedState, setOriginalState) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: " لطفااز فایل با فرمت عکس استفاده کنید.",
      });
    setResisedState(
      await tools.resizeFile({
        file: e.target.files[0],
      })
    );
    setOriginalState(e.target.files[0]);
  };
  const uploadFileDatabase = async (e, file, field, fieldState) => {
    e.preventDefault();
    if (!file) return;
    const resizedPhotoBlob = await tools.resizeFile({
      file,
      blob: true,
      width: 500,
      height: 500,
    });
    const formData = new FormData();
    formData.append("file", resizedPhotoBlob);
    console.log("formData", formData);
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: "images/demy/tutor",
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div> <div dir="ltr">{response.error}</div>
          </div>
        ),
      });

    console.log(response.data);
    formik.setValues((pre) => ({ ...pre, [field]: response.data }));
    fieldState(null);
    messageRef.current.showSuccess({
      message:
        "عکس با موفقیت بارگذاری شد، برای ثبت کلید بفرست/ویرایش را فشار دهید. ",
    });
  };

  const [currencies, setCurrencies] = useState([
    { currency: "CAD", currency_name: "CAD", exRate: 1 },
    { currency: "USD", currency_name: "USD", exRate: 0.8 },
    { currency: "EUR", currency_name: "EUR", exRate: 0.7 },
    { currency: "AUD", currency_name: "AUD", exRate: 1.2 },
    { currency: "GBP", currency_name: "GBP", exRate: 0.6 },
    { currency: "IRR", currency_name: "ریال", exRate: 500000 },
  ]);
  // console.log("originalPhoto", originalPhoto);
  // console.log("resisedPhotoAuth", resisedPhotoAuth);
  async function fetchCurrencies() {
    setLoading((pre) => ({ ...pre, currencies: true }));
    const response = await api.getAcceptedCur({
      token: user.token,
      accepted: "all",
    });
    setLoading((pre) => ({ ...pre, currencies: false }));
    if (response.error)
      return messageRef.current.showError({
        message: `مشکل در انجام عملیات : ${response.error}`,
      });
    setCurrencies(response.data);
  }
  const handleChange = (e) => {
    const value = e.target.value;
    const exRate =
      e.target.options[e.target.selectedIndex].getAttribute("data-exRate");
    console.log(value, exRate);

    if (value !== "more")
      return formik.setValues((pre) => ({
        ...pre,
        salary: {
          ...pre.salary,
          currency: value,
          exRate,
          cadValue: Number(pre.salary.rate) / Number(exRate),
        },
      }));

    fetchCurrencies();
  };

  const messageRef = useRef(null);
  const navigate = useNavigate();
  const { user, signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false, addSub: false });
  const countryDetails = JSON.parse(localStorage.getItem("countryDetails"));

  // const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  // const country = regionNames.of(countryShort.toUpperCase()).toLowerCase();
  const { id } = useParams();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const urlRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
  const englishRegex = /^[a-zA-Z0-9 ]+$/;

  const formik = useFormik({
    initialValues: {
      action,
      terms: false,
      img: "",
      logo: "",
      _id: "",
      lang,
      listingLangs: [window.lang],
      name: { fa: "", en: "", fr: "" },
      education: { fa: "", en: "", fr: "" },
      experience: { fa: "", en: "", fr: "" },
      about: { fa: "", en: "", fr: "" },
      intro: { fa: "", en: "", fr: "" },
      lcs: window.lcs,
      email: user?.email || "",
      serviceTypes: ["online"],
      ageGroups: [],
      // availability: [{ availableDay: "", availableTime: "" }],
      distances: [{ distance: "", cost: "" }],
      studyArea: [],
      // sex: "",
      freeSession: true,
      isActive: true,
      tags:`تدریس خصوصی در ${countryDetails.translations[window.lang]||country}+ معلم خصوصی ${countryDetails.translations[window.lang]||country}+ بهترین اساتید ${countryDetails.translations[window.lang]||country}`,
      levelSub: [{ level: "", subjects: [] }],
      category: {
        mainCat: {},
        subCat: {},
      },
      location: {
        displayAddress: false,
        googleAddress: "",
        country,
        countryShort,
        state,
        stateShort,
        city: "",
        route: "",
        municipality: "",
        streetNumber: "",
        number: "",
        postalCode: "",
        lat: "",
        lng: "",
      },
      salary: {
        period: "hourly",
        rate: 0,
        currency: countryDetails?.currency,
        exRate: countryDetails?.exRate,
        cadValue: 0,
      },
      bankInfo: {
        confirmed: false,
        firstName: "",
        lastName: "",
        paymentMethod: "paypal",
        email: user?.email || "",
        accountNumber: "",
        institution: "",
        currency: countryDetails?.currency,
        exRate: countryDetails?.exRate,
      },
      languages: [{ name: "", level: "" }],
      schedule: [
        {
          date: new Date(),
          weekDay: "Monday",
          slots: [
            {
              start: "09:00",
              duration: 1,
              booked: false,
              bookInfo: {
                userName: "",
                _id: "",
                bookDate: "",
                confirmed: false,
              },
            },
          ],
        },
      ],
      timeZone: timeZone,
      video: {
        fa: { description: "", youtube: "" },
        en: { description: "", youtube: "" },
        fr: { description: "", youtube: "" },
      },
    },
    onSubmit: async (values) => {
      if (values?.action === "edit")
        return editHandle({ messageRef, setLoading, user, values, navigate });

      if (
        !values.img.length &&
        window.confirm(
          " عکس پروفایل شما بارگذاری نشده‌است، آیا میخواهید بدون عکس ادامه دهید ؟"
        ) !== true
      )
        return;
      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await apiDemy.registerTutor({
        token: user.token,
        data: values,
      });
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "تبریک، اطلاعات استاد با موفقیت ثبت شد.",
      });

      const updateduser = await api.getUser();
      if (updateduser.error) return;
      signedUser(updateduser.data);
      window.scrollTo({ top: "0", behavior: "smooth" });
      window.navigateHandle(`/${window.lang}/demy/tutor/page/${tools.slugMaker({title:response.data.name?.[window.lang],city:response.data?.moreInfo?.address?.city,state:response.data?.moreInfo?.address?.state,serial:response.data?.serial})}`,response.data)
      // navigate(`/${window.lang}/demy/tutor/page/${tools.slugMaker({title:response.data.name?.[window.lang],city:response.data?.moreInfo?.address?.city,state:response.data?.moreInfo?.address?.state,serial:response.data?.serial})}`, {
      // state: response.data,
      // });
    },
    validationSchema: Yup.object({
      terms: Yup.boolean().oneOf(
        [true],
        "شرایط تدریس را مطالعه و دکمه پذیریش را بزنید."
      ),
      serviceTypes: Yup.array().test(
        "at-least-one-type",
        "لطفا دست کم یک نوع کلاس را انتخاب نمائید.",
        function (value) {
          // Check if the array contains "inPerson" or "Online"
          return value.includes("inPerson") || value.includes("online");
        }
      ),
      name: Yup.object().shape({
        fa: Yup.string()
          .required(" لطفا نام استاد را انتخاب کنید.")
          .min(3, "نام حداقل باید ۳ حرف باشد.")
          .max(50, "نام حداکثر باید 50 حرف باشد.")
          .trim(),
        en: Yup.string()
          .matches(englishRegex, "Please use English letters only")
          .min(3, "Name must be 3 letters and more.")
          .max(50, "Name must be 50 letters and less.")
          .trim(),
        fr: Yup.string()
          .matches(englishRegex, "Please use English letters only")
          .min(3, "Name must be 3 letters and more.")
          .max(50, "Name must be 50 letters and less.")
          .trim(),
      }),

      education: Yup.object().shape({
        fa: Yup.string()
          .max(4000, "توضیحات حداکثر باید 4000 حرف .")
          .min(10, "توضحیات حداقل باید 10 حرف باشد.")
          .required("لطفاً تحصیلات خود را را وارد کنید.")
          .trim(),
        en: Yup.string()
          .matches(englishRegex, "Please use English letters only")
          .max(4000, "The education section must be 4,000 characters or fewer."),
        fr: Yup.string()
          .matches(englishRegex, "Please use English letters only")
          .max(4000, "The education section must be 4,000 characters or fewer."),
      }),
      experience: Yup.object().shape({
        fa: Yup.string()
          .max(4000, "تجربه حداکثر باید 4000 حرف .")
          .min(10, "تجربه حداقل باید 10 حرف باشد.")
          .required("لطفاً تجربه کاری خود را را وارد کنید.")
          .trim(),
        en: Yup.string()
          .matches(englishRegex, "Please use English letters only")
          .max(4000, "The experience section must be 4,000 characters or fewer."),
        fr: Yup.string()
          .matches(englishRegex, "Please use English letters only")
          .max(4000, "The experience section must be 4,000 characters or fewer."),
      }),
      about: Yup.object().shape({
        fa: Yup.string()
          .max(4000, "درباره من حداکثر باید 4000 حرف .")
          .min(10, "درباره من حداقل باید 10 حرف باشد.")
          // .required("لطفاً درباره من را وارد کنید.")
          .trim(),
        en: Yup.string()
          .matches(englishRegex, "Please use English letters only")
          .max(4000, "The about me section must be 4,000 characters or fewer."),
        fr: Yup.string()
          .matches(englishRegex, "Please use English letters only")
          .max(4000, "The about me section must be 4,000 characters or fewer."),
      }),
      intro: Yup.object().shape({
        fa: Yup.string()
          .max(250, "خلاصه من حداکثر باید 250 حرف .")
          .min(10, "خلاصه من حداقل باید 10 حرف باشد.")
          .required("لطفاً خلاصه من را وارد کنید.")
          .trim(),
        en: Yup.string()
          .matches(englishRegex, "Please use English letters only")
          .max(250, "The intro must be 250 characters or fewer."),
        fr: Yup.string()
          .matches(englishRegex, "Please use English letters only")
          .max(250, "The intro must be 250 characters or fewer."),
      }),

      category: Yup.object().shape({
        mainCat: Yup.object().shape({
          _id: Yup.string().required(" دسته‌بندی را انتخاب کنید."),
        }),
        // subCat: Yup.object().shape({
        //   _id: Yup.string().required(" دسته بندی کوچکتر را انتخاب کنید."),
        // }),
      }),

      email: Yup.string()
        .required("لطفا ایمیل خود را وارد نمائید.")
        .trim()
        .lowercase()
        .email("ایمیل را به درستی وارد کنید."),
      // languages: Yup.array()
      //   .required(" دست کم یک زبان را انتخاب کنید.")
      //   .test(
      //     "array-length",
      //     " دست کم یک زبان را انتخاب کنید.",
      //     (value) => value.length > 0
      //   ),

      bankInfo: Yup.object().shape({
        confirmed: Yup.boolean(),
        firstName: Yup.string().when("confirmed", {
          is: true,
          then: Yup.string()
            .required("نام نمیتواند خالی باشد.")
            .min(3, "نام حداقل باید 3 حرف باشد.")
            .max(30, "نام حداکثر باید 30 حرف باشد."),
        }),
        lastName: Yup.string().when("confirmed", {
          is: true,
          then: Yup.string()
            .required("نام خانوادگی نمیتواند خالی باشد.")
            .min(3, "نام خانوادگی حداقل باید 3 حرف باشد.")
            .max(30, "نام خانوادگی حداکثر باید 30 حرف باشد."),
        }),
        email: Yup.string().when(["confirmed", "paymentMethod"], {
          is: (confirmed, paymentMethod) =>
            paymentMethod === "paypal" && confirmed === true,
          then: Yup.string()
            .required("لطفا ایمیل خود را وارد نمائید.")
            .trim()
            .lowercase()
            .email("ایمیل را به درستی وارد کنید."),
        }),
        paymentMethod: Yup.string().required(
          "لطفا روش پرداخت را انتخاب نمائید."
        ),
        accountNumber: Yup.string().when(["confirmed", "paymentMethod"], {
          is: (confirmed, paymentMethod) =>
            paymentMethod === "sheba" && confirmed === true,
          then: Yup.string().required("شماره شبای خود را وارد نمائید."),
        }),
        institution: Yup.string().when(["confirmed", "paymentMethod"], {
          is: (confirmed, paymentMethod) =>
            paymentMethod === "sheba" && confirmed === true,
          then: Yup.string().required("نام بانک خود را وارد نمائید."),
        }),
      }),
      location: Yup.object().shape({
        country: Yup.string().required("کشور نمیتواند خالی باشد."),
        state: Yup.string().required("استان نمیتواند خالی باشد."),
        city: Yup.string().when("serviceTypes", {
          is: (serviceTypes) =>
            serviceTypes && serviceTypes.includes("inPerson"),
          then: Yup.string().required("شهر نمیتواند خالی باشد."),
          otherwise: Yup.string().notRequired(),
        }),
        municipality: Yup.string(),
      }),

      // location: Yup.object().shape({
      //   country: Yup.string().required("کشور نمیتواند خالی باشد."),
      //   state: Yup.string().required("استان نمیتواند خالی باشد."),
      //   city: Yup.string().notRequired("شهر نمیتواند خالی باشد."),
      //   municipality: Yup.string(),
      // }),

      // location: Yup.object().when("serviceTypes", {
      //   is: (serviceTypes) => serviceTypes && serviceTypes.includes("inPerson"),
      //   then: Yup.object().shape({
      //     country: Yup.string().required("کشور نمیتواند خالی باشد."),
      //     state: Yup.string().required("استان نمیتواند خالی باشد."),
      //     city: Yup.string().required("شهر نمیتواند خالی باشد."),
      //     municipality: Yup.string(),
      //   }),
      // }),

      languages: Yup.array().of(
        Yup.object().shape({
          level: Yup.string().required("لطفا سطح زبانی خود را انتخاب نمائید."),
          name: Yup.string().required("لطفا زبان را انتخاب نمائید."),
        })
      ),
      // availability: Yup.array().of(
      //   Yup.object().shape({
      //     availableDay: Yup.string().required("لطفا روزهای کاری را انتخاب نمائید."),
      //     availableTime: Yup.string().required("لطفا ساعات کاری را انتخاب نمائید."),
      //   })
      // ),
      distances: Yup.array().when("serviceTypes", {
        is: (serviceTypes) => serviceTypes && serviceTypes.includes("inPerson"),
        then: Yup.array().of(
          Yup.object().shape({
            distance: Yup.string().required("لطفا فاصله را انتخاب نمائید"),
            cost: Yup.string().required("لطفا هزینه را انتخاب نمائید."),
          })
        ),
      }),

      salary: Yup.object().shape({
        currency: Yup.string().required("لطفا نرخ تدریس را وارد کنید"),
        rate: Yup.number()
          .min(1, "لطفا مبلغ درخواستی را بزرگتر از 0 انتخاب کنید.")
          .required("لطفا واحد پول مورد نظر را انتخاب کنید."),
      }),
      levelSub: Yup.array().of(
        Yup.object().shape({
          level: Yup.string().required("لطفا مقطع آموزشی را انتخاب کنید."),
          subjects: Yup.array()
            .of(Yup.string()) // Validate individual subject items if needed
            .min(1, "لطفا دست کم یک سرفصل آموزشی را انتخاب کنید."), // Ensure at least one subject is entered
        })
      ),
      schedule: Yup.array().of(
        Yup.object().shape({
          weekDay: Yup.string().required(
            "لطفا دست کم یک روز هفته را انتخاب نمائید."
          ),
          slots: Yup.array().of(
            Yup.object().shape({
              start: Yup.string().required("لطفا ساعت شروع را انتخاب نمائید."),
              duration: Yup.number().required(
                "لطفا مدت کلاس را انتخاب نمائید."
              ),
            })
          ),
        })
      ),
    }),
  });

  useEffect(() => {
    fetchDemyDetails();
    // fetchCurrencies()
    // fetchSubjects()
  }, [id]);

  async function fetchDemyDetails() {
    console.log(id);
    if (!id) return;
    const response = await apiDemy.fetchTutorDetails(id);
    if (response.error) return alert(response.error);
    // console.log(response.data);
    editInitialData(formik, response.data, action);
  }

  console.log("formik", formik.values);
  // console.log("formik.errors", formik.errors);
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Tutor Register",
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/demy`,
              name: `آکادمی متامارس`,
              description:
                "پل ارتباطی بین دانشجوها و مدرسین مدرسه و دانشگاه و تدریس خصوصی در شهرها و محله‌های گوناگون ... ",
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/dashboard/demy/registercenter`,
              name: "ثبت رایگان صفحه استاد",
              description: "ثبت آسان، انتشار سریع و خودکار صفحه استاد",
            },
          },
        ],
      },
    },
  ];

  const metaTagsData = {
    title: "ثبت نام استاد",
    description: "ثبت اطلاعات مربوط به تدریس",
    section: "Tutor register",
    url: `https://www.metamarce.com/${window.lang}/demy/registertutor`,
    canonical: `https://www.metamarce.com/${window.lang}/demy/registertutor`,
    img: "https://www.metamarce.com/img/demy/Hiring-rafiki.svg",
    type: "webPage",
  };

  return (
    <div className="user max-w-m mx-auto mt-3  px-0">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {loading.spinner && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />

      <p className="divider2 border-0 mt-3 ">
           <MyIcons icon="dotsTitr" />
        <span className="p-0 m-z ">ثبت نام استاد</span>
        <span></span>
      </p>
      {user?.roles?.includes(9999) ? (
        <form
          onSubmit={formik.handleSubmit}
          className="row userShow"
          name="tutorRegForm00"
          autoComplete="true"
        >
          {/* Right Section */}

          <div className=" px-0 px-md-3">
            <div>
              {action === "edit"&&<div className="userShow form-check form-switch py-3 px-2  mx-0 mx-md-5 text-red-dark bg-beigeGray">
                <input
                  className="form-check-input form-check-input-filter1  m-0 p-0"
                  type="checkbox"
                  role="switch"
                  id="activeSwitch"
                  checked={formik.values?.isActive}
                  onChange={(e) =>
                    formik.setValues((prev) => ({
                      ...prev,
                      isActive: !formik.values.isActive,
                    }))
                  }
                />
                <label
                  className="form-check-label ps-2 pe-0 mt-0"
                  htmlFor="activeSwitch"
                >
                  نمایش در لیست اساتید
                  <div className="small-gray-font font-10 my-2">
                    (با خاموش کردن این کلید پروفایل شما به صورت موقت غیرفعال
                    می‌شود.)
                  </div>
                </label>
              </div>}

              <div className="row">
                <div className="col-md-6 d-flex flex-column">
                  <div className="mt-auto">
                    <label
                      className="form-label mt-3 titr6 mb-0 required"
                      htmlFor="name"
                    >
                      نام
                      <div className="small-gray-font font-10 my-2">
                        (عنوانی که در پروفایل شما نمایش داده می‌شود.)
                      </div>
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className={`form-control py-1 box2 ${addErrClass({
                        formik,
                        field: `name.${window.lang}`,
                      })} `}
                      id="name"
                      placeholder=""
                      name="name"
                      {...formik.getFieldProps(`name[${window.lang}]`)}
                    />
                    <FormikErrRender
                      formik={formik}
                      field={`name.${window.lang}`}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="userUpdateUpload d-flex flex-column justify-content-center m-0 mt-3">
                    <img
                      src={resisedPhotoAuth || formik.values?.img}
                      alt={formik.values.name?.[window.lang]}
                      className="userShowImg-profile"
                      loading="lazy"
                    />

                    <label className="my-2" htmlFor="fileAuth">
                      <Publish className="userUpdateIcon" />
                      انتخاب عکس پروفایل
                    </label>
                    <input
                      type="file"
                      id="fileAuth"
                      onChange={(e) =>
                        photoHandle(e, setResisedPhotoAuth, setOriginalPhoto)
                      }
                      style={{ display: "none" }}
                    />
                    <span
                      className="userUpdateButton edit-btn"
                      style={{ display: resisedPhotoAuth ? "block" : "none" }}
                      onClick={(e) =>
                        uploadFileDatabase(
                          e,
                          originalPhoto,
                          "img",
                          setResisedPhotoAuth
                        )
                      }
                    >
                      بارگذاری
                    </span>
                    {user?.roles?.includes(1000) && (
                      <span
                        onClick={(e) => {
                          setResisedPhotoAuth(null);
                          setOriginalPhoto(null);
                          formik.setFieldValue("img", "");
                        }}
                      >
                        <MyIcons icon={"delete2"} />
                      </span>
                    )}
                  </div>

                  {/* <div className="userUpdateUpload d-flex flex-column justify-content-center">
              <img
                src={resisedLogoAuth || formik.values?.logo}
                alt={formik.values.name[window.lang]}
                className="userShowImg-profile"
                loading="lazy"
              />
              <label className="my-2" htmlFor="filelogo">
                <Publish className="userUpdateIcon" />
                Change Logo
              </label>
              <input
                type="file"
                id="filelogo"
                onChange={(e) =>
                  photoHandle(e, setResisedLogoAuth, setOriginalLogo)
                }
                style={{ display: "none" }}
              />
              <span
                className="userUpdateButton edit-btn"
                style={{ display: resisedLogoAuth ? "block" : "none" }}
                onClick={(e) =>
                  uploadFileDatabase(
                    e,
                    originalLogo,
                    "logo",
                    setResisedLogoAuth
                  )
                }
              >
                بارگذاری
              </span>
            </div> */}
                </div>
              </div>
              <p className="form-label mt-5 titr6 mb-0">
                توانایی‌های تدریس و دروس مورد نظر{" "}
              </p>
              <CatsSelector formik={formik} section={"tutor"} />

              <div>
                {formik.values?.category?.mainCat?._id?.length && (
                  <div>
                    <p className="form-label titr6 required">مقطع و نام درس</p>
                    <AddLevelSub
                      formik={formik}
                      user={user}
                      messageRef={messageRef}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  </div>
                )}
              </div>

              {/* <div className="d-flex">
                    <p className="form-label mt-3 titr6 mb-0">جنسیت</p>
                    {renderData?.sex?.map((gen) => (
                      <div className="form-check form-check-px1 m-2">
                        <input
                          checked={formik.values.sex === gen.name}
                          className="form-check-input"
                          type="radio"
                          name="sex"
                          id={"sex" + gen.name}
                          value={gen.name}
                          onChange={(e) =>
                            formik.setValues((pre) => ({
                              ...pre,
                              sex: e.target.value,
                            }))
                          }
                        />
                        <label
                          className="form-check-label d-flex"
                          htmlFor={"sex" + gen.name}
                        >
                          {gen[window.lang]}
                        </label>
                      </div>
                    ))}
                  </div> */}

              <p className="form-label mt-5 titr6 ">گروههای سنی</p>
              <div className="d-flex flex-wrap mb-5">
                {renderData.ageGroups.map((age, i) => (
                  <div key={i} className="form-check mx-3">
                    <input
                      className="form-check-input px-1"
                      type="checkbox"
                      id={"ageGroups" + age.name}
                      checked={formik.values?.ageGroups?.includes(age.name)}
                      value={age.name}
                      onChange={(e) =>
                        formik.setValues((pre) => {
                          const ageGroups = [...pre.ageGroups];
                          const valueIndex = ageGroups.indexOf(age.name);
                          if (valueIndex !== -1) {
                            ageGroups.splice(valueIndex, 1);
                          } else {
                            ageGroups.push(age.name);
                          }
                          return { ...pre, ageGroups };
                        })
                      }
                    />
                    <label
                      className="form-check-label "
                      htmlFor={"ageGroups" + age.name}
                    >
                      {age[window.lang]}
                    </label>
                  </div>
                ))}
              </div>
              <div className="d-flex my-5">
                <p className="form-label  titr6 required">نوع کلاس</p>
                {renderData.serviceTypes.slice(0, 2).map((type, i) => (
                  <div key={i} className="form-check mx-3">
                    <input
                      className="form-check-input px-1"
                      type="checkbox"
                      id={"serviceTypes" + type.name}
                      checked={formik.values?.serviceTypes?.includes(type.name)}
                      value={type.name}
                      onChange={(e) =>
                        formik.setValues((pre) => {
                          const serviceTypes = [...pre.serviceTypes];
                          const valueIndex = serviceTypes.indexOf(type.name);
                          if (valueIndex !== -1) {
                            serviceTypes.splice(valueIndex, 1);
                          } else {
                            serviceTypes.push(type.name);
                          }
                          return { ...pre, serviceTypes };
                        })
                      }
                    />
                    <label
                      className="form-check-label "
                      htmlFor={"serviceTypes" + type.name}
                    >
                      {type[window.lang]}
                    </label>
                  </div>
                ))}
              </div>
              <FormikErrRender formik={formik} field={"serviceTypes"} />

              {formik.values?.serviceTypes?.includes("inPerson") ? (
                <div className="tutor-color16 p-2">
                  <div>
                    <div className="form-label titr6 mb-0  border-0 required">
                      محل برگزاری کلاس
                    </div>
                    <RegisterAddressPlaces formik={formik} section="demy" />
                    <div className="form-check form-switch  py-0 px-1">
                      <input
                        className="form-check-input form-check-input-filter1  m-0 p-0"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked={formik.values.location?.displayAddress}
                        onChange={(e) =>
                          formik.setValues((prev) => ({
                            ...prev,
                            location: {
                              ...prev.location,
                              displayAddress:
                                !formik.values.location?.displayAddress,
                            },
                          }))
                        }
                      />
                      <label
                        className="form-check-label px-2 mt-0"
                        htmlFor="flexSwitchCheckChecked"
                      >
                        نمایش نشانی به دانشجویان !
                      </label>
                    </div>
                  </div>
                  <p className="form-label titr6 mt-5">
                    تا چه فاصله‌ای امکان ملاقات حضوری دارید؟{" "}
                  </p>
                  <AddDistances formik={formik} />
                </div>
              ) : (
                <div>
                  <p className="form-label mt-5 titr6 required"> محل زندگی</p>
                  <CountryStateCitySelector formik={formik} />
                </div>
              )}

              {/* <div className="d-flex ">
                    <p className="form-label titr6"> روز و ساعت کاری</p>
                    <AddAvailability formik={formik} />
                    <FormikErrRender formik={formik} field="availableDay" />
                  </div> */}

              <div className="my-5">
                <label htmlFor="salary-rate" className="form-label titr6">
                  نرخ پایه براساس هر ساعت
                  {
                    <span className="small-gray-font font-10 my-2">
                      {" "}
                      ({formik.values.salary.currency})
                    </span>
                  }
                </label>
                <div className="d-flex">
                  <div className=" mx-2">
                    <input
                      id="salary-rate"
                      type="number"
                      autoComplete="off"
                      aria-label="Amount"
                      className={`form-control ${addErrClass({
                        formik,
                        field: "salary.rate",
                      })}`}
                      placeholder="با حروف لاتین تایپ کنید"
                      // {...formik.getFieldProps("salary.rate")}
                      value={formik.values.salary.rate}
                      onChange={(e) =>
                        formik.setValues((pre) => ({
                          ...pre,
                          salary: {
                            ...pre.salary,
                            rate: e.target.value,
                            cadValue:
                              Number(e.target.value) /
                              Number(pre.salary?.exRate),
                          },
                        }))
                      }
                    />
                  </div>
                  {loading.currencies ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    <select
                      className={`login-btn my-0 ${addErrClass({
                        formik,
                        field: "salary.currency",
                      })}`}
                      name="salaryCurrency"
                      value={formik.values.salary.currency}
                      onChange={handleChange}
                    >
                      <option value="">انتخاب کنید :</option>
                      {currencies.map((curr) => (
                        <option
                          key={curr.currency} // Add a unique key
                          value={curr.currency} // Set the currency value
                          data-exRate={curr.exRate} // Set the exchange rate in dataset
                        >
                          {curr.currency_name}
                        </option>
                      ))}
                      {currencies.length < 10 && (
                        <option value="more">
                          {" "}
                          <div className="bg-redDark font-20">موارد بیشتر </div>
                        </option>
                      )}
                    </select>
                  )}
                </div>
                <FormikErrRender formik={formik} field="salary.rate" />
                <FormikErrRender formik={formik} field="salary.currency" />
              </div>
              {/* Left Section */}
              <div className="userUpdateItem  ">
                <div className="my-3">
                  <span className="p-0  titr6 required"> مهارتهای زبانی</span>
                  <LangsLevel formik={formik} />
                </div>

                <div className="my-5">
                  <p id="tagsInput" className="mb-3 titr6 ">
                    برچسب‌ها
                    <Tooltip1
                      message={
                        <div>
                          <div>
                            برچسب‌ها - تگ‌ها - را توسط '+'از یکدیگر جداکنید.
                          </div>
                        </div>
                      }
                    />
                    <div className="small-gray-font">(با "+" جدا شوند )</div>
                    <input
                      type="text"
                      autoComplete="off"
                      name="other"
                      placeholder={
                        formik?.values?.tags ||
                        `برای مثال : عمران+تهران+آمادگی کنکور ارشد`
                      }
                      className="form-control mt-3"
                      {...formik.getFieldProps("tags")}
                    />
                  </p>
                </div>
              </div>
              <div className="mb-5">
                <label
                  htmlFor="educationTextarea"
                  className="form-label titr6 required"
                >
                  تحصیلات
                </label>
                <textarea
                  id="educationTextarea"
                  className={`form-control ${addErrClass({
                    formik,
                    field: `education.${window.lang}`,
                  })}`}
                  rows="3"
                  name="education"
                  defaultValue={formik.values.education[window.lang]}
                  onChange={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                    }}
                  onBlur={(e) =>
                    formik.setValues((pre) => ({
                      ...pre,
                      education: {
                        ...pre.education,
                        [window.lang]: e.target.value,
                      },
                    }))
                  }
                ></textarea>
                <FormikErrRender
                  formik={formik}
                  field={`education.${window.lang}`}
                />
              </div>
              <div className="mb-5">
                <label
                  htmlFor="experienceTextarea"
                  className="form-label titr6 required"
                >
                  تجربه
                </label>
                <textarea
                  id="experienceTextarea"
                  className={`form-control ${addErrClass({
                    formik,
                    field: `experience.${window.lang}`,
                  })}`}
                  rows="3"
                  name="experience"
                  defaultValue={formik.values.experience?.[window.lang]}
                  onChange={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                    }}
                  onBlur={(e) =>
                    formik.setValues((pre) => ({
                      ...pre,
                      experience: {
                        ...pre.experience,
                        [window.lang]: e.target.value,
                      },
                    }))
                  }
                ></textarea>
                <FormikErrRender
                  formik={formik}
                  field={`experience.${window.lang}`}
                />
              </div>
              <div className="mb-5">
                <label htmlFor="aboutTextarea" className="form-label titr6">
                  درباره من
                </label>
                <textarea
                  id="aboutTextarea"
                  className={`form-control ${addErrClass({
                    formik,
                    field: `about.${window.lang}`,
                  })}`}
                  rows="3"
                  name="about"
                  defaultValue={formik.values.about?.[window.lang]}
                  onChange={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                    }}
                  onBlur={(e) =>
                    formik.setValues((pre) => ({
                      ...pre,
                      about: { ...pre.about, [window.lang]: e.target.value },
                    }))
                  }
                ></textarea>
                <FormikErrRender
                  formik={formik}
                  field={`about.${window.lang}`}
                />
              </div>
              <div className="mb-5">
                <label
                  htmlFor="introTextarea"
                  className="form-label titr6 required"
                >
                  معرفی مختصر
                  <div className="small-gray-font font-10 my-2">
                    (برای نمایش در لیست اساتید)
                  </div>
                </label>
                <textarea
                  id="introTextarea"
                  className={`form-control ${addErrClass({
                    formik,
                    field: `intro.${window.lang}`,
                  })}`}
                  rows="3"
                  name="intro"
                  defaultValue={formik.values.intro?.[window.lang]}
                  onChange={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                    }}
                  onBlur={(e) =>
                    formik.setValues((pre) => ({
                      ...pre,
                      intro: {
                        ...pre.intro,
                        [window.lang]: e.target.value,
                      },
                    }))
                  }
                ></textarea>
                <FormikErrRender
                  formik={formik}
                  field={`intro.${window.lang}`}
                />
              </div>
              <div>
                <div className="modal-header titr6">
                  <div className="modal-title fs-5" id="sendMessageModalLabel">
                    تنظیم برنامه کلاس ها
                    <Tooltip1
                      message={
                        <div>
                          <div>
                            دانشجویان متادمی از میان روزها و ساعات آزاد شما در
                            این جدول اقدام به رزرو کلاس می کنند، بدون نیاز به
                            تماس تلفنی یا ایمیلی
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>

                <Schedule formik={formik} />
              </div>
              <div className="userShow form-check form-switch mt-5 py-3 px-1 mx-0 mx-md-5 bg-beigeGray">
                <input
                  className="form-check-input form-check-input-filter1  m-0 p-0 cursor"
                  type="checkbox"
                  role="switch"
                  id="freeSessionSwitch"
                  checked={formik.values?.freeSession}
                  onChange={(e) =>
                    formik.setValues((prev) => ({
                      ...prev,
                      freeSession: !formik.values.freeSession,
                    }))
                  }
                />
                <label
                  className="form-check-label px-2 mt-0"
                  htmlFor="freeSessionSwitch"
                >
                  <div> تمایل به برگزاری جلسه آزمایشی رایگان دارم.</div>
                  <div className="small-gray-font font-10 my-2">
                    ( جلسه اول که به منظور آشنایی استاد و دانشجوست و نیم ساعت
                    خواهد بود.)
                  </div>
                </label>
              </div>
              <div>
                <label
                  className="form-label mt-5 titr6 mb-0 required"
                  htmlFor="emailApply"
                >
                  آدرس ایمیل
                  <div className="small-gray-font font-10 my-2">
                    ( از این آدرس برای مکاتبات شما با دانشجو استفاده می‌شود.)
                  </div>
                </label>
                <input
                  dir="ltr"
                  id="emailApply"
                  {...formik.getFieldProps("email")}
                  className={`userUpdateInput  form-control box2 ${addErrClass({
                    formik,
                    field: "email",
                  })}`}
                  type="text"
                  autoComplete="off"
                  placeholder={formik.values.email}
                />
                <FormikErrRender formik={formik} field="email" />
              </div>
              <div className=" row my-5">
                <div className="col-md-6">
                  <label className="form-label titr6 mt-3  ">
                    لینک ویدیوی یوتوب
                    <Tooltip1
                      message={
                        <div>
                          <div>
                            .لینک ویدنو رو را مطابق عکس از یوتیوب کپی کنید.
                          </div>
                          <img
                            src="/img/videoLink.jpg"
                            height="300"
                            width="500"
                            loading="lazy"
                          />
                        </div>
                      }
                      position="top-end"
                    />
                  </label>
                  <a
                    tabIndex="0"
                    className="btn info-btn shadow-none "
                    role="button"
                    data-bs-toggle="popover"
                    data-bs-trigger="focus"
                    data-bs-content="در صورت تمایل لینک ویدئوی معرفی خود را به صورت کامل وارد کنید."
                  >
                    {/* <i className="bi bi-question-circle-fill"></i> */}
                  </a>

                  <input
                    type="text"
                    autoComplete="off"
                    className=" form-control my-5 mt-3 py-1 box2"
                    id="f55"
                    placeholder=" مثال:https://youtu.be/yfY9zRZxqtY."
                    defaultValue={formik.values.video?.[window.lang].youtube}
                    onBlur={(e) => {
                      const videoIdMatch = e.target.value?.match(
                        /(?:v=|v\/|embed\/|youtu\.be\/)(.{11})/
                      );
                      if (!videoIdMatch)
                        return messageRef.current?.showError({
                          message: "لینک ویدئو درست نمی باشد .",
                        });
                      formik.setValues((pre) => ({
                        ...pre,
                        video: {
                          ...pre.video,
                          [window.lang]: {
                            youtube: e.target.value,
                            description: "",
                          },
                        },
                      }));
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <div className="ratio ratio-16x9 ">
                    {generateYoutubeIframe(
                      formik.values?.video?.[window.lang]?.youtube ?? ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            {action === "edit" ? (
              <div
                className="titr7 cursor"
                onClick={(e) => {
                  if (
                    window.confirm(
                      "شما در حال انتقال به صفحه تکمیل پروفایل و حساب بانکی هستید."
                    ) !== true
                  )
                    return;
                  window.navigateHandle(
                    `/${window.lang}/dashboard/user/profile/me`
                  );
                }}
              >
                <i className="bi bi-pencil-fill font-16 pe-2"></i>
                ویرایش اطلاعات بانکی
              </div>
            ) : (
              <div className="userShow bg-beigeYellow mx-0 mx-md-5 ">
                <div className="titr7 my-3">اطلاعات پرداخت</div>
                <div className="med-gray-font">
                  (تکمیل این قسمت در مرحله ثبت نام الزامی نمی باشد ولی برای
                  تسویه حساب به آن نیازاست.)
                </div>
                <div className="d-flex my-5">
                  <p className="form-label  titr6 ">نوع پرداخت</p>
                  <div className="form-check mx-3">
                    <input
                      className="form-check-input px-1"
                      type="radio"
                      id="paymentTypeSheba"
                      value="sheba"
                      name="paymentInformation"
                      checked={
                        formik.values.bankInfo?.paymentMethod === "sheba"
                      }
                      onChange={(e) =>
                        formik.setValues((pre) => ({
                          ...pre,
                          bankInfo: {
                            ...pre.bankInfo,
                            paymentMethod: e.target.value,
                            email: "",
                            currency: "IRR",
                          },
                        }))
                      }
                    />
                    <label className="form-check-label " for="paymentTypeSheba">
                      شماره شبا
                    </label>
                  </div>
                  <div className="form-check mx-3">
                    <input
                      className="form-check-input px-1"
                      type="radio"
                      id="paymentTypePaypal"
                      value="paypal"
                      name="paymentInformation"
                      checked={
                        formik.values.bankInfo?.paymentMethod === "paypal"
                      }
                      onChange={(e) =>
                        formik.setValues((pre) => ({
                          ...pre,
                          bankInfo: {
                            ...pre.bankInfo,
                            paymentMethod: e.target.value,
                            currency: countryDetails.currency,
                            accountNumber: "",
                            institution: "",
                          },
                        }))
                      }
                    />
                    <label
                      className="form-check-label "
                      for="paymentTypePaypal"
                    >
                      Paypal
                    </label>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-6">
                    <label
                      className={`form-label mt-3 titr6 mb-0 ${
                        formik.values.bankInfo.confirmed ? "required" : ""
                      }`}
                      htmlFor="paymentInfoName"
                    >
                      نام
                    </label>
                    <div className="med-gray-font font-10 my-2">
                      (نام دقیق، به همان صورت که در حساب{" "}
                      {formik.values.bankInfo?.paymentMethod} شماست .)
                    </div>
                    <input
                      type="text"
                      autoComplete="off"
                      className={`form-control py-1 box2 ${addErrClass({
                        formik,
                        field: "bankInfo.firstName",
                      })}`}
                      placeholder=""
                      id="paymentInfoName"
                      {...formik.getFieldProps("bankInfo.firstName")}
                    />
                    <FormikErrRender
                      formik={formik}
                      field="bankInfo.firstName"
                    />
                  </div>

                  <div className="col-md-6">
                    <label
                      className={`form-label mt-3 titr6 mb-0 ${
                        formik.values.bankInfo.confirmed ? "required" : ""
                      }`}
                      htmlFor="paymentInfoFamily"
                    >
                      نام خانوادگی
                    </label>
                    <div className="med-gray-font font-10 my-2">
                      (نام خانوادگی دقیق، به همان صورت که در حساب{" "}
                      {formik.values.bankInfo?.paymentMethod} شماست .)
                    </div>
                    <input
                      type="text"
                      autoComplete="off"
                      className={`form-control py-1 box2 ${addErrClass({
                        formik,
                        field: "bankInfo.lastName",
                      })}`}
                      placeholder=""
                      id="paymentInfoFamily"
                      {...formik.getFieldProps("bankInfo.lastName")}
                    />
                    <FormikErrRender
                      formik={formik}
                      field="bankInfo.lastName"
                    />
                  </div>
                </div>

                {formik.values.bankInfo?.paymentMethod === "paypal" ? (
                  <div>
                    <label
                      // className="form-label titr6 mb-0 required"
                      className={`form-label titr6 mb-0 ${
                        formik.values.bankInfo.confirmed ? "required" : ""
                      }`}
                      htmlFor="emailPayment"
                    >
                      آدرس ایمیل
                    </label>
                    <div className="med-gray-font font-10 my-2">
                      (ایمیلی که PayPal شما به آن متصل است را در این‌جا وارد
                      کنید .)
                    </div>
                    <input
                      dir="ltr"
                      id="emailPayment"
                      {...formik.getFieldProps("bankInfo.email")}
                      className={`userUpdateInput  form-control box2 ${addErrClass(
                        {
                          formik,
                          field: "bankInfo.email",
                        }
                      )}`}
                      type="text"
                      autoComplete="off"
                      placeholder={formik.values.email}
                    />
                    <FormikErrRender formik={formik} field="bankInfo.email" />
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-6 mt-auto">
                      <label
                        // className="form-label mt-3 titr6 mb-0 required"
                        className={`form-label mt-3 titr6 mb-0 ${
                          formik.values.bankInfo.confirmed ? "required" : ""
                        }`}
                        htmlFor="sheba"
                      >
                        شماره شبا
                      </label>
                      <input
                        type="text"
                        autoComplete="off"
                        className={`form-control py-1 box2 ${addErrClass({
                          formik,
                          field: `bankInfo.accountNumber`,
                        })} `}
                        id="sheba"
                        placeholder=""
                        sheba="sheba"
                        {...formik.getFieldProps(`bankInfo.accountNumber`)}
                      />
                      <FormikErrRender
                        formik={formik}
                        field={`bankInfo.accountNumber`}
                      />
                    </div>
                    <div className="col-md-6 mt-auto">
                      <label
                        // className="form-label mt-3 titr6 mb-0 required"
                        className={`form-label mt-3 titr6 mb-0 ${
                          formik.values.bankInfo.confirmed ? "required" : ""
                        }`}
                        htmlFor="institution"
                      >
                        نام بانک
                      </label>
                      <input
                        type="text"
                        autoComplete="off"
                        className={`form-control py-1 box2 ${addErrClass({
                          formik,
                          field: `bankInfo.institution`,
                        })} `}
                        id="institution"
                        placeholder=""
                        {...formik.getFieldProps(`bankInfo.institution`)}
                      />
                      <FormikErrRender
                        formik={formik}
                        field={`bankInfo.institution`}
                      />
                    </div>
                  </div>
                )}
                <div className="mt-5">
                  <label
                    htmlFor="confirmedPaymentInfo"
                    className="med-gray-font"
                  >
                    <span>اطلاعات پرداخت دقیق می باشد </span>
                    <input
                      id="confirmedPaymentInfo"
                      type="checkbox"
                      className="form-check-input mx-2"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "bankInfo.confirmed",
                          !formik.values.bankInfo?.confirmed
                        );
                      }}
                      checked={formik.values.bankInfo?.confirmed}
                    />
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="px-0 pt-5">
            <label
              htmlFor="termsBtn"
              className={`required my-3 mb-5 med-gray-font `}
            >
              <a
                className="fw-bold fst-italic py-0 pe-1 "
                href={`/${window.lang}/prompt/tutor`}
                target="_blank"
              >
                (قوانین تدریس)
              </a>
              <span>را مطالعه کردم و می‌پذیرم</span>

              <input
                id="termsBtn"
                type="checkbox"
                className="form-check-input mx-2"
                onChange={(e) => {
                  if (formik.values.terms) return;
                  formik.setValues((pre) => ({ ...pre, terms: true }));
                }}
                checked={formik.values.terms}
              />
              <FormikErrRender formik={formik} field="terms" />
            </label>
            <FormikListErr formik={formik} />
            <SubmitBtn formik={formik} text="" />{" "}
          </div>
        </form>
      ) : (
        <div className="container-404 text-center d-flex flex-column align-items-center px-4 ">
          <div className="my-3 ">
            <div> در گام نخست شما نیاز دارید تا وارد حساب کاربری شوید.</div>

            <Link
              id="loginTrigger"
              className="btn primary-btn btn-404 checkout-btn my-5 mt-3"
              data-bs-toggle="modal"
              to="#exampleModalToggle"
              role="button"
            >
              ورود به حساب کاربری
            </Link>
            <img
              className=" img-404 w-75 "
              src="/img/illustration/access-denied.svg"
              alt="404"
            />
          </div>
        </div>
      )}

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );

  function SubmitBtn({ text, formik }) {
    let textBtn = "بفرست";
    if (formik.values.action === "edit") textBtn = "ویرایش";
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2"
        style={{
          backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {textBtn}
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          Object.keys(formik.errors)?.length === 0 && <CheckCircle />
        )}
        <span>
          {Object.keys(formik.errors)?.length > 0 && (
            <Tooltip1
              message=" موارد الزامی را کامل کنید."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
            />
          )}
        </span>
      </button>
    );
  }
}

function editInitialData(formik, data, action) {
  const initialValues = {
    action,
    terms: data?.moreInfo?.terms || false,
    _id: action === "edit" ? data._id : "",
    lang: data?.lang ?? window.lang,
    listingLangs: data?.listingLangs ?? [window.lang],
    lcs: data?.lcs ?? window.lcs,
    email: data.email,
    name: data.name,
    category: data.category,
    img: data.moreInfo.img,
    logo: data.moreInfo.logo,
    freeSession: data.moreInfo.freeSession,
    isActive: data.moreInfo.isActive,
    // sex: data.moreInfo.sex,
    ageGroups: data.moreInfo.ageGroups,
    serviceTypes: data.moreInfo.serviceTypes,
    subjects: data.moreInfo.subjects,
    // availability: data.moreInfo.availability,
    distances: data.moreInfo.distances,
    languages: data.moreInfo.languages,
    salary: data.moreInfo.salary,
    education: data.moreInfo.education,
    experience: data.moreInfo.experience,
    about: data.moreInfo.about,
    intro: data.moreInfo.intro,
    video: data.moreInfo.video || {
      fa: { description: "", youtube: "" },
      en: { description: "", youtube: "" },
    },
    schedule: data.moreInfo.schedule,
    timeZone:
      data.moreInfo.timeZone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    tags: data.moreInfo.tags.join("+"),
    levelSub: data.moreInfo.levelSub,
    bankInfo: {
      confirmed: false,
      firstName: "",
      lastName: "",
      paymentMethod: "paypal",
      email: "",
      accountNumber: "",
      institution: "",
      currency: "",
      exRate: "",
    },
    location: {
      ...data.moreInfo.address,
      lat: data.moreInfo.address?.coordinates?.lat,
      lng: data.moreInfo.address?.coordinates?.lng,
    },
  };
  formik.setValues(initialValues);
}

async function editHandle({ messageRef, setLoading, user, values, navigate }) {
  console.log("edit handle triggred");
  setLoading((pre) => ({ ...pre, spinner: true }));
  const response = await apiDemy.editTutor({
    token: user.token,
    data: values,
  });
  setLoading((pre) => ({ ...pre, spinner: false }));
  if (response.error)
    return messageRef.current.showError({
      message: (
        <div>
          <div> مشکل در انجام عملیات !</div>
          <div dir="ltr">{response.error}</div>
        </div>
      ),
    });

  messageRef.current.showSuccess({
    message: " اطلاعات استاد با موفقیت ویرایش شد.",
  });

  navigate(`/${window.lang}/demy/tutor/page/${tools.slugMaker({title:response.data.name?.[window.lang],city:response.data?.moreInfo?.address?.city,state:response.data?.moreInfo?.address?.state,serial:response.data?.serial})}`, {
    state: response.data,
  });
}

// function AddAvailability({ formik }) {
//   return (
//     <div className="table" id="maintable3">
//       {formik.values?.availability.map((availabel, i) => (
//         <div key={i} className="mb-2">
//           <div id={i} className="input-group mb-1 addSocialMedia">
//             <select
//               className=" login-btn  selectpicker shadow-none w-30"
//               aria-label="Default select example"
//               name={availabel + i}
//               style={{
//                 borderTopRightRadius: "20px",
//                 bordeBbottomRightRadius: "20px",
//                 fontSize: "small",
//               }}
//               {...formik.getFieldProps(`availability[${i}].availableDay`)}
//             >
//               <option>Select :</option>
//               {demyData.availableDay?.map((stl, i) => (
//                 <option key={i} value={stl.name}>
//                   {stl[window.lang]}
//                 </option>
//               ))}
//             </select>
//             <select
//               className=" login-btn  selectpicker shadow-none w-30"
//               aria-label="Default select example"
//               name={availabel + i}
//               style={{
//                 borderTopRightRadius: "20px",
//                 bordeBbottomRightRadius: "20px",
//                 fontSize: "small",
//               }}
//               {...formik.getFieldProps(`availability[${i}].availableTime`)}
//             >
//               <option>Select :</option>
//               {demyData.availableTime?.map((enrolrT, i) => (
//                 <option key={i} value={enrolrT.name}>
//                   {enrolrT[window.lang]}
//                 </option>
//               ))}
//             </select>
//           </div>

//           {i > 0 && (
//             <button
//               type="button"
//               className="deleteContact btn btn login-trigger3 btn-xs mx-2"
//               title="پاک کردن"
//               onClick={() => removeHandle(i)}
//             >
//               <i
//                 className="bi bi-x-circle-fill p-0 "
//                 style={{ fontSize: "1.5em" }}
//               ></i>
//             </button>
//           )}
//           <FormikErrRender
//               formik={formik}
//               field={`availability.${i}.availableDay`}
//             />
//             <FormikErrRender
//               formik={formik}
//               field={`availability.${i}.availableTime`}
//             />
//         </div>
//       ))}

//       {!formik.errors.enrolment && (
//         <div
//         // className='border-top border-secondary'
//         >
//           <div
//             className="btn btn-xs shadow-none login-trigger2  "
//             title="افزودن شبکه اجتماعی"
//           >
//             <i
//               className="bi bi-plus-circle-fill "
//               style={{ fontSize: "1.5em" }}
//               onClick={addTuitionHandle}
//             ></i>
//           </div>
//         </div>
//       )}
//     </div>
//   );

//   function addTuitionHandle() {
//     formik.setValues((prev) => {
//       const newF = { ...prev };
//       const index = newF.availability.length;
//       newF.availability.push(newF.availability[index - 1]);
//       return newF;
//     });
//   }

//   function removeHandle(index) {
//     console.log("removeHandle");
//     formik.setValues((prev) => {
//       const newFormik = { ...prev };
//       newFormik.availability.splice(index, 1);
//       return newFormik;
//     });
//   }
// }
function AddDistances({ formik }) {
  return (
    <div className="table" id="maintable3">
      {formik.values?.distances.map((distance, i) => (
        <div key={i} className="mb-2">
          <div id={i} className="input-group mb-1 addSocialMedia">
            <select
              className=" login-btn  selectpicker shadow-none w-30"
              aria-label="Default select example"
              name={distance + i}
              style={{
                borderTopRightRadius: "20px",
                bordeBbottomRightRadius: "20px",
                fontSize: "small",
              }}
              {...formik.getFieldProps(`distances[${i}].distance`)}
            >
              <option>انتخاب کنید:</option>
              {demyData.distances?.map((stl, i) => (
                <option key={i} value={stl.name}>
                  {stl[window.lang]}
                </option>
              ))}
            </select>
            <select
              className=" login-btn  selectpicker shadow-none w-30"
              aria-label="Default select example"
              name={distance + i}
              style={{
                borderTopRightRadius: "20px",
                bordeBbottomRightRadius: "20px",
                fontSize: "small",
              }}
              {...formik.getFieldProps(`distances[${i}].cost`)}
            >
              <option> انتخاب کنید:</option>
              {demyData.charges?.map((charge, i) => (
                <option key={i} value={charge.name}>
                  {charge[window.lang]}
                </option>
              ))}
            </select>
          </div>
          <FormikErrRender formik={formik} field={`distances.${i}.distance`} />
          <FormikErrRender formik={formik} field={`distances.${i}.cost`} />
          {i > 0 && (
            <button
              type="button"
              className="deleteContact btn btn login-trigger3 btn-xs mx-2"
              title="پاک کردن"
              onClick={() => removeHandle(i)}
            >
              <i
                className="bi bi-x-circle-fill p-0 "
                style={{ fontSize: "1.5em" }}
              ></i>
            </button>
          )}
        </div>
      ))}

      {!formik.errors.distances && (
        <div
        // className='border-top border-secondary'
        >
          <div
            className="btn btn-xs shadow-none login-trigger2  "
            title="افزودن "
          >
            <i
              className="bi bi-plus-circle-fill "
              style={{ fontSize: "1.5em" }}
              onClick={addTuitionHandle}
            ></i>
          </div>
        </div>
      )}
    </div>
  );

  function addTuitionHandle() {
    formik.setValues((prev) => {
      const newF = { ...prev };
      const index = newF.distances.length;
      newF.distances.push(newF.distances[index - 1]);
      return newF;
    });
  }

  function removeHandle(index) {
    console.log("removeHandle");
    formik.setValues((prev) => {
      const newFormik = { ...prev };
      newFormik.distances.splice(index, 1);
      return newFormik;
    });
  }
}

function generateYoutubeIframe(videoLink) {
  const videoIdMatch = videoLink?.match(
    /(?:v=|v\/|embed\/|youtu\.be\/)(.{11})/
  );
  if (!videoIdMatch) return;
  const videoId = videoIdMatch[1];
  const iframeSrc = `https://www.youtube.com/embed/${videoId}`;

  return (
    <iframe
      width="560"
      height="315"
      src={iframeSrc}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen="allowFullScreen"
      mozallowfullscreen="mozallowfullscreen"
      msallowfullscreen="msallowfullscreen"
      oallowfullscreen="oallowfullscreen"
      webkitallowfullscreen="webkitallowfullscreen"
    ></iframe>
  );

  return null; // Return null if video ID couldn't be extracted
}
