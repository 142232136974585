import { Route } from "react-router-dom";
// import Disclaimer from "../components/persian/common/alerts/disclaimer";
// import AboutUs from "../components/persian/common/alerts/aboutUs";
// import ContactUs from "../components/persian/common/alerts/contactUs";
// import Privacy from "../components/persian/common/alerts/privacy";
import ClaimAdv from "../components/persian/common/alerts/claimBen";
import ComingSoon from "../components/persian/common/alerts/comingSoon";
import FaqEn from "../components/persian/common/alerts/faqEn";
import Guides from "../components/persian/common/alerts/guides";
// import TutorGuide from "../components/persian/common/alerts/tutorsStudentsGuide";
// import Affiliation from "../components/persian/common/alerts/affiliation";
import TutorsStudentsGuideEn from "../components/persian/common/alerts/tutorsStudentsGuideEn";
import AboutUsEn from "../components/persian/common/alerts/aboutUsEn";
import ContactUsEn from "../components/persian/common/alerts/contactUsEn";
import DisclaimerEn from "../components/persian/common/alerts/disclaimerEn";
import PrivacyEn from "../components/persian/common/alerts/privacyEn";
import AffiliationEn from "../components/persian/common/alerts/affiliationEN";
// import AffiliationEn from "../components/persian/common/alerts/affiliationEn"; 

export default function promptRoutesEn() {
  return (
    <>
      <Route path="disclaimer" element={<DisclaimerEn />} />
      <Route path="tutor" element={<TutorsStudentsGuideEn />} />
      <Route path="about" element={<AboutUsEn />} />
      <Route path="guides" element={<Guides />} />
      <Route path="contact" element={<ContactUsEn />} />
      <Route path="privacy" element={<PrivacyEn />} />
      <Route path="claimben" element={<ClaimAdv />} />
      <Route path="coming" element={<ComingSoon />} />
      <Route path="faq" element={<FaqEn />} />
      <Route path="affiliation" element={<AffiliationEn />} />
    </>
  );
}
