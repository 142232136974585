import { Link } from "react-router-dom";
import MetaDecorator from "../../../../utils/metaDecorator";

export default function PrivacyEn() {
  const structuredData = [   
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Privacy",
      description:
        "Metamarce Privacy Policy for user registration",

      url: `https://www.metamarce.com/${window.lang}/prompt/privacy`,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "start",
              description:  "A bridge between teachers and students around the world",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/prompt/privacy`,
              name: "Privacy policy",
              description:
                "This Privacy Policy explains how Metamarce collects, uses, and protects the information provided by users when logging in to our platform.",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "Privacy policy",
    description:
      "How Metamarce collects, uses, and protects the user's information",
    section: "Privacy",
    url: `https://www.metamarce.com/${window.lang}/prompt/privacy`,
    canonical: `https://www.metamarce.com/${window.lang}/prompt/privacy`,

    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
  };

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="d-flex flex-column justify-content-center align-items-center mx-md-5 mx-2">
        
        {/* </div> */}

        <div
          dir="ltr"
          className="container-404 d-flex flex-column align-items-start "
        >
          {/* <img className=" img-404 p-0 m-0" src="/img/AccessDenied.png" alt="AccessDenied" /> */}
          <div className="mb-3  mt-5">
            <h1 className="titr1 font-40 my-5 text-center">
              Metamarce Privacy Policy for user registration
            </h1>
            This Privacy Policy explains how Metamarce collects, uses, and
            protects the information provided by users when logging in to our
            web application.
            <h2 className="titr7 mt-3 mt-md-4  text-start ">
              1. Information Collected
            </h2>
            When you log in to our web application, we may collect the following
            information: Your login provider user ID Your email address
            associated with your login. Your profile information (e.g., name,
            profile picture) Access to your OAuth token, which we use to
            authenticate and authorize your access to our application
            <h2 className="titr7 mt-3 mt-md-4  text-start">
              2. Use of Information
            </h2>
            We collect and use the information mentioned above for the following
            purposes: To create and manage your account To personalize and
            improve your user experience To communicate with you, including
            sending important updates and notifications To ensure the security
            and integrity of our web application To comply with legal and
            regulatory requirements
            <h2 className="titr7 mt-3 mt-md-4  text-start">
              3. Third-Party Access
            </h2>
            We do not share, sell, or disclose your login information to third
            parties except in the following cases: To comply with legal
            obligations or protect our rights and interests. With trusted
            third-party service providers who assist in the operation of our web
            application and are bound by confidentiality. In the event of a
            merger, acquisition, or sale of all or a portion of our assets, your
            information may be transferred to the acquiring entity.
            <h2 className="titr7 mt-3 mt-md-4  text-start">4. Data Security </h2>
            We employ industry-standard security measures to protect your
            information. However, please be aware that no data transmission over
            the internet or electronic storage is entirely secure.
            <h2 className="titr7 mt-3 mt-md-4  text-start"> 5. Your Choices </h2>
            You have the right to review, update, or delete your account
            information at any time. You can do this by logging into your
            account or contacting us directly.
            <h2 className="titr7 mt-3 mt-md-4  text-start">
              6. Changes to this Privacy Policy
            </h2>
            We may update this Privacy Policy to reflect changes in our data
            practices or legal requirements. Any changes will be posted on this
            page, and we recommend reviewing this policy periodically.
            <h2 className="titr7 mt-3 mt-md-4  text-start"> 7. Contact Us </h2>
            If you have any questions or concerns about our privacy practices or
            this Privacy Policy, please contact us at metamarce.com@gmail.com.
            By logging in to our web application, you agree to the terms of this
            Privacy Policy. If you do not agree with any part of this policy,
            please do not use our services.
          </div>
            <Link
          className="btn primary-btn btn-404 checkout-btn my-5"
          onClick={(e) => window.scroll(0, 0)}
          to={`/${window.lang}/prompt/disclaimer`}
        >
         View Disclaimer
        </Link>
        </div>
      </div>
    </div>
  );
}
