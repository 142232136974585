export default function BlogSearch(props) {
  // console.log(props);
  const trans = {
    search:{fa:"... جستجوی",en:"Search ..."},    
  }
  return (
    <div className="btn-search d-flex justify-content-between align-items-center w-100 mx-md-3 mx-0 my-2 ">
      <i className="bi bi-search px-2" onClick={props.searchHandle}></i>
      <span className="w-100">
        <input
          type="search"
          autoComplete="off"
          className="form-input3 shadow-none ps-1 w-100"
          placeholder={trans.search[window.lang]}
          onKeyUp={props.searchHandle}
          style={{ color: "black" }}
          name="searchForm"
        />
      </span>
    </div>
  );
}
