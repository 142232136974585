import React, { useState } from "react";
// import dayjs from "dayjs";
// import Badge from "@mui/material/Badge";
// import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import renderData from "../../utils/renderData";
import FormikErrRender from "../persian/common/alerts/formikErrRender";

const ScheduleComponent = ({ formik }) => {
  const trans={
    select:{fa:"روز کاری را انتخاب کنید",en:"Select a day"},
    slotStart:{fa:"زمان شروع",en:"Start"},
    slotEnd:{fa:"زمان پایان",en:"End"},
    slotDuration:{fa:"مدت کلاس",en:"Duration"},
    addHour:{fa:" افزودن ساعت",en:"Add a time"},
    removeDay:{fa:"پاک کردن روز کاری",en:"Remove a day"},
    addDay:{fa:"افزودن روز کاری",en:"َAdd a day"},
    
  }
  return (
    <div className=" my-2 text-center">
      {formik.values.schedule?.map((sche, parentIndex) => (
        <div className=" my-3">
          <div key={parentIndex}>
            <div className="day-time  py-3">
              <div className="modal-body mx-auto" style={{ maxWidth: "500px" }}>
                <div className="row">
                  {/* <div>اطلاعات زمانی {formik.values.timeZone}</div> */}
                  <div>
                    <select
                      className="pb-2 my-2 login-btn "
                      dir="rtl"
                      name="days"
                      id="days"
                      value={formik.values.schedule[parentIndex].weekDay}
                      onChange={(e) => {
                        formik.setFieldValue(
                          `schedule[${parentIndex}].weekDay`,
                          e.target.value
                        );
                      }}
                    >
                      <option value="">{trans.select[window.lang]} </option>
                      {/* <option value="">روز کاری را انتخاب کنید</option> */}
                      {renderData.weekDays
                        ?.filter((day) => {
                          // Filter out the weekdays that are already selected for other schedules
                          return !formik.values.schedule.some(
                            (schedule, index) => {
                              return (
                                index !== parentIndex &&
                                schedule.weekDay === day.name
                              );
                            }
                          );
                        })
                        .map((day, i) => (
                          <option key={i} value={day.name}>
                            {day?.[window.lang]}
                          </option>
                        ))}
                    </select>{" "}
                  </div>

                  <div>
                    {sche.slots?.map((slot, i) => (
                      <div className="d-flex justify-content-around" key={i}>
                        <div className="d-flex my-1 ">
                          <label
                            className="pt-2 pe-2 font-12"
                            htmlFor={"slotStart" + parentIndex + i}
                          >
                            {trans.slotStart[window.lang]}
                          </label>
                          <span>
                            <input
                              dir="ltr"
                              id={"slotStart" + parentIndex + i}
                              type="time"
                              className="form-control pt-2 px-1 font-12 lh-1"
                              // value={selectedTime}
                              // onChange={(e) => setSelectedTime(e.target.value)}
                              {...formik.getFieldProps(
                                `schedule.[${parentIndex}].slots[${i}].start`
                              )}
                            />
                          </span>
                        </div>
                        <div className="my-1 d-flex">
                          <label
                            className="pe-2 pt-2 font-12"
                            htmlFor={parentIndex + "sessionDuration" + i}
                          >
                            {trans.slotDuration[window.lang]}
                          </label>
                          <select
                            className="form-control font-12 px-1"
                            name=""
                            id={parentIndex + "sessionDuration" + i}
                            {...formik.getFieldProps(
                              `schedule.[${parentIndex}].slots[${i}].duration`
                            )}
                          >
                    {renderData.sessionDuration.map((du,i)=><option key={i} value={du.name}> {du[window.lang]}</option>)}
                            {/* <option className=" py-5" value=".5">
                              نیم ساعت
                            </option>
                            <option value="1"> یکساعت</option>
                            <option value="2"> دو ساعت</option> */}
                          </select>
                        </div>

                        <button
                          type="button"
                          style={{ visibility: i > 0 ? "visible" : "hidden" }}
                          className="deleteContact btn pt-2 login-trigger3 btn-xs mx-0"
                          title="پاک کردن"
                          onClick={() =>
                            formik.setValues((prev) => {
                              const newFormik = { ...prev };
                              newFormik.schedule[parentIndex].slots.splice(
                                i,
                                1
                              );
                              return newFormik;
                            })
                          }
                        >
                          <i
                            className="bi bi-x-circle-fill p-0 "
                            style={{ fontSize: "1.5em" }}
                          ></i>
                        </button>
                        <FormikErrRender
                          formik={formik}
                          field={`schedule.${parentIndex}.slots.${i}.start`}
                        />
                      </div>
                    ))}

                    <FormikErrRender
                      formik={formik}
                      field={`schedule.${parentIndex}.weekDay`}
                    />
                    <div
                    // className='border-top border-secondary'
                    >
                      {!("schedule" in formik?.errors) && (
                        <div
                        className="btn btn-xs shadow-none login-trigger2 px-2 py-1"
                          style={{ backgroundColor: "#5c888554" }}
                          title="Add a time "
                          onClick={(e) => {
                            formik.setValues((prevValues) => {
                              const newSchedule = [...prevValues.schedule];
                              const lastSlot =
                                newSchedule[parentIndex].slots[
                                  newSchedule[parentIndex]?.slots?.length - 1
                                ] || 0;

                              // Calculate the new start time based on the duration
                              const duration = lastSlot.duration; // Duration in hours
                              const [hours, minutes] = lastSlot.start
                                .split(":")
                                .map(Number);
                              const minutesSinceMidnight =
                                hours * 60 + minutes;
                              const newMinutesSinceMidnight =
                                minutesSinceMidnight + duration * 60; // Add duration in minutes
                              const newHours = Math.floor(
                                newMinutesSinceMidnight / 60
                              );
                              const newMinutes = newMinutesSinceMidnight % 60;
                              const newStartTime = `${newHours
                                .toString()
                                .padStart(2, "0")}:${newMinutes
                                .toString()
                                .padStart(2, "0")}`;                             
                              newSchedule[parentIndex].slots=[...newSchedule[parentIndex].slots,{
                                start: newStartTime,
                                duration,
                                booked: false,
                                bookInfo: {
                                  userName: "",
                                  _id: "",
                                  bookDate: "",
                                  confirmed: false,
                                },
                              }]

                              return {
                                ...prevValues,
                                schedule: newSchedule,
                              };
                            });
                          }}
                        >                         
                          <div>
                <i
                 id={"addSlotbtn" + parentIndex}
                  className="bi bi-plus-circle-fill "
                  style={{ fontSize: "1.5em" }}
                ></i>
                <span className="font-12 ps-2"> {trans.addHour[window.lang]}</span>
              </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* left section */}
                </div>
              </div>
            </div>
          </div>
          {parentIndex > 0 && (
            <button
              type="button"
              className="deleteContact btn btn login-trigger3 btn-xs mx-2"
              title="Delete"
              onClick={() =>
                formik.setValues((prev) => {
                  const newFormik = { ...prev };
                  newFormik.schedule.splice(parentIndex, 1);
                  return newFormik;
                })
              }
            >
              <i
                className="bi bi-x-circle-fill p-0 "
                style={{ fontSize: "1.5em" }}
              ></i>
              <span className="font-12 ps-2">{trans.removeDay[window.lang]}   </span>
            </button>
          )}
        </div>
      ))}
      <div
      // className='border-top border-secondary'
      >
        {!("schedule" in formik?.errors) &&
          formik.values?.schedule?.length <= 6 && (
            <div
              className="btn btn-xs shadow-none login-trigger2 px-2 py-1"
              style={{ backgroundColor: "#9c988554" }}
              title="Add "
            >
              <div onClick={addLevelHandle}>
                <i
                  className="bi bi-plus-circle-fill "
                  style={{ fontSize: "1.5em" }}
                ></i>
                {/* <span className="font-12 ps-2">افزودن روز کاری</span> */}
                <span className="font-12 ps-2">{trans.addDay[window.lang]}</span>
              </div>
            </div>
          )}
      </div>
    </div>
  );

  function addLevelHandle() {
    formik.setValues((prev) => {
      const newF = { ...prev };
      const index = newF.schedule.length;
      const newSh = { ...newF.schedule[index - 1] };
      console.log("newSh", newSh);
      newSh.weekDay = "";
      newF.schedule.push(newSh);
      return newF;
    });
  }
};

export default ScheduleComponent;
