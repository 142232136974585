import {
  // Bounce,
  // Flip,
  // Slide,
  toast,
  ToastContainer,
  Zoom,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ToastNote(props) { 
  const showMessage = (props) => {
    toast(props.message || <h3>custom react Node</h3>,);
  };
  const showSuccess = (props) => {
    toast.success(props.message || "Show Success!",);
  };
  const showWarning = (props) => {
    toast.warning(props.message || "Show Warning!",);
  };
  const showError = (props) => {
    toast.error(props.message || "Show Error", );
  };
  const showLoading = (props) => {
    toast.promise(
      new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, 2000); 
      }),
      {
        pending: "Loading",
        success: "Loaded Successfully!",
        Error: "Error Received!!",
      },
      {
        autoClose: 300,
      }
    );
  };

  // const customProgress = (props) => {
  //   toastId.current = toast("custom Progress", {
  //     progress: 0,
  //     autoClose: false,
  //   });
  //   setProgress(0);
  // };
  // const UpdateProgress = (props) => {   
  //   setProgress((prev) => prev + 0.1);
  //   toast.update(toastId.current, { progress: progress + 0.1 });
  //   console.log(progress);
  // };

  // const dismissAll = () => {
  //   toast.dismiss();
  // };
  props.messageRef.current={showMessage,showSuccess,showWarning,showError,showLoading}
  return (
    <div>
      {/* <button onClick={showMessage}>Show Message</button>
      <button onClick={showSuccess}>Show Success</button>
      <button onClick={showWarning}>Show Warning</button>
      <button onClick={showError}>Show Error</button>
      <button onClick={showLoading}>Show Loading</button>
      <button onClick={customProgress}>Custom Progress</button>
      <button onClick={UpdateProgress}>Update Progress</button>
      <button onClick={dismissAll}>Dismiss All</button> */}
      <ToastContainer
        position={props.position || "top-center"}
        // closeOnClick={false}
        // pauseOnHover={false}
        // pauseOnFocusLoss={false}
        // autoClose={false}
        // autoClose={2000}

        // draggable={false}
        // closeButton={<p>Close</p>}
        // icon={"star"}
        // toastStyle={{ color: "white", backgroundColor: "black" }}
        bodyStyle={{backgroundColor:"inherit" ,
         fontSize:'15px' ,direction:window.lang==='fa'?"rtl":"ltr"}}
        // style={{backgroundColor:'green'}}
        // transition={Slide}
        // transition={Bounce}
        // transition={Flip}
        transition={Zoom}
      />
    </div>
  );
}
