import {
  Dangerous,
  DoneAll,
  Edit,
  FiberNew,
  Help,
  TaskAlt,
  Verified,
  VisibilityTwoTone,
} from "@mui/icons-material";
import React from "react";

export default function MyIcons({ icon }) {
  switch (icon) {
    case "01-draft":
      return <i className="bi bi-question-circle" aria-label="More info"></i>;
    case "02-claimed":
      return <i className="bi bi-check-circle" aria-label="Claimed"></i>;
    case "03-verified":
      return <i className="bi bi-patch-check-fill" aria-label="Verified"></i>;
    case "04-trusted":
      return (
        <span className="m-0 p-0" aria-label="Trusted">
          <img
            src="/img/icons/premium-quality.png"
            className="arrow2"
            alt="premium"
            loading="lazy"
          />
        </span>
      );
    case "new":
      return <FiberNew sx={{ fontSize: "36px", color: "var(--pinkOrangeDark)" }} />;
    case "premium":
      return (
        <span className="m-0 p-0" aria-label="premium">
          <img
            src="/img/icons/premium-quality.png"
            className="arrow2"
            alt="premium"
            loading="lazy"
          />
        </span>
      );
    case "delete2":
      return (
        <img
          title="Delete"
          src="/img/premium/delete-2.png"
          className="arrow3 ms-3 mt-3"
          alt="delete"
          loading="lazy"
        />
      );
    case "display":
      return (
        <div title="Display" className="widgetSmButton" aria-label="Display">
          <VisibilityTwoTone />
        </div>
      );
    case "edit":
      return (
        <div title="Edit" className="widgetSmButton" aria-label="edit">
          <Edit />
        </div>
      );
    case "activate":
      return (
        <div title="Activate" className="widgetSmButton">
          <DoneAll />
        </div>
      );
    case "portfolio":
      return (
        <img
          src="/img/icons/portfolio.png"
          className="arrow3 ms-3 "
          alt="portfolio"
          loading="lazy"
        />
      );
    case "dotsTitr":
      return (
        <svg
          className="text-dark-550 dark:text-white ml-2"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
          <circle
            cx="35"
            cy="16"
            r="8"
            fill="currentColor"
            fillOpacity="0.4"
          ></circle>
          <circle
            cx="20"
            cy="8"
            r="5"
            fill="currentColor"
            fillOpacity="0.7"
          ></circle>
        </svg>
      );
      
   
    default:
      <Dangerous />;
  }
}
