import { ArrowBackIos, Publish, Verified } from "@mui/icons-material";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { CartContext } from "../../../../contexts/cartContext";
import Resizer from "react-image-file-resizer";
import ToastNote from "../../../../golbal/notifications/toastNote";
import api from "../../../../../utils/api";
import ColoredSpiner from "../../../../alret/spiners/coloredSpiner/coloredSpinner";
import renderData from "../../../../../utils/renderData";
import tools from "../../../../../utils/tools";

export default function Categories({ section }) {
  const [loading, setLoading] = useState(false);
  const { user } = useContext(CartContext);
  const messageRef = useRef(null);
  const [categories, setCategories] = useState([]);

  const initials = {
    lang: window.lang,
    rowOrder: {},
    updateSubCat: {},
    newSubCat: {},
    newMainCat: {},
    updateMainCat: {},
    deleteSubCat: {},
    updateMainCatPhoto: {},
    updateMainCatLogo: {},
  };
  const [newValues, setNewValues] = useState(initials);
  console.log("newValues", newValues);
  const [originalPhotos, setOriginalPhotos] = useState([]);
  const [resizedPhotos, setResizedPhotos] = useState([
    { mainPhoto: "", mainLogo: "", subCat: [] },
  ]);

  useEffect(() => {
    fetchCategories(section);
  }, [section]);

  async function fetchCategories(section) {
    const response = await api.getCategories({ section });
    if (response.errpr)
      return messageRef.current.showError({ message: response.error });
    console.log("fetchCategories", response.data);
    setCategories(response.data);
  }

  async function updateMainCatHandle(e) {
    e.preventDefault();
    setLoading(true);
    const response = await api.updateMainCat(newValues, user.token, section);
    setLoading(false);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({ message: response.message });
    setCategories((pre) => {
      const cats = [...pre];
      const newCats = cats.map((cat) =>
        cat._id === response.data._id ? response.data : cat
      );
      return newCats;
    });
    document.querySelector(`#${e.target.id}`).parentElement.style.visibility =
      "hidden";
    setNewValues(initials);
  }

  async function submitHandler(e) {
    e.preventDefault();
    const text = "تائید به روز رسانی";
    if (window.confirm(text) != true) return console.log("cancel");
    console.log(newValues);
    setLoading(true);
    const response = await api.updateSubCat(newValues, user.token, section);
    setLoading(false);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setCategories((pre) => {
      const cats = [...pre];
      const newCats = cats.map((cat) =>
        cat._id === response.data._id ? response.data : cat
      );
      return newCats;
    });
    document.querySelector(`#${e.target.id}`).style.visibility = "hidden";
    setNewValues(initials);
  }

  async function createCatHandle(e) {
    e.preventDefault();
    console.log(newValues);
    const response = await api.createCat(newValues, user.token, section);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setCategories((pre) => {
      const current = [...pre];
      const filterd = current.filter(
        (cat) => cat._id !== newValues?.newSubCat?.mainCatId
      );
      filterd.push(response.data);
      return filterd;
    });
    // categorySet(response.data);
    // setCategories(response.data[section]);
    document.querySelector(`#${e.target.id}`).style.visibility = "hidden";
    setNewValues(initials);
    return messageRef.current.showSuccess({ message: response.message });
  }
  async function deleteSubCatBtnHandle(e) {
    e.preventDefault();
    const text = "Delete SubCategory Confirmation";
    if (window.confirm(text) != true) return console.log("cancel");
    console.log(e.currentTarget.id);
    const [mainCatId, index, subCatId] = e.currentTarget.id.split(",");
    setLoading(true);
    const response = await api.deleteSubCat(
      mainCatId,
      index,
      subCatId,
      user.token,
      section
    );
    setLoading(false);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    const UpdatedCats = categories.map((cat) =>
      cat._id === response.data._id ? response.data : cat
    );
    setCategories(UpdatedCats);

    setNewValues(initials);
    messageRef.current.showSuccess({ message: response.message });
  }
  async function deleteMainCatBtnHandle(e) {
    e.preventDefault();
    const text = "Delete the Main Category ?";
    if (window.confirm(text) != true) return;
    console.log(e.currentTarget.id);
    const mainCatId = e.currentTarget.id;
    setLoading(true);
    const response = await api.deleteMainCat(
      mainCatId,
      window.lang,
      user.token,
      section
    );
    setLoading(false);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setCategories((pre) => {
      const cats = [...pre];
      const filterdCats = cats.filter((cat) => cat._id !== mainCatId);
      return filterdCats;
    });
    // effectRan.current=false
    // categorySet(response.data);
    // setCategories(response.data[section]);
    // // document.querySelector(`#${e.target.id}`).style.visibility = "hidden";
    setNewValues(initials);
    messageRef.current.showSuccess({ message: response.message });
  }
  const mainPhotoHandle = async (e) => {
    e.preventDefault();
    console.log(e.target.id);
    const id = e.target.id.split(",")[0];
    const index = e.target.id.split(",")[1];
    // console.log(id);
    // console.log(index);
    const updateResized = { ...resizedPhotos };
    console.log(e.target.files[0]);
    updateResized[index] = {
      mainPhoto: await tools.resizeFile({
        file: e.target.files[0],
        format: "png",
        width: 300,
        height: 300,
      }),
      mainLogo: updateResized[index]?.mainLogo || "",
    };
    console.log(updateResized);
    setResizedPhotos(updateResized);

    const updateOrgPho = { ...originalPhotos };
    updateOrgPho[index] = {
      mainPhoto: e.target.files[0],
      mainLogo: updateOrgPho[index]?.mainLogo || "",
    };
    setOriginalPhotos(updateOrgPho);
  };

  const uploadMainPhotoDatabase = async (e) => {
    e.preventDefault();
    console.log(e.target.id);
    const id = e.target.id.split(",")[0];
    const index = e.target.id.split(",")[1];

    const file = originalPhotos[index].mainPhoto;
    console.log(file);

    if (file == undefined) return;

    const formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    const responseUrl = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: `images/categories/${section}`,
    });
    setLoading(false);
    if (responseUrl.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div>
            <div dir="ltr">{responseUrl.error}</div>
          </div>
        ),
      });

    // setPhotoUrl(url)
    setLoading(true);
    const response = await api.mainCatPhoto({
      id,
      url: responseUrl.data,
      lang: window.lang,
      token: user.token,
      section,
    });
    setLoading(false);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    // categorySet(response.data);
    setCategories((pre) => {
      const cats = [...pre];
      const newCats = cats.map((cat) =>
        cat._id === response.data._id ? response.data : cat
      );
      return newCats;
    });

    const updateResized = { ...resizedPhotos };
    updateResized[index].mainPhoto = "";

    setResizedPhotos(updateResized);
    messageRef.current.showSuccess({ message: response.message });
  };

  async function changeRowHandle(e) {
    e.preventDefault();
    if (
      !newValues?.rowOrder?.mainCatId?.length ||
      !newValues?.rowOrder?.row.length
    )
      return alert("Document Id or row order is not provided!");
    setLoading(true);
    const response = await api.changeRowOrder({
      token: user.token,
      data: newValues.rowOrder,
      section,
    });
    setLoading(false);
    if (response.error) return alert(response.error);
    console.log("changeRowHandle", response.data);
    const updatedCats = categories
      .map((cat) => (cat._id === response.data._id ? response.data : cat))
      .sort((a, b) => {
        if (a.row !== b.row) {
          return a.row - b.row;
        }
        return a.createdAt - b.createdAt;
      });
    setCategories(updatedCats);
  }

  const mainLogoHandle = async (e) => {
    e.preventDefault();
    console.log(e.target.id);
    const id = e.target.id.split(",")[0];
    const index = e.target.id.split(",")[1];
    // console.log(id);
    // console.log(index);
    const updateResized = { ...resizedPhotos };

    updateResized[index] = {
      mainPhoto: updateResized[index]?.mainPhoto || "",
      mainLogo: await tools.resizeFile({
        file: e.target.files[0],
        format: "png",
        width: 300,
        height: 300,
      }),
    };
    console.log(updateResized);
    setResizedPhotos(updateResized);

    const updateOrgPho = { ...originalPhotos };
    updateOrgPho[index] = {
      mainPhoto: updateOrgPho[index]?.mainPhoto || "",
      mainLogo: e.target.files[0],
    };
    setOriginalPhotos(updateOrgPho);
  };
  const subPhotoHandle = async (e) => {
    e.preventDefault();
    console.log(e.target.id);
    const id = e.target.id.split(",")[0];
    const index = e.target.id.split(",")[1];
    console.log(id);
    console.log(index);

    const updateResized = { ...resizedPhotos };
    console.log(resizedPhotos);
    const subCat = updateResized[id]?.subCat || [];
    console.log(subCat);
    subCat[index] = {
      photo: await tools.resizeFile({
        file: e.target.files[0],
        format: "png",
        width: 300,
        height: 300,
      }),
    };

    updateResized[id] = {
      mainPhoto: "",
      mainLogo: "",
      subCat,
    };
    console.log(updateResized);
    setResizedPhotos(updateResized);

    const updateOrgPho = { ...originalPhotos };
    const subCatpho = updateOrgPho[id]?.subCat || [];
    console.log(subCatpho);
    subCatpho[index] = { photo: e.target.files[0] };

    updateOrgPho[id] = { mainPhoto: "", mainLogo: "", subCat: subCatpho };

    setOriginalPhotos(updateOrgPho);
  };

  const uploadMainLogodatabase = async (e) => {
    e.preventDefault();
    console.log(e.target.id);
    const id = e.target.id.split(",")[0];
    const index = e.target.id.split(",")[1];
    const file = originalPhotos[index].mainLogo;
    console.log(file);
    if (file == undefined) return;
    // const resizedPhotoBlob = await resizeFile({ file, blob: true });

    const formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    const responseUrl = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: `images/categories/${section}`,
    });
    setLoading(false);
    if (responseUrl.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div>
            <div dir="ltr">{responseUrl.error}</div>
          </div>
        ),
      });

    // setPhotoUrl(url)
    setLoading(true);
    const response = await api.mainCatLogo({
      id,
      url: responseUrl.data,
      lang: window.lang,
      token: user.token,
      section,
    });
    setLoading(false);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setCategories((pre) => {
      const cats = [...pre];
      const newCats = cats.map((cat) =>
        cat._id === response.data._id ? response.data : cat
      );
      return newCats;
    });

    const updateResized = { ...resizedPhotos };
    updateResized[index].mainLogo = "";
    // console.log(updateResized);
    setResizedPhotos(updateResized);
    messageRef.current.showSuccess({ message: response.message });
  };
  const uploadSubPhotoDatabase = async (e) => {
    e.preventDefault();
    console.log(e.currentTarget.id);
    const id = e.currentTarget.id.split(",")[0];
    const index = e.currentTarget.id.split(",")[1];
    console.log(originalPhotos, id);
    const file = originalPhotos[id].subCat[index].photo;
    console.log(file);
    if (file == undefined) return;
    // const resizedPhotoBlob = await resizeFile({ file, blob: true });

    const formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    const responseUrl = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: `images/categories/${section}`,
    });
    setLoading(false);
    if (responseUrl.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div>
            <div dir="ltr">{responseUrl.error}</div>
          </div>
        ),
      });

    // setPhotoUrl(url)
    setLoading(true);
    const response = await api.subCatPhoto({
      id,
      url: responseUrl.data,
      index,
      lang: window.lang,
      token: user.token,
      section,
    });
    setLoading(false);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setCategories((pre) => {
      const cats = [...pre];
      const newCats = cats.map((cat) =>
        cat._id === response.data._id ? response.data : cat
      );
      return newCats;
    });

    const updateResized = { ...resizedPhotos };
    updateResized[id].subCat[index].photo = "";
    setResizedPhotos(updateResized);
    messageRef.current.showSuccess({ message: response.message });
  };

  const resizeFile = ({ file, width, height, quality, blob }) =>
    new Promise((resolve) => {
      tools.Resizer.imageFileResizer(
        file,
        width || 300,
        height || 300,
        "png",
        quality || 100,
        0,
        (uri) => {
          resolve(uri);
        },
        blob ? "blob" : "base64"
      );
    });

  return (
    <div>
      <ToastNote messageRef={messageRef} />
      {loading && <ColoredSpiner />}
      <div className=" form-floating text-center mt-2 py-1">
        <button
          // id={`subCat${cat._id}${i}`}
          className="btn  text-center primary-btn view-cart justify w-50 mt-3"
          onClick={(e) => {
            e.preventDefault();
            setNewValues(initials);
            const el = document.querySelector(`#newMainInput`);
            el.style.visibility === "visible"
              ? (el.style.visibility = "hidden")
              : (el.style.visibility = "visible");
          }}
        >
          دسته‌بندی‌ اصلی جدید
        </button>
        <div
          className="d-flex"
          id={`newMainInput`}
          style={{ visibility: "hidden" }}
        >
          {renderData.languages.slice(0, 3).map((lan) => (
            <div>
              <label key={lan.name} htmlFor={`newMainCat${lan.name}`}>
                {lan?.[window.lang]}
              </label>
              <input
                id={`newMainCat${lan.name}`}
                type="text"
                autoComplete="off"
                className="form-control box1 w-50 py-1 mx-auto"
                placeholder=""
                onChange={(e) => {
                  const newValuesCo = { ...newValues };
                  newValuesCo.newMainCat[lan.name] = e.target.value.trim();
                  // newValuesCo.newMainCat = {
                  //   lang,
                  //   newValue: e.target.value.trim(),
                  // };
                  setNewValues(newValuesCo);
                  //   return console.log(newValues);
                }}
              />
              {newValues?.newMainCat?.[lan.name]?.trim()?.length >= 3 &&
                !categories.some(
                  (main2) =>
                    main2.mainCat[lan.name] ===
                    newValues?.newMainCat?.[lan.name]
                ) && <span>Ok</span>}
            </div>
          ))}

          {newValues?.newMainCat?.fa?.trim()?.length >= 3 &&
            !categories.some(
              (main2) => main2.mainCat?.fa === newValues?.newMainCat?.fa
            ) && (
              <button
                className="btn  btn-secondary mx-auto justify login-btn mt-3"
                onClick={createCatHandle}
                id={`newMainBtn`}
              >
                بفرست!
              </button>
            )}
        </div>
      </div>

      {categories?.map((cat, i) => (
        <div key={i + cat._id} className="mx-md-5 mx-3 catAndSubcat ">
          <input
            type="checkbox"
            name=""
            id={`bus-mainCat${cat._id}`}
            style={{ display: "none" }}
          />
          <label
            className="login-btn login-btn2 w-50 my-3 pe- d-flex justify-content-between"
            htmlFor={`bus-mainCat${cat._id}`}
          >
            <div> {cat.mainCat?.[window.lang]}</div>
            <ArrowBackIos className="arrowForwardIcon" />
          </label>

          <form
            className="catAndSubcatForm mx-0"
            name={`cat-subcat-Form-bus-mainCat${cat._id}`}
            autoComplete="true"
          >
            {/* mainCategory Edit */}
            <div className="d-flex  ">
              <button
                // id={`subCat${cat._id}${i}`}
                className="btn  primary-btn edit-btn m-2"
                onClick={(e) => {
                  e.preventDefault();
                  setNewValues(initials);
                  const el = document.querySelector(
                    `#changeMainInput${cat._id}`
                  );
                  el.style.visibility === "visible"
                    ? (el.style.visibility = "hidden")
                    : (el.style.visibility = "visible");
                }}
              >
                ویرایش طبقه اصلی
              </button>
              <span
                className="btn primary-btn delete-btn m-2 "
                id={cat._id}
                onClick={deleteMainCatBtnHandle}
              >
                {/* <DeleteForever style={{ backgroundColor: "red" }} /> */}
                حذف طبقه اصلی
              </span>
            </div>
            <div
              className="d-flex"
              id={`changeMainInput${cat._id}`}
              style={{ visibility: "hidden" }}
            >
              {renderData.languages.slice(0, 3).map((lan) => (
                <div>
                  <label key={lan.name} htmlFor={`updateMainCat${lan.name}`}>
                    {lan?.[window.lang]}
                  </label>
                  <input
                    id={`updateMainCat${lan.name}`}
                    type="text"
                    autoComplete="off"
                    className="form-control box1 py-1 w-50"
                    placeholder={cat.mainCat?.[lan.name]}
                    onChange={(e) =>
                      setNewValues((pre) => ({
                        ...pre,
                        updateMainCat: {
                          ...pre.updateMainCat,
                          mainCatId: cat._id,
                          [lan.name]: e.target.value.trim(),
                        },
                      }))
                    }
                  />
                  {/* {newValues?.updateMainCat?.[lan.name]?.trim()?.length >= 3 &&
            !categories.some(
              (main2) => main2.mainCat[lan.name] === newValues?.updateMainCat?.[lan.name]
            ) && (<span>Ok</span>              
            )}             */}
                </div>
              ))}

              {newValues.updateMainCat.mainCatId === cat._id && (
                <button
                  className="btn  btn-secondary mx-auto justify login-btn mt-3"
                  onClick={updateMainCatHandle}
                  id={`Input${cat._id}`}
                >
                  تغییر ثبت!
                </button>
              )}
              {/* )} */}
              {/* mainCategory Delete */}
            </div>
            <div>
              <label htmlFor={"rowOrder" + cat._id + i}>ردیف {cat.row} </label>
              <input
                id={"rowOrder" + cat._id + i}
                type="number"
                onChange={(e) =>
                  setNewValues((pre) => ({
                    ...pre,
                    rowOrder: {
                      row: e.target.value,
                      mainCatId: cat._id,
                      section,
                    },
                  }))
                }
              />
              <button
                className="btn  btn-secondary mx-auto justify login-btn ms-3"
                onClick={changeRowHandle}
              >
                تغییر ردیف
              </button>
            </div>
            <div className="d-flex row passwordChangeForm  mx-0 mt-3">
              <div
                className="col-md-3  p-0"
                style={{ background: "lightgray" }}
              >
                <h3 className="text-center primary-btn view-cart ">
                  دسته‌بندی اصلی
                </h3>

                {/* MainPhoto Section */}
                <div className="text-center">
                  <img
                    src={
                      resizedPhotos[i]?.mainPhoto?.length
                        ? resizedPhotos[i]?.mainPhoto
                        : cat.categoryPhoto
                    }
                    alt={cat.mainCategory}
                    className="userShowImg "
                    loading="lazy"
                  />
                  <div className="userUpdateUpload d-flex justify-content-center">
                    <label htmlFor={[cat._id, i]}>
                      <Publish className="userUpdateIcon" />
                      تغییر عکس طبقه اصلی
                    </label>
                    <input
                      type="file"
                      // id="file"
                      onChange={mainPhotoHandle}
                      style={{ display: "none" }}
                      id={[cat._id, i]}
                    />
                    <button
                      className=" mx-2  btn primary-btn edit-btn"
                      style={{
                        display: resizedPhotos[i]?.mainPhoto ? "block" : "none",
                      }}
                      onClick={uploadMainPhotoDatabase}
                      id={[cat._id, i]}
                    >
                      بارگذاری
                    </button>
                  </div>
                </div>

                {/* MainLogo Section */}
                <div className="text-center my-5">
                  <img
                    src={
                      resizedPhotos[i]?.mainLogo?.length
                        ? resizedPhotos[i]?.mainLogo
                        : cat.mainLogo
                    }
                    alt={cat.mainCategory}
                    className="userShowImg "
                    loading="lazy"
                  />
                  <div className="userUpdateUpload d-flex justify-content-center">
                    <label htmlFor={[cat._id, i, "mainLogo"]}>
                      <Publish className="userUpdateIcon text-center" />
                      تغییر لوگو
                    </label>
                    <input
                      type="file"
                      // id="file"
                      onChange={mainLogoHandle}
                      style={{ display: "none" }}
                      id={[cat._id, i, "mainLogo"]}
                    />
                    <button
                      className=" mx-2  btn primary-btn edit-btn"
                      style={{
                        display: resizedPhotos[i]?.mainLogo ? "block" : "none",
                      }}
                      onClick={uploadMainLogodatabase}
                      id={[cat._id, i, "mainLogo"]}
                    >
                      بارگذاری
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-9 p-0" style={{ background: "white" }}>
                <h3 className="text-center primary-btn checkout-btn">
                  دسته‌بندی‌های کوچکتر
                </h3>
                <form
                  //   onSubmit={passChangeFormik.handleSubmit}
                  className="m-3"
                  name={`subCatForm${cat._id}${i}`}
                  autoComplete="true"
                >
                  <div className=" form-floating  mt-2 py-1">
                    <button
                      id={`subCat${cat._id}${i}`}
                      className="btn primary-btn close-btn mx-auto justify mt-3"
                      onClick={(e) => {
                        e.preventDefault();
                        setNewValues(initials);
                        const el = document.querySelector(
                          `#newSubInput${cat._id}${i}`
                        );
                        const elAll =
                          document.querySelectorAll("input[type=text]");
                        Array.from(elAll)?.map((el2) =>
                          el2.parentElement.id != el.id
                            ? (el2.parentElement.style.visibility = "hidden")
                            : ""
                        );

                        el.style.visibility === "visible"
                          ? (el.style.visibility = "hidden")
                          : (el.style.visibility = "visible");
                      }}
                    >
                      طبقه کوچکتر جدید
                    </button>
                    <div
                      className="d-flex "
                      id={`newSubInput${cat._id}${i}`}
                      style={{ visibility: "hidden" }}
                    >
                      {renderData.languages.slice(0, 3).map((lan) => (
                        <>
                          <label
                            htmlFor={`addSubInput${cat._id}${i}` + lan.name}
                          >
                            {lan?.[window.lang]}
                          </label>
                          <input
                            id={`addSubInput${cat._id}${i}` + lan.name}
                            type="text"
                            autoComplete="off"
                            className="form-control box2 "
                            // placeholder={''}
                            onChange={(e) => {
                              setNewValues((pre) => ({
                                ...pre,
                                newSubCat: {
                                  ...pre.newSubCat,
                                  mainCatId: cat._id,
                                  // subCatIndex: i,
                                  [lan.name]: e.target?.value?.trim(),
                                },
                              }));

                              // const newValuesCo = { ...newValues };
                              // newValuesCo.newSubCat = {
                              //   mainCatId: cat._id,
                              //   subCatIndex: i,
                              //   lang,
                              //   // subCatValue: subCat.name,
                              //   newValue: e.target.value.trim(),
                              // };
                              // setNewValues(newValuesCo);
                            }}
                          />
                        </>
                      ))}

                      {/* {newValues?.newSubCat?.newValue?.trim()?.length >= 3 &&
                        newValues?.newSubCat?.mainCatId == cat._id &&
                        !cat.subCategory.find(
                          (sub2) => sub2.name == newValues?.newSubCat?.newValue
                        ) && ( */}
                      {newValues.newSubCat.mainCatId === cat._id &&
                        newValues.newSubCat.fa?.trim().length > 2 && (
                          <button
                            className="btn  btn-secondary login-btn mt-3 mx-2"
                            onClick={createCatHandle}
                            id={`newSubInput${cat._id}${i}`}
                          >
                            بفرست!
                          </button>
                        )}
                      {/* )} */}
                    </div>
                  </div>
                  <div
                    key={i}
                    className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-3 "
                  >
                    {cat.subCat?.map((subCat, i) => (
                      <div className="col d-flex flex-column justify-content-center mt-3 ">
                        <div className="m-1 border">
                          <div className="form-floating text-center mt-2 py-1 ">
                            <span
                              id={`subCat${i}`}
                              className=""
                              onClick={(e) => {
                                setNewValues(initials);

                                const el = document.querySelector(
                                  `#Input${cat._id}${i}`
                                );

                                const elAll =
                                  document.querySelectorAll("input[type=text]");
                                Array.from(elAll)?.map((el2) =>
                                  el != el2.parentElement
                                    ? (el2.parentElement.style.visibility =
                                        "hidden")
                                    : ""
                                );

                                el.style.visibility === "visible"
                                  ? (el.style.visibility = "hidden")
                                  : (el.style.visibility = "visible");
                              }}
                            >
                              {renderData.languages.slice(0, 3).map((lan) => (
                                <>
                                  <div className=""> {subCat?.[lan.name]}</div>
                                </>
                              ))}
                              <span>
                                <i
                                  className="bi bi-pencil-square mx-1"
                                  style={{ color: "orange", fontSize: 18 }}
                                ></i>
                              </span>
                              <span
                                id={[cat._id, i, subCat._id]}
                                onClick={deleteSubCatBtnHandle}
                              >
                                <i
                                  className="bi bi-x-square mx-1"
                                  style={{ color: "brown", fontSize: 18 }}
                                ></i>
                              </span>
                            </span>

                            <div
                              className="d-flex flex-column px-2"
                              id={`Input${cat._id}${i}`}
                              style={{ visibility: "hidden" }}
                              //   style={{display:"none"}}
                            >
                              {renderData.languages.slice(0, 3).map((lan) => (
                                <>
                                  <label
                                    htmlFor={
                                      "subCatEdit" + cat._id + i + lan.name
                                    }
                                  >
                                    {lan?.[window?.lang]}
                                  </label>
                                  <input
                                    id={"subCatEdit" + cat._id + i + lan.name}
                                    type="text"
                                    autoComplete="off"
                                    className="form-control box1 p-2"
                                    placeholder={subCat?.[lan.name]}
                                    onChange={(e) => {
                                      setNewValues((pre) => ({
                                        ...pre,
                                        updateSubCat: {
                                          ...pre.updateSubCat,
                                          mainCatId: cat._id,
                                          subCatIndex: i,
                                          subCatValue: subCat.name,
                                          [lan.name]: e.target.value.trim(),
                                          // lang,
                                        },
                                      }));
                                      // const newValuesCo = { ...newValues };
                                      // newValuesCo.updateSubCat = {
                                      //   mainCatId: cat._id,
                                      //   subCatIndex: i,
                                      //   subCatValue: subCat.name,
                                      //   newValue: e.target.value.trim(),
                                      //   lang,
                                      // };
                                      // setNewValues(newValuesCo);
                                      //   return console.log(newValues);
                                    }}
                                  />
                                </>
                              ))}
                              {newValues?.updateSubCat?.fa?.trim()?.length >=
                                3 &&
                                newValues?.updateSubCat?.mainCatId ===
                                  cat._id &&
                                newValues?.updateSubCat?.subCatIndex === i && (
                                  // className="btn  btn-secondary mx-auto justify login-btn my-1"
                                  <i
                                    className="bi bi-check2-square mt-2"
                                    style={{ color: "green", fontSize: 18 }}
                                    onClick={submitHandler}
                                    id={`Input${cat._id}${i}`}
                                  ></i>
                                )}
                            </div>
                          </div>
                          <div className="text-center">
                            <img
                              className="userShowImg userShowImg-subcat my-0 mx-3"
                              src={
                                resizedPhotos[cat._id]?.subCat[i]?.photo?.length
                                  ? resizedPhotos[cat._id]?.subCat[i]?.photo
                                  : subCat.photo
                              }
                              alt={subCat.name}
                              loading="lazy"
                            />
                            <div className="userUpdateUpload">
                              <label
                                className="small-gray-font  mx-auto my-2 "
                                htmlFor={[cat._id, i, "subCatPhoto"]}
                              >
                                تغییر لوگو
                              </label>
                              <span>
                                <Publish
                                  className="userUpdateIcon"
                                  style={{
                                    display: resizedPhotos[cat._id]?.subCat[i]
                                      ?.photo
                                      ? "block"
                                      : "none",
                                  }}
                                  onClick={uploadSubPhotoDatabase}
                                  id={[cat._id, i]}
                                />
                              </span>
                              <input
                                type="file"
                                // id="file"
                                onChange={subPhotoHandle}
                                style={{ display: "none" }}
                                id={[cat._id, i, "subCatPhoto"]}
                              />

                              {/* <button
                            className=" mx-2 btn primary-btn edit-btn"
                            style={{
                              display: resizedPhotos[cat._id]?.subCat[i]?.photo
                                ? "block"
                                : "none",
                            }}
                            onClick={uploadSubPhotoDatabase}
                            id={[cat._id, i]}
                          >
                            بارگذاری
                          </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
            </div>
          </form>
        </div>
      ))}
    </div>
  );
}
