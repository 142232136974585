// import "../../../userList/userList.css";
import { useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "../../../../contexts/cartContext";
import apiDemy from "../../../../../utils/apiDemy";
import ToastNote from "../../../../golbal/notifications/toastNote";
import Tooltip1 from "../../../../golbal/tooltip/tooltip";
import ColoredSpinner from "../../../../alret/spiners/coloredSpiner/coloredSpinner";
// import SearchBarUser from "../profile/transactions/myTrans/searchBarUser";
// import MyIcons from "../../../../golbal/icons/myIcons";
import tools from "../../../../../utils/tools";
import api from "../../../../../utils/api";
import ActionBtnGenerator from "../../../demy/components/actionBtnGenerator";
// import renderData from "../../../../../utils/renderData";

export default function SessionList({ access }) {
  const { user, signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false });
  const [user2, setUser2] = useState({ _id: user._id });
  const [data, setData] = useState({});
  const [history, setHistory] = useState("false");
  const [tutors, setTutors] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [inviteLink, setInviteLink] = useState({});
  // console.log("user", user);
  // console.log("data", data);

  const currentDate = Date.now();
  // const [selectedAction,setSelectedAction]=useState(null)

  const messageRef = useRef(null);
  const { lang, state:{state,stateShort}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});

  useEffect(() => {
    fetchData();
  }, [history, access]);

  async function fetchTutorList() {
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiDemy.fetchTutorList({
      token: user.token,
      access: access,
      id: user2._id,
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setTutors(response.data);
  }

  async function fetchData() {
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiDemy.fetchSessionReqList({
      token: user.token,
      data: {
        access: access,
        id: user?._id,
        history,
        currentDate,
      },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    const categorizedSessions = response.data.reduce((acc, session) => {
      const status = session.history?.[0]?.status;
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(session);
      return acc;
    }, {});

    // console.log(categorizedSessions);
    setData(categorizedSessions);
    // console.log('Object.keys(data)',Object.keys(categorizedSessions))
    setActiveTab(Object.keys(categorizedSessions)[0]);
  }

  function RenderHeaderTabs() {
    const sortedKeys = Object.keys(data).sort();
    const sortedData = {};

    // Populate sortedData with sorted session types
    sortedKeys.forEach((key) => {
      sortedData[key] = data[key];
    });
    let row = [];
    let i = 0;
    for (let key in sortedData) {
      row.push(
        <li
          className="nav-item px-2 text-gray-green-light"
          role="presentation"
          onClick={(e) => setActiveTab(key)}
        >
          <div
            className={`panel-tab my-2 d-flex ${key === activeTab && "active"}`}
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target={`#pills-home${key}`}
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            <div className="me-2 ">
              {tools.renderTrans({ section: "status", key })}
              {/* {renderData.status.find((st) => st.name === key)?.[window.lang]} */}
            </div>

            {/* {window.capitalize( key)} */}
            <div className="items-count pt-1 ">{sortedData[key].length}</div>
          </div>
        </li>
      );
      i++;
    }

    return row;
  }
  // console.log("data", data);
  function RenderRows() {
    let row = [];
    let i = 0;
    for (let key in data) {
      row.push(
        <div
          className={`tab-pane fade ${key === activeTab && "show active"}`}
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabIndex="0"
          id={`pills-home${key}`}
        >
          {data[key].map((session, i) => {
            const currentStatus = session.history?.[0]?.status;
            const hasLink = session?.sessionInfo?.inviteLink?.length > 10;
            return (
              //  session.tutorInfo._id === data[key]._id&&
              <div className="row py-3 mx-0 border-bottom">
                <div className="col-md-6">
                  <div className="row text-center">
                    <div className="col-7">
                      {access === "admin" || access === "tutor" ? (
                        <div className="row">
                          <div className="col-3 px-0">
                            <div className="px-0 d-flex justify-content-center align-items-center">
                              <img
                                className="img-fluid jobCrad-img2 "
                                src={session?.guest?.img}
                                alt={session?.guest?.userName}
                              />
                            </div>
                          </div>
                          <div className="col-9">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <strong>{session?.guest?.userName}</strong>
                              <div className=" small-gray-font">
                                <div>
                                  {tools.dynamicDate({
                                    date: session.sessionInfo?.start,
                                    countryShort,
                                    lang: window.lang,
                                  })}
                                </div>
                                <div>
                                  {tools.dynamicTime({
                                    date: session.sessionInfo?.end,
                                    countryShort,
                                    lang: window.lang,
                                  })}{" "}
                                  -
                                  {tools.dynamicTime({
                                    date: session.sessionInfo?.start,
                                    countryShort,
                                    lang: window.lang,
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-3 px-0">
                            <div className="px-0 d-flex justify-content-center align-items-center">
                              <img
                                className="img-fluid jobCrad-img2 "
                                src={session?.host?.img}
                                alt={session?.host?.name}
                              />
                            </div>
                          </div>
                          <div className="col-9">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <strong>{session?.host?.name}</strong>
                              <div className=" small-gray-font">
                                <div>
                                  {tools.dynamicDate({
                                    date: session.sessionInfo?.start,
                                    countryShort,
                                    lang: window.lang,
                                  })}
                                </div>
                                <div>
                                  {tools.dynamicTime({
                                    date: session.sessionInfo?.end,
                                    countryShort,
                                    lang: window.lang,
                                  })}{" "}
                                  -
                                  {tools.dynamicTime({
                                    date: session.sessionInfo?.start,
                                    countryShort,
                                    lang: window.lang,
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-5 d-flex flex-column text-center px-4">
                      {
                        // (currentStatus === "canceled"||currentStatus === "completed"||currentStatus === "noShowedT"||currentStatus === "noShowedS")
                        currentStatus !== "booked" &&
                          currentStatus !== "test" && (
                            <div className=" small-gray-font ">
                              {
                                tools.renderTrans({ section: "status", key })+" "
                                // renderData.status.find((st) => st.name === key)?.[
                                //   window.lang
                                // ]
                              } 
                              {trans.by[window.lang]} {session.history[0].userName}
                            </div>
                          )
                      }

                      {!inviteLink[session?._id]?.length && (
                        <ActionBtnGenerator
                          session={session}
                          currentStatus={currentStatus}
                          actionHandler={actionHandler}
                          currentDate={currentDate}
                          access={access}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">{session.sessionInfo.desc}</div>
                <div>
                  {(currentStatus === "booked" ||
                    currentStatus === "test" ||
                    currentStatus === "confirmed") &&
                    new Date(session.sessionInfo.start).getTime() +
                      session.sessionInfo.duration * 60 * 60 * 1000 >
                      Date.now() && (
                      <div className="border-bottom border-secondary mb-3">
                        {hasLink && (
                          <button
                            className="btn primary-btn checkout-btn w-100 my-2 p-0 font-10 max-w-200 bg-beigeDark text-green-dark"
                            title={session.sessionInfo.inviteLink}
                            disabled={!hasLink}
                            onClick={(e) =>
                              window.open(session.sessionInfo.inviteLink)
                            }
                          >
                            <strong>{trans.goSe[window.lang]}  </strong>
                          </button>
                        )}

                        {access !== "user" && (
                          <button
                            className="btn primary-btn checkout-btn w-100 my-2 p-0 font-10 max-w-200  bg-beigeGray text-green-dark "
                            onClick={(e) => {
                              e.stopPropagation();
                              const el = e.target.nextElementSibling;
                              el.style.display =
                                el.style.display === "none" ? "flex" : "none";
                            }}
                          >
                            <i className="bi bi-link-45deg font-16"></i>
                            {hasLink
                              ? "Placed a new link"
                              : "Send a new link"}
                          </button>
                        )}
                        <div
                          style={{ display: "none" }}
                          className="input-group mb-2"
                        >
                          <input
                            type="text"
                            id={session._id + "textArea"}
                            defaultValue={inviteLink?.[session?._id]}
                            name=""
                            className="form-control small-gray-font"
                            placeholder="Placed the session link here..."
                            // onBlur={(e) => {
                            //   console.log('blur');                             
                            //   setInviteLink((pre) => ({
                            //     ...pre,
                            //     [session._id]: e.target.value,
                            //   }));
                            // }}
                          />
                          {/* {inviteLink[session?._id]?.length > 0 && ( */}
                            <div
                              className="input-group-append"
                              onClick={(e) =>
                                sendLinkHandle(e, session, currentStatus)
                              }
                            >
                              <span className="input-group-text send_btn h-100">
                                <i className="bi bi-send font-14 "></i>
                              </span>
                            </div>
                          {/* // )} */}
                        </div>
                      </div>
                    )}{" "}
                </div>
              </div>
            );
          })}
        </div>
      );
      i++;
    }
    return row;
  }

  const trans = {
    fuSessions:{fa:"پیش رو", en:"Future sessions"},
    pastSe:{fa:"گذشته، نیاز به اقدام",en:"Past, Action requierd"},
    all:{fa:"همه",en:"All"},
    confirmed:{fa:"تائید",en:"confirmed"},
    canceled:{fa:"کنسل",en:"canceled"},
    completed:{fa:"برگزاری کلاس",en:"completed"},
    by:{fa:"توسط",en:"by"},
    goSe:{fa:"برو به کلاس",en:"Go to the session"},
    by:{fa:"توسط",en:"by"},
  }
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <ToastNote messageRef={messageRef} />
      {loading.spinner && <ColoredSpinner />}
      {/* {!data?.length ? (
        <div>
          داده ای پیدا نشد. پس از اطمینان از وجود داده، دوباره تلاش کنید.
        </div>
      ) : ( */}

      <div className="panel-tab0 d-flex">
        <div
          className={` panel-tab2 mx-1 ${
            history === "needAction" ? "active" : ""
          }`}
        >
          <label htmlFor="needAction">{trans.pastSe[window.lang]}</label>
          <input
            className="form-check-input ms-2 d-none"
            name="historicalData"
            id="needAction"
            type="radio"
            checked={history === "needAction"}
            onChange={(e) => setHistory("needAction")}
          />
        </div>
        <div
          className={` panel-tab2 mx-1 ${history === "false" ? "active" : ""}`}
        >
          <label htmlFor="currentRequests"> {trans.fuSessions[window.lang]}</label>
          <input
            className="form-check-input ms-1 d-none"
            name="historicalData"
            id="currentRequests"
            type="radio"
            checked={history === "false"}
            onChange={(e) => setHistory("false")}
          />
        </div>

        <div
          className={` panel-tab2 mx-1 ${history === "true" ? "active" : ""}`}
        >
          <label htmlFor="historicalRequests">{trans.all[window.lang]}</label>
          <input
            className="form-check-input ms-2 d-none"
            name="historicalData"
            id="historicalRequests"
            type="radio"
            checked={history === "true"}
            onChange={(e) => setHistory("true")}
          />
        </div>
      </div>
      <div>
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <RenderHeaderTabs />
        </ul>

        {/* <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">...</div> */}
        <div className="tab-content" id="pills-tabContent">
          {/* <RenderBody /> */}
          <RenderRows />
        </div>
      </div>
    </div>
  );
  async function actionHandler({ e, session, currentStatus, newStatus }) {
    console.log(currentStatus, newStatus);
    console.log("session", session);

    if (
      window.confirm(window.lang==='fa'?"تائید تغییر وضعیت کلاس به ":"Change the session status to " + tools.renderTrans({
        section: "status",
        key: newStatus,
      }) +" ?") !== true
    )
      return;
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiDemy.changeSessionStatus({
      token: user.token,
      data: {
        lang: window.lang,
        lcs: window.lcs,
        newStatus,
        currentStatus,
        hostId: session.host._id,
        start: session.sessionInfo.start,
        sessionId: session._id,
        slot: session.sessionInfo,
        access,
      },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });

    messageRef.current.showSuccess({
      message: `Session status changed to ${newStatus}`
      //  `${
      //   newStatus === "confirmed"
      //     ? "تائید"
      //     : newStatus === "canceled"
      //     ? "کنسل"
      //     : newStatus === "completed"
      //     ? "برگزاری کلاس"
      //     : ""
      // } موفق`,
    });

    const newData = {
      ...data,
      [newStatus]: data[newStatus]
        ? [...data[newStatus], response.data]
        : [response.data],
    };

    // Remove session from previous status
    const previousStatus = currentStatus;
    const filterPrevious = (data[previousStatus] || []).filter(
      (se) => se._id !== session._id
    );

    // If the previous status array is empty, remove it from data
    if (filterPrevious.length === 0) {
      delete newData[previousStatus];
    } else {
      newData[previousStatus] = filterPrevious;
    }

    setData(newData);
    if (
      access === "user" &&
      (newStatus === "canceled" ||
        newStatus === "noShowedT" ||
        newStatus === "refunded")
    ) {
      // setLoading((pre) => ({ ...pre, spinner: true }));
      const userSuccess = await api.getUser();
      console.log("userSuccess", userSuccess);
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (userSuccess.error)
        return messageRef.current.showError({
          message: (
            <div>
              {/* <div> مشکل در انجام عملیات !</div> */}
              <div>{response.error}</div>
            </div>
          ),
        });
      // console.log(userSuccess.data);
      signedUser(userSuccess.data);
    }
  }
  async function sendLinkHandle(e, session, currentStatus) {
    e.preventDefault();
    // const link = inviteLink[session._id];
    const link = document.getElementById(`${session._id}textArea`)?.value
    if (!link?.length )return messageRef.current.showError({
        message: "Please Place the session link",
      });
    console.log("link", link);
    const urlRegex =
      /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

    if (link?.length && !urlRegex.test(link))
      return messageRef.current.showError({
        message: "Please check the session's Link. ",
      });

    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiDemy.sendInviteLink({
      token: user.token,
      data: { sessionId: session._id, link },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            {/* <div>مشکل در انجام عملیات.</div> */}
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: response.message,
    });
    setInviteLink({});
    document.getElementById(session._id + "textArea").value = "";

    const newData = data[currentStatus].map((se) =>
      se._id === response.data._id ? response.data : se
    );
    const new2 = { ...data, [currentStatus]: newData };
    setData(new2);
  }
}
