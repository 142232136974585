import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";
import { useContext, useRef } from "react";
import { CartContext } from "../../../contexts/cartContext";
import tools from "../../../../utils/tools";
import ToastNote from "../../../golbal/notifications/toastNote";

export default function TutorsGuideEn() {
  const { user } = useContext(CartContext);
  const messageRef = useRef(null);
  return (
    <div className="d-flex align-items-start ">
      <ToastNote messageRef={messageRef} />
      <div className="accordion w-100" id="accordionPanelsStayOpenExample4">
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-register`}
            aria-controls={`open-col-register`}
          >
            <div> + How to complete my teaching profil? </div>
          </div>
          <div
            className="accordion-collapse collapse "
            id={`open-col-register`}
          >
            <div>
              <div>
                <div className="fm1">
                  <div>
                    First, you have to be a Metamarce member.
                    {!user?.roles?.includes(9999) && (
                      <strong
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalToggle"
                        role="button"
                      >
                        (Here)
                      </strong>
                    )}
                  </div>

                  <span>
                    After signing up as a user, complete your tutor profile from
                    the (tutor registration) section.
                  </span>
                  <span
                    onClick={(e) => {
                      if (!window.logined(9999, "tutor")) return;
                      window.navigateHandle(
                        `/${window.lang}/dashboard/user/demy/registertutor`
                      );
                    }}
                    // href={`/${window.lang}/dashboard/user/demy/registertutor`}
                    className="content__link bold-700 cursor"
                  >
                    <strong>(Here)</strong>
                  </span>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                Steps to complete the tutor's profile:
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    The general process of registering on the site is as
                    follows:
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  1
                </div>
                <span>Enter your name</span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  2
                </div>
                <span>
                  Uploading a profile picture is not mandatory, but if uploaded,
                  it must be unique and related to the tutor and not
                  unrealistic.
                </span>
              </div>

              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  3
                </div>
                <span>Select the main category</span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  4
                </div>
                <span>
                  Choose the course and the name of the courses you teach
                  according to the main category (if you don't find the course
                  you want, you can click on
                  <strong> the request for a new course </strong>enter the
                  course you want and finally click the button to
                  <strong> add to the list.</strong>)
                </span>
              </div>

              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  5
                </div>
                <span>
                  Choose from a variety of options if you teach specific age
                  groups (teens, children, teens, or adults).
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  6
                </div>
                <span>Choosing the type of class: face-to-face or online</span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  7
                </div>
                <span>
                  Determining the tuition rate and requested currency based on
                  your country
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  8
                </div>
                <span>
                  Enter the language that you can teach according to your skill
                  level. You can enter more languages ​​by pressing the +
                  button.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  9
                </div>
                <span>
                  Choosing the right keywords to find you more easily and
                  separating them from each other with the + sign
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  10
                </div>
                <span>
                  Filling in "Education" "Experience" "About Me" sections
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  11
                </div>
                <span>
                  "Introduction": This text will be displayed on your
                  introduction card in the list of tutors.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  12
                </div>
                <span>
                  Choosing you schedule
                  <div>
                    Your class schedule will be set for only one week and then
                    displayed to students on a weekly basis and will be
                    automatically renewed until you need to change or stop it.
                  </div>
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  13
                </div>
                <span>
                  If you want to hold the first session as a trial and free,
                  choose this option. The purpose of the trial session is to get
                  to know the tutor and the student better and its duration will
                  be half an hour.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  14
                </div>
                <span>
                  Enter your email address. This address will be used for
                  correspondence with you and information about reserved classes
                  and class start reminder emails and will not be available to
                  students.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  15
                </div>
                <span>
                  In the video section, upload the link of your introduction
                  video on YouTube so that students can learn more about you and
                  your teaching method.
                  <div>
                    Note that uploading a video is not mandatory, but having a
                    video will strengthen your profile for two ways: 1- getting
                    to know you better before booking a trial and Learning
                    Session by the student, 2- being a higher priority when
                    searching in the list of tutors. .
                  </div>
                </span>
              </div>
            </div>
            <div>
              <div>
                <div className="">
                  <p className="fm1 bg-beigeYellow">
                    <strong>
                      💡 To start teaching online in Metamarce , having a work
                      permit and the minimum legal age to teach according to the
                      laws of the country of residence, as well as minimum
                      facilities and tools such as high-speed Internet, laptop
                      and suitable headphones, are necessary and necessary, and
                      it is the responsibility of the tutors to comply with
                      these requirements. Any consequences resulting from
                      non-observance of these items are the responsibility of
                      the tutor. Metamarce may periodically control and evaluate
                      the mentioned regulations
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-metaCredit`}
            aria-controls={`open-col-metaCredit`}
            aria-labelledby="open-col-metaCredit-tab"
          >
            <div>+ What is the Metamarce welcome gift?</div>
          </div>
          <div className="accordion-collapse collapse" id="open-col-metaCredit">
            <div>
              In Metamarce , tutors will receive thirty dollars credit as a
              welcome gift at the time of registration. You can see your gift
              credit balance.
              <span
                onClick={(e) => {
                  if (!window.logined(9999)) return;
                  if (!user?.roles?.includes(1505))
                    return messageRef.current.showError({
                      message:
                        "You have not yet completed your tutoring profile.",
                    });
                  window.navigateHandle(
                    `/${window.lang}/dashboard/user/demy/myTutorlist`
                  );
                }}
                // href={`/${window.lang}/dashboard/user/demy/myTutorlist`}

                className="content__link bold-700 cursor"
              >
                <strong> (here) </strong>
              </span>
            </div>
            <div>
              The balance of Metamarce gift credit can be withdrawn after
              teaching 500 hours of Learning Session.
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed"
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-afterRegister`}
            aria-controls={`open-col-afterRegister`}
            aria-labelledby="open-col-afterRegister-tab"
          >
            <div>+ What should I do after registering as a tutor?</div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-afterRegister"
          >
            <div>
              <p className="content__title fm1">
                Metamarce uses an intelligent system for registration and
                activation, and new tutors after passing the registration
                process are automatically evaluated and enter the list of
                tutors, and gradually and with the level of interaction with
                students, they get a better position and users can Register the
                classes they need in your class schedule.
              </p>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    💡 Note that the more complete and professional your
                    teaching profile is, the system will display it higher and
                    also help more students to choose their own classes from
                    your profile.
                  </p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1"></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed"
            type="button"
            data-bs-toggle="collapse"
            // aria-expanded="true"
            data-bs-target={`#open-col-interview`}
            aria-controls={`open-col-interview`}
            aria-labelledby="open-col-interview-tab"
          >
            <div> + Do I need to do an interview before activation?</div>
          </div>

          <div className="accordion-collapse collapse" id="open-col-interview">
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    In Metamarce , based on the honest and realistic statement
                    of the tutors about their own abilities on the one hand, and
                    on the other hand, the students' ability to choose a tutor
                    according to their own needs and using the information
                    provided by the tutor himself, the views of other students,
                    and the introduction video and free trail session. However,
                    in Metamarce , tutors' profiles are always monitored by
                    artificial intelligence and its report is sent to the
                    support team on a daily basis. And if any contradiction with
                    educational goals and Metamarce rules is observed,
                    suspicious profiles will be temporarily disabled or deleted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed"
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-conditions`}
            aria-controls={`open-col-conditions`}
            aria-labelledby="open-col-conditions-tab"
          >
            <div>
              {" "}
              + What is the requirement for tutors to cooperate with Metamarce ?
            </div>
          </div>

          <div className="accordion-collapse collapse" id="open-col-conditions">
            <div>
              <div>
                <div className="">
                  <p className="fm1">
                    Commitment, knowledge, sufficient expertise, ability to
                    transfer concepts and necessary experience in the desired
                    field of teaching
                  </p>
                </div>
                <div className="">
                  <p className="fm1">
                    Having a work permit and the minimum legal age to work
                    according to the laws of the country and province of
                    residence.
                  </p>
                </div>
                <div className="">
                  <p className="fm1">
                    Having a suitable hardware and software platform and stable
                    and high-quality internet for holding online meetings
                  </p>
                </div>
                <div className="">
                  <p className="fm1">
                    For online classes, access to meeting platforms
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed"
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-completedProfile`}
            aria-controls={`open-col-completedProfile`}
            aria-labelledby="open-col-completedProfile-tab"
          >
            <div> + چرا پروفایل(های) خودم رو باید به صورت کامل به روز نگه دارم ؟ </div>
          </div>

          <div
            className="accordion-collapse collapse"
            id="open-col-completedProfile"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1"></p>
                </div>
                <div className="fm1">
                  <span>
                    تهیه یک پروفایل به روز و کامل همیشه دارای 
                    داشتن یک پروفایل کامل بسیار حائز اهمیت است، زیرا مخاطبین بعد
                    از ورود به سایت،
                  </span>
                  <a
                    href={`/${window.lang}/demy/tutor/filters`}
                    className="content__link bold-700"
                  >
                    لیست اساتید
                  </a>
                  <span>
                    را مشاهده و پروفایل آنها را با هم مقایسه می‌کنند، پس هرچقدر
                    پروفایل شما با کیفیت بهتری ساخته شده باشد، برای مخاطبین
                    قابل‌اعتمادتر خواهد بود.
                  </span>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    منظور از پروفایل کامل یعنی علاوه بر تکمیل بودن موارد اجباری،
                    موارد اختیاری را هم به دقت تکمیل نمائید از جمله موارد زیر :
                  </p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">1. عکس پروفایل مناسب</p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">2. برنامه کلاسی به روز</p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">3. ویدئو معرفی با کیفیت و مناسب</p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">4.انتخاب کلمات کلیدی مناسب</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed"
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-uploadPhoto`}
            aria-controls={`open-col-uploadPhoto`}
            aria-labelledby="open-col-uploadPhoto-tab"
          >
            <div>
              
              + برای آپلود عکس پروفایل مناسب چه نکاتی رو باید رعایت کنیم؟
            </div>
          </div>

          <div
            className="accordion-collapse collapse"
            id="open-col-uploadPhoto"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    عکس پروفایل شما یکی از مهم‌ترین بخش‌های جذب دانشجو است. پس
                    سعی کنید بهترین عکس را برای پروفایل‌ خود انتخاب کنید. عکسی
                    کاملاً واضح که ترجیحاً فقط صورتتان را نشان دهد.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    از عکس‌های تار و بی کیفیت استفاده نکنید.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    اگر بک‌گراند تصویرتان شلوغ نباشد حس خیلی بهتری به کاربر
                    منتقل می‌شود و تمرکز فقط روی شماست.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    عکس فقط از خود شما باشد و شخص دیگری در عکس حضور نداشته باشد.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div className="">
                  <p className="fm1">
                    بهتر است در نور شب عکس نیندازید و یا عکس‌هایی که فیلتر تاریک
                    و یا تار دارند را انتخاب نکنید.
                  </p>
                </div>
                <div className="flex column-flex"></div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed"
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-VideoContent`}
            aria-controls={`open-col-VideoContent`}
            aria-labelledby="open-col-VideoContent-tab"
          >
            <div> + Why is it important to have an introduction video?</div>
          </div>

          <div
            className="accordion-collapse collapse"
            id="open-col-VideoContent"
          >
            <div>
              <p className="content__title fm1">
                Introduction video is your most important tool to convey your
                level of knowledge and the general atmosphere of your classes,
                and it helps students to experience being your student for a few
                minutes and get to know you better before booking a trial or
                educational class. Therefore, it is important to prepare a good
                introduction video of yourself and provide it to the students in
                order to intelligently attract more students to you and save
                your and your students' time.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed"
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-technicalVideo`}
            aria-controls={`open-col-technicalVideo`}
            aria-labelledby="open-col-technicalVideo-tab"
          >
            <div> + What points should I follow in preparing the video?</div>
          </div>

          <div
            className="accordion-collapse collapse"
            id="open-col-technicalVideo"
          >
            <div>
              <div className="boolet-point">
                <p className="fm1">Presence of yourself in the video.</p>
              </div>
            </div>
            <div>
              <div className="boolet-point">
                <p className="fm1">
                  The duration of the video should be between 1 and 3 minutes.
                </p>
              </div>
            </div>

            <div>
              <div className="boolet-point">
                <p className="fm1">Avoid direct communication.</p>
              </div>
            </div>
            <div>
              <div className="boolet-point">
                <p className="fm1">Be sure to make the video horizontally.</p>
              </div>
              <div className="flex column-flex"></div>
            </div>

            <div>
              <div>
                <p className="fm1">
                  Before starting to record the video, it is better to use a
                  checklist or text and try to remember its points and proceed
                  according to it during the video recording, without having to
                  refer to it again during the video production. And try to
                  include the following if possible:
                </p>
              </div>
            </div>
            <div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  1
                </div>
                <span>
                  Name and surname, educational records and experiences.
                </span>
              </div>

              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  2
                </div>
                <span>
                  The teaching method and probably the reasons that made you
                  interested in teaching.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  3
                </div>
                <span>
                  Try to be yourself and behave naturally and have a smile on
                  your face and don't be too dry (students are not comfortable
                  with a very dry and serious tutor).
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  4
                </div>
                <span>
                  Try to show students that you understand them and understand
                  their needs.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  5
                </div>
                <span>
                  Mention your free trial class and let them know how to book a
                  trial class with you.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  6
                </div>
                <span>
                  If you have the ability and experience of teaching a certain
                  group, mention it, for example, children or teenagers, and
                  dedicate a part of the video to talk with them.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  7
                </div>
                <span>
                  If you have expertise in a specific field, you can also
                  mention it, for example, the entrance exams of institutions or
                  universities...
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  8
                </div>
                <span>
                  If you think that you teach in a certain way and it may be
                  attractive to students, you can express it in a few minutes of
                  teaching.
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-introduction`}
            aria-controls={`open-col-introduction`}
            aria-labelledby="open-col-introduction-tab"
          >
            <div>
              + چرا باید بخش «درباره‌ی من» را بنویسم؟ نکات مهم «درباره من»
            </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-introduction"
          >
            <div>
              <p className="content__title fm1">
                توجه داشته باشید که این بخش هم توجه بسیاری از کاربران را جلب
                می‌کند و ممکن است کاربران بدون دیدن ویدئو معرفی شما و با خواندن
                این متن، تصمیم بگیرند که با شما کلاس داشته باشند.
              </p>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                در متن بیو چه اطلاعاتی رو باید بنویسم؟
              </h2>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    درباره تجربیات و مهارت‌های قابل تدریس و مدارکی که دارید
                    توضیح بدید.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    تکنیک‌ها و روش‌های منحصربه‌فردی که در تدریس استفاده می‌کنید
                    را توضیح بدید.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    توضیح بدید که دنبال چه دانشجویانی هستید از نظر رنج سنی و سطح
                    و به چه افرادی تدریس کرده‌اید.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    اگر جلسه آزمایشی‌ شما رایگان می‌باشد، سعی کنید توضیح مختصری
                    داشته باشید و در اون توضیح اعلام کنید که در این جلسه قصد
                    دارید به چه موضوعاتی بپردازید.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    اگر لازمه دانشجویان قبل از رزرو کلاس با شما، پیش‌نیازی را
                    بدانند، حتماً از این پیش‌نیاز مطلع‌شان کنید.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                در متن بیو چه اطلاعاتی رو نباید بنویسید:
              </h2>
              <div>
                <div className="boolet-point">
                  <p className="fm1">اطلاعات تماس شخصی</p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    معرفی پیج و یا سروریس‌هایی به جز متامارس
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">تبلیغ سرویس‌ها و پلتفرم‌های دیگه</p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">
                    توجه داشته باشید اگر فقط به گروه خاصی از دانشجویان تدریس
                    می‌کنید، حتما این نکته رو هم در قسمت درباره‌من بنویسید تا
                    دانشجویانی که در نظر دارید راحت‌تر بتوانند پروفایل شما را
                    پیدا کنند. و هر چقدر این متن دقیق نوشته شود، کاربران بیشتر
                    ترغیب خواهند شد که ویدئوی معرفی شما را ببینند و یا یک جلسه‌ی
                    رایگان با شما رزرو کنند.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                ویرایش پروفایل
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    برای به روز رسانی و ویرایش پروفایل خود کافیست تنها به صفحه
                    خودتون برین و دکمه ویرایش رو بزنید و در این صورت میتونین
                    تمام اطلاعات خودتون رو به صورت آنلاین به روز کنین.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-tutorGrading`}
            aria-controls={`open-col-tutorGrading`}
            aria-labelledby="open-col-tutorGrading-tab"
          >
            <div>
              + How are tutors evaluated and displayed in the list of tutors?
            </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-tutorGrading"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    <div>
                      Professors in Metamarce are evaluated based on various
                      parameters and get points that determine their display
                      position in the list of tutors. This evaluation is done on
                      a daily basis and determines the performance position of
                      each tutor.
                    </div>
                    <div>You can see the ranking parameters below:</div>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                Activity parameters
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    <div> Commitment:</div>It means your commitment to holding
                    classes and its number is based on:
                    <div>
                      The number of reservation classes (experimental and
                      educational), confirmed, held or canceled.
                    </div>
                    <div
                      className="cursor"
                      onClick={(e) => {
                        const elementToOpen = document.getElementById(
                          "open-col-tutorWallet"
                        )?.previousElementSibling;
                        if (elementToOpen.classList.contains("collapsed")) {
                          elementToOpen.click();
                        }
                        setTimeout(() => {
                          tools.scrollToAnchor("open-col-tutorWallet");
                        }, 100);
                      }}
                    >
                      (more information)
                    </div>
                  </p>
                </div>
              </div>
              <div className="">
                <p className="fm1">
                  The number of students who will hold a training class with you
                  after the trial class.
                </p>
              </div>
              <div className="">
                <p className="fm1">
                  The number of students who repeat their classes with you.
                </p>
              </div>
              <div className="">
                <p className="fm1">Number of views received from students</p>
              </div>
              <div className="">
                <p className="fm1">Points received from students</p>
              </div>
              <div className="">
                <p className="fm1">
                  Updating the training calendar and having more training times
                  in the schedule of classes.
                </p>
              </div>
            </div>

            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                Free trial session
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    Being active in the trial session will get points for this
                    section and also encourage more students to book sessions
                    with you.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                New tutors
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    Professors who have just registered are supported by
                    Metamarce for up to 4 months, and respectively, in the first
                    20 days, the maximum support points and after each month and
                    up to four months, their support amount decreases.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                YouTube video
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    Professors who upload their YouTube introduction video will
                    receive a high score in this section and their profile will
                    be displayed in the list of tutors higher than other tutors
                    who do not have a video.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                Teaching profile picture
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    Uploading a profile picture has points and it is suggested
                    that tutors upload it to make their profile stand out
                    compared to other tutors and receive points in this section.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-freeSessions`}
            aria-controls={`open-col-freeSessions`}
            aria-labelledby="open-col-freeSessions-tab"
            id="first-session-rules"
          >
            <div>+ A class is reserved with me, what should I do?</div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-freeSessions"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    Students expect tutors to be committed and attend classes on
                    time.
                    <div></div>For students, it is very disappointing when the
                    tutor does not show up for the first meeting. Failure to
                    attend the first meeting is not only bad for the tutor, but
                    also damages the position of other Metamarce tutors.
                  </p>
                  This is especially important for trial courses with new
                  students.
                </div>
              </div>
              <div></div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    After receiving the class reservation email, you can confirm
                    or cancel it, with this notification, the student is assured
                    of holding or not holding the class at the designated time,
                    and each of them has positive and negative points as
                    follows:
                  </p>
                  <div>
                    <h2 className="titr7 text-gray-green text-start my-3">
                      Confirmation or cancellation of booked classes:
                    </h2>
                    <div></div>
                    <div>
                      By confirming the reservation of a training class of 1
                      point and a trial class of 2 positive points, your
                      commitment points and gift credit will increase by 0.5% of
                      the class amount.
                    </div>
                    <div>
                      Canceling a training and trial class will result in a
                      reduction of 1 point from your commitment points to your
                      students and 7 dollars from your Metamarce gift credit.
                    </div>
                    <div>
                      Canceling approved classes will result in a 2 point
                      deduction from your Student Commitment Points and a $10
                      deduction from your Metamarce gift credit.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <h2 className="titr7 text-gray-green text-start my-3">
                    How do I prepare for class?
                  </h2>
                  In online classes, at least half an hour before the start of
                  the class, upload the link to the class through the tutor's
                  own page or from the user panel for the student, and after
                  that the link will be automatically sent to the student
                  through Metamarce chat and email, and the tutor will also It
                  will be in the list of email recipients, you can also send the
                  link to enter the class directly to the student's email or
                  chat.
                  <div>
                    To generate a class link, you can use any of the platforms
                    that you have worked with and are familiar with, such as
                    Zoom, Teams, Google Meet, etc., as long as they are not
                    filtered. Be sure of the service in Iran or other countries
                    under filtering.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                What should I do after the class?
              </h2>
              <div>
                After the class date, two options will be activated for the
                class, where you can choose the key to confirm the holding or
                absence of the student.
              </div>
              <div>
                <span className="text-pink-orange-dark ">Confirmation:</span>The
                amount of the class will be transferred to your account and will
                be credited to your account at the end of the month
              </div>
              <div>
                <span className="text-pink-orange-dark ">
                  Absence of a student:
                </span>{" "}
                If you select the button of absence of a student, two other
                options will be displayed to you, in which you can return the
                class fee to the student or select the deposit to my account
                button so that the amount will be transferred to your account at
                the end of the month. .
              </div>
            </div>
            <div>
              <div className="boolet-point">
                <p className="fm1">
                  Note that you are required to wait for the student for 10
                  minutes from the start of the class, and if the student is not
                  present, send the class link to the student twice, and then
                  select the absent student button if the student is not
                  present.
                </p>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                What happens if I miss a class?
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    If a tutor misses a class (tutor absence), the student can
                    declare that the tutor was absent. After this announcement,
                    an email will be automatically sent to the tutor, and tutors
                    will always have access to the history of their classes in
                    their user panel.
                  </p>
                  <div>
                    * If for any reason you face a problem before or during the
                    class and you are unable to hold the class, make sure to
                    coordinate and hold a compensatory meeting to prevent the
                    student from using this option.
                  </div>
                  <div>
                    Non-attendance of the tutor in the class means the
                    cancellation of the class, and the class fee will be
                    deducted from the student's account and 4 points will be
                    deducted from the commitment points and 15 dollars will be
                    deducted from the 30 dollar Metamarce gift at the time of
                    registration.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-tutorPayments`}
            aria-controls={`open-col-tutorPayments`}
            aria-labelledby="open-col-tutorPayments-tab"
          >
            <div>+ How are the classes paid?</div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-tutorPayments"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    After the class date, two options for confirmation of class
                    holding and non-attendance of students will be activated for
                    tutors in the Metademy user panel and the tutors menu and
                    the sub-menu (past, need to take action )
                    <span
                      onClick={(e) => {
                        if (!window.logined(9999)) return;
                        if (!user?.roles?.includes(1505))
                          return messageRef.current.showError({
                            message:
                              "You have not yet completed your tutoring profile.",
                          });
                        window.navigateHandle(
                          `/${window.lang}/dashboard/user/demy/listsessions`
                        );
                      }}
                      // href={`/${window.lang}/dashboard/user/demy/myTutorlist`}

                      className="content__link bold-700 cursor"
                    >
                      <strong> (here) </strong>
                    </span>
                    You will add
                    <span
                      onClick={(e) => {
                        if (!window.logined(9999)) return;
                        if (!user?.roles?.includes(1505))
                          return messageRef.current.showError({
                            message:
                              "You have not yet completed your tutoring profile.",
                          });
                        window.navigateHandle(
                          `/${window.lang}/dashboard/user/demy/tutortranslist`
                        );
                      }}
                      // href={`/${window.lang}/dashboard/user/demy/myTutorlist`}

                      className="content__link bold-700 cursor"
                    >
                      <strong> (here) </strong>
                    </span>
                    nd collect reports at the end of each month, and in the
                    first week, the deposit will be sent to your PayPal account
                    and to the email address provided in the financial
                    information section
                    <span
                      onClick={(e) => {
                        if (!window.logined(9999)) return;
                        if (!user?.roles?.includes(1505))
                          return messageRef.current.showError({
                            message:
                              "You have not yet completed your tutoring profile.",
                          });
                        window.navigateHandle(
                          `/${window.lang}/dashboard/user/user/profile/me`
                        );
                      }}
                      // href={`/${window.lang}/dashboard/user/demy/myTutorlist`}

                      className="content__link bold-700 cursor"
                    >
                      <strong> (here) </strong>
                    </span>
                  </p>
                  <div>
                    Note: Professors who have registered from Iran and entered
                    their Shaba account, the deposits will be to their Shaba
                    account, and if they have chosen a currency other than the
                    Rial, they are required to provide their PayPal account
                    information to deposit the class money to it. are considered
                  </div>
                  {/* <div>نکته2: متامارس ممکن است بنا به درخواست اساتیدی که فاقد حساب پی پال میباشند و علاقه مند به برگزاری کلاس با نرخهایی غیر از ریال ایران باشند،از خدمات صرافی های مجاز استفاده نماید و مبلغ کارکرد اساتید را در روز واریز به نرخ روز تبدیل و به ریال ایران و به حساب آنها واریز نماید و متعاقب آن هزینه های صرافی و پردازش از حق الزحمه آنها کسر خواهد شد.  </div> */}
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    <i className="bi bi-circle-fill font-14 me-1"></i>You can
                    use my profile and user panel to check your email or update
                    your name and surname.
                    <span
                      onClick={(e) => {
                        if (!window.logined(9999)) return;
                        if (!user?.roles?.includes(1505))
                          return messageRef.current.showError({
                            message:
                              "You have not yet completed your tutoring profile.",
                          });
                        window.navigateHandle(
                          `/${window.lang}/dashboard/user/demy/registertutor`
                        );
                      }}
                      // href={`/${window.lang}/dashboard/user/demy/myTutorlist`}

                      className="content__link bold-700 cursor"
                    >
                      <strong> (here) </strong>
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <i className="bi bi-circle-fill font-14 me-1"></i>Note that
                  the name and surname of the payee must match the name and
                  surname of the registered family.
                </div>
                <div className="boolet-point">
                  <p className="fm1">
                    <i className="bi bi-circle-fill font-14 me-1"></i>According
                    to the future needs due to the geographical spread of tutors
                    throughout the countries of the world including Iran, other
                    ways and financial institutions may be used to transfer
                    money according to the tutors' needs, which is proportional
                    to their financial and processing costs. Processing fees
                    will be deducted from it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-tutorWallet`}
            aria-controls={`open-col-tutorWallet`}
            aria-labelledby="open-col-tutorWallet-tab"
          >
            <div>
              + Description of the financial report of tutors' performance
            </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-tutorWallet"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    in
                    <span
                      onClick={(e) => {
                        if (!window.logined(9999)) return;
                        if (!user?.roles?.includes(1505))
                          return messageRef.current.showError({
                            message:
                              "You have not yet completed your tutoring profile.",
                          });
                        window.navigateHandle(
                          `/${window.lang}/dashboard/user/demy/tutortranslist`
                        );
                      }}
                      // href={`/${window.lang}/dashboard/user/demy/myTutorlist`}

                      className="content__link bold-700 cursor"
                    >
                      <strong> (here) </strong>
                    </span>{" "}
                    you can see the list of classes along with their status and
                    net amount, which will be sent to your PayPal account in the
                    first week of every month.
                  </p>
                </div>
                <div className="flex column-flex"></div>
              </div>

              <div>
                <div className="">
                  <p className="fm1">
                    Please note that in Metademy, in order to maintain order and
                    discipline when the class is canceled by the tutor, a
                    negative commitment score and negative credit will be
                    considered from the amount of Metamarce gift credit, the
                    negative score will affect your performance position.
                  </p>
                </div>
              </div>

              <div>
                <div className="">
                  <p className="fm1">
                    - If the student cancels more than 24 hours before the start
                    of the class, Metamarce will return all the money to the
                    student's account. However, if the cancellation is made less
                    than 24 hours before the start of the class, or the student
                    does not attend the class, the decision will be the
                    responsibility of the tutor. In this way, after the end of
                    the class time, the option of refund or non-refund will be
                    activated for the tutor and the tutor's decision will be
                    applied automatically. In this case, the contribution will
                    not be considered as a penalty or cancellation fee for
                    Metamarce . However, depending on the type of transaction
                    and the money transfer fees that will be imposed by the
                    bank, PayPal or other third parties to return the money, it
                    may be deducted from the account that caused the
                    cancellation.
                    <p>
                      * Respected tutors should also note that after
                      registration and acceptance, Metamarce will give you a $30
                      credit as a gift. And in case of not attending the class,
                      $15 will be deducted.
                    </p>
                    <p>
                      ** Each cancellation of a class with reservation status
                      will result in a deduction of $7 and each cancellation of
                      a class with a confirmed status will result in a deduction
                      of $10 from your credit.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-canceledSessions`}
            aria-controls={`open-col-canceledSessions`}
            aria-labelledby="open-col-canceledSessions-tab"
          >
            <div>
              + How much will Metamarce's share of Learning Sessions and
              canceled sessions be?
            </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-canceledSessions"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    Metamarce's share of Learning Sessions is 20% of the amount
                    that tutors set during registration and pricing.
                    {/* <div>
                      نکته : اساتیدی که بالای 1000 ساعت جلسه آموزشی برگزار کنند
                      کمیسیون آن به ۱۵ درصد کاهش می‌یابد.
                    </div> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-cancelationFees`}
            aria-controls={`open-col-cancelationFees`}
          >
            <div>
              + Will there be a penalty for the tutor and the user by canceling
              the sessions?
            </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-cancelationFees"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                Cancellation rules
              </h2>
              <div>
                <h2 className="titr7 text-gray-green text-start my-3"></h2>
                <div>
                  <div className="">
                    <p className="fm1">
                      Note that in Metamarce , tutors have full authority
                      regarding classes canceled late (less than 24 hours before
                      the start) by users, and they can receive the full amount
                      of the class or return it to the student.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <h2 className="titr7 text-gray-green text-start my-3"></h2>
                <div>
                  <div className="">
                    <p className="fm1">
                      Class cancellation by tutors is subject to the following.
                    </p>
                    <div>
                      Cancellation of classes with reservation status will have
                      7 negative credit points and cancellation of approved
                      classes will have 10 negative credit points from your gift
                      credit, and after your gift credit reaches 0, the tutor's
                      profile will not be displayed in the list of tutors
                      automatically. Reactivation and display is possible only
                      by charging the initial credit amount.
                    </div>
                    Also, canceling the class will reduce the tutor's commitment
                    rate to the students, which is visible to the students and
                    has an impact on the tutor's profile display.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-commitmentScore`}
            aria-controls={`open-col-commitmentScore`}
          >
            <div>
              + What is Metamarce ' professional badge and how do I get it?
            </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-commitmentScore"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    You may have seen the professional badge under the name of
                    some tutors. We award professional badges to tutors who have
                    performed well.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                Benefits of the professional tutor badge
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    Professional badge: Having this badge on your profile will
                    increase the number of students who book trial and Learning
                    Sessions with you and help you grow your business and
                    increase your income.
                  </p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">
                    The professional badge gives students the confidence even
                    before reading the profile that they have found a tutor who
                    is professional in his work and can trust him in the
                    learning process.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                How can you become a certified tutor?
              </h2>
              <div>
                <div className="">
                  <div className="d-flex my-md-4  my-2">
                    <span>
                      Professors in Metamarce are evaluated dynamically and any
                      activity or lack of activity can affect the conditions of
                      receiving a professional badge.
                    </span>
                  </div>
                  <div className="my-md-4  my-2">
                    <div className="d-flex ">
                      <span>Professional tutor evaluation criteria:</span>
                    </div>

                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        1
                      </div>
                      8
                      <span>
                        The rate of conversion of experimental students to
                        educational ones
                      </span>
                    </div>

                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        2
                      </div>
                      <span>
                        Course absence: You have not been absent for any of the
                        courses (experimental courses or non-experimental
                        courses).
                      </span>
                    </div>
                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        3
                      </div>
                      <span>
                        Educational classes held: you must be 10 or more.
                      </span>
                    </div>
                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        4
                      </div>
                      <span>
                        Average review score: You have a mastery score of 4.5 or
                        higher.
                      </span>
                    </div>
                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        5
                      </div>
                      <span>
                        Response rate: You have responded to at least 90% of
                        initial messages from your potential students in less
                        than 24 hours.
                      </span>
                    </div>
                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        6
                      </div>
                      <span>
                        {" "}
                        The number of cancellations of trial and Learning
                        Sessions.
                      </span>
                    </div>

                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        7
                      </div>
                      <span>
                        Tutor's approval rate for attending the scheduled
                        training and trial classes.
                      </span>
                    </div>
                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        8
                      </div>
                      <span>Upload video introduction.</span>
                    </div>
                  </div>

                  <div className="d-flex">
                    <span>
                      The professional badge will be calculated based on the
                      above criteria and as soon as all the conditions are met,
                      this badge will be automatically awarded to the active
                      tutor, and if you lose the conditions, you will lose it.
                    </span>
                  </div>

                  <div>
                    <div>
                      In the meantime, read some important tips to help you
                      adapt and become a professional tutor:
                    </div>
                    <div>
                      ★ Have as many times as possible This will increase the
                      number of students who book sessions with you. Also, make
                      sure you are free and available at the designated times.
                    </div>
                    <div>
                      ★ If you do not teach certain students, specify them. You
                      do not teach certain students, such as junior or lower
                      level students, mention this in your description. This
                      will reduce your chances of booking trial sessions with
                      the wrong students.
                    </div>
                    <div>
                      ★ Ask your students for feedback and ratings - don't be
                      shy! If a student enjoys your classes, ask them to rate
                      you. Students are usually happy to write positive things
                      about their tutor.
                    </div>
                    <div>
                      <div>
                        ★ Block your non-empty times in your class schedule
                      </div>
                      <div>
                        To avoid canceling or rescheduling lessons, if you were
                        busy when you would normally be free to teach, use edit
                        your profile and remove that day or time.
                      </div>
                    </div>
                    <div>
                      <div>
                        ★ Use a customizable template for student responses
                      </div>
                      <div>
                        To quickly respond to your new students, create a
                        template that you can easily modify as needed.
                      </div>
                    </div>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-pricing`}
            aria-controls={`open-col-pricing`}
            aria-labelledby="open-col-pricing-tab"
          >
            <div>+ How do I determine the price and currency of my class?</div>
          </div>
          <div className="accordion-collapse collapse" id="open-col-pricing">
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    To determine the price of each hour of teaching, you must
                    pay attention to several factors, which are summarized as
                    follows.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                What currency should I choose?
              </h2>
              <div>
                <div className="">
                  <div className="fm1">
                    By default, it is better to choose the currency of your
                    country of residence, but you can still choose other
                    currencies.
                  </div>

                  <p>
                    Students see your prices based on the official currency of
                    their country of residence, and all tutors' rates in
                    different countries are converted into one rate and
                    displayed, so for students there is no difference between
                    dollars and rials and they are all the same.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                What price should I pay for my classes?
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    The price of the classes depends entirely on your assessment
                    of yourself and the marketing method you choose for your
                    teaching, and it is not the same for all tutors, and you can
                    start from very cheap, expensive or moderate, but it is
                    recommended to check the prices of other tutors first.
                    Compare the newness, number of students, classes held,
                    rating and views received, and if it is similar to your
                    profile, you can use them to determine the price of your
                    class.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                Trial sessions
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    Trial sessions are the key to attracting students because
                    they can easily get to know the tutor and make their
                    decision. Of course, all trial sessions are half an hour
                    long.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                When and how should I increase my class rate?
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    The best time to increase your class rate is when you have
                    built your brand, attracted students, and have at least 50%
                    of your class schedule booked, but before you increase the
                    price of your existing students. Keep them up-to-date on the
                    increase and ask for their feedback, and make sure they're
                    still eager to continue with you after the increase.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-discontinuing`}
            aria-controls={`open-col-discontinuing`}
            aria-labelledby="open-col-discontinuing-tab"
          >
            <div>
              + What will be the tutor's obligations at the time of termination
              of cooperation?
            </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-discontinuing"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                Decision to terminate cooperation
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    In case of decision to terminate the cooperation, respected
                    tutors are obliged to first deactivate their educational
                    account (the activation/deactivation button is located at
                    the top of the tutor information editing page) so that new
                    students do not book classes with them and Metamarce support
                    by sending E-mail to (metamarce.com@gmail.com). and finish
                    their current classes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-multiTutor`}
            aria-controls={`open-col-multiTutor`}
            aria-labelledby="open-col-multiTutor-tab"
          >
            <div>+ Can I teach in two separate fields?</div>
          </div>
          <div className="accordion-collapse collapse" id="open-col-multiTutor">
            <div>
              In Metamarce , it is possible that if a tutor has the ability to
              teach in two different fields, they can create a separate profile
              for each of them
              <span
                onClick={(e) => {
                  if (!window.logined(9999)) return;
                  if (!user?.roles?.includes(1505))
                    return messageRef.current.showError({
                      message:
                        "You have not yet completed your tutoring profile.",
                    });
                  window.navigateHandle(
                    `/${window.lang}/dashboard/user/demy/registertutor`
                  );
                }}
                // href={`/${window.lang}/dashboard/user/demy/myTutorlist`}

                className="content__link bold-700 cursor"
              >
                <strong> (here) </strong>
              </span>
              for example, teaching guitar and math
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
