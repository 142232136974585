import { useContext, useRef, useState } from "react";
import api from "../../../../utils/api";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ColoredSpinner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
// import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";

export default function Faq() {
  const messageRef = useRef(null);
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useContext(CartContext);
  const questions = [
    {
      row: 1,
      q: "How can I create an account on Metamarce?",
      a: "To create an account, click the Login/Register button and follow the steps to set up your login information.",
    },
    {
      row: 2,
      q: "I forgot my password, what should I do?",
      a: "Click the Login/Register button in the top right corner, then the Activation/Forgot Password button, then enter your email and follow the instructions to recover.",
    },
    {
      row: 9,
      q: "How do I solve the Access denied problem?",
      a: "Log out of your account once and log back in.",
    },
  ];
  const qTutors = [
    {
      row: 1,
      q: "Is there a fee to list my profile in the tutor's directory?",
      a: "Registering as tutorin Metademy, and showing your profile in the tutor directory is completely free.",
    }
  ];
  const structuredData = [
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "frequently asked questions",
      description:"Search for your questions and ask yours.",

      url: "https://www.metamarce.com/en/prompt/faq",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "Landing",
              description: "A bridge between teachers and students around the world",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/prompt/faq`,
              name: "FAQ",
              description:
                "Frequently asked questions",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "FAQ",
    description:
      "Search for your questions and ask yours.",
    section: "Frequently asked questions",
    url: `https://www.metamarce.com/${window.lang}/prompt/faq`,
    canonical: `https://www.metamarce.com/${window.lang}/prompt/faq`,
    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
  };

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      {loading && <ColoredSpinner />}
      <h1 className="titr1 font-40 my-5"> It might be your question too! (FAQ)</h1>
      <div className="row">
        <div className="col-md-6  d-flex flex-column align-items-center p-md-5 p-3 pb-1  ">
          <h2 className="titr7 my-3"> User questions</h2>

          <div className="accordion w-100" id="accordionPanelsStayOpenExample">
            {questions.map((question) => (
              <div key={question.row} className="accordion-item2 ">
                <h2 className="accordion-header" id={`question${question.row}`}>
                  <button
                    className="accordion-button accordion-button2 collapsed "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#panelsStayOpen-collapse${question.row}`}
                    aria-expanded="true"
                    aria-controls={`panelsStayOpen-collapse${question.row}`}
                  >
                    + {question.q}
                  </button>
                </h2>
                <div
                  id={`panelsStayOpen-collapse${question.row}`}
                  className="accordion-collapse collapse "
                  aria-labelledby={`question${question.row}`}
                >
                  <div className="accordion-body">
                    <strong>{question.a}</strong>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Business */}
        <div className="col-md-6  d-flex flex-column align-items-center p-md-5 p-3 pb-1  ">
          <h2 className="titr7 my-3">Questions from Tutors</h2>

          <div className="accordion w-100" id="accordionPanelsStayOpenExample2">
            {qTutors.map((question) => (
              <div key={question.row} className="accordion-item2 ">
                <h2 className="accordion-header" id={`qBus${question.row}`}>
                  <button
                    className="accordion-button accordion-button2 collapsed "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#open-col-qBus${question.row}`}
                    aria-expanded="true"
                    aria-controls={`open-col-qBus${question.row}`}
                  >
                    + {question.q}
                  </button>
                </h2>
                <div
                  id={`open-col-qBus${question.row}`}
                  className="accordion-collapse collapse "
                  aria-labelledby={`qBus${question.row}`}
                >
                  <div className="accordion-body">
                    <strong>{question.a}</strong>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <!-- Ask us!--> */}
      <div className="banner-listing-2 w-100  mt-0">
        <div className="row ads-all mx-auto">
          <div className="col-md-6  d-flex flex-column align-items-center p-5  pt-1 img-banner-2 arrow-box ">
            <form
              action=""
              className="w-100 my-5 "
              name="homeForm"
              autoComplete="true"
            >
              <div className="my-5">
                <label htmlFor="f25" className="h4 form-label my-2 titr7">
                Can't find your question? Ask it here!
                </label>
                <textarea
                  id="f25"
                  className="form-control shadow-none my-5"
                  rows="7"
                  placeholder=""
                  onChange={(e) =>{
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                    setQuestion(e.target.value)}}
                ></textarea>
              </div>

              <button
                type="button"
                className="btn primary-btn checkout-btn cart-footer mx-auto w-75 mt-3"
                onClick={sendRequestHandle}
              >
                <i className="bi bi-pencil-square p-1"></i>
                Send!
              </button>
            </form>
          </div>

          <div className="col-md-6 d-flex flex-column align-items-center  px-md-5 py-0 ">
            <img
              src="/img/Images/questions-animate.svg"
              alt="faq"
              className="faq-img mx-md-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
  async function sendRequestHandle(e) {
    if (!window.logined(9999)) return;
    if (question.length < 20)
      return messageRef.current.showError({
        message: "Please add more details.",
      });
    if (question.length > 1000)
      return messageRef.current.showError({
        message: "Please ask your question in brief",
      });

    e.target.setAttribute("disabled", true);
    setLoading(true);
    const response = await api.askq({
      token: user.token,
      data: question,
      lang: window.lang,
    });
    setLoading(false);
    setTimeout(() => e.target.removeAttribute("disabled"), 10000);
    if (response.error)
      return messageRef.current.showError({
        message: response.error,
      });
    messageRef.current.showSuccess({
      message: "Your question was sent successfully.",
    });
    document.querySelector("textarea").value = "";
    setQuestion("");
  }
}
