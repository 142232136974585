const lang = window.location.pathname
  .toLowerCase()
  .split("/")[1]
 
const renderData = {
  facilities: [
    {
      name: "freeDelivery",
      en: "freeDelivery",
      fa: "دلیوری رایگان",
      img: "/img/bussiness-page/delivery-truck.png",
    },
    {
      name: "carCharger",
      en: "Car charger",
      fa: "شارژ ماشین برقی",
      img: "/img/bussiness-page/charging-location.png",
    },
    {
      name: "paidDelivery",
      en: "paidDelivery",
      fa: "دلیوری$",
      img: "/img/bussiness-page/wallet.png",
    },
    {
      name: "freeParking",
      en: "freeParking",
      fa: "پارکینگ رایگان",
      img: "/img/bussiness-page/parking.png",
    },
    {
      name: "paidParking",
      en: "paidParking",
      fa: "پارکینگ$",
      img: "/img/bussiness-page/wallet.png",
    },
    {
      name: "freeWifi",
      en: "freeWifi",
      fa: "وای فای رایگان",
      img: "/img/bussiness-page/free-wifi.png",
    },
    {
      name: "specialOffer",
      en: "specialOffer",
      fa: "تخفیف ویژه",
      img: "/img/bussiness-page/gift-card.png",
    },
    {
      name: "weeklyFlyer",
      en: "weeklyFlyer",
      fa: "فلایر هفتگی",
      img: "/img/bussiness-page/brochure.png",
    },
    {
      name: "childFriendly",
      en: "childFriendly",
      fa: "فضای کودکان",
      img: "/img/bussiness-page/donation.png",
    },
    {
      name: "servingTable",
      en: "servingTable",
      fa: "میز پذیرایی",
      img: "/img/bussiness-page/coffee-maker.png",
    },
    {
      name: "waitingRoom",
      en: "waitingRoom",
      fa: "سالن انتظار",
      img: "/img/bussiness-page/hotel-bell.png",
    },
    {
      name: "petFriendly",
      en: "petFriendly",
      fa: "فضای حیوانات",
      img: "/img/bussiness-page/pet-friendly.png",
    },
    {
      name: "smokingArea",
      en: "smokingArea",
      fa: "فضای سیگار",
      img: "/img/bussiness-page/smoking-area.png",
    },
    {
      name: "smokingFree",
      en: "smokingFree",
      fa: "  سیگار کشیدن ممنوع",
      img: "/img/bussiness-page/no-smoking.png",
    },
    {
      name: "specialGift",
      en: "specialGift",
      fa: "هدیه ویژه",
      img: "/img/bussiness-page/gift.png",
    },
    {
      name: "resrRoom",
      en: "resrRoom",
      fa: " سرویس بهداشتی",
      img: "/img/bussiness-page/toilets.png",
    },
    {
      name: "atm",
      en: "ATM",
      fa: "دستگاه خود پرداز",
      img: "/img/bussiness-page/atm-machine.png",
    },
  ],
  sex: [
    { name: "female", en: "Female", fa: "زن", img: "" },
    { name: "male", en: "Male", fa: "مرد", img: "" },
  ],
  activityType: [
    { name: "carrier", en: "Carrier", fa: "مسافر", img: "" },    
    { name: "sender", en: "Sender", fa: "به دنبال مسافر", img: "" },    
  ],
  rideType: [
    { name: "carrier", en: "Carrier", fa: "ارائه راید", img: "" },
    { name: "sender", en: "Sender", fa: "درخواست راید", img: "" },    
  ],

  sessionDuration: [
    { name: ".5", en: "Half an hour", fa: "نیم ساعت", img: "" },
    { name: "1", en: "One hour", fa: "یک ساعت", img: "" },
    { name: "2", en: "Two hours", fa: "دو ساعت", img: "" },
    
  ],
  carCompanies :[
    { name: "acura", en: "Acura", fa: "آکورا", img: "" },
    { name: "alfa-romeo", en: "Alfa Romeo", fa: "آلفا رومئو", img: "" },
    { name: "aston-martin", en: "Aston Martin", fa: "استون مارتین", img: "" },
    { name: "audi", en: "Audi", fa: "آئودی", img: "" },
    { name: "bentley", en: "Bentley", fa: "بنتلی", img: "" },
    { name: "bmw", en: "BMW", fa: "بی‌ام‌و", img: "" },
    { name: "bugatti", en: "Bugatti", fa: "بوگاتی", img: "" },
    { name: "buick", en: "Buick", fa: "بیوک", img: "" },
    { name: "cadillac", en: "Cadillac", fa: "کادیلاک", img: "" },
    { name: "chevrolet", en: "Chevrolet", fa: "شورولت", img: "" },
    { name: "chrysler", en: "Chrysler", fa: "کرایسلر", img: "" },
    { name: "citroen", en: "Citroen", fa: "سیتروئن", img: "" },
    { name: "dodge", en: "Dodge", fa: "دوج", img: "" },
    { name: "ferrari", en: "Ferrari", fa: "فراری", img: "" },
    { name: "fiat", en: "Fiat", fa: "فیات", img: "" },
    { name: "ford", en: "Ford", fa: "فورد", img: "" },
    { name: "genesis", en: "Genesis", fa: "جنسیس", img: "" },
    { name: "gmc", en: "GMC", fa: "جی‌ام‌سی", img: "" },
    { name: "honda", en: "Honda", fa: "هوندا", img: "" },
    { name: "hyundai", en: "Hyundai", fa: "هیوندای", img: "" },
    { name: "infiniti", en: "Infiniti", fa: "اینفینیتی", img: "" },
    { name: "jaguar", en: "Jaguar", fa: "جگوار", img: "" },
    { name: "jeep", en: "Jeep", fa: "جیپ", img: "" },
    { name: "kia", en: "Kia", fa: "کیا", img: "" },
    { name: "koenigsegg", en: "Koenigsegg", fa: "کوئنیگزگ", img: "" },
    { name: "lamborghini", en: "Lamborghini", fa: "لامبورگینی", img: "" },
    { name: "land-rover", en: "Land Rover", fa: "لندروور", img: "" },
    { name: "lexus", en: "Lexus", fa: "لکسوس", img: "" },
    { name: "maserati", en: "Maserati", fa: "مازراتی", img: "" },
    { name: "mazda", en: "Mazda", fa: "مزدا", img: "" },
    { name: "mclaren", en: "McLaren", fa: "مک‌لارن", img: "" },
    { name: "mercedes-benz", en: "Mercedes-Benz", fa: "مرسدس بنز", img: "" },
    { name: "mitsubishi", en: "Mitsubishi", fa: "میتسوبیشی", img: "" },
    { name: "nissan", en: "Nissan", fa: "نیسان", img: "" },
    { name: "pagani", en: "Pagani", fa: "پاگانی", img: "" },
    { name: "peugeot", en: "Peugeot", fa: "پژو", img: "" },
    { name: "porsche", en: "Porsche", fa: "پورشه", img: "" },
    { name: "ram", en: "Ram", fa: "رم", img: "" },
    { name: "renault", en: "Renault", fa: "رنو", img: "" },
    { name: "rolls-royce", en: "Rolls-Royce", fa: "رولزرویس", img: "" },
    { name: "subaru", en: "Subaru", fa: "سوبارو", img: "" },
    { name: "tesla", en: "Tesla", fa: "تسلا", img: "" },
    { name: "toyota", en: "Toyota", fa: "تویوتا", img: "" },
    { name: "volkswagen", en: "Volkswagen", fa: "فولکس‌واگن", img: "" },
    { name: "volvo", en: "Volvo", fa: "ولوو", img: "" },
    { name: "others", en: "Others", fa: "موارد دیگر", img: "" },
],
 bodyTypes : [
   { name: "hatchback", en: "Hatchback", fa: "هاچ‌بک", img: "https://cdn-icons-png.flaticon.com/128/13214/13214265.png" },
   { name: "coupe", en: "Coupe", fa: "کوپه", img: "https://cdn-icons-png.flaticon.com/128/15808/15808029.png" },
  { name: "sedan", en: "Sedan", fa: "سدان", img: "https://cdn-icons-png.flaticon.com/128/13214/13214314.png" },
  { name: "convertible", en: "Convertible", fa: "بدون سقف", img: "https://cdn-icons-png.flaticon.com/128/15807/15807959.png" },
  { name: "wagon", en: "Wagon", fa: "واگن", img: "https://cdn-icons-png.flaticon.com/512/5411/5411380.png" },
  { name: "van", en: "Van", fa: "ون", img: "https://cdn-icons-png.flaticon.com/128/5411/5411418.png" },
  { name: "crossover", en: "Crossover", fa: "نیمه شاسی", img: "   https://cdn-icons-png.flaticon.com/512/15807/15807942.png" },
  { name: "suv", en: "SUV", fa: "شاسی بلند", img: "https://cdn-icons-png.flaticon.com/128/5411/5411392.png" },
  { name: "pickup", en: "Pickup", fa: "وانت", img: "https://cdn-icons-png.flaticon.com/128/5411/5411462.png" },
  { name: "sportscar", en: "Sportscar", fa: "خودرو اسپرت", img: "https://cdn-icons-png.flaticon.com/128/9559/9559720.png" },
  { name: "limousine", en: "Limousine", fa: "لیموزین", img: "https://cdn-icons-png.flaticon.com/128/12789/12789011.png" },
  { name: "truck", en: "Truck", fa: "کامیون", img: "https://cdn-icons-png.flaticon.com/128/14154/14154800.png" },
  { name: "bus", en: "Bus", fa: "اتوبوس", img: "https://cdn-icons-png.flaticon.com/128/1992/1992929.png" },
  { name: "others", en: "Others", fa: "موارد دیگر", img: "/img/used/cars/car.png" }
],

  transmission: [
    { name: "auto", en: "ُAuto", fa: "اتوماتیک", img: "" },
    { name: "manuel", en: "Manuel", fa: "غیر اتوماتیک ", img: "" },
  ],
  fuelTypes: [
    { name: "gas", en: "Gas", fa: "بنزین", img: "https://cdn-icons-png.flaticon.com/128/846/846347.png" },
    { name: "diesel", en: "Diesel", fa: "گازوئیل", img: "" },
    { name: "electric", en: "Electric", fa: "الکتریکی", img: "https://cdn-icons-png.flaticon.com/128/11758/11758370.png" },
    { name: "hybrid", en: "Hybrid", fa: "هایبریدی", img: "https://cdn-icons-png.flaticon.com/128/2017/2017821.png" },
    { name: "cng", en: "CNG", fa: "CNG سی‌ان‌جی", img: "" },
    { name: "lpg", en: "LPG", fa: "LPG ال‌پی‌جی", img: "https://cdn-icons-png.flaticon.com/128/8308/8308901.png" },
    { name: "hydrogen", en: "Hydrogen", fa: "هیدروژن", img: "https://cdn-icons-png.flaticon.com/128/6255/6255597.png" }
  ],
  // laundry: [
  //   { name: "inUnit", en: "In-Unit", fa: "داخل واحد", img: "" },
  //   { name: "inBuilding", en: "In-Building", fa: "داخل ساختمان", img: "" },
  //   { name: "no", en: "no", fa: "ندارد", img: "" },
  // ],
  transType: [
    { name: "sale", en: "Sale", fa: "فروش", img: "" },
    { name: "rent", en: "Rent", fa: "اجاره", img: "" },
    { name: "leaseTransfer", en: "Lease Transfer", fa: "لیزترانسفر", img: "" },
    { name: "subLease", en: "Sub Lease", fa: "ساب لیز", img: "" },
    { name: "sublet", en: "Sublet", fa: "سابلت", img: "" },
  ],
  hFacilities: [
    { name: "evStation", en: "Ev station", fa: " شارژر ماشین الکتریکی", img: "bi bi-ev-station" },
    { name: "freeEl", en: "Electricity Included", fa: "برق رایگان", img: "bi bi-building" },
    { name: "freeHeating", en: "Heating Included", fa: "گرمایش رایگان", img: "bi bi-building" },
    { name: "laundryInUnit", en: "In-Unit Laundry", fa: "لاندری داخل واحد", img: "bi bi-building" },
    { name: "laundryCentral", en: "Central Laundry", fa: "لاندری مرکزی", img: "bi bi-building" },
    { name: "elevator", en: "Elevator", fa: "آسانسور", img: "bi bi-building" },
    { name: "aPool", en: "Above-Ground Pool", fa: "استخر روی زمین", img: "bi bi-water" },
    { name: "iPool", en: "In-Ground Pool", fa: "استخر داخل زمین", img: "bi bi-water" },
    { name: "gym", en: "Gym/Fitness", fa: "باشگاه", img: "bi bi-person-lines-fill" },
    { name: "cctv", en: "CCTV", fa: "دوربین های امنیتی", img: "bi bi-camera" },
    { name: "gated", en: "Gated Entrances", fa: "ورودی غیر عمومی", img: "bi bi-shield-lock" },
    { name: "playground", en: "Playground", fa: "محل بازی کودکان", img: "bi bi-balloon" },
    { name: "clubhouse", en: "Clubhouse", fa: "مرکز تجمع", img: "bi bi-house-door" },
    { name: "powerBackup:", en: "24/7 Power Backup", fa: "برق اضطراری 24 ساعته", img: "bi bi-battery-charging" },
    { name: "Internet", en: "Free Wi-Fi", fa: "اینترنت رایگان", img: "bi bi-wifi" },
    { name: "wasteManagement", en: "Waste Management", fa: "مدیریت زباله", img: "bi bi-trash" },
    { name: "Sauna", en: "Sauna/Steam Room", fa: "سونا", img: "bi bi-steam" },
    { name: "conciergeServices", en: "Concierge Services", fa: "نگهبان", img: "bi bi-cone-striped" },
    { name: "petArea", en: "Pet Area", fa: "محل بازی حیونات", img: "bi bi-heart" },
    { name: "SHF", en:"Smart Home Features", fa: "خانه هوشمند", img: "bi bi-smartphone" },
    { name: "roofTerrace", en:"Rooftop Terrace", fa: "تراس پشت بام", img: "bi bi-smartphone" },
  ],
  heating: [
    { name: "oFurnace", en: "Oil Furnace (Forced Air)", fa:  "مشعل نفتی", img: "" },
    { name: "eFurnace", en: "Electrice Furnace (Forced Air)", fa:  "مشعل برقی", img: "" },
    { name: "boiler", en: "Boiler (Radiant Heat)", fa:  "رادیاتور آبی", img: "" },
    { name: "heat-pump", en: "Heat Pump", fa: "گرمایشی مرکزی(Heat Pump)", img: "" },
    { name: "radiant-floor-heating", en: "Radiant Floor Heating", fa:"گرمایش از کف", img: "" },
    { name: "baseboard-heating", en: "Baseboard Heating", fa: "بخاری برقی دیواری(Baseboard)", img: "" },
    { name: "electric-heater", en: "Electric Heater", fa: "بخاری برقی", img: "" },
    { name: "wood-burning-stove", en: "Wood-Burning Stove", fa:"شومینه چوبی", img: "" },
    { name: "fireplace", en: "Fireplace", fa:"شومینه گازی", img: "" },
    { name: "geothermal-heating", en: "Geothermal Heating", fa: "گرمایش ژئوترمال", img: "" },
    { name: "solar-heating", en: "Solar Heating", fa: "گرمایش خورشیدی", img: "" },
  ],
  airCondition: [
    { name: "CAC", en: "Centreal AC", fa: "سرمایش مرکزی", img: "" },
    { name: "ACA", en: "AC Available", fa: "کولر دیواری", img: "" },
    { name: "none", en: "None", fa: "بدون سرمایش", img: "" },
  ],
  proType: [
    { name: "lot", en: "Lot", fa: "زمین", img: "" },
    { name: "studio", en: "Loft / Studio", fa: "استودیو", img: "" },
    { name: "apt", en: "Apartment", fa: "آپارتمان", img: "" },
    { name: "newConstruction", en: "New construction", fa: "پیش فروش", img: "" },
    { name: "bungalow", en: "Bungalow", fa: "بانگلو", img: "" },
    { name: "split-level", en: "Split-level", fa: "Split-level", img: "" },
    { name: "detached", en: "Detached house", fa: "Detached house", img: "" },
    { name: "semi-detached", en: "Semi-detached house", fa: "(Semi-detached) خانه ", img: "" },
    { name: "attached", en: "Attached", fa: "(Attached)", img: "" },
    { name: "townHouse", en: "Town House", fa: "(Town House) خانه ", img: "" },
  ],
  parking: [
    { name: "garage", en: "Garage", fa: "داخل ساختمان", img: "" },
    { name: "driveWay", en: "Drive way", fa: " Drive way داخل ", img: "" },
    { name: "stParking", en: "Street Parking", fa: "جای پارک در خیابان", img: "" },
    { name: "offStParking", en: "Off-street parking", fa: "پارکینگ شهرداری", img: "" },
    { name: "parkingAv", en: "Parking available", fa: "دارای پارکینگ", img: "" },
    { name: "noParking", en: "No Parking", fa: "بدون پارکینگ", img: "" },
  ],
  usedStatus: [
    { name: "active", en: "Active", fa: "در دسترس", img: "" },
    { name: "pending", en: "Pending", fa: "در انتظار", img: "" },
    { name: "sold", en: "Sold", fa: "فروش رفته", img: "" },
    { name: "expired", en: "Expired", fa: "پایان اعتبار", img: "" },
  ],
  education: [
    { name: "noDeg", en: "No Degree", fa: " زیر دیپلم", img: "" },
    { name: "ged", en: "High school diploma or GED", fa: "دیپلم دبیرستان", img: "" },
    // { name: "asp", en: "ASP", fa: "ASP", img: "" },
    // { name: "aep", en: "AEP", fa: "AEP", img: "" },
    // { name: "dep", en: "DEP", fa: "DEP", img: "" },
    // { name: "aec", en: "AEC", fa: "AEC", img: "" },
    // { name: "AssociateDegree", en: "Associate's degree", fa: "Associate's degree ", img: "" },
    { name: "College diploma", en: "College diploma", fa: " فوق دیپلم/کالج", img: "" },
    { name: "bachelor", en: "Bachelor's", fa: "کارشناسی", img: "" },
    // { name: "DESS", en: "DESS", fa: "DESS", img: "" },
    { name: "master's", en: "Master's degree", fa: "کارشناسی ارشد", img: "" },
    { name: "Doctoral", en: "Doctoral degree", fa: "دکترا", img: "" },
    // { name: "postDoctoral", en: "Post-Doctoral", fa: "فوق دکتری", img: "" },
    // { name: "collegeCertificate", en: "College certificate", fa: "فوق دیپلم/کالج", img: "" },
    // { name: "dec", en: "DEC", fa: "دانشجوی ارشد", img: "" },
    // { name: "postSecondaryEdu", en: "Post-secondary education", fa: "دانشجوی ارشد", img: "" },
    // { name: "undergraduate", en: "Undergraduate level certificate/diploma", fa: "مرد", img: "" },
     { name: "gradCert", en: "Graduate certificate", fa: "دوره های تخصصی", img: "" },
  ],
  condition: [
    { name: "new", en: "New", fa: "نو", img: "" },
    { name: "usedLN", en: "Used Like New", fa: "شبیه نو", img: "" },
    { name: "usedGood", en: "Used Good", fa: "خوب", img: "" },
    { name: "usedFair", en: "Used Fair", fa: "بدنیست", img: "" },
    { name: "broken", en: "Broken", fa: "مشکل دار", img: "" },
    { name: "other", en: "Other", fa: "سایر", img: "" },
  ],
  delivery: [
    { name: "doorPick", en: "Door Pickup", fa: "محل فروشنده", img: "" },
    { name: "doorDrop", en: "Door Dropoff", fa: "محل خریدار", img: "" },
    { name: "publicMeet", en: "Public MeetUp", fa: "جای عمومی", img: "" },
    { name: "other", en: "Other", fa: "سایر", img: "" },
  ],
  paymentMethods: [
    {
      name: "cash",
      en: "cash",
      fa: "نقدی",
      img: "/img/img/listing/details/amenities/ame-1.png",
    },
    {
      name: "creditCard",
      en: "creditCard",
      fa: "کردیت کارت",
      img: "/img/img/listing/details/amenities/ame-1.png",
    },
    {
      name: "debitCard",
      en: "debitCard",
      fa: "دبیت کارت",
      img: "/img/img/listing/details/amenities/ame-1.png",
    },
    {
      name: "payPal",
      en: "payPal",
      fa: "پی پل",
      img: "/img/img/listing/details/amenities/ame-1.png",
    },
    {
      name: "eTransfer",
      en: "eTransfer",
      fa: "ای‌ترنسفر",
      img: "/img/img/listing/details/amenities/ame-1.png",
    },
    // {name: "metaCoin",   en: "metaCoin",   fa: "متاکوین",    img: "/img/img/listing/details/amenities/ame-1.png" },
  ],
  workShifts: [
    {
      name: "mondayToFriday",
      en: "Monday to Friday",
      fa: "دوشنبه تا جمعه",
      fr: "/img/img/listing/details/amenities/ame-1.png",
    },
    {
      name: "weekends",
      en: "Weekends",
      fa: "آخر هفته ",
      fr: "/img/img/listing/details/amenities/ame-1.png",
    },
    {
      name: "8hourShift",
      en: "8-hour shift",
      fa: " ۸ ساعت در روز",
      fr: "/img/img/listing/details/amenities/ame-1.png",
    },
    {
      name: "morningShift",
      en: "Morning shift",
      fa: " صبح‌ها",
      fr: "/img/img/listing/details/amenities/ame-1.png",
    },
    { name: "eveningShift", en: "Evening shift", fa: " عصرها", fr: "" },
    { name: "dayShift", en: "Day shift", fa: "شیفت روز", fr: "" },
    { name: "nightshift", en: "Night shift", fa: "شیفت شب", fr: "" },
    { name: "overtime", en: "Overtime", fa: "اضافه‌کاری", fr: "" },
    {
      name: "saturdayToThursday",
      en: "Saturday to Thursday",
      fa: "شنبه تا پنجشنبه",
      fr: "",
    },
    {
      name: "saturdayToWednesday",
      en: "Saturday to Wednesday",
      fa: "شنبه تا چهارشنبه",
      fr: "",
    },
  ],
  serviceTypes: [
    {
      name: "inPerson",
      en: "In-Person",
      fa: "حضوری",
      img: "/img/icons/face-to-face.png",
    },
    {
      name: "online",
      en: "Online",
      fa: "آنلاین",
      img: "/img/icons/profile.png",
    },
    {
      name: "onPhone",
      en: "On Call",
      fa: "تلفنی",
      img: "/img/icons/profile.png",
    },
    {
      name: "email",
      en: "By Email",
      fa: "ایمیلی",
      img: "/img/icons/profile.png",
    },
  ],
  collabTypes: [
    {
      name: "acceptAds",
      en: "Accept Ads",
      fa: "پذیرش آگهی از صاحبان کسب و کارها ",
      img: "/img/bussiness-page/business.png",
    },
    {
      name: "pageExchange",
      en: "Page Exchange",
      fa: "تبادل با دیگر صفحه‌ها",
      img: "/img/bussiness-page/support.png",
    },
  ],
  tuitionTypes: [
    { name: "graduateInter", en: "Graduate International", fa: "", img: "" },
    { name: "graduateCitizen", en: "Graduate Citizen", fa: "", img: "" },
    {
      name: "underGraduateCitizen",
      en: "Under Graduate Citizen",
      fa: "",
      img: "",
    },
    {
      name: "underGraduateInter",
      en: "Under Graduate International",
      fa: "",
      img: "",
    },
  ],
  plansName: [
    { name: "plansX4", en: "Plans X4", fa: "(ماهیانه)تبلیغات ویژه" },
    { name: "plansS1", en: "Plans S1", fa: "برگزیدگان(ماهیانه)" },
    { name: "plansX5", en: "Plans X5", fa: "(ماهیانه)تبلیغات ممتاز" },
    { name: "plansCats", en: "Plans Cats", fa: "(ماهیانه)آگهی ویژه طبقه‌بندی" },
    {
      name: "targetAddress",
      en:   "Target Address",
      fa:   "خدمات به سایر کشورها",
    },
    { name: "facilities", en: "Facilities", fa: "امکانات ویژه(یکبار پرداخت)" },
    {
      name: "verifiedDoc",
      en: "Verified Business",
      fa: " نشان تأیید (سالیانه)",
    },
    { name: "trustedDoc", en: "Trusted Business", fa: "(سالیانه)نشان اعتماد " },
    { name: "video", en: "youtube video", fa: "لینک ویدئو(یکبار پرداخت)" },
    { name: "eventTicket", en: "Event ticket", fa: "بلیط کنسرت" },
    { name: "TutorSession", en: "Book Session", fa: "رزرو کلاس" },
  ],

  plansDef: {
    plansLg: [
      {
        title: "آکادمی متامارس",
        url: `/${lang}/demy`,
        user: "633af9395b72c63167d2e59a",
        img: "/img/Metademy/Metademy-mobile-green-red2.jpg",
      },
      {
        title: "استاد شو",
        url: `/${lang}`,
        user: "633af9395b72c63167d2e59a",
        img: "/img/plans/x4-2.jpg",
      },
      {
        title: "همین حالا ثبت نام کن!",
        url: `/${lang}/media`,
        user: "633af9395b72c63167d2e59a",
        img: "/img/plans/x4-4.jpg",
      },
      {
        title: "همین حالا ثبت نام کن!",
        url: `/${lang}/business`,
        user: "633af9395b72c63167d2e59a",
        img: "/img/plans/x4-1.jpg",
      },
      {
        title: "بیشتر دیده شوید",
        url: `/${lang}/store/plans`,
        user: "633af9395b72c63167d2e59a",
        img: "/img/plans/x4-3.jpg",
      },
      // {title :  "", url :  "/fa/business",user :  "633af9395b72c63167d2e59a" ,img: "/img/plans/x4-2.jpg"},
      // {title :  "", url :  "/fa/jobs",user :  "633af9395b72c63167d2e59a" ,img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-hwWK3uBeCgX4jV400IsN5VA4xCU92XeuJA&usqp=CAU"},
      // {title :  "", url :  "/fa/media",user :  "633af9395b72c63167d2e59a" ,img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKklcIE8MY0NFda7N23MvaJmW92ASy79I5YBNm_zLDvBlk3386iIkDcplCPN0lvHNElBY&usqp=CAU"},
      // {title :  "", url :  "/fa/blog/filters",user :  "633af9395b72c63167d2e59a" ,img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjPY0DtdMIJDNAjwpMfp1Ao3qhR6DQFEB78A&usqp=CAU"},
      // {title :  "", url :  "/fa/business",user :  "633af9395b72c63167d2e59a" ,img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREkEJdVqAyJVZDDnW8CsSFj8tr9ItKlaY5tJvdu_WIm8C96DiRtCburwsll-4WnzPbCLA&usqp=CAU"},
      // {title :  "", url :  "/fa/media",user :  "633af9395b72c63167d2e59a" ,img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTc9Z1b4j-QFtRhukGsi27-T9CoXh-44dLyXg&usqp=CAU"},
    ],
    plansMd: [
      {
        title: " ",
        url: `/${lang}/demy/tutor/home`,
        user: "633af9395b72c63167d2e59a",
        img: "/img/Metademy/Metademy-green-red-5.jpg",
      },
      {
        title: " ",
        description: "",
        url: `/${lang}/store/plans`,
        user: "633af9395b72c63167d2e59a",
        img: "/img/plans/x5-1.jpg",
      },
      {
        title: " ",
        url: `/${lang}/business`,
        user: "633af9395b72c63167d2e59a",
        img: "/img/plans/x5-2.jpg",
      },
      {
        title: " ",
        url: `/${lang}/media`,
        user: "633af9395b72c63167d2e59a",
        img: "/img/plans/x5-3.jpg",
      },
      {
        title: " ",
        url: `/${lang}/business`,
        user: "633af9395b72c63167d2e59a",
        img: "/img/plans/x5-4.jpg",
      },
      {
        title: " ",
        url: `/${lang}/business/filters`,
        user: "633af9395b72c63167d2e59a",
        img: "/img/plans/p1.jpg",
      },
    ],
    plansS1: [
      {
        title: "متامارس بیزینس ها",
        url: `/${lang}/business`,
        businessCard: "/img/plans/p5.jpg",
        rate: 5,
      },
      {
        title: "متامارس بیزینس ها)",
        url: `/${lang}/business`,
        businessCard: "/img/plans/p4.jpg",
        rate: 5,
      },
      {
        title: "متامارس شبکه‌اجتماعی (متامدیا)",
        url: `/${lang}/media`,
        businessCard: "/img/plans/p2.jpg",
        rate: 5,
      },
      {
        title: "متامارس بیزینس ها",
        url: `/${lang}/business`,
        businessCard: "/img/plans/p1.jpg",
        rate: 5,
      },
      {
        title: "متامارس بیزینس ها",
        url: `/${lang}/business`,
        businessCard: "/img/plans/p3.jpg",
        rate: 5,
      },
      {
        title: "متامارس شبکه‌اجتماعی (متامدیا)",
        url: `/${lang}/media`,
        businessCard: "/img/plans/p2.jpg",
        rate: 5,
      },
    ],
  },
  socialMedias: [
    {
      name: "instagram",
      en: "Instagram",
      fa: "اینستاگرام",
      img: "/img/socialMedia/icons8-instagram-500.png",
    },
    {
      name: "youtube",
      en: "YouTube",
      fa: "یوتوب",
      img: "/img/socialMedia/icons8-youtube-500.png",
    },
    {
      name: "telegram",
      en: "Telegram",
      fa: "تلگرام",
      img: "/img/socialMedia/icons8-telegram-app-500.png",
    },
    {
      name: "facebook",
      en: "Facebook",
      fa: "فیسبوک",
      img: "/img/socialMedia/icons8-facebook-500.png",
    },
    {
      name: "whatsapp",
      en: "WhatsApp",
      fa: "واتساپ",
      img: "/img/socialMedia/icons8-whatsapp-500.png",
    },
    {
      name: "messenger",
      en: "Messenger",
      fa: "فیسبوک مسینجر",
      img: "/img/socialMedia/icons8-facebook-messenger-500.png",
    },
    {
      name: "tikTok",
      en: "TikTok",
      fa: "تیک تاک",
      img: "/img/socialMedia/icons8-tiktok-500.png",
    },
    {
      name: "pinterest",
      en: "Pinterest",
      fa: "پینترست",
      img: "/img/socialMedia/icons8-pinterest-500.png",
    },
    {
      name: "twitter",
      en: "Twitter",
      fa: "تویتر",
      img: "/img/socialMedia/icons8-twitter-250.png",
    },
    {
      name: "linkedin",
      en: "LinkedIn",
      fa: "لینکدین",
      img: "/img/socialMedia/icons8-linkedin-500.png",
    },
    {
      name: "github",
      en: "Github",
      fa: "گیت هاب",
      img: "/img/socialMedia/icons8-github-500.png",
    },
    {
      name: "reddit",
      en: "Reddit",
      fa: "ردیت",
      img: "/img/socialMedia/icons8-reddit-500.png",
    },
    {
      name: "snapChat",
      en: "SnapChat",
      fa: "اسنپ چت",
      img: "/img/socialMedia/icons8-snapchat-500.png",
    },
  ],
  weekDays: [
    { name: "Monday", en: "Monday", fa: "دوشنبه", fr: "" },
    { name: "Tuesday", en: "Tuesday", fa: "سه‌شنبه", fr: "" },
    { name: "Wednesday", en: "Wednesday", fa: "چهارشنبه", fr: "" },
    { name: "Thursday", en: "Thursday", fa: "پنج‌شنبه", fr: "" },
    { name: "Friday", en: "Friday", fa: "جمعه", fr: "" },
    { name: "Saturday", en: "Saturday", fa: "شنبه", fr: "" },
    { name: "Sunday", en: "Sunday", fa: "یکشنبه", fr: "" },
  ],
  validation: [
    { name: "trusted", en: "Trusted businesses", fa: "قابل اعتماد", fr: "" },
    { name: "verified", en: "Verified businesses", fa: "مورد تائید", fr: "" },
    {
      name: "claimed",
      en: "Claimed businesses",
      fa: "کسب و کار ثبت شده توسط مالک",
      fr: "",
    },
    {
      name: "draft",
      en: "Un claimed businesses",
      fa: "کسب و کارهای فاقد مالکیت",
      fr: "",
    },
    {
      name: "localAddress",
      en: "Physical Address",
      fa: "دارای آدرس فیزیکی در استان من",
      fr: "",
    },
    {
      name: "displayAddress",
      en: "Display Address",
      fa: "کسب و کارهای دارای آدرس",
      fr: "",
    },
  ],
  status: [
    { name: "available", en: "Available", fa: "موجود", fr: "" },
    { name: "trialSession ", en: "Trial Session ", fa: "رایگان", fr: "" },
    { name: "test", en: "Test Session", fa: "آزمایشی ", fr: "" },
    { name: "booked", en: "Booked", fa: " رزرو شده ", fr: "" },
    { name: "selected", en: "Selected", fa: "انتخاب شده", fr: "" },
    { name: "canceled", en: "Canceled", fa: "کنسل شد ", fr: "" },
    { name: "refunded", en: "Refunded", fa: "برگشت شهریه", fr: "" },
    { name: "confirmed", en: "confirmed", fa: "تائید شد ", fr: "" },
    { name: "completed", en: "Completed", fa: "برگزار شد", fr: "" },
    { name: "notRefunded", en: "Not Refunded", fa: "عدم برگشت شهریه", fr: "" },
    { name: "noShowedT", en: "No Showed Tutor", fa: "عدم حضوراستاد", fr: "" },
    {
      name: "noShowedS",
      en: "No Showed Student",
      fa: "عدم حضور دانشجو",
      fr: "",
    },
  ],
  ageGroups: [
    { name: "preSchool", en: "Under 6", fa: " زیر ۶ سال", fr: "" },
    { name: "Kids", en: "6-11 years", fa: " ۶ تا ۱۱ سال", fr: "" },
    { name: "teens", en: "12-17 years", fa: " ۱۲ تا ۱۷ سال", fr: "" },
    { name: "adults", en: "18+ years", fa: "بالای ۱۸ سال", fr: "" },
  ],
  months: [
    { name: "january", en: "January", fa: "ژانویه", fr: "janvier", number: 1 },
    { name: "february", en: "February", fa: "فوریه", fr: "février", number: 2 },
    { name: "march", en: "March", fa: "مارچ", fr: "mars", number: 3 },
    { name: "april", en: "April", fa: "آپریل", fr: "avril", number: 4 },
    { name: "may", en: "May", fa: "می", fr: "mai", number: 5 },
    { name: "june", en: "June", fa: "جون", fr: "juin", number: 6 },
    { name: "july", en: "July", fa: "جولای", fr: "juillet", number: 7 },
    { name: "august", en: "August", fa: "آگوست", fr: "aout ", number: 8 },
    {
      name: "september",
      en: "September",
      fa: "سپتامبر",
      fr: "septembre ",
      number: 9,
    },
    { name: "october", en: "October", fa: "اکتبر", fr: "octobre ", number: 10 },
    {
      name: "november",
      en: "November",
      fa: "نوامبر",
      fr: "novembre",
      number: 11,
    },
    {
      name: "december",
      en: "December",
      fa: "دسامبر",
      fr: "décembre",
      number: 12,
    },
  ],
  emType: [
    { name: "permanent", en: "Permanent", fa: "دایمی", fr: "Permanent" },
    { name: "fullTime", en: "Full Time", fa: "تمام وقت", fr: "Temps plein" },
    { name: "partTime", en: "Part Time", fa: "پاره‌وقت", fr: "Temps partiel" },
    { name: "online", en: "Online", fa: "دورکاری", fr: "En ligne" },
    { name: "temporary", en: "Temporary", fa: "موقت", fr: "Temporaire" },
    { name: "contract", en: "Contract", fa: "قراردادی", fr: "Contracter" },
    { name: "project", en: "Project", fa: "پروژه‌ای", fr: "Projet" },
    { name: "internship", en: "Internship", fa: "کارآموزی", fr: "Stage" },
    { name: "voluntarily", en: "Voluntarily ", fa: "داوطلبانه", fr: "Stage" },
    { name: "other", en: "Other", fa: "سایر موارد", fr: "autre " },
  ],
  jobExperience: [
    { name: "noExp", en: "No experience", fa: "بدون سابقه", fr: "Temps plein" },
    { name: "1-3", en: "1 - 3 Years", fa: "یک تا سه سال", fr: "Temps partiel" },
    { name: "3-7", en: "3 - 5 Years", fa: " سه تا هفت سال", fr: "Contracter" },
    { name: "7-12", en: "7 - 12 Years", fa: "هفت تا دوازده سال", fr: "Stage" },
    {
      name: "more12",
      en: "More than 12",
      fa: "بالای دوازده سال",
      fr: "Temporaire",
    },
  ],
  jobPeriod: [
    { name: "hourly", en: "Per hour", fa: "ساعتی", fr: "Temps plein" },
    { name: "monthly", en: "Per month", fa: "ماهانه", fr: "Temps partiel" },
    { name: "yearly", en: "Per year", fa: "سالانه", fr: "Contracter" },
    { name: "project", en: "Project base", fa: "پروژه‌ای", fr: "Stage" },
  ],
  process: [
    { name: "urgent", en: "Urgent", fa: "فوری", fr: "Urgente" },
    {
      name: "month",
      en: "Less than a month",
      fa: "کمتر از یکماه",
      fr: "Moins d'un mois",
    },
    { name: "1-3months", en: "1-3 Months", fa: "یک تا سه ماه", fr: "Un mois" },
    {
      name: "more3months",
      en: "More than 3 months",
      fa: "بیش از سه ماه",
      fr: "Plus de 3 mois",
    },
  ],
  langsLevel: [
    { name: "beg", en: "Beginner", fa: "مبتدی", fr: "" },
    { name: "inter", en: "Intermediate", fa: " متوسط", fr: "" },
    { name: "fluent", en: "Fluent", fa: "پیشرفته", fr: "" },
    { name: "native", en: "Native", fa: "زبان مادری", fr: "" },
  ],

  languages: [
    { name: "fa", en: "Persian", fr: "persan", fa: "فارسی" },
    { name: "en", en: "English", fr: "anglais", fa: "انگلیسی" },
    { name: "fr", en: "French", fr: "français", fa: "فرانسوی" },
    {
      name: "es",
      en: "Spanish Castilian",
      fr: "espagnol-castillan",
      fa: "اسپانیایی",
    },
    { name: "it", en: "Italian", fr: "italien", fa: "ایتالیایی" },
    { name: "da", en: "Danish", fr: "danois", fa: "دانمارکی" },
    { name: "de", en: "German", fr: "allemand", fa: "آلمانی" },
    { name: "ku", en: "Kurdish", fr: "kurde", fa: "کردی" },
    { name: "aa", en: "Afar", fr: "afar", fa: "" },
    { name: "ab", en: "Abkhazian", fr: "abkhaze", fa: "" },
    { name: "ae", en: "Avestan", fr: "avestique", fa: "" },
    { name: "af", en: "Afrikaans", fr: "afrikaans", fa: "آفریقایی" },
    { name: "ak", en: "Akan", fr: "akan", fa: "" },
    { name: "am", en: "Amharic", fr: "amharique", fa: "" },
    { name: "an", en: "Aragonese", fr: "aragonais", fa: "" },
    { name: "ar", en: "Arabic", fr: "arabe", fa: "عربی" },
    { name: "as", en: "Assamese", fr: "assamais", fa: "" },
    { name: "av", en: "Avaric", fr: "avar", fa: "" },
    { name: "ay", en: "Aymara", fr: "aymara", fa: "" },
    { name: "az", en: "Azerbaijani", fr: "azéri", fa: "آذربایجانی" },
    { name: "ba", en: "Bashkir", fr: "bachkir", fa: "" },
    { name: "be", en: "Belarusian", fr: "biélorusse", fa: "بلاروسی" },
    { name: "bg", en: "Bulgarian", fr: "bulgare", fa: "بلغاری" },
    { name: "bh", en: "Bihari-languages", fr: "langues-biharis", fa: "" },
    { name: "bi", en: "Bislama", fr: "bichlamar", fa: "" },
    { name: "bm", en: "Bambara", fr: "bambara", fa: "" },
    { name: "bn", en: "Bengali", fr: "bengali", fa: "بنگالی" },
    { name: "bo", en: "Tibetan", fr: "tibétain", fa: "" },
    { name: "br", en: "Breton", fr: "breton", fa: "" },
    { name: "bs", en: "Bosnian", fr: "bosniaque", fa: "" },
    { name: "ca", en: "Catalan Valencian", fr: "catalan,valencien", fa: "" },
    { name: "ce", en: "Chechen", fr: "tchétchène", fa: "" },
    { name: "ch", en: "Chamorro", fr: "chamorro", fa: "" },
    { name: "co", en: "Corsican", fr: "corse", fa: "" },
    { name: "cr", en: "Cree", fr: "cree", fa: "" },
    { name: "cs", en: "Czech", fr: "tchèque", fa: "چکی" },
    { name: "cv", en: "Chuvash", fr: "tchouvache", fa: "" },
    { name: "cy", en: "Welsh", fr: "gallois", fa: "" },
    { name: "dv", en: "Divehi", fr: "maldivien", fa: "" },
    { name: "dz", en: "Dzongkha", fr: "dzongkha", fa: "" },
    { name: "ee", en: "Ewe", fr: "éwé", fa: "" },
    { name: "el", en: "Greek modern", fr: "grec-moderne", fa: "یونانی" },
    { name: "eo", en: "Esperanto", fr: "espéranto", fa: "" },
    { name: "et", en: "Estonian", fr: "estonien", fa: "" },
    { name: "eu", en: "Basque", fr: "basque", fa: "" },
    { name: "ff", en: "Fulah", fr: "peul", fa: "" },
    { name: "fi", en: "Finnish", fr: "finnois", fa: "فنلاندی" },
    { name: "fj", en: "Fijian", fr: "fidjien", fa: "" },
    { name: "fo", en: "Faroese", fr: "féroïen", fa: "" },
    { name: "fy", en: "Western Frisian", fr: "frison-occidental", fa: "" },
    { name: "ga", en: "Irish", fr: "irlandais", fa: "" },
    {
      name: "gd",
      en: "Gaelic Scottish-Gaelic",
      fr: "gaélique-gaélique-écossais",
      fa: "",
    },
    { name: "gl", en: "Galician", fr: "galicien", fa: "" },
    { name: "gn", en: "Guarani", fr: "guarani", fa: "" },
    { name: "gu", en: "Gujarati", fr: "goudjrati", fa: "" },
    { name: "gv", en: "Manx", fr: "manx-mannois", fa: "" },
    { name: "ha", en: "Hausa", fr: "haoussa", fa: "" },
    { name: "he", en: "Hebrew", fr: "hébreu", fa: "" },
    { name: "hi", en: "Hindi", fr: "hindi", fa: "هندی" },
    { name: "ho", en: "Hiri Motu", fr: "hiri-motu", fa: "" },
    { name: "hr", en: "Croatian", fr: "croate", fa: "کرواتی" },
    {
      name: "ht",
      en: "Haitian Haitian-Creole",
      fr: "haïtien-créole-haïtien",
      fa: "",
    },
    { name: "hu", en: "Hungarian", fr: "hongrois", fa: "مجاری" },
    { name: "hy", en: "Armenian", fr: "arménien", fa: "ارمنی" },
    { name: "hz", en: "Herero", fr: "herero", fa: "" },
    { name: "ia", en: "Interlingua", fr: "interlingua", fa: "" },
    { name: "id", en: "Indonesian", fr: "indonésien", fa: "اندونزیایی" },
    { name: "ie", en: "Interlingue-Occidental", fr: "interlingue", fa: "" },
    { name: "ig", en: "Igbo", fr: "igbo", fa: "" },
    { name: "ii", en: "Sichuan-Yi-Nuosu", fr: "yi-de-Sichuan", fa: "" },
    { name: "ik", en: "Inupiaq", fr: "inupiaq", fa: "" },
    { name: "io", en: "Ido", fr: "ido", fa: "" },
    { name: "is", en: "Icelandic", fr: "islandais", fa: "ایسلندی" },
    { name: "iu", en: "Inuktitut", fr: "inuktitut", fa: "" },
    { name: "ja", en: "Japanese", fr: "japonais", fa: "ژاپنی" },
    { name: "jv", en: "Javanese", fr: "javanais", fa: "" },
    { name: "ka", en: "Georgian", fr: "géorgien", fa: "" },
    { name: "kg", en: "Kongo", fr: "kongo", fa: "" },
    { name: "ki", en: "Kikuyu" - "Gikuyu", fr: "kikuyu", fa: "" },
    { name: "kj", en: "KuanyamaKwanyama", fr: "kuanyama-kwanyama", fa: "" },
    { name: "kk", en: "Kazakh", fr: "kazakh", fa: "قزاقستانی" },
    { name: "kl", en: "Kalaallisut-Greenlandic", fr: "groenlandais", fa: "" },
    { name: "km", en: "CentralKhmer", fr: "khmercentral", fa: "" },
    { name: "kn", en: "Kannada", fr: "kannada", fa: "" },
    { name: "ko", en: "Korean", fr: "coréen", fa: "کره ای" },
    { name: "kr", en: "Kanuri", fr: "kanouri", fa: "" },
    { name: "ks", en: "Kashmiri", fr: "kashmiri", fa: "" },
    { name: "kv", en: "Komi", fr: "kom", fa: "" },
    { name: "kw", en: "Cornish", fr: "cornique", fa: "" },
    { name: "ky", en: "Kirghiz-Kyrgyz", fr: "kirghiz", fa: "" },
    { name: "la", en: "Latin", fr: "latin", fa: "" },
    {
      name: "lb",
      en: "Luxembourgish-Letzeburgesch",
      fr: "luxembourgeois",
      fa: "",
    },
    { name: "lg", en: "Ganda", fr: "ganda", fa: "" },
    {
      name: "li",
      en: "Limburgan-Limburger-Limburgish",
      fr: "limbourgeois",
      fa: "",
    },
    { name: "ln", en: "Lingala", fr: "lingala", fa: "" },
    { name: "lo", en: "Lao", fr: "lao", fa: "" },
    { name: "lt", en: "Lithuanian", fr: "lituanien", fa: "" },
    { name: "lu", en: "Luba-Katanga", fr: "luba-katanga", fa: "" },
    { name: "lv", en: "Latvian", fr: "letton", fa: "" },
    { name: "mg", en: "Malagasy", fr: "malgache", fa: "" },
    { name: "mh", en: "Marshallese", fr: "marshall", fa: "" },
    { name: "mi", en: "Maori", fr: "maori", fa: "" },
    { name: "mk", en: "Macedonian", fr: "macédonien", fa: "" },
    { name: "ml", en: "Malayalam", fr: "malayalam", fa: "" },
    { name: "mn", en: "Mongolian", fr: "mongol", fa: "" },
    { name: "mr", en: "Marathi", fr: "marathe", fa: "" },
    { name: "ms", en: "Malay", fr: "malais", fa: "" },
    { name: "mt", en: "Maltese", fr: "maltais", fa: "" },
    { name: "my", en: "Burmese", fr: "birman", fa: "" },
    { name: "na", en: "Nauru", fr: "nauruan", fa: "" },
    { name: "nb", en: "NorwegianBokmål", fr: "norvégienbokmål", fa: "" },
    { name: "nd", en: "Ndebele-NorthNdebele", fr: "ndébéléduNord", fa: "" },
    { name: "ne", en: "Nepali", fr: "népalais", fa: "" },
    { name: "ng", en: "Ndonga", fr: "ndonga", fa: "" },
    { name: "nl", en: "Dutch-Flemish", fr: "néerlandais-flamand", fa: "هلندی" },
    { name: "nn", en: "NorwegianNynorsk", fr: "norvégiennynorsk", fa: "" },
    { name: "no", en: "Norwegian", fr: "norvégien", fa: "نروژی" },
    { name: "nr", en: "Ndebele-SouthNdebele", fr: "ndébéléduSud", fa: "" },
    { name: "nv", en: "Navajo", fr: "navaho", fa: "" },
    {
      name: "ny",
      en: "Chichewa-Chewa-Nyanja",
      fr: "chichewa-chewa-nyanja",
      fa: "",
    },
    { name: "oc", en: "Occitan-post1500", fr: "occitan-après1500", fa: "" },
    { name: "oj", en: "Ojibwa", fr: "ojibwa", fa: "" },
    { name: "om", en: "Oromo", fr: "galla", fa: "" },
    { name: "or", en: "Oriya", fr: "oriya", fa: "" },
    { name: "os", en: "Ossetian", fr: "ossète", fa: "" },
    { name: "pa", en: "Panjabi-Punjabi", fr: "pendjabi", fa: "پنجابی" },
    { name: "pi", en: "Pali", fr: "pali", fa: "" },
    { name: "pl", en: "Polish", fr: "polonais", fa: "لهستانی" },
    { name: "ps", en: "Pushto", fr: "pachto", fa: "پشتو" },
    { name: "pt", en: "Portuguese", fr: "portugais", fa: "پرتغالی" },
    { name: "qu", en: "Quechua", fr: "quechua", fa: "" },
    { name: "rm", en: "Romansh", fr: "romanche", fa: "" },
    { name: "rn", en: "Rundi", fr: "rundi", fa: "" },
    { name: "ro", en: "Romanian-Moldovan", fr: "roumain", fa: "رومانیایی" },
    { name: "ru", en: "Russian", fr: "russe", fa: "روسی" },
    { name: "rw", en: "Kinyarwanda", fr: "rwanda", fa: "" },
    { name: "sa", en: "Sanskrit", fr: "sanskrit", fa: "" },
    { name: "sc", en: "Sardinian", fr: "sarde", fa: "" },
    { name: "sd", en: "Sindhi", fr: "sindhi", fa: "" },
    { name: "se", en: "NorthernSami", fr: "samiduNord", fa: "" },
    { name: "sg", en: "Sango", fr: "sango", fa: "" },
    { name: "si", en: "Sinhala", fr: "singhalais", fa: "" },
    { name: "sk", en: "Slovak", fr: "slovaque", fa: "" },
    { name: "sl", en: "Slovenian", fr: "slovène", fa: "" },
    { name: "sm", en: "Samoan", fr: "samoan", fa: "" },
    { name: "sn", en: "Shona", fr: "shona", fa: "" },
    { name: "so", en: "Somali", fr: "somali", fa: "سومالیایی" },
    { name: "sq", en: "Albanian", fr: "albanais", fa: "آلبانیایی" },
    { name: "sr", en: "Serbian", fr: "serbe", fa: "صربستانی" },
    { name: "ss", en: "Swati", fr: "swati", fa: "" },
    { name: "st", en: "Sotho-Southern", fr: "sothoduSud", fa: "" },
    { name: "su", en: "Sundanese", fr: "soundanais", fa: "" },
    { name: "sv", en: "Swedish", fr: "suédois", fa: "سوئدی" },
    { name: "sw", en: "Swahili", fr: "swahili", fa: "" },
    { name: "ta", en: "Tamil", fr: "tamoul", fa: "" },
    { name: "te", en: "Telugu", fr: "télougou", fa: "" },
    { name: "tg", en: "Tajik", fr: "tadjik", fa: "تاجیکی" },
    { name: "th", en: "Thai", fr: "thaï", fa: "تایلندی" },
    { name: "ti", en: "Tigrinya", fr: "tigrigna", fa: "" },
    { name: "tk", en: "Turkmen", fr: "turkmène", fa: "ترکمنی" },
    { name: "tl", en: "Tagalog", fr: "tagalog", fa: "" },
    { name: "tn", en: "Tswana", fr: "tswana", fa: "" },
    { name: "tr", en: "Turkish", fr: "turc", fa: "ترکی" },
    { name: "ts", en: "Tsonga", fr: "tsonga", fa: "" },
    { name: "tt", en: "Tatar", fr: "tatar", fa: "" },
    { name: "tw", en: "Twi", fr: "twi", fa: "" },
    { name: "ty", en: "Tahitian", fr: "tahitien", fa: "" },
    { name: "ug", en: "Uighur", fr: "ouïgour", fa: "" },
    { name: "uk", en: "Ukrainian", fr: "ukrainien", fa: "اوکراینی" },
    { name: "ur", en: "Urdu", fr: "ourdou", fa: "اردو" },
    { name: "uz", en: "Uzbek", fr: "ouszbek", fa: "ازبکی" },
    { name: "ve", en: "Venda", fr: "venda", fa: "" },
    { name: "vi", en: "Vietnamese", fr: "vietnamien", fa: "ویتنامی" },
    { name: "vo", en: "Volapük", fr: "volapük", fa: "" },
    { name: "wa", en: "Walloon", fr: "wallon", fa: "" },
    { name: "wo", en: "Wolof", fr: "wolof", fa: "" },
    { name: "xh", en: "Xhosa", fr: "xhosa", fa: "" },
    { name: "yi", en: "Yiddish", fr: "yiddish", fa: "" },
    { name: "yo", en: "Yoruba", fr: "yoruba", fa: "" },
    { name: "za", en: "Zhuang-Chuang", fr: "zhuang-chuang", fa: "" },
    { name: "zh", en: "Chinese", fr: "chinois", fa: "چینی" },
    { name: "zu", en: "Zulu", fr: "zoulou", fa: "" },
  ],
  pointsGroups: [
    { name: "welcome", en: "Welcoming", fa: "ثبت نام و فعالسازی حساب", fr: "" },
    {
      name: "busSug",
      en: "Suggest activated business",
      fa: "پیشنهاد کسب و کار فعال",
      fr: "",
    },
    {
      name: "busDel",
      en: "Deleted business",
      fa: "حذف کسب و کار فعال",
      fr: "",
    },
    {
      name: "busRate",
      en: "Rate a business",
      fa: "امتیاز دهی به کسب و کار",
      fr: "",
    },
    { name: "postSug", en: "post Suggestion", fa: "پیشنهاد پست", fr: "" },
    { name: "postSus", en: "post Suspention", fa: "تعلیق پست", fr: "" },
    { name: "postDra", en: "Post suggestion", fa: "پیشنهاد پست", fr: "" },
    { name: "postDel", en: "Deleted post", fa: "حذف پست", fr: "" },
    { name: "postAct", en: "Activated post", fa: "پست فعال", fr: "" },
    {
      name: "postDeAct",
      en: "De activated post",
      fa: "لغو فعالیت پست",
      fr: "",
    },
    {
      name: "authFeat",
      en: "Author Biography Featured",
      fa: "فعالسازی بیوگرافی نویسنده.",
      fr: "",
    },
    {
      name: "authunFe",
      en: "Author Biography Unfeatured",
      fa: " بیوگرافی نویسنده غیر فعال",
      fr: "",
    },
    { name: "like", en: "Like", fa: "دوست داشتن", fr: "" },
    { name: "comment", en: "Comment", fa: "دیدگاه", fr: "" },
    { name: "reply", en: "Reply", fa: "پاسخ", fr: "" },
    { name: "answer", en: "Answer", fa: "پاسخ کاربر", fr: "" },
    {
      name: "tutorRate",
      en: "Rate a tutor",
      fa: "امتیاز دهی به استاد",
      fr: "",
    },
    {
      name: "completedSession",
      en: "completed a session",
      fa: "تکمیل جلسه",
      fr: "",
    },
  ],
  walletDesc: [
    {
      name: "canceledSessionT",
      en: "Session canceled by tutor",
      fa: "کنسلی کلاس توسط استاد",
      fr: "",
    },
    {
      name: "canceledSessionS",
      en: "Session canceled by Student",
      fa: "کنسلی کلاس توسط دانشجو",
      fr: "",
    },
    {
      name: "refundedSession",
      en: "Session refunded by Tutor",
      fa: "بازگشت مبلغ باتائید استاد",
      fr: "",
    },
    {
      name: "noShowedTSession",
      en: "No showed tutor",
      fa: "عدم حضور استاد در کلاس",
      fr: "",
    },
    {
      name: "purchase",
      en: "Purchase by wallet",
      fa: "خرید از کیف پول",
      fr: "",
    },
  ],

  structuredData: [
    {
      "@context": "http://schema.org",
      "@type": "Organization",
      name: "MetaMarce",
      logo: "https://www.metamarce.com/favicon.ico",
      image: "https://www.metamarce.com/img/plans/x4-2.jpg",
      url: "https://www.metamarce.com",
      sameAs: [
        "https://www.instagram.com/metamarce?igsh=azJoejNtdjdydXY4&utm_source=qr",
        "https://www.facebook.com/profile.php?id=61555192350817&mibextid=LQQJ4d",
        "https://twitter.com/i/flow/login?redirect_after_login=%2FMetamarceCom",
        "https://t.me/joinchat/BfW__kawWflm6_tY_fwH8w",
      ],
      contactPoint: [
        { "@type": "ContactPoint", email: "metamarce.com@gmail.com" },
      ],
    },
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      name: "MetaMarce",
      image: "https://www.metamarce.com/img/plans/x4-2.jpg",
      url: "https://www.metamarce.com",
      description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "کسب و کارها",
      image: "https://www.metamarce.com/img/plans/x4-1.jpg",
      url: "https://www.metamarce.com/fa/business",
      inLanguage: "fa-IR",
      description: "دسترسی آسون به کسب و کارهای گوناگون در سراسر دنیا ... ",
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "مجله",
      inLanguage: "fa-IR",
      image: "https://www.metamarce.com/img/illustration/blogs-and-article.svg",
      url: "https://www.metamarce.com/fa/blog/filters",
      description:
        "  نکات کاربردی مهاجرت و زندگی، جاهای دیدنی و خوراکی خوشمزه و خوردنی، تجربیات باحال و آموزنده ...",
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "کاریابی",
      inLanguage: "fa-IR",
      description:
        "پل ارتباطی بین جویندگان کار و کارفرماها در شهرها و محله‌های گوناگون ... ",
      image:
        "https://www.metamarce.com/img/illustration/man-with-join-us-sign-for-open-recruitment.svg",
      url: "https://www.metamarce.com/fa/jobs",
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "مدیا دایرکتوری",
      inLanguage: "fa-IR",
      image: "https://www.metamarce.com/img/plans/x4-4.jpg",
      description:
        "بستری برای معرفی و دسترسی گروه‌های تخصصی، شبکه‌های اجتماعی در هر گوشه از دنیا... ",
      url: "https://www.metamarce.com/fa/media",
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "آکادمی متامارس",
      inLanguage: "fa-IR",     
      image: "https://www.metamarce.comimg/Metademy/Metademy-green-red2.jpg",
      description:
        "دنبال معلم خصوصی میگردی یا اینکه میخوای تدریس کنی و درآمد دلاری داشته باشی، متادمی برای شماست ...",
      url: "https://www.metamarce.com/fa/demy",
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "MetaDemy",
      inLanguage: "fa-IR",
      image: "https://www.metamarce.comimg/Metademy/Metademy-green-red2.jpg",
      description:
        "دنبال معلم خصوصی میگردی یا اینکه میخوای تدریس کنی و درآمد دلاری داشته باشی، متادمی برای شماست ...",
      url: "https://www.metamarce.com/fa/demy",
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "ثبت رایگان بیزینس",
      description:
        "از اینجا میتون به رایگان و اتوماتیک کسب و کارتون ثبت و به روز رسانی کنین...",
      inLanguage: "fa-IR",
      url: "https://www.metamarce.com/fa/business/register",
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "ثبت رایگان آگهی استخدام",
      description: "ثبت آسان، انتشار سریع و خودکار آگهی استخدام",
      inLanguage: "fa-IR",
      url: "https://www.metamarce.com/fa/jobs/register",
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "ثبت رایگان سوشیال مدیا",
      image: "https://www.metamarce.com/img/jobs/connected-world-animate.svg",
      description:
        "ثبت نام رایگان و معرفی گروه ها و صفحات شما در شبکه های اجتماعی به صورت اتوماتیک ...",
      inLanguage: "fa-IR",
      url: "https://www.metamarce.com/fa/media/register",
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "درباره ما",
      description:
        "یک پلتفرم آنلاین پویا که برای جامعه پارسی‌زبان در سراسر جهان طراحی شده ...",
      inLanguage: "fa-IR",
      url: "https://www.metamarce.com/fa/prompt/about",
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "راهنمای ثبت نام",
      description: "راهنمای ثبت نام بعنوان کاربر و افزودن کسب و کار رایگان",
      inLanguage: "fa-IR",
      url: "https://www.metamarce.com/fa/prompt/guides",
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "راهنمای متادمی",
      description: "راهنمای ثبت نام رایگان بعنوان استاد",
      inLanguage: "fa-IR",
      url: "https://www.metamarce.com/fa/prompt/tutor",
    },
  ],
};

export default renderData;
