import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  useLoadScript,
} from "@react-google-maps/api";
import "./map.css";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import RegisterAddressManuel from "./registerAddressManuel";
import ToastNote from "../../../golbal/notifications/toastNote";
import tools from "../../../../utils/tools";
import addErrClass from "../../../persian/common/alerts/addErrClass";
import countryStates from "../../../../utils/countryStates";

const libraries = ["places"];

const RegisterAddressPlaces = (props) => {
  const mapOptions = useMemo(() => ({
    zoom: 12,
    center: {
      lat: props.values?.location?.lat,
      lng: props.values?.location?.lng,
    },
  }), [props.values?.location?.lat, props.values?.location?.lng]);

  const [mapContainer, setMapContainer] = useState(null);
  const [lat, setLat] = useState(props.values?.location?.lat);
  const [lng, setLng] = useState(props.values?.location?.lng);
  const containerStyle = {
    width: "400px",
    height: "400px",
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  if (loadError) return <div>Error loading Google Maps API</div>;
  if (!isLoaded) return <div>Loading ....</div>;

  return <Map lat={lat} lng={lng} setLat={setLat} setLng={setLng} {...props} />;
};

const Map = ({ lat, lng, setLat, setLng, ...props }) => {
  const center = useMemo(() => ({ lat, lng }), [lat, lng]);
  const [selected, setSelected] = useState(null);

  return (
    <div className="places-container">
      <PlacesAutocomplete setSelected={setSelected} setLat={setLat} setLng={setLng} {...props} />
      <RegisterAddressManuel formik={props.formik} section={props.section} />
    </div>
  );
};

const PlacesAutocomplete = ({ setSelected, setLat, setLng, ...props }) => {
  const messageRef = useRef(null);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });
  const ref = useOnclickOutside(clearSuggestions);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => async () => {
    setValue(description, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address: description });
      console.log ("results[0]",results)
      const { lat, lng } = await getLatLng(results[0]);
      const addressParts =await parseAddressComponents(results[0]?.address_components, lat, lng,props.setLoading);

     
      const { lang, state:{state,stateShort}, country, countryShort } =tools.getItemFromLocalStorage("initSetup", {});
      console.log('addressParts 2 :', addressParts);
      if (addressParts.countryShort.toLowerCase() !== countryShort.toLowerCase())
        return messageRef.current.showError({
          message: `آدرس انتخابی در ${window.capitalize(
            addressParts.country
          )} می باشد در حالیکه شما از وبسایت ${window.capitalize(
            country
          )} می باشید.`,
        });
        console.log('addressParts.state03', addressParts ,addressParts.state,addressParts.stateShort ,addressParts.country, state)
      // if (
      //   window.convert(addressParts.state||"").indexOf(window.convert(state)) === -1
      // )
      //   return messageRef.current.showError({
      //     message: `آدرس در استان ${window.capitalize(
      //       addressParts.state
      //     )} می باشد در حالیکه شما از وبسایت ${window.capitalize(
      //       state
      //     )} در حال ثبت می باشید.`,
      //   });
        
      props.formik.setValues((prev) => ({
        ...prev,
        location: {
          googleAddress: results[0].formatted_address,
          lat,
          lng,
          country: country,
          countryShort: countryShort,
          state:addressParts.state,
          stateShort:addressParts.stateShort,
          municipality: addressParts.municipality,
          city: addressParts.city,
          route: addressParts.route,
          streetNumber: addressParts.streetNumber,
          postalCode: addressParts.postalCode,
          displayAddress: true,
        },
      }));

      setLat(lat);
      setLng(lng);
    } catch (error) {
      console.error("Error getting address details:", error);
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const { place_id, structured_formatting: { main_text, secondary_text } } = suggestion;
      return (
        <button
          key={place_id}
          onClick={handleSelect(suggestion)}
          type="button"
          className="list-group-item list-group-item-action groupItems"
        >
          <strong className="listOptions">{main_text}</strong>
          <small>{secondary_text}</small>
        </button>
      );
    });

  return (
    <div>
      <ToastNote messageRef={messageRef} />
      <div ref={ref} className={`search-bar-dropdown my-3 w-100 btn-login2 ${addErrClass({ formik: props.formik, field: "location" })}`}>
        <input
          dir="ltr"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Start typing your address and choose from the suggested options..."
          className="form-control"
        />
        {status === "OK" && <ul className="list-group">{renderSuggestions()}</ul>}
      </div>
    </div>
  );
};


const parseAddressComponents = async (components, lat, lng,setLoading) => {
  const addressParts = {};
  console.log('parseAddressComponents', components);

  components.forEach(({ types, long_name, short_name }) => {
    if (types.includes("administrative_area_level_1")) {
      addressParts.state = long_name.toLowerCase();
      addressParts.stateShort = short_name.toLowerCase();
    } else if (types.includes("locality")) {
      addressParts.city = long_name;
    } else if ((types.includes("postal_town")||types.includes("administrative_area_level_2")) && !addressParts?.city?.length) {
      addressParts.city = long_name;
    } else if (types.includes("country")) {
      addressParts.country = long_name.toLowerCase();
      addressParts.countryShort = short_name.toLowerCase();
    } else if (types.includes("postal_code")) {
      addressParts.postalCode = long_name;
    } else if (types.includes("sublocality")) {
      addressParts.municipality = long_name;
    } else if (types.includes("street_number")) {
      addressParts.streetNumber = long_name;
    } else if (types.includes("route")) {
      addressParts.route = long_name;
    }
  });

  
  // if (!addressParts?.state?.length) {
  //   console.log('addressParts before fetch', addressParts);
  //   try {
  //     setLoading((pre) => ({ ...pre, spinner: true }));
  //     // await new Promise(resolve => setTimeout(resolve, 2000));
  //     const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&r_url=https%3A%2F%2Fwww.metamarce.com`);
  //     setLoading((pre) => ({ ...pre, spinner: false }));
  //     const data = await response.json();
  //     console.log('data:', data);

  //     if (data.status === 'OK') {
  //       const results = data.results;

  //       if (results.length > 0) {
  //         const countryShortCap = addressParts.countryShort.toUpperCase();
  //         results.forEach(result => {
  //           result.address_components.forEach(component => {
  //             if (component.types.includes('administrative_area_level_1')) {
  //               addressParts.state = component.long_name.toLowerCase();              
  //               const count = countryStates.find(count => count.countryShort === countryShortCap);
  //               console.log('count:', count);
  //               const state = count.states.find(st => st.state.toLowerCase() === component.long_name.toLowerCase());
  //               console.log('state:', state);
  //               if (state) {
  //                 addressParts.stateShort = state.stateShort.toLowerCase();
  //               }
  //               return;
  //             }else  if (component.types.includes('sublocality')) {
  //               addressParts.state = component.long_name.toLowerCase();
  //               const count = countryStates.find(count => count.countryShort === countryShortCap);
  //               console.log('count2:', count);
  //               const state = count.states.find(st => st.state.toLowerCase() === component.long_name.toLowerCase());
  //               console.log('state2:', state);
  //               if (state) {
  //                 addressParts.stateShort = state.stateShort.toLowerCase();
  //               }
  //               return;
  //             }
  //           });
  //         });

  //         console.log('addressParts after fetch', addressParts);
  //       }
  //     } else {
  //       console.error('Geocoding API error:', data.status);
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // }

  return { ...addressParts };
};


export default RegisterAddressPlaces;
