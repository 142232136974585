import { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import apiDemy from "../../../utils/apiDemy";
import { CircularProgress } from "@mui/material";
import CommentsRating from "../../comment/commentsRating";
import { CartContext } from "../../contexts/cartContext";
import renderData from "../../../utils/renderData";
import ToastNote from "../../golbal/notifications/toastNote";
import ShareSocialMedias from "../business/components/shareSocialMedias";
import api from "../../../utils/api";
import tools from "../../../utils/tools";
import MetaDecorator from "../../../utils/metaDecorator";
// import GoogleMap from "../../googleMap/googleMap";
import demyData from "../../../utils/demyData";
import ColoredSpinner from "../../alret/spiners/coloredSpiner/coloredSpinner";
// import MyIcons from "../../golbal/icons/myIcons";
import RatingManuel from "../../comment/rating";
import TutorGrades from "./components/tutorGrades";
import ActionBtnGenerator from "./components/actionBtnGenerator";
import ShowAddress from "../../golbal/showAddress";
import Tooltip1 from "../../golbal/tooltip/tooltip";
import ProfTutor from "./components/profTutor";

export default function TutorPageEn(props) {
  const messageRef = useRef(null);
  const stateData = useLocation().state;
  // console.log("stateData", stateData);

  // const navigate = useNavigate();
  const effectRan = useRef(false);
  const { user, signedUser } = useContext(CartContext);
  const { id } = useParams();
  const [data, setData] = useState(stateData?.data || {});
  const [currencies, setCurrencies] = useState(stateData?.currencies || []);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [freeSession, setFreeSession] = useState(false);
  const isTutor = user?._id === data?.moreInfo?.history?.createdBy?._id;
  // console.log('currencies',currencies);
  async function fetchAcceptedCurHandle() {
    // console.log('fetchAcceptedCurHandle triggried');
    if (currencies.length) return;
    // console.log('fetchAcceptedCurHandle passed');
    console.log('selectedTimes',selectedTimes);
    const response = await api.getAcceptedCur({ token: user.token });
    if (response.error)
      return messageRef.current.showError({
        message: response.error,
      });
    setCurrencies(response.data);
  }
  // console.log("TutorPage data",data);
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState({ spinner: false, save: false });
  const [request, setRequest] = useState({ email: "", message: "" });
  const repliesCounter = () => {
    const comments = data?.feedbackInfo?.comment?.comments;
    let count = 0;
    comments?.forEach((com) => {
      count += 1;
      com.reply.forEach((rep) => {
        count += 1;
        rep.replyToReply.forEach((rep2) => (count += 1));
      });
    });
    return count;
  };
  const {
    currency,
    currency_symbol,
    exRate,
    convertRate,
    accepted,
    translations,
  } = JSON.parse(localStorage?.getItem("countryDetails")) ?? {};

  const matchedCurr = currencies.find(
    (curr) => curr.currency === data?.moreInfo?.salary?.currency
  );
  // console.log('exRate',currency,exRate);
  // console.log('currencies',currencies);
  // console.log('matchedCurr',matchedCurr);

  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    setLoading((pre) => ({ ...pre, save: true }));
    e.target.setAttribute("disabled", true);
    // console.log("saveHandler", data);
    const response = await api.saveHandler({
      id: data?._id,
      section: "tutor",
      token: user.token,
      title: data.moreInfo?.title,
    });

    setLoading((pre) => ({ ...pre, save: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 1000 * 5);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    // console.log("data", user?.saved?.data);
    signedUser(response.data);

    !user?.saved?.tutor?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "Tutor saved successfully.",
        })
      : messageRef.current.showWarning({
          message: "Tutor unsaved successfully",
        });
  };

  useEffect(() => {
    if (!id) return;
    (async () => {
      // if (id?.length !== 24)
      //   return messageRef.current.showError({
      //     message: "شناسه صحیح نمی باشد،  دوباره وارد این صفحه شوید.",
      //   });

      const response = await apiDemy.fetchTutorDetails(id);
      fetchAcceptedCurHandle();
      if (response.error) return alert(response.error);

      setData(response.data);
      // console.log('schedules',response.data);
      fetchSessions();
      tools.scrollToAnchor();
      if (window?.location?.hash?.substring(1) === "tutor-schedule")
        setFreeSession(true);
      if (effectRan.current) return;
      effectRan.current = true;
    })();
  }, [id]);
  const lastWeek = new Date().setDate(new Date().getDate() - 1 * 7);
  async function fetchSessions() {
    setLoading((pre) => ({ ...pre, sessions: true }));
    // const yesterday = new Date();
    // yesterday.setDate(yesterday.getDate() - 1);
    const response = await apiDemy.fetchSessionReqList({
      token: user.token,
      data: {
        hostId: id,
        currentDate: lastWeek,
      },
    });
    setLoading((pre) => ({ ...pre, sessions: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setSessions(response.data);
  }
  const isOwner = data?.moreInfo?.history?.createdBy?._id === user?._id;

  const t1 = data?.moreInfo?.levelSub
    ?.flatMap((le) => le.subjects.map((su) => su))
    ?.slice(0, 3);
  // console.log('t1',t1)
  const freeSessionChecker =
    !data?.moreInfo?.freeSession ||
    data?.moreInfo?.requests.some((c) => c._id === user._id);
  const url = `https://www.metamarce.com/${
    window.lang
  }/demy/tutor/page/${tools.slugMaker({
    title: data?.name?.[window.lang],
    city: data?.moreInfo?.address?.city,
    state: data?.moreInfo?.address?.state,
    serial: data?.serial,
  })}`;
  const title = `${
    data?.name?.[window.lang]
  } | Teaching ${t1} from ${window.capitalize(data?.moreInfo?.address?.country)}`;
  const description = data?.moreInfo?.intro?.[window.lang]?.substring(0, 100);

  const structuredData = [
    {
      "@context": "http://schema.org",
      "@type": "LocalBusiness",
      name: data?.name?.[window.lang],
      description: data?.moreInfo?.intro?.[window.lang],
      priceRange: "$",
      // image: [data?.moreInfo?.img],
      image: {
        "@type": "ImageObject",
        url: data?.moreInfo?.img || "/img/Metademy/Green-Red-Stickers-5.png",
        contentUrl:
          data?.moreInfo?.img || "/img/Metademy/Green-Red-Stickers-5.png",
        height: 600,
        width: 600,
      },
      url,
      address: {
        "@type": "PostalAddress",
        addressLocality: data?.moreInfo?.address?.city?.toUpperCase() ?? "",
        addressRegion: data?.moreInfo?.address?.state?.toUpperCase(),
        addressCountry: data?.moreInfo?.address?.countryShort?.toUpperCase(),
      },
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": url,
      },
      ...(data?.feedbackInfo?.rates > 0 && {
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: data?.feedbackInfo?.rate ?? 0,
          bestRating: 5,
          ratingCount: data?.feedbackInfo?.rates ?? 0,
        },
      }),
      ...(data?.feedbackInfo?.comment?.comments?.length > 0 && {
        review: data?.feedbackInfo?.comment?.comments?.map((rev) => ({
          "@type": "Review",
          reviewRating: {
            "@type": "Rating",
            ratingValue: rev?.rate || 0,
          },
          author: {
            "@type": "Person",
            name: rev?.userName,
          },
        })),
      }),
      ...(data?.moreInfo?.tags?.length > 0 && {
        keywords: data?.moreInfo?.tags?.map((tag) => tag),
      }),
      // teachingLevels: data?.moreInfo?.levelSub?.map((lev) => ({
      //   "@type": "EducationalOccupationalCredential",
      //   credentialCategory: lev.level,
      //   credentialSubject: lev.subjects?.map((subject, index) => ({
      //     "@type": "ItemList",
      //     itemListElement: [
      //       {
      //         "@type": "ListItem",
      //         position: index + 1,
      //         name: subject,
      //       },
      //     ],
      //   })),
      // })),
      // "openingHoursSpecification":
      //   data?.moreInfo?.workingDays?.map(day=>({
      //     "@type": "OpeningHoursSpecification",
      //     "dayOfWeek": day.weekDay,
      //     "opens": day.from,
      //     "closes": day.to
      //   }))
      // ,
      additionalType: [
        data?.category?.mainCat?.[window.lang],
        // Add more categories as needed
      ],
      // "acceptsReservations": "True"
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: data?.name?.[window.lang],
      description: data?.moreInfo?.desc?.[window.lang],
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "Landing",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `Home`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، درآمد دلاری از تدریس، آموزش آنلاین و مجازی بدون مرز، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/demy`,
              name: `Metademy  ${translations?.[window.lang]}`,
              description: `آکادمی متامارس`,
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": window.location.href,
              name: data?.name?.[window.lang],
            },
          },
        ],
      },
    },
    {
      "@context": "http://schema.org",
      "@graph": [
        {
          "@type": "Course",
          "@id": url,
          name: data?.name?.[window.lang],
          description: data?.moreInfo?.intro?.[window.lang],
          isAccessibleForFree: "http://schema.org/False",
          // "image":data?.moreInfo?.img,
          image: {
            "@type": "ImageObject",
            url:
              data?.moreInfo?.img || "/img/Metademy/Green-Red-Stickers-5.png",
            contentUrl:
              data?.moreInfo?.img || "/img/Metademy/Green-Red-Stickers-5.png",
            height: 800,
            width: 600,
          },
          publisher: [],
          about: { "@type": "Thing", name: t1 },
          creator: { "@type": "Person", name: data?.name?.[window.lang] },
          provider: {
            "@type": "Organization",
            name: data?.name?.[window.lang],
            sameAs: url,
          },

          hasCourseInstance: {
            "@type": "CourseInstance",
            courseMode: data?.moreInfo?.serviceTypes,
            courseSchedule: {
              "@type": "Schedule",
              repeatCount: data?.moreInfo?.schedule?.length * 3,
              repeatFrequency: "Weekly",
            },
            courseWorkload: "P5H",
          },
          ...(data?.moreInfo?.video?.[window.lang]?.youtube?.length && {
            video: {
              "@type": "VideoObject",
              "@id": data?.moreInfo?.video?.[window.lang]?.youtube,
              name: data?.name?.[window.lang],
              description: data?.moreInfo?.intro?.[window.lang],
              thumbnailUrl:
                data?.moreInfo?.img || "/img/Metademy/Green-Red-Stickers-5.png",
              uploadDate: data?.moreInfo?.lastUpdate,
              contentUrl: data?.moreInfo?.video?.[window.lang]?.youtube,
              author: { "@type": "Person", name: data?.name?.[window.lang] },
            },
          }),
          offers: [
            {
              "@type": "Offer",
              url: url,
              availability: "http://schema.org/LimitedAvailability",
              category: "Paid",
              price: data?.moreInfo?.salary?.rate,
              priceCurrency: data?.moreInfo?.salary?.currency,
              priceSpecification: {
                "@type": "UnitPriceSpecification",
                referenceQuantity: {
                  "@type": "QuantitativeValue",
                  value: 1,
                  unitCode: "HUR",
                },
              },
              hasMerchantReturnPolicy: {
                "@type": "MerchantReturnPolicy",
                applicableCountry: ["CA", "US"],
                returnPolicyCountry: "CA",
                returnPolicyCategory:
                  "https://schema.org/MerchantReturnFiniteReturnWindow",
                merchantReturnDays: 30,
                returnMethod: "https://schema.org/ReturnByMail",
                returnFees: "https://schema.org/FreeReturn",
                refundType: "https://schema.org/FullRefund",
              },
              shippingDetails: {
                "@type": "OfferShippingDetails",
                shippingRate: {
                  "@type": "MonetaryAmount",
                  value: 0,
                  currency: data?.moreInfo?.salary?.currency,
                },
                shippingDestination: {
                  "@type": "DefinedRegion",
                  addressCountry:
                    data?.moreInfo?.address?.countryShort?.toUpperCase(),
                },
                deliveryTime: {
                  "@type": "ShippingDeliveryTime",
                  handlingTime: {
                    "@type": "QuantitativeValue",
                    minValue: 0,
                    maxValue: 1,
                    unitCode: "DAY",
                  },
                  transitTime: {
                    "@type": "QuantitativeValue",
                    minValue: 1,
                    maxValue: 5,
                    unitCode: "DAY",
                  },
                },
              },
            },
          ],
        },
        {
          "@type": "Product",
          name: data?.name?.[window.lang],
          mpn: `metamarce:${data?.serial}`,
          sku: `metamarce:${data?.serial}`,
          description: data?.moreInfo?.intro?.[window.lang],
          // "image":data?.moreInfo?.img,
          image: {
            "@type": "ImageObject",
            url:
              data?.moreInfo?.img || "/img/Metademy/Green-Red-Stickers-5.png",
            contentUrl:
              data?.moreInfo?.img || "/img/Metademy/Green-Red-Stickers-5.png",
            height: 800,
            width: 600,
          },
          brand: {
            "@type": "Brand",
            name: "Metamarce",
            url: "https://www.metamarce.com/",
            logo: "/img/landing/favicon-16x16.png",
            description: data?.moreInfo?.intro?.[window.lang],
          },
          offers: {
            "@type": "Offer",
            url: url,
            availability: "http://schema.org/LimitedAvailability",
            category: "Paid",
            price: data?.moreInfo?.salary?.rate,
            priceCurrency: data?.moreInfo?.salary?.currency,
            priceSpecification: {
              "@type": "UnitPriceSpecification",
              referenceQuantity: {
                "@type": "QuantitativeValue",
                value: 1,
                unitCode: "HUR",
              },
            },
          },
          ...(data?.feedbackInfo?.rates > 0 && {
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: data?.feedbackInfo?.rate ?? 0,
              bestRating: 5,
              ratingCount: data?.feedbackInfo?.rates ?? 0,
            },
          }),
          ...(data?.moreInfo?.tags?.length > 0 && {
            keywords: data?.moreInfo?.tags?.map((tag) => tag),
          }),
          priceValidUntil: "9999-12-31",
          hasMerchantReturnPolicy: {
            "@type": "MerchantReturnPolicy",
            applicableCountry: ["CA", "US"],
            returnPolicyCountry: "CA",
            returnPolicyCategory:
              "https://schema.org/MerchantReturnFiniteReturnWindow",
            merchantReturnDays: 30,
            returnMethod: "https://schema.org/ReturnByMail",
            returnFees: "https://schema.org/FreeReturn",
            refundType: "https://schema.org/FullRefund",
          },
          shippingDetails: {
            "@type": "OfferShippingDetails",
            shippingRate: {
              "@type": "MonetaryAmount",
              value: 0,
              currency: data?.moreInfo?.salary?.currency,
            },
            shippingDestination: {
              "@type": "DefinedRegion",
              addressCountry:
                data?.moreInfo?.address?.countryShort?.toUpperCase(),
            },
            deliveryTime: {
              "@type": "ShippingDeliveryTime",
              handlingTime: {
                "@type": "QuantitativeValue",
                minValue: 0,
                maxValue: 1,
                unitCode: "DAY",
              },
              transitTime: {
                "@type": "QuantitativeValue",
                minValue: 1,
                maxValue: 5,
                unitCode: "DAY",
              },
            },
          },
        },
      ],
    },
  ];
  const metaTagsData = {
    title,
    description,
    section: "tutor ",
    url,
    img: data?.moreInfo?.img || "/img/Metademy/Green-Red-Stickers-5.png",
    type: "profile",
  };
  function openGoogleMaps(destination) {
    if (!destination)
      return messageRef.current.showError({
        message: "Address is not available.",
      });
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      destination
    )}`;
    window.open(googleMapsUrl, "_blank");
  }

  // const uniqueSt = sessions.filter(se=>se.history[se.history.length-1].status==="completed")
  // console.log('uniqueSt',uniqueSt);
  // console.log("session", sessions);
  // console.log("data", data);
  return (
    <div className="max-w-m mx-auto tutorPage metademy-en text-gray-green">
      {loading.spinner && <ColoredSpinner />}
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      {/* ****** cover ******** */}

      <div className=" py-0">
        <div className=" container2 p-0 ">
          <nav className="p-md-3 p-2" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={`/${window.lang}`} className="breadcrumb-item">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/${window.lang}/demy`} className="breadcrumb-item">
                  Metademy
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to={`/${window.lang}/demy/tutor/filters`}
                  className="breadcrumb-item"
                >
                  Filters
                </Link>
              </li>
              <li className="breadcrumb-item">
                <span className="breadcrumb-item active">
                  <strong> {data?.name?.[window.lang]}</strong>
                </span>
              </li>
            </ol>
          </nav>
          <div className="ms-5"></div>
          <div className="row py-3 px-lg-5 g-3">
            <div className="col-lg-2 d-flex justify-content-lg-start justify-content-center">
              <div className="picture-container">
                <div className=" picture  image-container image-container-media text-center m-0 ">
                  <img
                    // src="/img/Images/icons8-add-image-64.png"
                    src={
                      data?.moreInfo?.img ||
                      "/img/Metademy/Green-Red-Stickers-5.png"
                    }
                    className="picture-src"
                    id={"tutor" + data?._id}
                    title={data?.name?.[window.lang]}
                  />
                  {/* <input type="file" id="wizard-picture" className="" /> */}
                </div>
              </div>
            </div>

            <div className="col-lg-6 text-center px-0 d-flex flex-column align-items-center justify-content-start  ">
              <h1 className="uni-name-cover text-gray-green m-2 ">
                {data?.name?.[window.lang]}
                {(user?.roles?.includes(1000) || isTutor) &&
                  !data?.moreInfo?.isActive && (
                    <span
                      onClick={(e) =>
                        window.navigateHandle(
                          `/${
                            window.lang
                          }/dashboard/demy/edittutor/${tools.slugMaker({
                            title: data?.name?.[window.lang],
                            city: data?.moreInfo?.address?.city,
                            state: data?.moreInfo?.address?.state,
                            serial: data?.serial,
                          })}`
                        )
                      }
                      className="btn btn-primary save-cover-btn mx-2 font-16 bg-grayGreen border-0 cursor"
                    >
                      (Inactive)
                    </span>
                  )}
                <TutorGrades data={data} />
              </h1>
              <ProfTutor data={data} />

              <div>
                <span className="text-pink-orange me-1">
                  (
                  {tools.dynamicNumber({
                    number: Math.floor(data?.feedbackInfo?.rate * 100) / 100,
                    lang: window.lang,
                  })}
                  ){/* {Math.floor(data?.feedbackInfo?.rate * 100) / 100} */}
                </span>
                <RatingManuel value={data?.feedbackInfo?.rate} />

                <span className="review-text font-10 mx-1">
                  (
                  {data?.feedbackInfo?.rates
                    ? tools.dynamicNumber({
                        number: data?.feedbackInfo?.rates,
                        lang: window.lang,
                      }) + " "
                    : "No "}
                  Review(s) )
                </span>
              </div>
              <ShowAddress address={data?.moreInfo?.address} />
              {/* <div className="d-flex ">
                {data?.moreInfo?.stList?.length > 0 && (
                  <div className="small-gray-font me-3">
                    <i className="bi bi-pencil me-1"></i>
                    {tools.dynamicNumber({
                      number: data?.moreInfo?.stList?.length,
                      lang: window.lang,
                    })}
                    دانشجوی فعال
                  </div>
                )}
                {data?.moreInfo?.transactions?.sessionCounter > 0 && (
                  <div className="small-gray-font">
                    <i className="bi bi-book me-1"></i>
                    {tools.dynamicNumber({
                      number: data?.moreInfo?.transactions?.sessionCounter,
                      lang: window.lang,
                    })}
                    کلاس برگزار شده
                  </div>
                )}
              </div> */}
            </div>
            <div className="col-lg-4 order-1 text-center px-0 d-flex flex-column align-items-center justify-content-start  ">
              <div className=" d-flex ">
                {(user?.roles?.includes(1000) || isTutor) && (
                  <Link
                    className="btn btn-primary save-cover-btn mx-2 font-12 bg-grayGreen border-0"
                    to={`/${
                      window.lang
                    }/dashboard/demy/edittutor/${tools.slugMaker({
                      title: data?.name?.[window.lang],
                      city: data?.moreInfo?.address?.city,
                      state: data?.moreInfo?.address?.state,
                      serial: data?.serial,
                    })}`}
                  >
                    Edit
                  </Link>
                )}
                {isTutor && (
                  <div
                    className="btn btn-primary save-cover-btn mx-2 font-12 py-1 bg-grayGreen border-0"
                    data-bs-target="#offcanvasChatboxContainer"
                    data-bs-toggle="offcanvas"
                    data-bs-backdrop="false"
                  >
                    Meta chat
                    <span
                      style={{ background: "black", borderRadius: "50%" }}
                      // title=" تمام پیام های من"
                    >
                      {/* {data?.moreInfo?.requests.reduce((a, c) => 1 + a, 0)} */}
                    </span>
                  </div>
                )}

                <button
                  className="btn btn-primary save-cover-btn mx-2 font-12 py-1 bg-grayGreen border-0"
                  data-bs-toggle="modal"
                  data-bs-target={`#myModel${data?._id}`}
                  id="shareBtn"
                  data-bs-placement="top"
                  title="Share!"
                >
                  Share
                  <i className="bi bi-share-fill mx-2"></i>
                </button>

                {/* <!-- Modal --> */}
                <ShareSocialMedias
                  url={`${window.location.origin}/${
                    window.lang
                  }/demy/tutor/page/${tools.slugMaker({
                    title: data?.name?.[window.lang],
                    city: data?.moreInfo?.address?.city,
                    state: data?.moreInfo?.address?.state,
                    serial: data?.serial,
                  })}`}
                  title={data?.name?.[window.lang]}
                  img={
                    data?.moreInfo?.img ||
                    "/img/Metademy/Green-Red-Stickers-5.png"
                  }
                  _id={data?._id}
                />

                <button
                  className="btn btn-outline-secondary save-cover-btn mx-2 font-12 py-1 bg-grayGreen border-0"
                  onClick={saveHandler}
                >
                  Save
                  {loading.save ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    <i
                      className={
                        user?.saved?.tutor?.includes(data?._id)
                          ? "bi bi-bookmark-fill mx-1"
                          : "bi bi-bookmark mx-1"
                      }
                    ></i>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ****** end of cover ******** */}
      <div className="mx-md-3">
        <div className="row p-0 m-0">
          <div className=" col-lg-6  d-flex flex-wrap  justify-content-lg-start justify-content-center mt-3 px-lg-5">
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lang}/demy/tutor/filters?mainCat=${
                data?.category?.mainCat?.[window.lang]
              }&mainCatId=${data?.category?.mainCat?._id}`}
              className="btn btn-primary button-filter   "
            >
              <span>
                <i className="bi bi-list-ul me-1"></i>
              </span>
              {data?.category?.mainCat?.[window.lang]}
            </Link>
            {/* <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lang}/demy/tutor/filters?subCat=${
                data?.category?.subCat?.[window.lang]
              }&subCatId=${data?.category?.subCat?._id}`}
              className="btn btn-primary button-filter button-filter-tag  "
            >
              <span>
                <i className="bi bi-list-nested me-1 "></i>
              </span>
              {data?.category?.subCat?.[window.lang]}
            </Link> */}
            <a href="#comments2" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-chat-dots me-1"></i>
              </span>
              (
              {tools.dynamicNumber({
                number: repliesCounter(),
                lang: window.lang,
              })}
              ) Comment
            </a>
          </div>
          {/* <div className=" col-lg-6   d-flex flex-wrap  justify-content-lg-end justify-content-center  mt-3 px-lg-5"> */}
        </div>
        {/* <!-- Listing Details Section Begin -->*/}
        <div className="row m-md-2 p-2 gx-5">
          <div className="col-lg-8  order-2 order-lg-1">
            {/* <div className="w-100 image-container image-container-gd rounded-top">
              <img src={data?.moreInfo?.img} alt={data?.name} />
            </div> */}

            {data?.moreInfo?.about?.[window.lang]?.length > 0 && (
              <div className="listing-details-information px-md-5  p-3 mb-5">
                <div className="  divider-small divider-small-en w-100  border-0 mt-4">
                  <span>
                    <img
                      src="/img/icons/about.png"
                      className="arrow2 pe-2"
                      alt="info"
                    />
                  </span>

                  <span className="p-0 mb-0 "> About me</span>
                </div>

                <div className="text-justify-metamarce mt-3">
                  {tools.linesSpliter(data?.moreInfo?.about?.[window.lang])}
                </div>
              </div>
            )}
            <div className="listing-details-information px-md-5  p-3 mb-5">
              <div className="  divider-small divider-small-en w-100  border-0 mt-4">
                <span>
                  <img
                    src="/img/icons/notebook.png"
                    className="arrow4 pe-2"
                    alt="info"
                  />
                </span>
                <span className="p-0 mb-0 ">Experties</span>
              </div>

              <div className="text-justify-metamarce mt-3">
                <div className="small-gray-font mb-3">
                  <i className="bi bi-circle-fill me-2 "></i>
                  Languages | Teach In
                </div>

                <ul className="d-flex flex-wrap font-16">
                  {data?.moreInfo?.languages?.map((lang, i, arr) => (
                    <li key={i} className=" d-flex ">
                      <div
                        htmlFor={lang + i}
                        className="form-check-label px-1 "
                      >
                        {tools.renderTrans({
                          section: "languages",
                          key: lang.name,
                        })}
                      </div>
                      <div className="mid-gray-font">
                        (
                        {tools.renderTrans({
                          section: "langsLevel",
                          key: lang.level,
                        })}
                        ){i < arr.length - 1 && "،"}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="text-justify-metamarce mt-5">
                <div className="small-gray-font mb-3">
                  <i className="bi bi-circle-fill me-2"></i>
                  Subjects & Levels
                </div>

                <div className="ms-3 ">
                  {data?.moreInfo?.levelSub?.map((sub, i) => (
                    <div key={i}>
                      <div className="form-check-label ">
                        {
                          demyData.tutorLevel.find(
                            (le) => le.name === sub.level
                          )?.[window.lang]
                        }
                      </div>
                      <ul className="d-flex flex-wrap mt-1">
                        {sub.subjects?.map((sub, i) => (
                          <li
                            key={i}
                            className="ms-2 mb-1 blog-tag mid-gray-font"
                          >
                            {sub}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>

            
            </div>

            <div className="listing-details-information px-md-5  p-3 mb-5">
              <div
                className="  divider-small divider-small-en w-100 w-100  border-0 w-100 mx- mt-4"
                id="tutor-schedule"
              >
                <span>
                  <img
                    src="/img/icons/reminder.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>
                <span className="p-0 mb-0 ">Schedule </span>
                <div className="small-gray-font px-1">
                  (Choose your preferred hour. The times are displayed in your
                  local timezone)
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    onClick={(e) => setFreeSession(false)}
                    className={`btn me-3 primary-btn checkout-btn cart-footer bg-pinkOrangeDark  mt-3 px-md-5 px-3 py-2 shadow-none ${
                      !freeSession && "activeBtn"
                    }`}
                  >
                    Learning Session {!freeSession &&selectedTimes.length? `(${selectedTimes.length})`:""}
                  </button>
                  {!freeSessionChecker && (
                    <button
                      className={`btn  primary-btn checkout-btn cart-footer bg-pinkOrange  mt-3 px-md-5 px-3 py-2 shadow-none ${
                        freeSession && "activeBtn"
                      }`}
                      onClick={(e) => {
                        if (!window.logined(9999)) return;
                        if (
                          data?.moreInfo.requests.some(
                            (t) => t._id === user?._id
                          )
                        ) {
                          document.getElementById("chatBoxBtn").click();
                          messageRef.current.showWarning({
                            message:
                              "A trial session has been booked with this tutor , \n please continue in MetaChat.",
                          });
                        } else {
                          setSelectedTimes([]);
                          setFreeSession(true);
                        }
                      }}
                    >
                      Trial session
                    </button>
                  )}
                </div>
              </div>
              {data?.moreInfo?.schedule && (
                <TutorSchedule
                  messageRef={messageRef}
                  data={data}
                  sessions={sessions}
                  setSessions={setSessions}
                  matchedCurr={matchedCurr}
                  setLoading={setLoading}
                  selectedTimes={selectedTimes}
                  setSelectedTimes={setSelectedTimes}
                  freeSession={freeSession}
                  setFreeSession={setFreeSession}
                  lastWeek={lastWeek}
                  isTutor={isTutor}
                />
              )}
              {/* <p className="text-justify-metamarce mt-3">
                  <select name="timeZone" id="">
                    <option value="">{data?.moreInfo?.timeZone}</option>
                  </select>
                  <ul className="d-flex ">
                    {data?.moreInfo?.schedule.map((sch,parentIndex)=> <li key={parentIndex}>
                    {sch.weekDay}
                    <ul>
                      {sch.slots.map((slot,i )=> <li key={i}>
                        {slot.start}
                      </li>)}
                    </ul>
                    
                    </li>)}
                    </ul>
                </p> */}
            </div>

            {data?.moreInfo?.education?.[window.lang]?.length > 0 && (
              <div className="listing-details-information px-md-5  p-3 mb-5">
                <div className="  divider-small divider-small-en w-100  border-0 mt-4">
                  <span>
                    <img
                      src="/img/icons/mortarboard.png"
                      className="arrow4 pe-2"
                      alt="info"
                    />
                  </span>
                  <span className="p-0 mb-0 ">Education</span>
                </div>

                <div className="text-justify-metamarce mt-3">
                  {tools.linesSpliter(data?.moreInfo?.education?.[window.lang])}
                </div>
              </div>
            )}

            {data?.moreInfo?.experience?.[window.lang]?.length > 0 && (
              <div className="listing-details-information px-md-5  p-3 mb-5">
                <div className="  divider-small divider-small-en w-100  border-0 mt-4">
                  <span>
                    <img
                      src="/img/icons/portfolio.png"
                      className="arrow4 pe-2"
                      alt="info"
                    />
                  </span>
                  <span className="p-0 mb-0 ">Work Experience </span>
                </div>

                <div className="text-justify-metamarce mt-3">
                  {tools.linesSpliter(
                    data?.moreInfo?.experience?.[window.lang]
                  )}
                </div>
              </div>
            )}
              <div className="listing-details-information px-md-5  p-3 mb-5">
              {data?.moreInfo?.tags?.length > 0 && (
                <div>
                  <div className=" divider-small divider-small-en w-100 border-0 mt-2 mb-0">
                    <span>
                      <img
                        src="/img/bussiness-page/tag.png"
                        className="arrow2 pe-2"
                        alt="tags"
                        loading="lazy"
                      />
                    </span>
                    <span className="p-0 mb-0 ">Tags </span>
                  </div>
                  <ul className=" d-flex flex-wrap font-12 my-0">
                    {data?.moreInfo?.tags?.map((tag, i) => (
                      <li key={i} className="mx-2 my-1">
                        <Link
                          onClick={(e) =>
                            window.scrollTo({ top: 0, smooth: true })
                          }
                          key={i}
                          to={`/${window.lang}/demy/tutor/filters?tag=${tag}`}
                        >
                          <span key={i}>
                            {/* <img
                    src="/img/bussiness-page/tag.png"
                    className="arrow2 pe-2"
                    alt="tags"
                  /> */}
                            {/* <i className="bi bi-hash"></i> */}
                            <i className="bi bi-tag font-10"></i>
                          </span>
                          {tag}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              </div>
            {/* {data?.moreInfo?.about?.[window.lang]?.length > 0 && (
              <div className="listing-details-information px-md-5  p-3 mb-5">
                <div className="  divider-small divider-small-en w-100  border-0 mt-4">
                  <span>
                    <img
                      src="/img/icons/bowling.png"
                      className="arrow4 pe-2"
                      alt="info"
                    />
                  </span>
                  <span className="p-0 mb-0 ">سرگرمی‌ها</span>
                </div>

                <p className="ttext-justify-metamarce mt-3">
                  {tools.linesSpliter(data?.moreInfo?.about?.[window.lang])}
                </p>
              </div>
            )} */}
          </div>
          <div className=" col-lg-4 order-1 order-lg-2">
            <div className="px-3">
              <button
                type="button"
                className="btn primary-btn checkout-btn cart-footer shadow-none py-2 w-100 bg-pinkOrangeDark  "
              >
                <a href="#tutor-schedule">
                  <i className="bi bi-calendar4-week me-2 font-20"></i>
                  Schedule
                </a>
              </button>

              {!freeSessionChecker && (
                <button
                  type="button"
                  // className="btn primary-btn checkout-btn cart-footer bg-pinkOrange  mt-3 py-2 border-0 shadow-none  w-100 "
                  className={`btn primary-btn checkout-btn cart-footer bg-${
                    data?.moreInfo.freeSession ? "pinkOrange" : "grayGreen"
                  }  mt-3 py-2 border-0 shadow-none  w-100`}
                  // data-bs-toggle="modal"
                  // data-bs-target={
                  //   user.roles?.includes(9999)
                  //     ? "#messageModal"
                  //     : "#exampleModalToggle"
                  // }
                  disabled={
                    !data?.moreInfo?.freeSession ||
                    data?.moreInfo?.requests.some((c) => c._id === user._id)
                  }
                  onClick={(e) => {
                    document
                      .getElementById("tutor-schedule")
                      .scrollIntoView({ behavior: "smooth" });
                    if (!window.logined(9999)) return;
                    if (
                      data?.moreInfo.requests.some((t) => t._id === user?._id)
                    )
                      return messageRef.current.showWarning({
                        message:
                          "The trial session with this tutor has been held in the past and \n For further coordination, you can use the Meta chat.",
                      });
                    setFreeSession(true);
                    setSelectedTimes([]);
                  }}
                >
                  <i className="bi bi-clipboard-check me-2 font-20"></i>
                  Trial Session{" "}
                  <span
                    style={{ background: "black", borderRadius: "50%" }}
                  ></span>
                </button>
              )}
              <button
                type="button"
                // disabled={data?.moreInfo?.requests?.some(t=>t._id===user?._id)}
                className="btn primary-btn checkout-btn cart-footer mb-5 mt-3 shadow-none py-2 w-100 tutor-color0 "
                // className={`btn primary-btn checkout-btn cart-footer bg-${isOwner?"grayGreen":"pinkOrangeDark"} mt-3  border-0 shadow-none py-1 w-100  `}
                disabled={isOwner}
                onClick={(e) => {
                  if (!window.logined(9999)) return;
                  if (
                    data?.moreInfo?.requests?.some((t) => t._id === user?._id)
                  ) {
                    document.getElementById("chatBoxBtn").click();
                    messageRef.current.showWarning({
                      message:
                        "A trial session has been booked with this tutor , \n please continue in MetaChat.",
                    });
                  } else {
                    const myModal = document.getElementById("messageModal"); // Get the modal element
                    const modal = new window.bootstrap.Modal(myModal, {
                      // backdrop: "static",
                      keyboard: true,
                    });
                    modal.show();
                  }
                }}
              >
                <span>
                  <i
                    className="bi bi-send-plus mx-2"
                    style={{ fontSize: 20 }}
                  ></i>

                  {/* <i
                    className="bi bi-envelope-plus mx-2"
                    style={{ fontSize: 20 }}
                  ></i> */}
                </span>
                Send Message
              </button>

              {/* <!-- Modal --> */}
              <div
                className="modal fade"
                id="messageModal"
                tabIndex="-1"
                aria-labelledby="sendMessageModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1
                        className="modal-title titr5 text-gray-green "
                        id="sendMessageModalLabel"
                      >
                        Send message to{data?.name?.[window.lang]}
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-7">
                          {/* <div>
                            <label htmlFor="emailCheck">
                              مکاتبه شمااز ایمیل زیر انجام خواهد شد.
                            </label>
                            <input
                              className="w-100 mx-2"
                              type="email"
                              id="emailCheck"
                              defaultValue={user.email}
                              onChange={(e) =>
                                setRequest((pre) => ({
                                  ...pre,
                                  email: e.target.value,
                                }))
                              }
                            />
                          </div> */}

                          <div>
                            <label
                              className="med-gray-font m-3"
                              htmlFor="sessionRequest"
                            >
                              You can introduce yourself here, Talk to your
                              tutor about your purpose session
                            </label>
                            <textarea
                              placeholder="Write your message here..."
                              className="w-100 px-3 border-0"
                              name=""
                              id="sessionRequest"
                              cols="50"
                              rows="10"
                              onChange={(e) => {
                                e.target.style.height = "auto";
                                e.target.style.height =
                                  e.target.scrollHeight + "px";
                                setRequest((pre) => ({
                                  ...pre,
                                  message: e.target.value,
                                }));
                              }}
                            ></textarea>
                            <button
                              type="button"
                              className="btn btn-primary border-0 bg-pinkOrange save-cover-btn w-100 px-3"
                              // data-bs-dismiss="modal"
                              onClick={sendRequestHandle}
                            >
                              Send messsage
                            </button>
                          </div>
                        </div>
                        <div className="col-md-5 align-items-center  display-none-md">
                          <img
                            src="/img/Metademy/Messaging.svg"
                            alt="claim"
                            loading="lazy"
                            // style={{ verticalAlign: "center" }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="modal-footer">
                   
                      <button
                        type="button"
                        className="btn btn-outline-secondary save-cover-btn ms-3"
                        data-bs-dismiss="modal"
                      >
                        بستن
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {data?.moreInfo?.video?.[window.lang]?.youtube?.length > 0 && (
              <div className="listing-sidebar-section mt-1 p-2 mb-5">
                <div className="ratio ratio-16x9 video-frame">
                  {tools.generateYoutubeIframe(
                    data?.moreInfo?.video?.[window.lang].youtube ?? ""
                  )}
                </div>
              </div>
            )}
            <div className="listing-sidebar-section mt-0 mb-5">
              {/* <div className="divider2  divider-small divider-small-en w-100   border-0 mt-5 mb-3">
                <span>
                  <img
                    src="/img/bussiness-page/information.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>
                <span>جزییات </span>
              </div> */}
              <div className="divider2  divider-small divider-small-en w-100 my-3  border-0">
                <span>
                  <img
                    src="/img/icons/reminder.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>
                <span>Class Format</span>
              </div>
              <ul className="d-flex ps-1">
                {renderData?.serviceTypes?.map((ST, i) =>
                  !data?.moreInfo?.serviceTypes?.includes(ST.name) ? (
                    ""
                  ) : (
                    <li key={i} className="me-3">
                      <img src={ST.img} className="arrow2 ps-2" alt="info" />
                      <span
                        className="form-check-label px-1 "
                        htmlFor={ST.name + i}
                      >
                        {ST?.[window.lang]}
                      </span>
                    </li>
                  )
                )}
              </ul>

              {/* <div className="divider2  divider-small divider-small-en w-100 mt-5 mb-3  border-0">
                <span>
                  <img
                    src="/img/icons/reminder.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>
                <span>زمان دسترسی </span>
              </div>
              <ul className="d-flex flex-column">
                {data?.moreInfo?.availability?.map((ST, i) => (
                  <li key={i} className="d-flex justify-content-between">
                    <div>
                      <span>
                        {
                          demyData?.availableDay?.find(
                            (RST) => RST.name === ST.availableDay
                          )?.[window.lang]
                        }
                        {ST?.[window.lang]}
                      </span>
                      <span> : </span>
                    </div>
                    <span>
                      {
                        demyData?.availableTime?.find(
                          (RST) => RST.name === ST.availableTime
                        )?.[window.lang]
                      }
                      {ST?.[window.lang]}
                    </span>
                  </li>
                ))}
              </ul> */}

              <div className="divider2  divider-small divider-small-en w-100 mt-5 mb-3  border-0">
                <span>
                  <img
                    src="/img/icons/income.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>
                <span>Rate </span>
              </div>

              <div className="d-flex justify-content-between">
                <p className="mid-gray-font">Per hour</p>
                <div>
                  <strong>
                    {tools.showPrice(
                      Math.ceil(
                        Math.ceil(
                          data?.moreInfo?.salary?.rate /
                            (matchedCurr?.exRate ||
                              data?.moreInfo?.salary?.exRate)
                        ) * exRate
                      ),
                      currency
                    )}
                  </strong>
                </div>
              </div>
              {data?.moreInfo?.ageGroups?.length > 0 && (
                <div>
                  <div className="divider2  divider-small divider-small-en w-100  mt-5 mb-3 border-0">
                    <span>
                      <img
                        src="/img/icons/people.png"
                        className="arrow2 pe-2"
                        alt="info"
                      />
                    </span>
                    <span>Age groups</span>
                  </div>

                  <ul className="d-flex flex-column ">
                    {data?.moreInfo?.ageGroups?.map((ag, i) => (
                      <li key={i} className="d-flex justify-content-between">
                        <div>
                          <span>
                            <input
                              className="form-check-input me-2"
                              type="checkbox"
                              checked
                              readOnly
                            />
                            {tools.renderTrans({
                              section: "ageGroups",
                              key: ag,
                            })}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {data?.moreInfo?.serviceTypes?.includes("inPerson") && (
                <div>
                  <div className="divider2  divider-small divider-small-en w-100 mt-5 mb-3 border-0">
                    <span>
                      <img
                        src="/img/bussiness-page/home-address.png"
                        className="arrow2 pe-2 py-1"
                        alt="more info"
                      />
                    </span>
                    <span className="mb-0 "> Meeting Location</span>
                  </div>
                  <ul className="list-group px-1" dir="ltr">
                    <li className="group-list-item flex-column">
                      <div>
                        {/* <span>
                      <i className="bi bi bi-geo-alt mx-3"></i>
                    </span> */}
                        {data?.moreInfo?.address?.displayAddress && (
                          <span className="addressBusPage">
                            {`
                    ${
                      data?.moreInfo?.address?.number?.length
                        ? "#" +
                          window.capitalize(data?.moreInfo?.address?.number) +
                          ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.streetNumber?.length
                        ? window.capitalize(
                            data?.moreInfo?.address?.streetNumber
                          ) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.route?.length
                        ? window.capitalize(data?.moreInfo?.address?.route) +
                          ","
                        : ""
                    }
                    
                    ${
                      data?.moreInfo?.address?.postalCode
                        ? data?.moreInfo?.address?.postalCode
                        : ""
                    }
                    
                   
                   `}
                          </span>
                        )}
                      </div>
                      <div>
                        {`
                    ${
                      data?.moreInfo?.address?.municipality?.length
                        ? window.capitalize(
                            data?.moreInfo?.address?.municipality
                          ) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.city?.length
                        ? window.capitalize(data?.moreInfo?.address?.city) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.state?.length
                        ? window.capitalize(data?.moreInfo?.address?.state) +
                          ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.countryShort?.length
                        ? window.capitalize(
                            data?.moreInfo?.address?.countryShort
                          )
                        : ""
                    }
                    `}
                      </div>
                    </li>
                    {data?.moreInfo?.address?.displayAddress && (
                      <li
                        className="d-flex cursor text-green-dark ms-2"
                        onClick={() =>
                          openGoogleMaps(data?.moreInfo?.address?.googleAddress)
                        }
                        dir="rtl"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="32"
                          fill="currentColor"
                          className="bi bi-sign-turn-right mx-2 my-1"
                          viewBox="0 0 16 32"
                        >
                          <path d="M5 8.5A2.5 2.5 0 0 1 7.5 6H9V4.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L9.41 8.658A.25.25 0 0 1 9 8.466V7H7.5A1.5 1.5 0 0 0 6 8.5V11H5V8.5Z" />
                          <path
                            fillRule="evenodd"
                            d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435Zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134Z"
                          />
                        </svg>
                        <div className="map-dir">Directions</div>
                      </li>
                    )}
                  </ul>
                  <div className="divider2  divider-small divider-small-en w-100  mt-5 mb-3 border-0">
                    <span>
                      <img
                        src="/img/icons/car.png"
                        className="arrow2 pe-2"
                        alt="info"
                      />
                    </span>
                    <span>Meet Near You!</span>
                    <div className="small-gray-font font-10">
                      (The student chooses the meeting location.)
                    </div>
                  </div>

                  <ul className="d-flex flex-column px-0">
                    {data?.moreInfo?.distances?.map((ST, i) => (
                      <li key={i} className="">
                        <div className="fst-italic">
                          <span>
                            {
                              demyData?.distances?.find(
                                (RST) => RST.name === ST.distance
                              )?.[window.lang]
                            }
                            {ST?.[window.lang]}
                          </span>
                          <span> : </span>
                        </div>
                        <span className="font-14 text-gray-green-dark fw-bold">
                          {
                            demyData?.charges?.find(
                              (RST) => RST.name === ST.cost
                            )?.[window.lang]
                          }
                          {ST?.[window.lang]}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* <div className="listing-sidebar-section mt-0 mb-5">
             

              {data?.moreInfo?.address?.displayAddress && (
                <div className="listing-details-map mt-3">
                  <GoogleMap data={data?.moreInfo?.address.coordinates} />
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>
      {/* <SliderPosts data={busSlider}/> */}
      {/* <BusCardSlider data={busSlider} /> */}
      {/* *****************comments************** */}
      <div className="row m-0 p-0 gx-5 justify-content-center ">
        <div className="col-md-9 col-lg-9 col-xl-6" id="comments2">
          <CommentsRating
            id={data?._id}
            value={data}
            setData={setData}
            collection="Tutor"
          />
        </div>
      </div>
      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Back to top"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );
  async function sendRequestHandle(e) {
    if (!window.logined(9999)) return;
    // console.log(request);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (request.message.length < 10)
      return messageRef.current.showError({
        message: "Please describe more.",
      });
    if (request.message.length > 1000)
      return messageRef.current.showError({
        message: "Please describe less than 1000 characters.",
      });
    if (request.email.length && !emailRegex.test(request.email))
      return messageRef.current.showError({
        message: "Please check you email format.",
      });

    e.target.setAttribute("disabled", true);

    setLoading((pre) => ({ ...pre, spinner: true }));
    const email = request.email.length ? request.email : user.email;
    if (!data?.moreInfo?.history?.createdBy?._id)
      return messageRef.current.showError({
        message: "Tutor id is not provided.",
      });
    const response = await apiDemy.reqTutor({
      token: user.token,
      data: {
        message: request.message,
        guestEmail: email,
        tutor: data?.name,
        hostEmail: data?.email,
        hostUser: data?.moreInfo.history.createdBy._id,
        hostId: data?._id,
        lang: window.lang,
        lcs: data?.lcs,
        guestId: user._id,
      },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    // setTimeout(() => e.target.removeAttribute("disabled"), 10000);
    if (response.error)
      return messageRef.current.showError({
        message: "خطا در انجام عملیات : " + response.error,
      });
    messageRef.current.showSuccess({
      message: (
        <div>
          <div>درخواست شما با موفقیت ثبت شد</div>
          <div>استاد در متاچت به درخواست شما پاسخ خواهد داد</div>
        </div>
      ),
    });
    setData(response.data);
    var myModal = document.getElementById("messageModal"); // Get the modal element
    var modal = window.bootstrap.Modal.getInstance(myModal);
    modal.hide();
    document.querySelector("textarea").value = "";
    setRequest({ message: "", email: "" });
  }
}

function TutorSchedule({
  data,
  messageRef,
  sessions,
  setSessions,
  matchedCurr,
  setLoading,
  selectedTimes,
  setSelectedTimes,
  freeSession,
  setFreeSession,
  lastWeek,
  isTutor,
}) {
  // console.log('data',data);
  const [weeks, setWeeks] = useState(0);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [availableSlots, setAvailableSlots] = useState([]);
  const [clickedSession, setClickedSession] = useState({});
  const [bookingComment, setBookingComment] = useState("");
  const {
    lang,
    state: { state, stateShort },
    country,
    countryShort,
  } = tools.getItemFromLocalStorage("initSetup", {});
  const currentDate = Date.now();
  const [inviteLink, setInviteLink] = useState({});
  // console.log('inviteLink',inviteLink);
  // const [selectedTimes , setSelectedTimes] = useState([]);

  // console.log('clickedSession',clickedSession);
  // console.log("availableSlots", availableSlots);

  const { serviceIncrement, serviceItems, user, cartEmptier } =
    useContext(CartContext);
  // const messageRef = useRef(null)

  const {
    currency,
    currency_symbol,
    exRate,
    convertRate,
    accepted,
    translations,
  } = JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const curCom = {
    currency,
    currency_symbol,
    exRate,
    convertRate,
    accepted,
    translations,
    // state,
  };

  function weekDaysCalculator() {
    if (!data?.moreInfo?.schedule?.length === 0) return;
    const schedules = [...data?.moreInfo?.schedule];
    // console.log('schedules',schedules)
    // console.log('schedules',data?.moreInfo?.schedule);

    // Parse the current date starting from tomorrow
    const currentDate = new Date();

    // Function to get the next available weekday
    function getNextWeekday(weekday) {
      const today = currentDate.getDay();
      // console.log('currentDate',currentDate.toISOString())
      const days = { addDay: 6, addWeekDay: +1 };
      if (isTutor) {
        days.addDay = 7;
        days.addWeekDay = 0;
      }
      let daysToAdd = (days.addDay + weekday - today) % 7; // Calculate days to add to reach the next weekday
      // if (daysToAdd === isTutor) daysToAdd = 7; // If today is the same as the desired weekday, move to next week
      const nextWeekday = new Date(currentDate);
      nextWeekday.setDate(
        nextWeekday.getDate() + 7 * weeks + daysToAdd + days.addWeekDay
      ); // Set the date to the next weekday
      return nextWeekday;
    }

    // Assign dates to each schedule based on the next available weekday
    // schedules.forEach((schedule) => {
    //   const nextWeekday = getNextWeekday(getWeekdayNumber(schedule.weekDay));
    //   schedule.date =getDateFormat(nextWeekday) // Extracting date in YYYY-MM-DD format
    // });
    const schedules2 = schedules.map((schedule) => {
      const nextWeekday = getNextWeekday(getWeekdayNumber(schedule.weekDay));
      const updatedDate = getDateFormat(nextWeekday); // Extracting date in YYYY-MM-DD format
      return { ...schedule, date: updatedDate };
    });
    // console.log('schedules2',schedules2)
    function getDateFormat(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if needed
      const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if needed

      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    }

    // Generate missing weekdays and assign them
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    for (let i = 0; i < 7; i++) {
      const existingWeekday = schedules2.find(
        (schedule) => getWeekdayNumber(schedule.weekDay) === i
      );
      if (!existingWeekday) {
        const nextWeekday = getNextWeekday(i);
        const newSchedule = {
          weekDay: weekdays[i],
          slots: [],
          date: getDateFormat(nextWeekday),
        };
        schedules2.push(newSchedule);
      }
    }

    // Sort the schedules based on weekdays
    schedules2.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Function to get the numeric value of weekday
    function getWeekdayNumber(weekday) {
      const weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return weekdays.indexOf(weekday);
    }
    const updatedSortedData = schedules2?.map((day, index) => {
      const matchedSessions = sessions.filter(
        (se, i) => getDateFormat(new Date(se.sessionInfo.start)) === day.date
      );

      const convertedMatched = matchedSessions?.map((se) => ({
        guest: se.guest,
        history: se.history,
        sessionInfo: se.sessionInfo,
        duration: se.sessionInfo.duration,
        end: new Date(
          new Date(se.sessionInfo.end).toLocaleString("en-US", {
            timeZone: se.sessionInfo.timeZone,
          })
        ),
        start: new Date(
          new Date(se.sessionInfo.start).toLocaleString("en-US", {
            timeZone: se.sessionInfo.timeZone,
          })
        ),
        status: se.history[0].status,
        sessionId: se._id,
        // weekDay: weekdays[new Date (se.sessionInfo.start).getDay()]
      }));

      const convertedSlots = day.slots
        .filter((sl) => !sl?.sessionId)
        .map((slot) => {
          // const start =
          // console.log("typeof slot.start",typeof slot.start);
          if (typeof slot.start === "string") {
            slot.start = new Date(
              tools.generateUtcDate({
                date: day.date,
                time: slot.start,
                // timeZone: "Europe/Warsaw",
                // timeZone:'+03:30',
                timeZone: data?.moreInfo?.timeZone,
                // timeZone : "Asia/Tehran",
              })
            );
          } else {
            slot.start = new Date(
              day.date + "T" + new Date(slot.start).toISOString().substring(11)
            );
          }

          slot.end = endCalculator(slot.start, slot.duration);
          slot.status = "available";
          return slot;
        });

      const combinedMatch = [...convertedMatched, ...convertedSlots].sort(
        (a, b) => a.start - b.start
      );

      function removeDuplicates(array) {
        const uniqueMap = new Map();
        for (const item of array) {
          // Check if an item with the same start date already exists in the map
          if (uniqueMap.has(item.start.toISOString())) {
            // If the existing item doesn't have a sessionId and the current item does, replace it
            if (
              !uniqueMap.get(item.start.toISOString()).sessionId &&
              item.sessionId
            ) {
              uniqueMap.set(item.start.toISOString(), item);
            }
          } else {
            // Otherwise, add the current item to the map
            uniqueMap.set(item.start.toISOString(), item);
          }
        }
        return Array.from(uniqueMap.values());
      }

      day.slots = removeDuplicates(combinedMatch);
      return day;
    });
    setAvailableSlots(updatedSortedData);
    // console.log('updatedSortedData',updatedSortedData)
  }
  function endCalculator(start, duration) {
    const newDate = new Date(start);
    const currentHours = newDate.getHours();
    const currentMinutes = newDate.getMinutes();
    const totalMinutes = currentHours * 60 + currentMinutes + duration * 60; // Add duration
    const newHours = Math.floor(totalMinutes / 60);
    const newMinutes = totalMinutes % 60;
    newDate.setHours(newHours);
    newDate.setMinutes(newMinutes);
    return newDate;
  }

  const calculateTotalPrice = (() => {
    let totalPrice = 0;
    selectedTimes.forEach((sel) => {
      totalPrice +=
        sel.duration *
        Math.ceil(
          data?.moreInfo?.salary?.rate /
            (matchedCurr?.exRate ?? data?.moreInfo?.salary?.exRate)
        );
    });
    return totalPrice;
  })();

  useEffect(() => {
    weekDaysCalculator();
  }, [data, sessions, weeks]);

  return (
    <div className="mx-md-3 mx-1">
      <div className="d-flex justify-content-between w-100 p-2 ">
        <span>
          <button
            disabled={
              (isTutor
                ? lastWeek
                : new Date().setDate(new Date().getDate() + 1)) >=
              new Date(availableSlots[0]?.date)
            }
            onClick={(e) =>
              setWeeks((pre) => {
                return (pre -= 1);
              })
            }
            className="icon-btn"
          >
            <i className="bi bi-caret-left-fill direction-pagination mx-1 my-2 "></i>
          </button>
        </span>

        <div className=" d-flex mid-gray-font align-items-center">
          {/* March 5-11, 2024 */}
          <GetWeekDatesString data={availableSlots} />
        </div>
        <span>
          <button
            onClick={(e) =>
              setWeeks((pre) => {
                return (pre += 1);
              })
            }
            className="icon-btn"
          >
            <i className="bi bi-caret-right-fill direction-pagination "></i>
          </button>
        </span>
      </div>
      <div id={"availableSlotsParent" + weeks} className="row">
        {availableSlots.map((daySchedule, mainIndex) => (
          <div
            className="col-1-7 text-center font-14 px-1"
            key={daySchedule?.weekDay + weeks}
            id={"avS" + daySchedule?.weekDay + weeks}
          >
            <div className="weekdays text-wrap p-1 pt-3 text-center">
              {/* {daySchedule?.weekDay} */}
              {tools.renderTrans({
                section: "weekDays",
                key: daySchedule?.weekDay,
              })}
            </div>
            {/* <strong className="pb-4"> {tools.dynamicNumber( {number:daySchedule?.date.split("-")[2],lang})} </strong>
                <strong className="pb-4"> {tools.dynamicNumber( {number:daySchedule?.date.split("-")[2],lang})} </strong> */}
            <strong className="pb-4">
              {tools.dynamicDate({
                lang,
                countryShort,
                singleDate: true,
                date: new Date(
                  tools.generateUtcDate({
                    date: daySchedule?.date,
                    time: "09:00",
                    // timeZone: "Asia/Tehran",
                    // timeZone: data?.moreInfo.timeZone,
                    timeZone,
                  })
                ),
              })}
            </strong>
            <ul className="px-0 text-center" id={"mainUl" + mainIndex}>
              {daySchedule?.slots?.map((slot, i) => {
                const isTest = slot?.history?.some(
                  (sl) => sl?.status === "test"
                );
                const hasLink = slot?.sessionInfo?.inviteLink?.length > 10;

                return (
                  (!isTutor ? slot.status === "available" : true) && (
                    <li
                      key={`keyMainIndex${mainIndex}slotIndex${i}`}
                      id={`mainIndex${mainIndex}slotIndex${i}`}
                      className={`cursor times-tutor my-2 font-14 ${
                        selectedTimes.some((sel) => {
                          return sel.start === slot.start.toISOString();
                        })
                          ? "selected-session"
                          : ""
                      } ${slot.status + "-" + "session"}`}
                      onClick={(e) => selectHandler(e, slot)}
                    >
                      {
                        selectedTimes.some((sel) => {
                          return sel.start === slot.start.toISOString();
                        })
                        // && <i className="bi bi-check"></i>
                      }
                      <div
                      // onClick={(e) =>selectHandler(slot) }
                      >
                        {/* {isTest
                           && <i  className="bi bi-check-circle" />} */}
                        {tools.dynamicTime({
                          date: slot.start,
                          lang: window.lang,
                          countryShort,
                        })}

                        {/* <div>{slot.start.toISOString()}</div> */}
                        {/* <div>{slot.status}</div> */}
                      </div>
                      {isTutor && slot?.sessionId?.length > 0 && (
                        <div className="dropdown">
                          <div
                            type="button"
                            className=" dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            title="  Edit"
                            // className="btn btn-primary share-cover-btn mx-2"
                          >
                            {slot?.status === "booked" ||
                            slot?.status === "confirmed" ||
                            slot?.status === "test" ||
                            slot?.status === "noShowedS" ||
                            (slot?.status === "canceled" &&
                              slot.history[0]?.user !== user?._id &&
                              slot.sessionInfo.rate > 0 &&
                              new Date(slot.start).getTime() -
                                new Date(slot.history[0].timestamp).getTime() <=
                                24 * 60 * 60 * 1000) ? (
                              <i className="bi bi-chevron-compact-down " />
                            ) : slot?.status !== "available" ? (
                              <i className="bi bi-info-circle-fill " />
                            ) : (
                              ""
                            )}
                          </div>

                          {/* <!-- Modal --> */}
                          <div className="dropdown-menu checkbox-form ">
                            <div className="row">
                              <div className="col-4">Status :</div>
                              <div
                                className={
                                  "col-8  border  border-1 rounded mb-2 " +
                                  slot?.status +
                                  "-" +
                                  "session"
                                }
                              >
                                {/* وضعیت */}

                                {/* ( */}
                                {tools.renderTrans({
                                  section: "status",
                                  key: slot?.status,
                                })}
                                {/* ) */}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-3 d-flex flex-column align-items-center justify-content-center px-0">
                                <img
                                  className="img-fluid jobCrad-img3 "
                                  src={slot?.guest?.img}
                                  alt={slot?.guest?.userName}
                                />
                                {isTest && (
                                  <span className="test-session mt-2 font-10">
                                    Trial
                                  </span>
                                )}
                              </div>
                              <div className="col-9 text-center">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                  <strong>{slot?.guest?.userName}</strong>
                                  <div className=" small-gray-font">
                                    <div>
                                      {tools.dynamicDate({
                                        date: slot.sessionInfo?.start,
                                        countryShort,
                                        lang: window.lang,
                                      })}
                                    </div>
                                    <div>
                                      {tools.dynamicTime({
                                        date: slot.sessionInfo?.end,
                                        countryShort,
                                        lang: window.lang,
                                      })}
                                      -
                                      {tools.dynamicTime({
                                        date: slot.sessionInfo?.start,
                                        countryShort,
                                        lang: window.lang,
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* start set link */}
                            {(slot.status === "booked" ||
                              slot.status === "test" ||
                              slot.status === "confirmed") &&
                              new Date(slot.start).getTime() +
                                slot.sessionInfo.duration * 60 * 60 * 1000 >
                                new Date() && (
                                <div className="border-bottom border-secondary mb-3">
                                  {hasLink && (
                                    <button
                                      className="btn primary-btn checkout-btn w-100 my-2 p-0 font-10 max-w-200 bg-beigeDark text-green-dark"
                                      title={slot.sessionInfo.inviteLink}
                                      disabled={!hasLink}
                                      onClick={(e) =>
                                        window.open(slot.sessionInfo.inviteLink)
                                      }
                                    >
                                      <i className="bi bi-door-open font-16"></i>
                                      <strong>Open Session</strong>
                                    </button>
                                  )}

                                  <button
                                    className="btn primary-btn checkout-btn w-100 my-2 p-0 font-10 max-w-200  bg-beigeGray text-green-dark "
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      const el = e.target.nextElementSibling;
                                      el.style.display =
                                        el.style.display === "none"
                                          ? "flex"
                                          : "none";
                                    }}
                                  >
                                    <i className="bi bi-link-45deg font-16"></i>
                                    {hasLink
                                      ? "Send new Link"
                                      : "Send session link"}
                                  </button>
                                  <div
                                    style={{ display: "none" }}
                                    className="input-group mb-2"
                                  >
                                    <input
                                      type="text"
                                      id={slot.sessionId + "textArea"}
                                      value={inviteLink[slot?.sessionId]}
                                      name=""
                                      className="form-control small-gray-font"
                                      placeholder="Please enter session link here..."
                                      onChange={(e) =>
                                        setInviteLink((pre) => ({
                                          ...pre,
                                          [slot.sessionId]: e.target.value,
                                        }))
                                      }
                                    ></input>
                                    {inviteLink[slot?.sessionId]?.length >
                                      0 && (
                                      <div
                                        className="input-group-append"
                                        onClick={(e) => sendLinkHandle(e, slot)}
                                      >
                                        <span className="input-group-text  h-100">
                                          <i className="bi bi-send font-14 "></i>
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            {/* end link setup */}

                            {!inviteLink[slot?.sessionId]?.length && (
                              <span key={mainIndex + "-" + i}>
                                <ActionBtnGenerator
                                  session={slot}
                                  currentStatus={slot.status}
                                  actionHandler={actionHandler}
                                  currentDate={currentDate}
                                  access={
                                    user.roles.includes(1000)
                                      ? "admin"
                                      : isTutor
                                      ? "tutor"
                                      : "user"
                                  }
                                />
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        ))}
      </div>
      {!freeSession && selectedTimes.length > 0 && (
        <div>
          <button
            className="btn primary-btn checkout-btn cart-footer  mt-3  border-0 shadow-none  w-100 bg-pinkOrange "
            data-bs-toggle="modal"
            data-bs-target="#bookingModal"
          >
            Continue
          </button>
        </div>
      )}
      {isTutor && (
        <div>
          <div className="d-flex flex-wrap ">
            <div className="booked-session font-10 px-2 m-1"> Session</div>
            <div className="test-session font-10 px-2 m-1"> Trial</div>
            <div className="confirmed-session font-10 px-2 m-1">
              {" "}
              Finalized{" "}
            </div>
            <div className="noShowedS-session font-10 px-2 m-1"> Absence </div>
            <div className="completed-session font-10 px-2 m-1">Finished</div>
            <div className="canceled-session font-10 px-2 m-1"> Cancelled </div>
            <div className="refunded-session font-10 px-2 m-1">
              Returned money
            </div>
            <div className="notRefunded-session font-10 px-2 m-1">
              Don't return money
            </div>
          </div>
          <div className="small-gray-font font-10 mt-1">
            ( For more details and full schedule, please visit your
            <span
              className="mx-1 fw-bold cursor"
              onClick={(e) =>
                window.navigateHandle(
                  `/${window.lang}/dashboard/user/demy/listsessions`
                )
              }
            >
              «Dashboard»
            </span>
            )
          </div>
        </div>
      )}
      <div
        className="modal fade"
        id="bookingModal"
        tabIndex="-1"
        aria-labelledby="claimModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title titr5 " id="claimModalLabel">
                Ask
                {freeSession && " trial Session "}
              </h1>
              <button
                type="button"
                id="modal-colse-btn"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <table className="w-100 text-center mb-3">
                <thead className="font-14 ">
                  <tr>
                    {!freeSession && <td></td>}
                    <th>Date</th>
                    <th>Start</th>
                    <th>Duration</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody className="font-12">
                  {freeSession ? (
                    <tr key={clickedSession._id}>
                      <td>
                        {tools.dynamicDate({
                          date: clickedSession.start,
                          countryShort,
                          lang: window.lang,
                        })}
                      </td>
                      <td dir="ltr">
                        {tools.dynamicTime({
                          date: clickedSession.start,
                          countryShort,
                          lang: window.lang,
                          hour12: true,
                        })}
                      </td>
                      <td>30Minutes</td>
                      <td>{tools.showPrice(0, currency)}</td>
                    </tr>
                  ) : (
                    selectedTimes?.map((sel, i) => (
                      <tr key={i}>
                        <td>
                          <button
                            type="button"
                            className="deleteContact btn text-pink-orange-dark btn-xs px-0"
                            title="Delete"
                            onClick={() =>
                              setSelectedTimes((prevTimes) =>
                                prevTimes.filter((time, index) => index !== i)
                              )
                            }
                          >
                            <i
                              className="bi bi-x-circle-fill p-0"
                              style={{ fontSize: "1.5em" }}
                            ></i>
                          </button>
                        </td>
                        <td>
                          {tools.dynamicDate({
                            date: sel.start,
                            countryShort,
                            lang: window.lang,
                          })}
                        </td>
                        <td dir="ltr">
                          {tools.dynamicTime({
                            date: sel.start,
                            countryShort,
                            lang: window.lang,
                            hour12: true,
                          })}
                        </td>
                        {/* <td>
                          {new Date(sel.end)?.toLocaleTimeString("en-US", {
                            timeZone,
                            hour12: false,
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </td> */}
                        <td>{sel.duration} Hour(s)</td>
                        <td>
                          {tools.showPrice(
                            Math.ceil(
                              sel.duration *
                                Math.ceil(
                                  data?.moreInfo?.salary?.rate /
                                    (matchedCurr?.exRate ??
                                      data?.moreInfo?.salary?.exRate)
                                ) *
                                exRate
                            ),
                            currency
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                  {!freeSession && (
                    <tr className="bg-greenGray text-beigeYellow">
                      <td colSpan="3" style={{ textAlign: "left" }}>
                        Sum :
                      </td>
                      <td>
                        {/* <strong> {calculateTotalPrice()}</strong> */}
                        <strong>
                          {selectedTimes.reduce(
                            (a, c) => a + Number(c.duration),
                            0
                          )}
                          Hour(s)
                        </strong>
                      </td>
                      <td>
                        <strong>
                          {tools.showPrice(
                            calculateTotalPrice * exRate,
                            currency
                          )}
                        </strong>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="row">
                <div className="col-md-6 px-4">
                  <textarea
                    className="claim-box mx-auto w-100"
                    rows={3}
                    placeholder="You can comment here if you wish..."
                    onChange={(e) => {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                      setBookingComment(e.target.value);
                    }}
                  />
                  {user?.roles && !user?.email?.length && (
                    <div className="d-flex btn btn-primary save-cover-btn bg-pinkOrange border-0 w-100 mb-3 justify-content-center">
                      <div
                        type="button"
                        data-bs-dismiss="modal"
                        data-bs-target="#bookingModal"
                        onClick={(e) =>
                          window.navigateHandle(
                            `/${window.lang}/dashboard/user/profile/me`
                          )
                        }
                      >
                        Email Address
                      </div>
                      <Tooltip1 message="Session inviting link will sending to this student email address and you have not set it yet." />
                    </div>
                  )}
                  {freeSession ? (
                    <div
                      type="button"
                      className="btn btn-primary save-cover-btn bg-pinkOrange border-0 w-100"
                      // data-bs-dismiss="modal"
                      onClick={bookFreeSessionHandle}
                    >
                      Book a trial session(free)
                    </div>
                  ) : (
                    <div
                      type="button"
                      className="btn btn-primary save-cover-btn bg-pinkOrange border-0 w-100"
                      // data-bs-dismiss="modal"
                      onClick={addToCartHandle}
                    >
                      Add to cart
                    </div>
                  )}
                </div>
                <div className="col-md-6 align-items-center px-5 py-0 display-none-md">
                  {!freeSession ? (
                    <img
                      src="/img/Metademy/class-select.svg"
                      alt="claim"
                      loading="lazy"
                    />
                  ) : (
                    <img
                      src="/img/Metademy/free-session.svg"
                      alt="claim"
                      loading="lazy"
                    />
                  )}
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
          
        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
  async function actionHandler({ e, session, currentStatus, newStatus }) {
    // console.log(currentStatus, newStatus, data?._id);
    // console.log("session", session);
    // console.log("session", session);
    if (
      window.confirm(
        `Do you want to change the status to ${tools.renderTrans({
          section: "status",
          key: newStatus,
        })} ?`
      ) !== true
    )
      return;
    if (!session?.sessionId)
      return messageRef.current.showError({
        message: " It is impossible to change this session",
      });
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiDemy.changeSessionStatus({
      token: user.token,
      data: {
        access: "tutor",
        lang: window.lang,
        lcs: window.lcs,
        newStatus,
        currentStatus,
        hostId: data?._id,
        start: session.start,
        sessionId: session.sessionId,
        slot: session,
      },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });

    messageRef.current.showSuccess({
      message: `${
        newStatus === "confirmed"
          ? "Confirmation"
          : newStatus === "canceled"
          ? "cancelation"
          : newStatus === "completed"
          ? "Completion"
          : ""
      } is successful`,
    });

    const newSessions = sessions?.map((se) =>
      se._id === response.data._id ? response.data : se
    );
    setSessions(newSessions);
    // setData(newData);
  }
  async function sendLinkHandle(e, slot) {
    // e.preventDefault();
    e.stopPropagation();
    const link = inviteLink[slot.sessionId];
    const urlRegex =
      /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

    if (link.length && !urlRegex.test(link))
      return messageRef.current.showError({
        message: "Please check the invite link.",
      });

    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiDemy.sendInviteLink({
      token: user.token,
      data: { sessionId: slot.sessionId, link },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            {/* <div>مشکل در انجام عملیات.</div> */}
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: "Invitation link sent successfully",
    });
    setInviteLink({});
    document.getElementById(slot.sessionId + "textArea").value = "";
    const newSessions = sessions?.map((se) =>
      se._id === response.data._id ? response.data : se
    );
    setSessions(newSessions);
  }
  async function addToCartHandle() {
    if (serviceItems.length > 0 && checkCurrencies(curCom?.currency)) return;
    // console.log("addToCartHandle");
    // const text =
    //   clickedSession?.status === "selected"
    //     ? "این جایگاه برای ده دقیقه رزرو شده است."
    //     : clickedSession?.status === "booked"
    //     ? "متاسفانه این جایگاه فروخته شده است."
    //     : "لطفا سایر جایگاه ها را انتخاب نمائید.";
    // if(clickedSession?.status!=="available")return messageRef.current.showWarning({message:text})
    const cadValue = Math.ceil(
      data.moreInfo.salary.rate /
        (matchedCurr?.exRate ?? data?.moreInfo?.salary?.exRate)
    );
    selectedTimes.forEach(async (sel, i) => {
      const sessionInfo = {
        duration: sel.duration,
        start: sel.start,
        end: sel.end,
        desc: bookingComment,
        rate: data?.moreInfo.salary.rate,
        currency: data?.moreInfo.salary.currency,
        exRate: matchedCurr?.exRate ?? data?.moreInfo?.salary?.exRate,
        hostId: data?._id,
        hostEmail: data?.email,
        hostImg: data?.moreInfo.img,
        hostName: data?.name?.[window.lang],
        lcs: window.lcs,
        lang: window.lang,
        hostUser: data?.moreInfo.history.createdBy._id,
        timeZone,
      };
      const createdItem = {
        type: "TutorSession",
        items: [sessionInfo],
        lcs: window.lcs,
        accepted: curCom?.accepted,
        currency: curCom?.currency,
        currency_symbol: curCom?.currency_symbol,
        convertRate: 1,
        exRate: curCom?.exRate,
        price: Math.ceil(sel.duration * cadValue * curCom?.exRate),
        count: sel.duration,
        _id: sel.start, //keep this as it is for finding duplicate items in cart
        title: `${data?.name?.[window.lang]}-
        ${tools.dynamicDate({
          date: sel?.start,
          countryShort,
          lang: window.lang,
        })}
        ${tools.dynamicTime({
          date: sel?.start,
          countryShort,
          lang: window.lang,
        })}-${tools.dynamicTime({
          date: sel?.end,
          countryShort,
          lang: window.lang,
        })}
        `,
        img: data?.moreInfo.img,
        itemImg: data?.moreInfo.img,
        targetIdentifier: "SessionT",
      };
      serviceIncrement(createdItem);
    });

    setSelectedTimes([]);

    messageRef.current.showSuccess({
      message: "Session added to cart successfully.",
    });
    document.querySelector("#modal-colse-btn").click();
    document.querySelector("#sidebarRightTrigger").click();
  }
  async function bookFreeSessionHandle() {
    // console.log("bookingHandle");
    if (!window.logined(9999)) return;
    if (data?.moreInfo.requests.some((t) => t._id === user?._id))
      return messageRef.current.showWarning({
        message:
          "Your message has been sent in the past,\n Please continue in Meta chat.",
      });
    const text =
      clickedSession?.status === "selected"
        ? "This session is reserved for 10 Minutes."
        : clickedSession?.status === "booked"
        ? "This session is booked and."
        : "Plese choose another session(s).";
    if (clickedSession?.status !== "available")
      return messageRef.current.showWarning({ message: text });
    if (
      window.confirm(
        `Do you agree to book a free session with${data?.name[window.lang]} ?.`
      ) !== true
    )
      return;

    const sessionInfo = {
      timeZone,
      duration: clickedSession.duration,
      // start:new Date(tools.convertToUserTimezone({date:clickedSession.schedule.date, time: clickedSession.start , timeZone2: 'Asia/Tehran',timeZone: data?.moreInfo.timeZone})),
      start: clickedSession.start,
      end: new Date(new Date(clickedSession.start).getTime() + 30 * 60 * 1000),
      desc: bookingComment,
      rate: data?.moreInfo.salary.rate,
      currency: data?.moreInfo.salary.currency,
      exRate: matchedCurr?.exRate ?? data?.moreInfo?.salary?.exRate,
      hostId: data?._id,
      hostEmail: data?.email,
      hostImg: data?.moreInfo.img,
      hostName: data?.name?.[window.lang],
      lcs: window.lcs,
      lang: window.lang,
      hostUser: data?.moreInfo.history.createdBy._id,
    };
    const response = await apiDemy.bookSession({
      token: user.token,
      data: sessionInfo,
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    // console.log(response.data);
    setSessions((pre) => [...pre, response.data]);
    setClickedSession({});
    setFreeSession(false);
    setBookingComment("");

    messageRef.current.showSuccess({
      message:
        "Free session successfully booked, and tuor will send you the confirmation soon by email.",
    });
    document.querySelector("#modal-colse-btn").click();
    // document.querySelector("#sidebarRightTrigger").click()
  }

  function GetWeekDatesString({ data, language }) {
    const allDates = data?.map(
      (item) =>
        new Date(
          item?.slots?.[0]?.start ||
            new Date(
              tools.convertToUserTimezone({
                date: item.date,
                time: "09:00",
                timeZone,
              })
            )
        )
    );

    // Finding the earliest and latest dates
    const startDate = new Date(Math.min(...allDates));
    const endDate = new Date(Math.max(...allDates));

    // Get the locale based on the language
    // const locale = window.lang === "fa" ? "fa-IR" : "en-US";
    const la = lang?.toLowerCase();
    const locale = `${
      la === "fa" && countryShort === "ir" ? la : "en"
    }-${countryShort.toUpperCase()}`;

    // Formatting the dates based on the locale
    const startMonth = startDate.toLocaleString(locale, { month: "long" });
    const endMonth = endDate.toLocaleString(locale, { month: "long" });
    const startYear = startDate.toLocaleString(locale, { year: "numeric" });
    const endYear = endDate.toLocaleString(locale, { year: "numeric" });
    const startDay = startDate.toLocaleString(locale, { day: "numeric" });
    const endDay = endDate.toLocaleString(locale, { day: "numeric" });

    // Constructing the string
    let dateString;
    if (window.lang === "fa" && countryShort === "ir") {
      if (startMonth === endMonth && startYear === endYear) {
        dateString = `${startDay} - ${endDay} ${startMonth}, ${startYear}`;
      } else if (startYear === endYear) {
        dateString = `${startDay} ${startMonth} - ${endDay} ${endMonth}, ${startYear}`;
      } else {
        dateString = `${startDay} ${startMonth} ${startYear} -- ${endDay} ${endMonth} ${endYear}`;
      }
    } else {
      if (startMonth === endMonth && startYear === endYear) {
        dateString = `${startMonth} ${startDay}-${endDay}, ${startYear}`;
      } else if (startYear === endYear) {
        dateString = `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${startYear}`;
      } else {
        dateString = `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
      }
    }

    return <div id={"WeekDatesString" + weeks}>{dateString}</div>;
  }

  function selectHandler(e, slot) {
    if (isTutor) return;
    if (freeSession) {
      if (slot.status !== "available")
        return messageRef.current.showError({
          message:
            slot.status !== "selected"
              ? "This session is reserved for 15 minutes"
              : "This session is already booked.",
        });

      setClickedSession(slot);

      var myModal = document.getElementById("bookingModal"); // Get the modal element
      var modal = new window.bootstrap.Modal(myModal, {
        // backdrop: "static",
        keyboard: true,
      });
      modal.show();
    } else {
      const matchIndex = selectedTimes.findIndex((item) => {
        return item.start === slot.start.toISOString();
      });
      if (
        user._id === data?.moreInfo.history.createdBy._id ||
        slot.status === "canceled"
      )
        return messageRef.current.showError({
          message: "this session can't be booked.",
        });

      setSelectedTimes((pre) => {
        let selected = [...pre];
        if (matchIndex === -1) {
          selected.push({
            ...slot,
            start: slot.start.toISOString(),
            end: slot.end.toISOString(),
          });
        } else {
          selected.splice(matchIndex, 1);
        }

        return selected;
      });
    }
  }
  function checkCurrencies(currency) {
    let multiCur = serviceItems.some((item) => item?.currency !== currency);
    const message =
      "Your current currncy is not matched with the other items in your cart,\n to remove prevoius items(Ok)\n return to previous country(Cancel)";
    // "واحد پول خرید فعلی شما با آیتم های داخل سبد همخوانی ندارد،\n حذف آیتم ها قبلی (OK). ،\n بازگشت به کشور قبل(Cancel).";
    if (multiCur) {
      if (window.confirm(message)) {
        cartEmptier();
        multiCur = false;
      } else {
        window.open(
          `/${window.lang}/demy/tutor/page/${tools.slugMaker({
            title: data?.name?.[window.lang],
            city: data?.moreInfo?.address?.city,
            state: data?.moreInfo?.address?.state,
            serial: data?.serial,
          })}`,
          {
            new: true,
          }
        );
        multiCur = true;
      }
    }
    return multiCur;
  }
}
