import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { CartContext } from "../../../../contexts/cartContext";

export default function UserDashNavbar() {
const {user}=useContext(CartContext)
  const trans={   
    myProfile:{fa:"پروفایل من",en:"My Profile"},
    authorProfile:{fa:"پروفایل نویسندگی",en:"Author Profile"},
   
   
  }
  return (
    <div className="dashboardNavigator d-flex">
      <NavLink
        to={`/${window.lang}/dashboard/user/profile/me`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {trans.myProfile[window.lang]}
       
      </NavLink>
      {(window.lang==="fa"||user?.roles?.includes(1000))&&<NavLink
        to={`/${window.lang}/dashboard/user/profile/author`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
       {trans.authorProfile[window.lang]}
       
      </NavLink>}
      {/* <NavLink to={`/${window.lang}/dashboard/user/profile/marketer`} className={`sidebarListItem m-1 link  ${({isActive})=>{return isActive && "active" }}`}> پروفایل بازاریابی</NavLink> */}
      {/* <NavLink to={`/${window.lang}/dashboard/user/profile/affiliate`} className={`sidebarListItem m-1 link  ${({isActive})=>{return isActive && "active" }}`}> ریفرال کد</NavLink> */}
    </div>
  );
}
